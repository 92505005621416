// Third party libraries
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import { useHistory, Prompt } from 'react-router-dom';
import { isEqual } from 'lodash';
import { connectSocket, disconnectSocket } from '../../context/socket';
import * as immutable from 'object-path-immutable';
// Components
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
// Util
import { fetchedData, replaceTagsByValues } from '../../util/utils';

import { FormContainer } from '../form-container/Form-container';
import { BtnSubmit } from '../btn-submit/Btn-submit';
// Local State Functions
import {
  getDepartment,
  getDepartmentLoadingStatus,
} from '../../clientStore/departmentSlice/department-slice';
import { loadDepartment } from '../../clientStore/departmentSlice/department-async-thunk';
import { loadDepartments } from '../../clientStore/departmentsSlice/departments-async-thunk';
import { loadLongDistances } from '../../clientStore/longdistancesSlice/longdistances-async-thunk';
import { loadOutOfStates } from '../../clientStore/outofstatesSlice/outofstates-async-thunk';
import { getDepartments, getProcessDepartmentsLoading } from '../../clientStore/departmentsSlice/departments-slice';
import { getLongDistances, getProcessLongDistancesLoading } from '../../clientStore/longdistancesSlice/longdistances-slice';
import { getOutOfStates, getProcessOutOfStatesLoading } from '../../clientStore/outofstatesSlice/outofstates-slice';
import {
  saveTemplate,
  updateTemplate,
} from '../../clientStore/templateSlice/template-async-thunk';
import { getUserId, getCompanyId } from '../../clientStore/authSlice/auth-slice';
import {
  setTemplatesLoadingStatus,
} from '../../clientStore/templatesSlice/templates-slice';
import {
  getProcessUsersAllLoading,
  getUsersAll,
  getProcessUsersLoading,
} from '../../clientStore/usersSlice/user-slice';
import { setUsersLoadingProcess } from '../../clientStore/usersSlice/user-slice';

import {
  getTemplateSavingStatus,
  setTemplateSavingStatus,
  setTemplateLoadingStatus,
  setTemplateRemovingStatus
} from '../../clientStore/templateSlice/template-slice';
import { getCompany } from '../../clientStore/companySlice/company-slice';
import { loadCompany } from '../../clientStore/companySlice/company-async-thunk';
// Data model
import { templateDataModel } from '../../models/template.js';
import { loadUsers, loadUsersAll } from '../../clientStore/usersSlice/users-async-thunk';

// Styles
import styles from './TemplateForm.module.css';

import { TemplateColumn } from '../template-column/Template-column.jsx';
import { TemplateTagsColumn } from '../template-tags-column/Template-tags-column.jsx';
import { TemplateParamsColumn } from '../template-params-column/Template-params-column.jsx';
import { isTemplateFormValid } from '../../util/formValidations';

export const TemplateForm = ({ templateData, formType = 'new', templateId = undefined, type }) => {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const dispatch = useDispatch();
  const [highLightItem, setHighLiteItem] = useState('');
  const savedStatus = useSelector(getTemplateSavingStatus);
  const userId = useSelector(getUserId);
  const company = useSelector(getCompany);
  const companyId = useSelector(getCompanyId);
  const history = useHistory();
  const template = templateData ? templateData : templateDataModel;
  const users = useSelector(getUsersAll);
  const departments = useSelector(getDepartments);
  const longdistances = useSelector(getLongDistances);
  const outOfStates = useSelector(getOutOfStates);
  const loadingProcess = useSelector(getProcessUsersLoading);
  const loadingProcessDep = useSelector(getProcessDepartmentsLoading);
  const departmentLoadingStatus = useSelector(getDepartmentLoadingStatus);
  const [unpRates, setUnpRates] = useState([]);
  const loadingLongDistancesStatus = useSelector(getProcessLongDistancesLoading);
  const loadingOutOfStatesStatus = useSelector(getProcessOutOfStatesLoading);
  const loadingUsersStatus = useSelector(getProcessUsersAllLoading);
  const [updateableSave, setUpdateableSave] = useState(false);
  const [calculationPopUpShown, setCalculationPopUpShown] = useState(false);
  const [rates, setRates] = useState([]);
  const [factors, setFactors] = useState([]);
  const [lastTarget, setLastTarget] = useState(undefined);


  const [furniture, setFurniture] = useState([]);//can be loaded from db
  const [roomsAreas, setRoomsAreas] = useState([]);//can be loaded from db

  const [removeTag, setRemoveTag] = useState(false);
  const [filters, setFilters] = useState(
    {
      'search': {
        'field': '',
        'searchText': '',
      },
      'sort': {
        'field': 'createdAt',
        'order': 1
      },
      'filter': {
        'field': ''
      }
    }
  );

  const [departmentsFilters, setDepartmentsFilters] = useState(
    {
      'search': {
        'field': '',
        'searchText': '',
      },
      'sort': {
        'field': 'createdAt',
        'order': 1
      },
      'filter': {
        'field': 'type',
        'value': template.type,
      }
    }
  );

  useEffect(() => {
    if (loadingProcess === 'idle') {
      dispatch(loadUsers({ 'companyId': companyId, 'filters': filters }));
    }
    if (loadingProcessDep === 'idle') {
      dispatch(loadDepartments({ 'filters': departmentsFilters }));
    }
    if (loadingUsersStatus === 'idle') {
      dispatch(loadUsersAll({ 'companyId': companyId }))
    }
    if (loadingLongDistancesStatus === 'idle') {
      dispatch(loadLongDistances());
    }
    if (loadingOutOfStatesStatus === 'idle') {
      dispatch(loadOutOfStates());
    }
    return () => {

    }
  }, [dispatch, companyId, filters, loadingProcess]);

  useEffect(() => {
    if (departmentLoadingStatus === 'idle') {
      if (departments && departments.length) {
        dispatch(loadDepartment(departments[0]._id));
      }
    }
  }, [dispatch, departments, departmentLoadingStatus]);


  const [templateForm, setTemplateFormValue] = useState({
    ...template,
    userId: userId,
    companyId: companyId,
    version: template.version + 1,
  });

  const unChangedTemplateForm = {
    ...template,
    userId: userId,
    companyId: companyId,
    version: template.version + 1,
  }

  const department = (templateForm && templateForm.templateCalcObj &&
    Object.keys(templateForm.templateCalcObj).length) ? templateForm.templateCalcObj.department : {};
  const longDistance = (templateForm && templateForm.templateCalcObj &&
    Object.keys(templateForm.templateCalcObj).length) ? templateForm.templateCalcObj.longDistance : {};
  useEffect(() => {
    if (removeTag) {
      formSubmit(null, false);
    }
  }, [removeTag, templateForm]);

  useEffect(() => {
    dispatch(loadCompany(companyId));
    if (savedStatus === 'successed' && updateableSave) {
      dispatch(setTemplateSavingStatus({ status: 'idle' }));
      dispatch(setTemplateLoadingStatus({ status: 'idle' }));
      dispatch(setTemplatesLoadingStatus({ status: 'idle' }));
      dispatch(setTemplateRemovingStatus({ status: 'idle' }));
      setIsFormDirty(false)
      window.location.reload();
    }
  }, [dispatch, history, savedStatus, companyId]);

  const onFormValueChange = (path, method, value) => {
    setTemplateFormValue(immutable[method](templateForm, path, value));
  };

  const setCalculationFormValue = (calcState) => {
    setTemplateFormValue(immutable.set(templateForm, 'templateCalcObj', calcState));
  };

  const resetLastTarget = () => {
    if (lastTarget) {
      setLastTarget(undefined);
    }
  }

  const onCalcFormValueChange = (name, method, val) => {
    onFormValueChange(`templateCalcObj.${name}`, method, val)
  }

  const formSubmit = (evt, refreshPage) => {
    if (evt) {
      evt.preventDefault();
    }
    setIsFormDirty(false)
    if (isTemplateFormValid(templateForm)) {
      dispatch(updateTemplate(templateForm, templateId));
      if (refreshPage) {
        setUpdateableSave(true)
        setTemplateFormValue({
          ...templateDataModel,
          userId: userId,
        });
      }
    }
    else {
      alert('Please check all values. There is something invalid')
    }
  };

  const onChangeLongDistanceSelect = async (evt) => {
    if (evt.target.value === 'None') {
      onCalcFormValueChange('longDistance', 'del');
      // setTemplateFormValue({ ...templateForm, templateCalcObj: { ...templateForm, templateCalcObj: undefined } })
      return;
    }
    const selectedLongDistance = await fetchedData('longdistance/getLongDistance', 'POST', { id: evt.target.value });
    if (selectedLongDistance.status === 200) {
      onCalcFormValueChange('longDistance', 'set', { ...selectedLongDistance.data });
    }
  }

  const onChangeOutOfStateSelect = async (evt) => {
    if (evt.target.value === 'None') {
      onCalcFormValueChange('outOfState', 'del');
      // setTemplateFormValue({ ...templateForm, templateCalcObj: { ...templateForm, templateCalcObj: undefined } })
      return;
    }
    const selectedOutOfState = await fetchedData('outOfState/getOutOfState', 'POST', { id: evt.target.value });
    console.log('selectedOutOfState', evt.target.value, selectedOutOfState)
    if (selectedOutOfState.status === 200) {
      onCalcFormValueChange('outOfState', 'set', { ...selectedOutOfState.data });
    }
  }

  const onChangeUnpDepartmentSelect = async (evt) => {
    if (evt.target.value === 'None') {
      onCalcFormValueChange('unpDepartment', 'del');
    }
    const selectedDepartment = await fetchedData('department/getDepartment', 'POST', { id: evt.target.value });
    if (selectedDepartment.status === 200) {
      onCalcFormValueChange('unpDepartment', 'set', { ...selectedDepartment.data });
      setUnpRates(selectedDepartment.data.rates.ratesList);
    }

    /*   if (evt.target.value === 'None') {
        onFormValueChange('unpDepartment', 'del');
        setCalculationFormValue({ ...calculationForm, unpDepartment: undefined });
        return;
      }
      const selectedDepartment = await fetchedData('department/getDepartment', 'POST', { id: evt.target.value });
      if (selectedDepartment.status === 200) {
        setCalculationFormValue(immutable.set(calculationForm, 'unpDepartment', { ...selectedDepartment.data }));
       
      } */
  }

  const [updateTempState, setUpdateTempState] = useState(false);
  const [tagsResultsTexts, setTagsResultsTextsState] = useState({});
  const setTagsResultsTexts = (data) => {
    setTagsResultsTextsState((prevTagsResultsTexts) => {
      return { ...prevTagsResultsTexts, ...data };
    });
  }
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setIsFormDirty((savedStatus !== 'successed' && savedStatus !== 'loading') && !isEqual(templateForm, unChangedTemplateForm))
  }, [templateForm, savedStatus]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isFormDirty) {
        e.preventDefault();
        e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
        disconnectSocket()
        connectSocket()
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);


  return (
    <FormContainer>
      <Prompt
        when={isFormDirty}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <form className={styles.form}>
        <div className={styles.gridItem}>
          <TemplateTagsColumn
            templateForm={templateForm}
            users={users}
            setHighLiteItem={setHighLiteItem}
            changeTag={onFormValueChange}
            departments={departments}
            company={company}
            setTemplateFormValue={setTemplateFormValue}
            setUpdateTempState={setUpdateTempState}
            tagsResultsTexts={tagsResultsTexts}
            department={department}
            saveBtnEvent={formSubmit}
            setRemoveTag={setRemoveTag}
            calculationPopUpShown={calculationPopUpShown}
            setCalculationPopUpShown={setCalculationPopUpShown}
            setRates={setRates}
            resetLastTarget={resetLastTarget}
            lastTarget={lastTarget}
            setLastTarget={setLastTarget}
            onCalcFormValueChange={onCalcFormValueChange}
            options={options}
          />
        </div>
        <div className={styles.gridItem}>
          <TemplateColumn
            templateForm={templateForm}
            onFormValueChange={onFormValueChange}
            updateTempState={updateTempState}
            setUpdateTempState={setUpdateTempState}
            company={company}
            setTagsResultsTexts={setTagsResultsTexts}
            tagsResultsTexts={tagsResultsTexts}
            department={department}
            saveBtnEvent={formSubmit}
            furniture={furniture}
            roomsAreas={roomsAreas}
          />
        </div>
        <div className={`${styles.gridItem} ${styles.scaleColumn}`}>
          <TemplateParamsColumn
            templateForm={templateForm}
            highLightItem={highLightItem}
            template={template}
            company={company}
            department={department}
            rates={rates}
            onCalcFormValueChange={onCalcFormValueChange}
            resetLastTarget={resetLastTarget}
            setOptions={setOptions}
            users={users}
            departments={departments}
            longdistances={longdistances}
            outOfStates={outOfStates}
            onChangeOutOfStateSelect={onChangeOutOfStateSelect}
            onChangeLongDistanceSelect={onChangeLongDistanceSelect}
            onChangeUnpDepartmentSelect={onChangeUnpDepartmentSelect}
            unpRates={unpRates}
            longDistance={longDistance}
            factors={factors}
            lastTarget={lastTarget}
            setLastTarget={setLastTarget}
            setCalculationFormValue={setCalculationFormValue}
     
          />
        </div>
      </form>
    </FormContainer>
  );
};
