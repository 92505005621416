import React, { useState, useContext, useCallback, useEffect } from 'react';
import useCollapse from 'react-collapsed'
import { SocketContext } from '../../context/socket';
// Calc util functions
import { generateNotIncludedServicesText, generateUnpackingText, getAdditionalServicesResText, getChargesResText, getCheckMarkLabelById, getCurrentRatePercent, getFormattedRateInfoTwoDays, getFormattedRateInfoTwoDaysDay2, getFormattedRateLoadInfoTwoDays, getFurnitureText, getHigherNumberOfHours, getLowerNumberOfHours, getTagsValues, getTotalTimeRange, replaceOptionsTagsByValues, replaceTagsByValues } from '../../util/utils';
// Styles
import styles from "./CalculationTwoDaysParams.module.css";
//Components
import { CalculationSeparateDayView } from '../calculation-separate-day-view/Calculation-separate-day-view';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import CopyIcon from '../results-copy-icon/Results-copy-icon';
import { ExtraOptionsResults } from '../extra-options-results/Extra-options-results';
import { AdditionalServicesResults } from '../additional-services-results/Additional-services-results';
import { setTWOTripsOrOK_Day2, setTotalFragileBoxes, setTotalMediumBoxes, setTotalSmallBoxes, setTotal_cubesG } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { useDispatch } from 'react-redux';
import { AdditionalServicesEstimateInfo } from '../additional-services-estimate-info/Additional-services-estimate-info';
import TruckloadsView from '../truckloads/TruckloadsView';
import PackingSuppliesOnTheGo from '../packingSuppliesOnTheGo/Packing-supplies-on-the-go';

export const CalculationTwoDaysParams = ({
  commonValues,
  department,
  twoDaysPacking,
  selectedMiscCheckMarks,
  checkboxValueChange,
  editorState,
  calculationData,
  tags,
  setPreviewText, setOptionsPreviewText,
  popUpShown,
  setShowGenerateEmail, furniture, roomsAreas,
  scrollRefs,
  highLightItem, setTagsValuesDefault, customTagsValues, showCustomTags
}) => {
  const dispatch = useDispatch();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const [Total_cubes, setTotal_cubes] = useState(0);
  const [Total_small_boxes, setTotal_small_boxes] = useState(0);
  const [Total_medium_boxes, setTotal_medium_boxes] = useState(0)
  const [Total_fragile_boxes, setTotal_fragile_boxes] = useState(0)
  const [Total_box_cubes, setTotal_box_cubes] = useState(0)
  const [Truckloads, setTruckloads] = useState(0);
  const [Stop_CN_Truckload, setStop_CN_Truckload] = useState(0);
  const [Stop_1_Truckload, setStop_1_Truckload] = useState(0);
  const [Double_drive, setDouble_drive] = useState(0);
  const [Heavy_fee, setHeavy_fee] = useState(0);
  const [Packing_kit_Show, setPacking_kit_Show] = useState(0);
  const [Packing_kit_total_amount_with_taxes, setPacking_kit_total_amount_with_taxes] = useState(0);
  const [Movers_To_Pack_Day1, setMovers_To_Pack_Day1] = useState(0);
  const [Trucks_To_Pack_Day1, setTrucks_To_Pack_Day1] = useState(0);
  const [Driving_Time_Day1, setDriving_Time_Day1] = useState(0);
  const [Unloading_hours_Day1, setUnloading_hours_Day1] = useState(0);
  const [Loading_hours_Day_1, setLoading_hours_Day_1] = useState(0);
  const [Total_Hours_Day_1, setTotal_Hours_Day_1] = useState(0);
  const [Packing_Time_Day1, setPacking_Time_Day1] = useState(0);
  const [Wrapping_time_Day1, setWrapping_time_Day1] = useState(0);
  const [Adjusted_loading_time_Day2, setAdjusted_loading_time_Day2] = useState(0);
  const [Lower_Number_of_Hours_Day1, setLower_Number_of_Hours_Day1] = useState(0);
  const [Higher_Number_of_Hours_Day1, setHigher_Number_of_Hours_Day1] = useState(0);
  const [TWO_Trips_Or_OK_Day2, setTWO_Trips_Or_OK_Day2] = useState(0);
  const [Lower_Number_of_Hours_Day2, setLower_Number_of_Hours_Day2] = useState(0);
  const [Higher_Number_of_Hours_Day2, setHigher_Number_of_Hours_Day2] = useState(0);
  const [Total_Cash_Day2, setTotal_Cash_Day2] = useState(0);
  const [Total_Card_Day2, setTotal_Card_Day2] = useState(0);
  const [Loading_hours_Day2, setLoading_hours_Day2] = useState(0);
  const [Movers_Day2, setMovers_Day2] = useState(0);
  const [Rate_cash_Movers_To_Unload_Day2, setRate_cash_Movers_To_Unload_Day2] = useState(0);
  const [Rate_card_Movers_To_Unload_Day2, setRate_card_Movers_To_Unload_Day2] = useState(0);
  const [Total_Destinationfee_Day2, setTotal_Destinationfee_Day2] = useState(0);
  const [Rate_cash_Movers_To_Load_Day2, setRate_cash_Movers_To_Load_Day2] = useState(0);
  const [Rate_card_Movers_To_Load_Day2, setRate_card_Movers_To_Load_Day2] = useState(0);
  const [Truck_fee_Day2, setTruck_fee_Day2] = useState(0);
  const [Rate_cash_Day2, setRate_cash_Day2] = useState(0);
  const [Rate_card_Day2, setRate_card_Day2] = useState(0);
  const [Rate_cash_Day1, setRate_cash_Day1] = useState(0);
  const [Rate_card_Day1, setRate_card_Day1] = useState(0);
  const [Truck_fee_Day1, setTruck_fee_Day1] = useState(0);
  const [Trucks_Day2, setTrucks_Day2] = useState(0);
  const [Unloading_hours_Day2, setUnloading_hours_Day2] = useState(0);
  const [Total_Hours_Day2, setTotal_Hours_Day2] = useState(0);
  const [Drivetime_Day2, setDrivetime_Day2] = useState(0);
  const [cubicf_Day1, setcubicf_Day1] = useState(0);
  const [Total_Cash_Day1, setTotal_Cash_Day1] = useState(0);
  const [Total_Card_Day1, setTotal_Card_Day1] = useState(0);
  const [Total_Card_2Days_Packing, setTotal_Card_2Days_Packing] = useState(0);
  const [Total_Cash_2Days_Packing, setTotal_Cash_2Days_Packing] = useState(0);
  const [BIGGEST_or_OK_Day2, setBIGGEST_or_OK_Day2] = useState('');
  const [Trucks_Unpacking_Separate_Day, setTrucks_Unpacking_Separate_Day] = useState(0)
  const [Movers_Unpacking_Separate_Day, setMovers_Unpacking_Separate_Day] = useState(0)
  const [Unpacking_Separate_Day_Lower_hours, setUnpacking_Separate_Day_Lower_hours] = useState(0)
  const [Unpacking_Separate_Day_Higher_hours, setUnpacking_Separate_Day_Higher_hours] = useState(0)
  const [Total_Card_Unpacking_Separate_Day, setTotal_Card_Unpacking_Separate_Day] = useState(0)
  const [Total_Cash_Unpacking_Separate_Day, setTotal_Cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_cash_Unpacking_Separate_Day, setRate_cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_card_Unpacking_Separate_Day, setRate_card_Unpacking_Separate_Day] = useState(0)
  const [Truck_fee_Unpacking_Separate_Day, setTruck_fee_Unpacking_Separate_Day] = useState(0)
  const [Flat_rate_Unpacking_Separate_Day, setFlat_rate_Unpacking_Separate_Day] = useState(0)
  const [Combined_mileage, setCombined_mileage] = useState(0)
  const [Time_to_pack, setTime_to_pack] = useState(0);
  const [UnPacking_Charge, setUnPacking_Charge] = useState(0)
  const [Packing_Charge, setPacking_Charge] = useState(0)
  const [Total_cubes_Within_Building, setTotal_cubes_Within_Building] = useState(0)
  const [Round_trip_miles, setRound_trip_miles] = useState(0);
  const [Truckloads_to_move, setTruckloads_to_move] = useState(0)

  const [Flat_rate_Card_2Days_Packing, setFlat_rate_Card_2Days_Packing] = useState(0)
  const [Flat_rate_Cash_2Days_Packing, setFlat_rate_Cash_2Days_Packing] = useState(0)
  const [Flat_rate_Unpacking_Separate_Day_cash, setFlat_rate_Unpacking_Separate_Day_cash] = useState(0)


  const isCuFtDepartment = department?.type === 'cuft';

  const socket = useContext(SocketContext);

  useEffect(() => {
    socket.emit('getFormulasValues', { calculationType: 'twoDaysPacking', commonValues: commonValues, twoDaysPacking: twoDaysPacking, department: department })

    socket.on('Total_cubes', (data) => { setTotal_cubes(data); dispatch(setTotal_cubesG(data)); });
    socket.on('Total_small_boxes', (data) => {
      setTotal_small_boxes(data);
      dispatch(setTotalSmallBoxes(data));
    });
    socket.on('Total_medium_boxes', (data) => {
      setTotal_medium_boxes(data)
      dispatch(setTotalMediumBoxes(data));
    });
    socket.on('Total_fragile_boxes', (data) => {
      dispatch(setTotalFragileBoxes(data));
      setTotal_fragile_boxes(data)
    });
    socket.on('Total_box_cubes', (data) => {
      setTotal_box_cubes(data)
    });
    socket.on('Truckloads', (data) => {
      setTruckloads(data)
    });
    socket.on('Stop_CN_Truckload', (data) => {
      setStop_CN_Truckload(data)
    });
    socket.on('Stop_1_Truckload', (data) => {
      setStop_1_Truckload(data)
    });
    socket.on('Double_drive', (data) => {
      setDouble_drive(data)
    });
    socket.on('Heavy_fee', (data) => {
      setHeavy_fee(data)
    });
    socket.on('Packing_kit_Show', (data) => {
      setPacking_kit_Show(data)
    });
    socket.on('Packing_kit_total_amount_with_taxes', (data) => {
      setPacking_kit_total_amount_with_taxes(data)
    });
    socket.on('Movers_To_Pack_Day1', (data) => {
      setMovers_To_Pack_Day1(data)
    });
    socket.on('Trucks_To_Pack_Day1', (data) => {
      setTrucks_To_Pack_Day1(data)
    });
    socket.on('Driving_Time_Day1', (data) => {
      setDriving_Time_Day1(data)
    });
    socket.on('Unloading_hours_Day1', (data) => {
      setUnloading_hours_Day1(data)
    });
    socket.on('Loading_hours_Day_1', (data) => {
      setLoading_hours_Day_1(data)
    });
    socket.on('Total_Hours_Day_1', (data) => {
      setTotal_Hours_Day_1(data)
    });
    socket.on('Packing_Time_Day1', (data) => {
      setPacking_Time_Day1(data)
    });
    socket.on('Wrapping_time_Day1', (data) => {
      setWrapping_time_Day1(data)
    });
    socket.on('Adjusted_loading_time_Day2', (data) => {
      setAdjusted_loading_time_Day2(data)
    });
    socket.on('Lower_Number_of_Hours_Day1', (data) => {
      setLower_Number_of_Hours_Day1(data)
    });
    socket.on('Higher_Number_of_Hours_Day1', (data) => {
      setHigher_Number_of_Hours_Day1(data)
    });
    socket.on('TWO_Trips_Or_OK_Day2', (data) => {
      setTWO_Trips_Or_OK_Day2(data)
      dispatch(setTWOTripsOrOK_Day2(data));
    });
    socket.on('Lower_Number_of_Hours_Day2', (data) => {
      setLower_Number_of_Hours_Day2(data)
    });
    socket.on('Higher_Number_of_Hours_Day2', (data) => {
      setHigher_Number_of_Hours_Day2(data)
    });
    socket.on('Total_Cash_Day2', (data) => {
      setTotal_Cash_Day2(data)
    });
    socket.on('Total_Card_Day2', (data) => {
      setTotal_Card_Day2(data)
    });
    socket.on('Loading_hours_Day2', (data) => {
      setLoading_hours_Day2(data)
    });
    socket.on('Movers_Day2', (data) => {
      setMovers_Day2(data)
    });
    socket.on('Rate_cash_Movers_To_Unload_Day2', (data) => {
      setRate_cash_Movers_To_Unload_Day2(data)
    });
    socket.on('Rate_card_Movers_To_Unload_Day2', (data) => {
      setRate_card_Movers_To_Unload_Day2(data)
    });
    socket.on('Total_Destinationfee_Day2', (data) => {
      setTotal_Destinationfee_Day2(data)
    });
    socket.on('Rate_cash_Movers_To_Load_Day2', (data) => {
      setRate_cash_Movers_To_Load_Day2(data)
    });
    socket.on('Rate_card_Movers_To_Load_Day2', (data) => {
      setRate_card_Movers_To_Load_Day2(data)
    });
    socket.on('Truck_fee_Day2', (data) => {
      setTruck_fee_Day2(data)
    });
    socket.on('Rate_cash_Day2', (data) => {
      setRate_cash_Day2(data)
    });
    socket.on('Rate_card_Day2', (data) => {
      setRate_card_Day2(data)
    });
    socket.on('Rate_cash_Day1', (data) => {
      setRate_cash_Day1(data)
    });
    socket.on('Rate_card_Day1', (data) => {
      setRate_card_Day1(data)
    });
    socket.on('Truck_fee_Day1', (data) => {
      setTruck_fee_Day1(data)
    });
    socket.on('Trucks_Day2', (data) => {
      setTrucks_Day2(data)
    });
    socket.on('Unloading_hours_Day2', (data) => {
      setUnloading_hours_Day2(data)
    });
    socket.on('Total_Hours_Day2', (data) => {
      setTotal_Hours_Day2(data)
    });
    socket.on('Drivetime_Day2', (data) => {
      setDrivetime_Day2(data)
    });
    socket.on('cubicf_Day1', (data) => {
      setcubicf_Day1(data)
    });
    socket.on('Total_Cash_Day1', (data) => {
      setTotal_Cash_Day1(data)
    });
    socket.on('Total_Card_Day1', (data) => {
      setTotal_Card_Day1(data)
    });
    socket.on('Total_Card_2Days_Packing', (data) => {
      setTotal_Card_2Days_Packing(data)
    });
    socket.on('Total_Cash_2Days_Packing', (data) => {
      setTotal_Cash_2Days_Packing(data)
    });
    socket.on('BIGGEST_or_OK_Day2', (data) => {
      setBIGGEST_or_OK_Day2(data)
    });

    socket.on('Truckloads_to_move', (data) => {
      setTruckloads_to_move(data)
    });

    socket.on('Flat_rate_Card_2Days_Packing', (data) => setFlat_rate_Card_2Days_Packing(data));
    socket.on('Flat_rate_Cash_2Days_Packing', (data) => setFlat_rate_Cash_2Days_Packing(data));
    socket.on('UnPacking_Charge', (data) => setUnPacking_Charge(data));
    socket.on('Packing_Charge', (data) => setPacking_Charge(data));

    socket.on('Trucks_Unpacking_Separate_Day', (data) => setTrucks_Unpacking_Separate_Day(data));
    socket.on('Movers_Unpacking_Separate_Day', (data) => setMovers_Unpacking_Separate_Day(data));
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => setUnpacking_Separate_Day_Lower_hours(data));
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => setUnpacking_Separate_Day_Higher_hours(data));
    socket.on('Total_Card_Unpacking_Separate_Day', (data) => setTotal_Card_Unpacking_Separate_Day(data));
    socket.on('Total_Cash_Unpacking_Separate_Day', (data) => setTotal_Cash_Unpacking_Separate_Day(data));
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => setRate_cash_Unpacking_Separate_Day(data));
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => setRate_card_Unpacking_Separate_Day(data));
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => setTruck_fee_Unpacking_Separate_Day(data));
    socket.on('Flat_rate_Unpacking_Separate_Day', (data) => setFlat_rate_Unpacking_Separate_Day(data));
    socket.on('Flat_rate_Unpacking_Separate_Day_cash', (data) => setFlat_rate_Unpacking_Separate_Day_cash(data));
    socket.on('Total_cubes_Within_Building', (data) => setTotal_cubes_Within_Building(data))
    socket.on('Round_trip_miles', (data) => setRound_trip_miles(data))

    socket.on('Combined_mileage', (data) => { setCombined_mileage(data) });
    socket.on('Time_to_pack', (data) => setTime_to_pack(data));
  }, [commonValues, twoDaysPacking, department])

  useEffect(() => {
    if (editorState) {
      let templateText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      let tagValues = getTagsValues({
        calculation: calculationData, tags,
        calculatedParams: {
          Total_cubes: Total_cubes,
          Total_small_boxes: Total_small_boxes,
          Total_medium_boxes: Total_medium_boxes,
          Total_fragile_boxes: Total_fragile_boxes,
          Total_box_cubes: Total_box_cubes,
          Truckloads: Truckloads,
          Truckloads_to_move: Truckloads_to_move,
          Total_cubes_Within_Building: Total_cubes_Within_Building,
          Stop_CN_Truckload: Stop_CN_Truckload,
          Stop_1_Truckload: Stop_1_Truckload,
          Double_drive: Double_drive,
          Heavy_fee: Heavy_fee,
          Packing_kit_Show: Packing_kit_Show,
          Packing_kit_total_amount_with_taxes: Packing_kit_total_amount_with_taxes,
          Movers_To_Pack_Day1: Movers_To_Pack_Day1,
          Trucks_To_Pack_Day1: Trucks_To_Pack_Day1,
          Driving_Time_Day1: Driving_Time_Day1,
          Unloading_hours_Day1: Unloading_hours_Day1,
          Loading_hours_Day_1: Loading_hours_Day_1,
          Total_Hours_Day_1: Total_Hours_Day_1,
          Packing_Time_Day1: Packing_Time_Day1,
          Wrapping_time_Day1: Wrapping_time_Day1,
          Adjusted_loading_time_Day2: Adjusted_loading_time_Day2,
          Lower_Number_of_Hours_Day1: Lower_Number_of_Hours_Day1,
          Higher_Number_of_Hours_Day1: Higher_Number_of_Hours_Day1,
          TWO_Trips_Or_OK_Day2: TWO_Trips_Or_OK_Day2,
          Lower_Number_of_Hours_Day2: Lower_Number_of_Hours_Day2,
          Higher_Number_of_Hours_Day2: Higher_Number_of_Hours_Day2,
          Total_Cash_Day2: Total_Cash_Day2,
          Total_Card_Day2: Total_Card_Day2,
          Loading_hours_Day2: Loading_hours_Day2,
          Movers_Day2: Movers_Day2,
          Rate_cash_Movers_To_Unload_Day2: Rate_cash_Movers_To_Unload_Day2,
          Rate_card_Movers_To_Unload_Day2: Rate_card_Movers_To_Unload_Day2,
          Total_Destinationfee_Day2: Total_Destinationfee_Day2,
          Rate_cash_Movers_To_Load_Day2: Rate_cash_Movers_To_Load_Day2,
          Rate_card_Movers_To_Load_Day2: Rate_card_Movers_To_Load_Day2,
          Truck_fee_Day2: Truck_fee_Day2,
          Rate_cash_Day2: Rate_cash_Day2,
          Rate_card_Day2: Rate_card_Day2,
          Rate_cash_Day1: Rate_cash_Day1,
          Rate_card_Day1: Rate_card_Day1,
          Truck_fee_Day1: Truck_fee_Day1,
          Trucks_Day2: Trucks_Day2,
          Unloading_hours_Day2: Unloading_hours_Day2,
          Total_Hours_Day2: Total_Hours_Day2,
          Drivetime_Day2: Drivetime_Day2,
          cubicf_Day1: cubicf_Day1,
          Total_Cash_Day1: Total_Cash_Day1,
          Total_Card_Day1: Total_Card_Day1,
          Total_Card_2Days_Packing: Total_Card_2Days_Packing,
          Total_Cash_2Days_Packing: Total_Cash_2Days_Packing,
          BIGGEST_or_OK_Day2: BIGGEST_or_OK_Day2,
          Trucks_Unpacking_Separate_Day: Trucks_Unpacking_Separate_Day,
          Movers_Unpacking_Separate_Day: Movers_Unpacking_Separate_Day,
          Unpacking_Separate_Day_Lower_hours: Unpacking_Separate_Day_Lower_hours,
          Unpacking_Separate_Day_Higher_hours: Unpacking_Separate_Day_Higher_hours,
          Total_Card_Unpacking_Separate_Day: Total_Card_Unpacking_Separate_Day,
          Total_Cash_Unpacking_Separate_Day: Total_Cash_Unpacking_Separate_Day,
          Rate_cash_Unpacking_Separate_Day: Rate_cash_Unpacking_Separate_Day,
          Rate_card_Unpacking_Separate_Day: Rate_card_Unpacking_Separate_Day,
          Truck_fee_Unpacking_Separate_Day: Truck_fee_Unpacking_Separate_Day,
          Combined_mileage: Combined_mileage,
          Flat_rate_Unpacking_Separate_Day: Flat_rate_Unpacking_Separate_Day,
          Flat_rate_Unpacking_Separate_Day_cash: Flat_rate_Unpacking_Separate_Day_cash,
          UnPacking_Charge: UnPacking_Charge,
          Packing_Charge: Packing_Charge,
          Flat_rate_Card_2Days_Packing: Flat_rate_Card_2Days_Packing,
          Flat_rate_Cash_2Days_Packing: Flat_rate_Cash_2Days_Packing,
        }, furniture, roomsAreas
      })
      const tagValuesCopy = JSON.parse(JSON.stringify(tagValues));
      if (typeof setTagsValuesDefault === 'function') {
        setTagsValuesDefault(prevState => ({
          ...prevState,
          [calculationData.calculationType]: tagValuesCopy
        }));
      }
      if (customTagsValues && Object.keys(customTagsValues).length > 0) {
        for (const key in customTagsValues) {
          tagValues[`|{${key}}`] = customTagsValues[key];
        }
      }
      setPreviewText(replaceTagsByValues(templateText, tagValues))
      setOptionsPreviewText(replaceOptionsTagsByValues(templateText, tagValues))
    }
  }, [popUpShown, tags, /* calculationData,  */showCustomTags]);

  const handleResCopy = (evt) => {
    evt.preventDefault();
    var truckloads = (Number(Total_cubes_Within_Building) > 0) ? Truckloads_to_move : Truckloads;

    let unpackingText = generateUnpackingText(commonValues, department, isCuFtDepartment, Movers_Unpacking_Separate_Day, Rate_cash_Unpacking_Separate_Day, Rate_card_Unpacking_Separate_Day, Truck_fee_Unpacking_Separate_Day, Trucks_Unpacking_Separate_Day, Unpacking_Separate_Day_Lower_hours, Unpacking_Separate_Day_Higher_hours, UnPacking_Charge, Total_Cash_Unpacking_Separate_Day, Total_Card_Unpacking_Separate_Day, twoDaysPacking, Flat_rate_Unpacking_Separate_Day_cash, Flat_rate_Unpacking_Separate_Day)

    let chargesText = getChargesResText(department, twoDaysPacking.selectedChargesNamesDay1, Trucks_To_Pack_Day1);

    let additionalServicesText = getAdditionalServicesResText(department, commonValues.selectedAdditionalServices, Trucks_Day2, commonValues.additionalServicesPlaceType, Total_cubes);


    let chargesTextDay2 = getChargesResText(department, twoDaysPacking.selectedChargesNamesDay2, Trucks_Day2);

    /*     department.extraOptions.charges.forEach((charge, chargeIndex) => {
          let showCharge = false;
          showCharge = twoDaysPacking.selectedChargesNamesDay2.includes(charge[`chargeName${chargeIndex}`]);
          if (showCharge && (charge[`chargeName${chargeIndex}`] !== '' || charge[`amount${chargeIndex}`] !== '')) {
            chargesTextDay2 += '• ' + charge[`chargeName${chargeIndex}`] + ': $' + charge[`amount${chargeIndex}`] + (charge[`perTruck${chargeIndex}`] ? ' (per truck)' : '') + '\n';
          }
        }); */

    let proceduresText = '';
    let procedureAdded = false;
    commonValues.procedures.forEach((procedure, pkIndex) => {
      if (procedure.procedureType !== '' && procedure.procedureType !== 'None' && twoDaysPacking.proceduresOnDay1) {
        if (!procedureAdded) {
          proceduresText += 'Procedures:\n';
          procedureAdded = true;
        }
        proceduresText += '• ' + procedure.procedureType + ' x ' + procedure.numberOfProcedures + ':';
        if (Object.keys(department).length !== 0) {
          department.packing.cratings.forEach((crating, index) => {
            if (procedure.procedureType === crating[`crateName${index}`]) {
              proceduresText += ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
            }
          });
        }
        proceduresText += '\n';
      }
    });

    let proceduresTextDay2 = '';

    commonValues.procedures.forEach((procedure, pkIndex) => {
      if (procedure.procedureType !== '' && procedure.procedureType !== 'None' && twoDaysPacking.proceduresOnDay2) {
        proceduresTextDay2 += '• ' + procedure.procedureType + ' x ' + procedure.numberOfProcedures + ':';
        if (Object.keys(department).length !== 0) {
          department.packing.cratings.forEach((crating, index) => {
            if (procedure.procedureType === crating[`crateName${index}`]) {
              proceduresTextDay2 += ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
            }
          });
        }
        proceduresTextDay2 += '\n';
      }
    });

    let notIncludedServicesText = generateNotIncludedServicesText(department, Total_cubes, commonValues)
    let suppliesPrices = `• Small box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.smallBox : ''}
  • Medium box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.mediumBox : ''}
  • Large box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.largeBox : ''}
  • Roll of packing paper: ${(Object.keys(department).length !== 0) ? department.packing.supplies.packingPapper : ''}
  • Roll of bubble wrap: ${(Object.keys(department).length !== 0) ? department.packing.supplies.bubbleWrap : ''}
         `;

    const invoiceText = `${(getFurnitureText(calculationData, furniture, roomsAreas, 'furnitureGoes').inventoryText) ? `INVENTORY` : ''}
Total Cubic ft: ${parseFloat(Total_cubes).toFixed(2)} cu ft.
${(Total_cubes_Within_Building > 0) ? `Cubic ft within building: ${parseFloat(Total_cubes_Within_Building).toFixed(2)} cu ft.
Cubic ft to move: ${parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)} cu ft.` : ''}
Total Truckloads: ${truckloads}${(truckloads > 1 && (Math.floor(Number(truckloads))) - Number(truckloads) < 0) ? Math.floor(truckloads) + ' truck(s) + ' + Math.floor(Number((Number(truckloads) - Math.floor(truckloads)).toFixed(2) * 100)) + "%" : ''}
${(isCuFtDepartment) ? `Day 1 - Round trip miles: ${parseFloat(commonValues.milesFromParkingCuFt * 2).toFixed(1)}
Total round trip miles: ${parseFloat(Round_trip_miles).toFixed(1)}
${(commonValues.unpacking === 'Separate Day') ? `Unpacking day Round trip miles: ${parseFloat(commonValues.milesToParkingCuFt * 2).toFixed(1)}` : ''}`
        : `Total miles: ${parseFloat(Combined_mileage).toFixed(1)}`}
${(commonValues.extraStops.filter((extraStop, index) =>
          extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
        ).length > 0) ? `There will be ${commonValues.extraStops.filter((extraStop, index) =>
          extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
        ).length} additional stops` : ''}
DAY 1 - PACKING
Movers: ${Movers_To_Pack_Day1}
${(!isCuFtDepartment) ? `Rate:${getFormattedRateInfoTwoDays(department, Total_Destinationfee_Day2, Rate_cash_Day1, Rate_card_Day1, Truck_fee_Day1).formattedRateTextOnly}` : ''}
${(isCuFtDepartment && Truck_fee_Day1) ? `Truck fee: $${Truck_fee_Day1}` : ''}
Trucks: ${Trucks_To_Pack_Day1}${(twoDaysPacking.shuttleOnDayOne) ? "+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${twoDaysPacking.quantityDay1})` : '') : ''}
${(twoDaysPacking.cubicFtToMoveDay1) ? `Cubic Ft to move:${twoDaysPacking.cubicFtToMoveDay1 + " cu ft."}` : ''}
${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && twoDaysPacking.selectedChargesNamesDay1.length > 0) ? `
Extra options:` : ''}
${chargesText}
${(cubicf_Day1 > 0 && !isCuFtDepartment) ? `Double Drive: ${Double_drive}` : ''}
Total Time Range: ${getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_Day1, Higher_Number_of_Hours_Day1)}
${((commonValues.procedures.length > 0) && (commonValues.procedures[0].procedureType !== "None" && commonValues.procedures[0].procedureType !== "") && twoDaysPacking.proceduresOnDay1) ? 'Procedures:' : ''}
${proceduresText}
${(commonValues.packing !== "No" && !isCuFtDepartment) ?
        (commonValues.packingKitOverride > 0) ? `Packing Kit:${'$' + Packing_kit_Show} ${commonValues.packing} [${Packing_kit_total_amount_with_taxes} with tax]`
          : `Packing Kit:${'$' + Packing_kit_Show} ${(commonValues.packing === "Yes") ? "Full" : commonValues.packing} [${Packing_kit_total_amount_with_taxes} with tax]` : ""}
${(commonValues.unpacking === 'Same Day') ? 'UNPACKING' : ''}
${(isCuFtDepartment && Packing_Charge) ? `Packing Charge:$${Packing_Charge}` : ''}
${(commonValues.packing !== "No" && (isCuFtDepartment ? department.packing.packingKits.enabled : true)) ?
  `Packing Supplies On The Go:` +
  `${department.packing.supplies.calculationMethod === 'suppliesPrices' ? suppliesPrices : ''}
  ${(!isCuFtDepartment) ? suppliesPrices : ''}
${department.packing.supplies.calculationMethod === 'perBox' ?
    `Per 1 packed box price: $${department.packing.supplies.perOnePackedBox}`
    : ''}`
  : ''
}
${(isCuFtDepartment) ? notIncludedServicesText : ''}

DAY 2 - MOVING
${(Number(twoDaysPacking?.moversToLoad) > 0)
        ? `Movers to Load: ${Number(twoDaysPacking.moversToLoad)}
        ${(!isCuFtDepartment) ? `Rate:${getFormattedRateLoadInfoTwoDays(department, Total_Destinationfee_Day2, Rate_cash_Movers_To_Load_Day2, Rate_card_Movers_To_Load_Day2, Truck_fee_Day2).formattedRateTextOnly.toString()}` : ''}`
        : `Movers: ${Movers_Day2}
${(!isCuFtDepartment) ? `Rate:${getFormattedRateInfoTwoDaysDay2(department, Total_Destinationfee_Day2, Rate_cash_Day2, Rate_card_Day2, Truck_fee_Day2).formattedRateDay2TextOnly}` : ''}
${(isCuFtDepartment && Truck_fee_Day2) ? `Truck fee: $${Truck_fee_Day2}` : ''}`}
${(Number(twoDaysPacking.moversToDrivesAndUnload) > 0) ?
        `Movers to Unload:${Number(twoDaysPacking.moversToDrivesAndUnload)}
  ${(!isCuFtDepartment) ? `Rate:${(department?.rates && department?.rates.calcMethod === 'deduct')
          ? '$' + Rate_cash_Movers_To_Unload_Day2 + '/h/$' + Rate_card_Movers_To_Unload_Day2 + "/h"
          : '$' + Rate_card_Movers_To_Unload_Day2 + "/h"
          }` : ''}        
        `


        : ""
      }


Trucks: ${Trucks_Day2}${commonValues.shuttle ? " + SHUTTLE" + ((department.extraOptions.shuttle.isCountable) ? `(x${twoDaysPacking.quantityDay2})` : '') : ""}

${TWO_Trips_Or_OK_Day2 !== "2 TRIPS" && BIGGEST_or_OK_Day2 !== 'OK' ? BIGGEST_or_OK_Day2 : ""}

${TWO_Trips_Or_OK_Day2 !== 'OK' ? TWO_Trips_Or_OK_Day2 : ""}

${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && twoDaysPacking.selectedChargesNamesDay2.length > 0) ? `
Extra options:` : ''}
${chargesTextDay2}
${additionalServicesText}
${(!isCuFtDepartment) ? `Double Drive: ${Double_drive}` : ''}
${Total_Destinationfee_Day2 > 0
        ? `Destination fee: $${(department.truckFee.tfdfCombined
          ? Total_Destinationfee_Day2 + Truck_fee_Day1 + Truck_fee_Day2
          : Total_Destinationfee_Day2
        )}`
        : ""
      }
Total time Range: ${getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_Day2, Higher_Number_of_Hours_Day2)}
${Heavy_fee > 0
        ? `Extra Heavy Items Charge: $${Heavy_fee}`
        : ""
      }
${((commonValues.procedures.length > 0) && (commonValues.procedures[0].procedureType !== "None" && commonValues.procedures[0].procedureType !== "") && twoDaysPacking.proceduresOnDay2) ? 'Procedures:' : ''}
${proceduresTextDay2}
${commonValues.liftGate ? ' | LIFTGATE' : ''}
${commonValues.hardFloorCovers ? ' | HARDWOOD FLOOR COVERS' : ''}
${commonValues.filmFloorCovers ? ' | CARPET FLOOR COVERS' : ''}
${selectedMiscCheckMarks.map(checkMark => ` | ${getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks).toUpperCase()}`).join('')}

${(twoDaysPacking.additionalAmount) ? `Additional amount:${twoDaysPacking.additionalAmount}` : ''}

${(isCuFtDepartment) ? `FLAT RATE: $${(department?.rates && department.rates.calcMethod === 'deduct') ?
        Flat_rate_Cash_2Days_Packing + ' cash / $' + Flat_rate_Card_2Days_Packing + " card"
        : Flat_rate_Card_2Days_Packing}
${(department?.rates && department?.rates.calcMethod !== 'deduct') ? `FLAT RATE with ${getCurrentRatePercent(twoDaysPacking, department)}% cash discount: $${Flat_rate_Cash_2Days_Packing}` : ""}` : ''}

${(!isCuFtDepartment) ? `NOT TO EXCEED PRICE:$${(department?.rates && department?.rates.calcMethod === 'deduct') ? Total_Cash_2Days_Packing + ' cash / $' + Total_Card_2Days_Packing + " card" : Total_Card_2Days_Packing}
  ${(department?.rates && department?.rates.calcMethod !== 'deduct') ? `NOT TO EXCEED PRICE with ${getCurrentRatePercent(twoDaysPacking, department)}% cash discount:${Total_Cash_2Days_Packing}` : ""}` : ''}

${unpackingText}
`;




    // Create a textarea element to hold the text
    const textArea = document.createElement("textarea");
    textArea.value = invoiceText.replace(/\n{2,}/g, '\n\n') + '\n';;

    // Append the textarea to the document
    document.body.appendChild(textArea);

    // Select and copy the text
    textArea.select();
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textArea);

  }
  if (typeof setShowGenerateEmail === 'function') {
    if (department && Object.keys(department).length > 0 && twoDaysPacking.firstDayRate
      && twoDaysPacking.secondDayRate && Trucks_To_Pack_Day1 > 0 && Trucks_Day2 > 0
      && Movers_To_Pack_Day1 > 0 && Movers_Day2 > 0) {
      setShowGenerateEmail(true)
    } else {
      setShowGenerateEmail(false)
    }
  }

  return (
    <ul className={styles.paramsSection}>
      <button {...getToggleProps()} className={styles.btnSubmit}>
        <i className={isExpanded ? styles.up : styles.down}></i>
      </button>
      <section {...getCollapseProps()}>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Small boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_small_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Medium boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_medium_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Fragile boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_fragile_boxes}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Boxes cubic ft:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_box_cubes.toFixed(2) + " cu ft."}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Time to pack for 1 mover:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Time_to_pack).toFixed(2) + " h"}
          </p>
        </li>
        <li className={styles.paramsItem} >
          <p className={styles.paramsName}>Truckloads from Stop 1:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_1_Truckload).toFixed(3)}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>
            Truckloads from Additional stops:
          </p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_CN_Truckload).toFixed(3)}
          </p>
        </li>
        <h4>DAY - 1 (Packing)</h4>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Loading hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Loading_hours_Day_1).toFixed(3)} {' h'}
          </p>
        </li>
        {(Driving_Time_Day1 > 0) ?
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Drive Time:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Driving_Time_Day1} {' h'}
            </p>
          </li>
          : ""}
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Unloading Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Unloading_hours_Day1).toFixed(3)} {' h'}
          </p>
        </li>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Packing Time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Packing_Time_Day1).toFixed(3)} {' h'}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Wrapping Time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Wrapping_time_Day1).toFixed(3)} {' h'}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_Hours_Day_1 + " hours"}
          </p>
        </li>
        {(!isCuFtDepartment) ?
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Day 1:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {(department?.rates && department?.rates.calcMethod === 'deduct') ?
                '$' + Total_Cash_Day1 + ' cash / $' + Total_Card_Day1 + " card"
                :
                <>
                  {'$' + Total_Card_Day1}
                </>}
            </p>
          </li>
          : ''}

        <h4>DAY - 2 (Moving)</h4>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Loading hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Loading_hours_Day2).toFixed(3)} {' h'}
          </p>
        </li>
        {(Number(twoDaysPacking?.moversToLoad) > 0) ?
          <div style={{ paddingLeft: '30px' }}>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Adjusted Loading hours:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Adjusted_loading_time_Day2).toFixed(3)} {' h'}
              </p>
            </li>
          </div>
          :
          ""
        }
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Drive Time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Drivetime_Day2} {' h'}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Unloading Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Unloading_hours_Day2).toFixed(3)} {' h'}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_Hours_Day2 + " hours"}
          </p>
        </li>
        {(!isCuFtDepartment) ?
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Day 2:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {(department?.rates && department?.rates.calcMethod === 'deduct') ?
                '$' + Total_Cash_Day2 + ' cash / $' + Total_Card_Day2 + " card"
                :
                <>
                  {'$' + Total_Card_Day2}
                </>}
            </p>
          </li>
          : ''}
      </section>
      {(department && Object.keys(department).length > 0 && twoDaysPacking.firstDayRate
        && twoDaysPacking.secondDayRate && Trucks_To_Pack_Day1 > 0 && Trucks_Day2 > 0
        && Movers_To_Pack_Day1 > 0 && Movers_Day2 > 0) ?
        <div>
          <h2 className={styles.titleCenter}><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Results  <CopyIcon onClick={handleResCopy} style={{ marginLeft: '5px' }} /></div>{(Total_box_cubes > 0 && commonValues.packing === 'No') ?
            <>
              <span style={{ color: 'red' }}> - No Packing
              </span>
              <label className={styles.commonLabeling} style={{ marginLeft: '5px' }}>
                Confirm
                <input
                  className={styles.mycheckbox}
                  type='checkbox'
                  name={'isNoPackingConfirmed'}
                  checked={commonValues.isNoPackingConfirmed}
                  value={commonValues.isNoPackingConfirmed}
                  onChange={checkboxValueChange}
                />
              </label>
            </> : ''}</h2>
            {(getFurnitureText(calculationData, furniture, roomsAreas, 'furnitureGoes').inventoryText) &&
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>
                  <span className={`${(highLightItem === 'inventoryText') ? styles.highLighted : ""}`} ref={scrollRefs?.current['inventoryText']}>
                    INVENTORY
                  </span>
                </p>
              </li>
            }
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Cubic ft:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Total_cubes.toFixed(2)}</span> cu ft.
            </p>
          </li>
          {(Total_cubes_Within_Building > 0) ? <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic ft within building:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{parseFloat(Total_cubes_Within_Building).toFixed(2)}</span> cu ft.
              </p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic ft to move:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)}</span> cu ft.
              </p>
            </li>
          </> : ''}
          <TruckloadsView
            Truckloads={Truckloads}
            Truckloads_to_move={Truckloads_to_move}
            Total_cubes_Within_Building={Total_cubes_Within_Building}
            scrollRefs={scrollRefs}
            highLightItem={highLightItem}
          />

          {(isCuFtDepartment) ?
            <>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Day 1 - Round trip miles:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}><span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{parseFloat(commonValues.milesFromParkingCuFt * 2).toFixed(1)}</span></p>
              </li>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Total round trip miles:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}><span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{parseFloat(Round_trip_miles).toFixed(1)}</span></p>
              </li>
              {commonValues.unpacking === 'Separate Day' &&
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Unpacking day Round trip miles:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}><span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{parseFloat(commonValues.milesToParkingCuFt * 2).toFixed(1)}</span></p>
                </li>
              }
            </>
            :
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Total miles:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}><span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{parseFloat(Combined_mileage).toFixed(1)}</span></p>
            </li>
          }
          {
            (commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ?
              <li className={styles.paramsItem}><div>
                There will be <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{commonValues.extraStops.filter((extraStop, index) =>
                  extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
                ).length}</span> additional stops</div>
              </li>
              : ''
          }
          <h2 className={styles.titleCenter}>Day 1 - Packing</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}><span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Movers_To_Pack_Day1}</span></p>
          </li>

          {!isCuFtDepartment &&
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Rate:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {getFormattedRateInfoTwoDays(department, Total_Destinationfee_Day2, Rate_cash_Day1, Rate_card_Day1, Truck_fee_Day1, highLightItem, scrollRefs, styles).formattedRate}
              </p>
            </li>
          }

          {(isCuFtDepartment && Truck_fee_Day1) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Truck fee:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Truck_fee_Day1}$</span>
              </p>
            </li>
            : ''
          }

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Trucks_To_Pack_Day1}</span>
              {twoDaysPacking.shuttleOnDayOne && <>  <span className={`${(highLightItem === 'shuttleDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttleDay1']}>+ SHUTTLE</span> {((department.extraOptions?.shuttle.isCountable) ? <span className={`${(highLightItem === 'shuttleQtyDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttleQtyDay1']}>(x{twoDaysPacking.quantityDay1})</span> : '')}</>}
            </p>
          </li>

          {(twoDaysPacking.cubicFtToMoveDay1) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic Ft to move:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                  {twoDaysPacking.cubicFtToMoveDay1 + " cu ft."}
                </span>
              </p>

            </li>
            : ''}

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={twoDaysPacking.selectedChargesNamesDay1}
            trucksCount={Trucks_To_Pack_Day1}
            scrollRefs={scrollRefs}
            highLightItem={highLightItem}
          />


          {(cubicf_Day1 > 0 && !isCuFtDepartment) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Double Drive:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                  {Double_drive}
                </span>
              </p>
            </li>
            : ""}

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                {getLowerNumberOfHours((department) ? department.minimumHours : 0, Lower_Number_of_Hours_Day1)}
              </span>
              <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                {getHigherNumberOfHours((department) ? department.minimumHours : 0, Higher_Number_of_Hours_Day1)}
              </span>
            </p>
          </li>

          {((commonValues.procedures.length > 0 && commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '')) && (commonValues.procedures[0].procedureType !== "None" && commonValues.procedures[0].procedureType !== "") && twoDaysPacking.proceduresOnDay1) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Procedures:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>

              </p>
              <ul>
                {
                  commonValues.procedures.map((procedure, pkIndex) => {
                    if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
                      return <li key={pkIndex}><span className={`${(highLightItem === `pIdName_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`pIdName_${procedure.uid}`]}>{procedure.procedureType}</span> x <span className={`${(highLightItem === `pIdQty_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current['destinationFee']}>{procedure.numberOfProcedures}</span>:{
                        (Object.keys(department).length !== 0) && department.packing.cratings.map((crating, index) => {
                          if (procedure.procedureType === crating[`crateName${index}`]) {
                            return <span className={`${(highLightItem === `pIdCost_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`pIdCost_${procedure.uid}`]}> ${(Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]))}</span>;
                          }
                          else {
                            return ''
                          }
                        })
                      }</li>
                    }
                    else { return '' }
                  })
                }
              </ul>
            </li>
            : ''}

          {(commonValues.packing !== "No" && !isCuFtDepartment) ?
            (commonValues.packingKitOverride > 0) ?
              <li className={styles.paramsItem} style={{ color: "red" }}>
                <p className={styles.paramsName}>Packing Kit:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === 'packingKit') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKit']}>{'$' + Packing_kit_Show} {commonValues.packing}</span>  <span className={`${(highLightItem === 'packingKitWithTax') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKitWithTax']}>[${Packing_kit_total_amount_with_taxes}</span> with tax]
                </p>
              </li>
              :
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Packing Kit:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === 'packingKit') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKit']}>{'$' + Packing_kit_Show}</span>  {(commonValues.packing === "Yes") ? "Full" : commonValues.packing} <span className={`${(highLightItem === 'packingKitWithTax') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKitWithTax']}>[${Packing_kit_total_amount_with_taxes}</span> with tax]
                </p>
              </li>
            : ""}

          {(commonValues.unpacking === 'Same Day') &&
            <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
              <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'cuftToMove') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftToMove']}>UNPACKING</span>  <span className={`${(highLightItem === 'cuftToMove') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftToMove']}>{commonValues.partialUnPacking && "PARTIAL"}</span></span>
            </div>
          }

          {(isCuFtDepartment && Packing_Charge) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Packing Charge:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'packingCharge') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingCharge']}>${Packing_Charge}</span>
              </p>
            </li>
            : ''
          }
          <PackingSuppliesOnTheGo
            department={department}
            highLightItem={highLightItem}
            scrollRefs={scrollRefs}
            commonValues={commonValues}
            isCuFtDepartment={isCuFtDepartment}
          />
          {(isCuFtDepartment) ? <AdditionalServicesEstimateInfo
            commonValues={commonValues}
            department={department}
            totalCubes={Total_cubes}
            scrollRefs={scrollRefs}
            highLightItem={highLightItem} /> : ''}

          <h2 className={styles.titleCenter}>Day 2 - Moving</h2>

          {(Number(twoDaysPacking?.moversToLoad) > 0)
            ?
            <>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Movers to Load:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                    {Number(twoDaysPacking.moversToLoad)}
                  </span>
                </p>
              </li>
              {!isCuFtDepartment &&
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Rate:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    {getFormattedRateLoadInfoTwoDays(department, Total_Destinationfee_Day2, Rate_cash_Movers_To_Load_Day2, Rate_card_Movers_To_Load_Day2, Truck_fee_Day2).formattedRate}
                  </p>
                </li>
              }
            </>
            :
            <>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Movers:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                    {Movers_Day2}
                  </span>
                </p>
              </li>
              {!isCuFtDepartment &&
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Rate:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    {getFormattedRateInfoTwoDaysDay2(department, Total_Destinationfee_Day2, Rate_cash_Day2, Rate_card_Day2, Truck_fee_Day2, highLightItem, scrollRefs, styles).formattedRateDay2}
                  </p>
                </li>
              }
              {(isCuFtDepartment && Truck_fee_Day2) ?
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Truck fee:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                      {Truck_fee_Day2}$
                    </span>
                  </p>
                </li>
                : ''
              }
            </>
          }

          {
            (Number(twoDaysPacking.moversToDrivesAndUnload) > 0)
              ?
              <>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Movers to Unload:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                      {Number(twoDaysPacking.moversToDrivesAndUnload)}
                    </span>
                  </p>
                </li>
                {!isCuFtDepartment &&
                  <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>Rate:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}>   {(department?.rates && department?.rates.calcMethod === 'deduct')
                      ? <> <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Rate_cash_Movers_To_Unload_Day2}/h</span>/  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Rate_card_Movers_To_Unload_Day2}/h</span></>
                      : <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Rate_card_Movers_To_Unload_Day2}/h</span>
                    }</p>
                  </li>
                }
              </>
              : ""
          }

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Trucks_Day2}</span>
              {commonValues?.shuttle && <>  <span className={`${(highLightItem === 'shuttle') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttle']}>+ SHUTTLE</span> {((department.extraOptions?.shuttle.isCountable) ? <span className={`${(highLightItem === 'shuttleQty') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttleQty']}>(x{twoDaysPacking.quantityDay2})</span> : '')}</>}
            </p>
          </li>

          {(TWO_Trips_Or_OK_Day2 !== "2 TRIPS") ?
            (BIGGEST_or_OK_Day2 !== 'OK') ?
              <li className={styles.paramsItem}>
                <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                  {BIGGEST_or_OK_Day2}
                </span>
              </li>
              : ""
            : ''}
          {(TWO_Trips_Or_OK_Day2 !== 'OK' && !(commonValues.baseLoadingHours == 0 || commonValues.baseUnloadingHours == 0)) ?
            <li className={styles.paramsItem}>
              <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                {TWO_Trips_Or_OK_Day2}
              </span>
            </li>
            : ""
          }

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={twoDaysPacking.selectedChargesNamesDay2}
            trucksCount={Trucks_Day2}
            scrollRefs={scrollRefs}
            highLightItem={highLightItem}
          />

          {isCuFtDepartment &&
            <AdditionalServicesResults
              selectedAdditionalServices={commonValues.selectedAdditionalServices}
              department={department}
              additionalServicesPlaceType={commonValues.additionalServicesPlaceType}
              Total_cubes={Total_cubes}
              trucksCount={Trucks_Day2}
              scrollRefs={scrollRefs}
              highLightItem={highLightItem}
            />
          }

          {!isCuFtDepartment &&
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Double Drive:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                  {Double_drive}
                </span>
              </p>
            </li>}

          {(Total_Destinationfee_Day2 > 0) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Destination fee:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>$
                {(department.truckFee.tfdfCombined) ?
                  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Total_Destinationfee_Day2 + Truck_fee_Day1 + Truck_fee_Day2}</span>
                  : <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Total_Destinationfee_Day2}</span>
                }
              </p>
            </li>
            : ""}

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_Day2, Higher_Number_of_Hours_Day2)}</p>
          </li>

          {(Heavy_fee > 0) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Extra Heavy Items Charge:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                  ${Heavy_fee}
                </span>
              </p>
            </li>
            : ""}

          {((commonValues.procedures.length > 0 && commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '')) && (commonValues.procedures[0].procedureType !== "None" && commonValues.procedures[0].procedureType !== "") && twoDaysPacking.proceduresOnDay2) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Procedures:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>

              </p>
              <ul>
                {
                  commonValues.procedures.map((procedure, pkIndex) => {
                    if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
                      return <li key={pkIndex}><span className={`${(highLightItem === `pIdName_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`pIdName_${procedure.uid}`]}>{procedure.procedureType}</span> x <span className={`${(highLightItem === `pIdQty_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current['destinationFee']}>{procedure.numberOfProcedures}</span>:{
                        (Object.keys(department).length !== 0) && department.packing.cratings.map((crating, index) => {
                          if (procedure.procedureType === crating[`crateName${index}`]) {
                            return <span className={`${(highLightItem === `pIdCost_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`pIdCost_${procedure.uid}`]}> ${(Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]))}</span>;
                          }
                          else {
                            return ''
                          }
                        })
                      }</li>
                    }
                    else { return '' }
                  })
                }
              </ul>
            </li>
            : ''}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'liftGate') ? styles.highLighted : ""}`} ref={scrollRefs?.current['liftGate']}>{commonValues.liftGate && ' | LIFTGATE'}</span></span>
            <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'hardwoodFloorCovers') ? styles.highLighted : ""}`} ref={scrollRefs?.current['hardwoodFloorCovers']}>{commonValues.hardFloorCovers && " | HARDWOOD FLOOR COVERS"}</span></span>
            <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'carpetFloorCovers') ? styles.highLighted : ""}`} ref={scrollRefs?.current['carpetFloorCovers']}>{commonValues.filmFloorCovers && " | CARPET FLOOR COVERS "}</span></span>
            {selectedMiscCheckMarks.map((checkMark, chmpkIndex) => <span key={chmpkIndex} style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
              {' '} |  <span className={`${(highLightItem === `mChId_${checkMark}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`mChId_${checkMark.uId}`]}>{getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks)}</span>
            </span>)}
          </div>

          {(twoDaysPacking.additionalAmount) ? (
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Additional amount:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                  ${twoDaysPacking.additionalAmount}
                </span>
              </p>
            </li>) : ''
          }

          {isCuFtDepartment && <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>FLAT RATE:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>$
                {(department?.rates && department.rates.calcMethod === 'deduct') ?
                  <> <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Flat_rate_Cash_2Days_Packing}</span> cash /  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Flat_rate_Card_2Days_Packing}</span> card</>
                  :
                  <>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                      {Flat_rate_Card_2Days_Packing}
                    </span>
                  </>}
              </p>
            </li>
            {(department?.rates && department?.rates.calcMethod !== 'deduct') ?
              <div style={{ paddingLeft: '30px' }}>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>FLAT RATE with {getCurrentRatePercent(twoDaysPacking, department)}% cash discount:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                      ${Flat_rate_Cash_2Days_Packing}
                    </span>
                  </p>
                </li>
              </div>
              : ""}
          </>}

          {(!isCuFtDepartment) ? <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>NOT TO EXCEED PRICE:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {(department?.rates && department?.rates.calcMethod === 'deduct') ? <>
                  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Total_Cash_2Days_Packing}</span> cash /   <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Total_Card_2Days_Packing}</span> card
                </>
                  :
                  <>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                      ${Total_Card_2Days_Packing}
                    </span>
                  </>}
              </p>
            </li>

            {(department?.rates && department?.rates.calcMethod !== 'deduct') ?
              <div style={{ paddingLeft: '30px' }}>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>NOT TO EXCEED PRICE with   <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{getCurrentRatePercent(twoDaysPacking, department)}%</span> cash discount:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Total_Cash_2Days_Packing}</span>
                  </p>
                </li>
              </div>
              : ""}
          </> : ''}

          <CalculationSeparateDayView commonValues={commonValues} anyCalculationType={twoDaysPacking} department={department}
            Trucks_Unpacking_Separate_Day={Trucks_Unpacking_Separate_Day}
            Movers_Unpacking_Separate_Day={Movers_Unpacking_Separate_Day}
            Unpacking_Separate_Day_Lower_hours={Unpacking_Separate_Day_Lower_hours}
            Unpacking_Separate_Day_Higher_hours={Unpacking_Separate_Day_Higher_hours}
            Total_Card_Unpacking_Separate_Day={Total_Card_Unpacking_Separate_Day}
            Total_Cash_Unpacking_Separate_Day={Total_Cash_Unpacking_Separate_Day}
            Rate_cash_Unpacking_Separate_Day={Rate_cash_Unpacking_Separate_Day}
            Rate_card_Unpacking_Separate_Day={Rate_card_Unpacking_Separate_Day}
            Truck_fee_Unpacking_Separate_Day={Truck_fee_Unpacking_Separate_Day}
            Flat_rate_Unpacking_Separate_Day={Flat_rate_Unpacking_Separate_Day}
            Flat_rate_Unpacking_Separate_Day_cash={Flat_rate_Unpacking_Separate_Day_cash}
            isCuFtDepartment={isCuFtDepartment}
            UnPacking_Charge={UnPacking_Charge}
            highLightItem={highLightItem}
            scrollRefs={scrollRefs}
          />
        </div>
        : ""}
    </ul>
  );
};
