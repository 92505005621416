import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { SocketContext } from '../../context/socket';
import useCollapse from 'react-collapsed'

import { getCheckMarkLabelById, getCurrentRatePercent, getHigherNumberOfHours, getLowerNumberOfHours, getTagsOptions, replaceSpaces } from '../../util/utils';

// Styles
import styles from "./TemplateOOSTwoDayParams.module.css";
//Components
import { TemplateSeparateDayView } from '../template-separate-day-view/Template-separate-day-view';
import { useDispatch, useSelector } from 'react-redux';
import { getUserName } from '../../clientStore/authSlice/auth-slice';


import { CalculationTabTwoDays150Values } from '../calculation-tab-two-days-150-values/Calculation-tab-two-days-150-values';
import { CalculationTabLongOneDayValues } from '../calculation-tab-long-one-day-values/Calculation-tab-long-one-day-values';
import { TemplateSeparateDayLongView } from '../template-separate-day-long-view/Template-separate-day-long-view';
import { CalculationTabOOSOneDayValues } from '../calculation-tab-oos-one-day-values/Calculation-tab-oos-one-day-values';
import { CalculationTabOOSLongTwoDayValues } from '../calculation-tab-oos-long-two-day-values/Calculation-tab-oos-long-two-day-values';
import { TemplatesExtraOptionsResults } from '../templates-extra-options-results/Templates-extra-options-results';
import { setTotal_cubesG, setTotalFragileBoxes, setTotalMediumBoxes, setTotalSmallBoxes } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { CalculationOOSLongTwoDayParams } from '../calculation-oos-long-two-day-params/Calculation-oos-long-two-day-params';

export const TemplateOOSTwoDayParams = ({
  highLightItem,
  company,
  department,
  templateForm,
  rates,
  onCalcFormValueChange,
  resetLastTarget,
  setOptions,
  users,
  departments,
  renderOOSOptions,
  onChangeOutOfStateSelect,
  onChangeUnpDepartmentSelect,
  unpRates,
  longDistance,
  longdistances,
  factors,
  checkMarkCheckboxValueChange,
  setLastTarget,
  lastTarget
}) => {
  const dispatch = useDispatch();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const userName = useSelector(getUserName);
  const scrollRefs = useRef([]);
  const calculationForm = templateForm.templateCalcObj;
  const [Total_cubes, setTotal_cubes] = useState(0)
  const [Total_small_boxes, setTotal_small_boxes] = useState(0)
  const [Total_medium_boxes, setTotal_medium_boxes] = useState(0)
  const [Total_fragile_boxes, setTotal_fragile_boxes] = useState(0)
  const [Total_box_cubes, setTotal_box_cubes] = useState(0)
  const [Truckloads, setTruckloads] = useState(0)
  const [Stop_CN_Truckload, setStop_CN_Truckload] = useState(0)
  const [Stop_1_Truckload, setStop_1_Truckload] = useState(0)
  const [Heavy_fee, setHeavy_fee] = useState(0)
  const [Packing_kit_Show, setPacking_kit_Show] = useState(0)
  const [Packing_kit_total_amount_with_taxes, setPacking_kit_total_amount_with_taxes] = useState(0)
  const [Truck_fee_LONG_Day1, setTruck_fee_LONG_Day1] = useState(0)
  const [Movers_LONG2DAYS_Unloading, setMovers_LONG2DAYS_Unloading] = useState(0)
  const [Movers_To_Pack_OOS_Day1, setMovers_To_Pack_OOS_Day1] = useState(0)
  const [Rate_card_OOS_Day1, setRate_card_OOS_Day1] = useState(0)
  const [Packing_Time_OOS_Day1, setPacking_Time_OOS_Day1] = useState(0)
  const [Wrapping_time_OOS_Day1, setWrapping_time_OOS_Day1] = useState(0)
  const [Total_Hours_OOS2DAYS_Loading, setTotal_Hours_OOS2DAYS_Loading] = useState(0)
  const [Total_Hours_OOS_Day1, setTotal_Hours_OOS_Day1] = useState(0)
  const [Lower_Number_of_Hours_OOS_Day1, setLower_Number_of_Hours_OOS_Day1] = useState(0)
  const [Higher_Number_of_Packing_Hours_OOS_Day1, setHigher_Number_of_Packing_Hours_OOS_Day1] = useState(0)
  const [Higher_Number_of_Hours_OOS_Day1, setHigher_Number_of_Hours_OOS_Day1] = useState(0)
  const [Total_Card_Packing_OOS_Day1, setTotal_Card_Packing_OOS_Day1] = useState(0)
  const [Total_Card_Packing_OOS_Day1_Rounded, setTotal_Card_Packing_OOS_Day1_Rounded] = useState(0)
  const [Movers_OOS2DAYS_Loading, setMovers_OOS2DAYS_Loading] = useState(0)
  const [Drivetime_OOSOne_Day, setDrivetime_OOSOne_Day] = useState(0)
  const [Total_Hours_OOS2DAYS_Unloading, setTotal_Hours_OOS2DAYS_Unloading] = useState(0)
  const [Total_Card_OOS2DAYS, setTotal_Card_OOS2DAYS] = useState(0)
  const [Total_Amount_MilesFactor_OOS2DAYS, setTotal_Amount_MilesFactor_OOS2DAYS] = useState(0)
  const [Trucks_OOS2DAYS, setTrucks_OOS2DAYS] = useState(0)
  const [Total_Hours_OOS2DAYS_Loading_Lower_number, setTotal_Hours_OOS2DAYS_Loading_Lower_number] = useState(0)
  const [Total_Hours_OOS2DAYS_Unloading_Lower_number, setTotal_Hours_OOS2DAYS_Unloading_Lower_number] = useState(0)
  const [Total_Cash_OOS2DAYS, setTotal_Cash_OOS2DAYS] = useState(0)


  const [Rate_card_Unpacking_Same_Day_OOS_2Days, setRate_card_Unpacking_Same_Day_OOS_2Days] = useState(0);
  const [Unpacking_hours_same_day_OOS2DAYS, setUnpacking_hours_same_day_OOS2DAYS] = useState(0);
  const [Total_Card_Unpacking_Same_Day_OOS_2Days, setTotal_Card_Unpacking_Same_Day_OOS_2Days] = useState(0);

  const [Trucks_Unpacking_Separate_Day, setTrucks_Unpacking_Separate_Day] = useState(0)
  const [Movers_Unpacking_Separate_Day, setMovers_Unpacking_Separate_Day] = useState(0)
  const [Unpacking_Separate_Day_Lower_hours, setUnpacking_Separate_Day_Lower_hours] = useState(0)
  const [Unpacking_Separate_Day_Higher_hours, setUnpacking_Separate_Day_Higher_hours] = useState(0)
  const [Rate_cash_Unpacking_Separate_Day, setRate_cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_card_Unpacking_Separate_Day, setRate_card_Unpacking_Separate_Day] = useState(0)
  const [Total_Card_Unpacking_Separate_Day_Long_and_OOS, setTotal_Card_Unpacking_Separate_Day_Long_and_OOS] = useState(0)
  const [Truck_fee_Unpacking_Separate_Day, setTruck_fee_Unpacking_Separate_Day] = useState(0)
  const [Combined_mileage, setCombined_mileage] = useState(0);
  const [Trucks_To_Pack_OOS_Day1, setTrucks_To_Pack_OOS_Day1] = useState(0);


  const scrollKeys = [
    'inventoryText',
    'username', 'department', 'truckloads', 'additionalStopsCount', 'movers',
    'moversToLoad', 'moversToDrivesAndUnload',
    'rate', 'rateCash', 'rateCashLoad', 'rateCardLoad', 'truckfee',
    'rateCardUnload', 'rateCashUnload', 'trucks', 'shuttle', 'doubleDrive', 'twoTrips',
    'biggestOrOk', 'lowerNumberOfHours', 'higherNumberOfHours', 'packingKit', 'packingType',
    'packingKitWithTax', 'smallBox', 'mediumBox', 'largeBox', 'rollOfPaper', 'rollOfBubble',
    'liftGate', 'hardwoodFloorCovers', 'carpetFloorCovers', 'notToExceedPrice',
    'notToExceedPricePercent', 'cashDiscount', 'sameDayUnpack', 'separateDayUnpack',
    'trucksUnpackingSeparateDay', 'moversUnpackingSeparateDay', 'unpackingSeparateDayLowerhours',
    'unpackingSeparateDayHigherhours', 'totalCardUnpackingSeparateDay', 'totalCashUnpackingSeparateDay',
    'rateCashUnpackingSeparateDay', 'rateCardUnpackingSeparateDay', 'truckFeeUnpackingSeparateDay',
    'truckFeeUnpackingSeparateDay', 'combinedMileage', 'calculationNumber', 'trucksPercent', 'truckloadsRoundDown'];
  if (company) {
    if (company.extraOptCharges) {
      company.extraOptCharges.forEach((charge, index) => {
        scrollKeys.push(`chIdName_${index}`)
        scrollKeys.push(`chIdAmount_${index}`)
      })
    }
    if (company.additProcedures) {
      company.additProcedures.forEach((procedure, index) => {
        scrollKeys.push(`pIdName_${index}`)
        scrollKeys.push(`pIdCost_${index}`)
        scrollKeys.push(`pIdQty_${index}`)
      })
    }
    if (company.miscCheckMarks) {
      company.miscCheckMarks.forEach((checkMark, index) => {
        scrollKeys.push(`mChId_${index}`)
      })
    }
  }
  scrollKeys.forEach(element => {
    scrollRefs.current[element] = React.createRef()
  });

  const commonValues = calculationForm.commonValues;
  const outOfState = calculationForm.outOfState;
  const unpDepartment = calculationForm.unpDepartment;
  const oos2days = calculationForm.oos2days



  const socket = useContext(SocketContext);

  useEffect(() => {

  }, [commonValues, outOfState, department])


  const sepCheckboxValueChange = (evt) => {
    resetLastTarget();

    switch (evt.target.value) {
      case 'true':
        return onCalcFormValueChange(`commonValues.${evt.target.name}`, 'set', false);
      case 'false':
        return onCalcFormValueChange(`commonValues.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };


  useEffect(() => {

    if (highLightItem) {
      try {
        scrollRefs.current[highLightItem].current.scrollIntoView({ block: 'nearest' });
      }
      catch {

      }
    }
  }, [highLightItem]);
  useEffect(() => {
    socket.on('Total_cubes', (data) => { setTotal_cubes(data); dispatch(setTotal_cubesG(data)); });
    socket.on('Total_small_boxes', (data) => {setTotal_small_boxes(data); dispatch(setTotalSmallBoxes(data));})
    socket.on('Total_medium_boxes', (data) => {setTotal_medium_boxes(data); dispatch(setTotalMediumBoxes(data));})
    socket.on('Total_fragile_boxes', (data) => { setTotal_fragile_boxes(data); dispatch(setTotalFragileBoxes(data)); })
    socket.on('Total_box_cubes', (data) => setTotal_box_cubes(data))
    socket.on('Truckloads', (data) => setTruckloads(data))
    socket.on('Stop_CN_Truckload', (data) => setStop_CN_Truckload(data))
    socket.on('Stop_1_Truckload', (data) => setStop_1_Truckload(data))
    socket.on('Heavy_fee', (data) => setHeavy_fee(data))
    socket.on('Packing_kit_Show', (data) => setPacking_kit_Show(data))
    socket.on('Packing_kit_total_amount_with_taxes', (data) => setPacking_kit_total_amount_with_taxes(data))
    socket.on('Truck_fee_LONG_Day1', (data) => setTruck_fee_LONG_Day1(data))
    socket.on('Movers_LONG2DAYS_Unloading', (data) => setMovers_LONG2DAYS_Unloading(data))
    socket.on('Movers_To_Pack_OOS_Day1', (data) => setMovers_To_Pack_OOS_Day1(data))
    socket.on('Rate_card_OOS_Day1', (data) => setRate_card_OOS_Day1(data))
    socket.on('Packing_Time_OOS_Day1', (data) => setPacking_Time_OOS_Day1(data))
    socket.on('Wrapping_time_OOS_Day1', (data) => setWrapping_time_OOS_Day1(data))
    socket.on('Total_Hours_OOS2DAYS_Loading', (data) => setTotal_Hours_OOS2DAYS_Loading(data))
    socket.on('Total_Hours_OOS_Day1', (data) => setTotal_Hours_OOS_Day1(data))
    socket.on('Lower_Number_of_Hours_OOS_Day1', (data) => setLower_Number_of_Hours_OOS_Day1(data))
    socket.on('Higher_Number_of_Packing_Hours_OOS_Day1', (data) => setHigher_Number_of_Packing_Hours_OOS_Day1(data))
    socket.on('Higher_Number_of_Hours_OOS_Day1', (data) => setHigher_Number_of_Hours_OOS_Day1(data))
    socket.on('Total_Card_Packing_OOS_Day1', (data) => setTotal_Card_Packing_OOS_Day1(data))
    socket.on('Total_Card_Packing_OOS_Day1_Rounded', (data) => setTotal_Card_Packing_OOS_Day1_Rounded(data))
    socket.on('Movers_OOS2DAYS_Loading', (data) => setMovers_OOS2DAYS_Loading(data))
    socket.on('Drivetime_OOSOne_Day', (data) => setDrivetime_OOSOne_Day(data))
    socket.on('Total_Hours_OOS2DAYS_Unloading', (data) => setTotal_Hours_OOS2DAYS_Unloading(data))
    socket.on('Total_Card_OOS2DAYS', (data) => setTotal_Card_OOS2DAYS(data))
    socket.on('Total_Amount_MilesFactor_OOS2DAYS', (data) => setTotal_Amount_MilesFactor_OOS2DAYS(data))
    socket.on('Trucks_OOS2DAYS', (data) => setTrucks_OOS2DAYS(data))
    socket.on('Total_Hours_OOS2DAYS_Loading_Lower_number', (data) => setTotal_Hours_OOS2DAYS_Loading_Lower_number(data))
    socket.on('Total_Hours_OOS2DAYS_Unloading_Lower_number', (data) => setTotal_Hours_OOS2DAYS_Unloading_Lower_number(data))
    socket.on('Total_Cash_OOS2DAYS', (data) => setTotal_Cash_OOS2DAYS(data))

    socket.on('Rate_card_Unpacking_Same_Day_OOS_2Days', (data) => setRate_card_Unpacking_Same_Day_OOS_2Days(data));
    socket.on('Unpacking_hours_same_day_OOS2DAYS', (data) => setUnpacking_hours_same_day_OOS2DAYS(data));
    socket.on('Total_Card_Unpacking_Same_Day_OOS_2Days', (data) => setTotal_Card_Unpacking_Same_Day_OOS_2Days(data));

    socket.on('Trucks_Unpacking_Separate_Day', (data) => setTrucks_Unpacking_Separate_Day(data))
    socket.on('Movers_Unpacking_Separate_Day', (data) => setMovers_Unpacking_Separate_Day(data))
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => setUnpacking_Separate_Day_Lower_hours(data))
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => setUnpacking_Separate_Day_Higher_hours(data))
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => setRate_cash_Unpacking_Separate_Day(data))
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => setRate_card_Unpacking_Separate_Day(data))
    socket.on('Total_Card_Unpacking_Separate_Day_Long_and_OOS', (data) => setTotal_Card_Unpacking_Separate_Day_Long_and_OOS(data))
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => setTruck_fee_Unpacking_Separate_Day(data))
    socket.on('Combined_mileage', (data) => setCombined_mileage(data));
    socket.on('Trucks_To_Pack_OOS_Day1', (data) => setTrucks_To_Pack_OOS_Day1(data));
  }, [calculationForm])

  useEffect(() => {
    let currentOptions = getTagsOptions({
      company: company, templateCalcType: templateForm.templateCalcType, users: users, departments: departments, longdistances: longdistances, calculation: templateForm.templateCalcObj,
      calculatedParams: {}
    });

    setOptions(currentOptions);
  }, [templateForm.tags, templateForm.templateCalcObj, users]);


  return (
    <div className={styles.parentCont}>

      {(calculationForm && Object.keys(calculationForm).length) ?
        <CalculationTabOOSLongTwoDayValues
          renderOOSOptions={renderOOSOptions}
          factors={factors.length > 0 ? factors : (calculationForm.outOfState && calculationForm.outOfState.parameters) ? calculationForm.outOfState.parameters : []}
          rates={(rates.length > 0) ? rates : (calculationForm.department && calculationForm.department.rates) ? calculationForm.department.rates.ratesList : []}
          formData={calculationForm}
          formChange={onCalcFormValueChange}
          resetLastTarget={resetLastTarget}
          onChangeOutOfStateSelect={onChangeOutOfStateSelect}
          sepCheckboxValueChange={sepCheckboxValueChange}
          departments={departments}
          unpRates={(unpRates.length > 0) ? unpRates : (calculationForm.unpDepartment && calculationForm.unpDepartment.rates) ? calculationForm.unpDepartment.rates.ratesList : []}
          unpDepartment={calculationForm.unpDepartment}
          onChangeUnpDepartmentSelect={onChangeUnpDepartmentSelect}
          templatePage
          checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
          lastTarget={lastTarget}
          setLastTarget={setLastTarget}
        />
        : ""}

      <div>
        <h3 className={`${styles.title} ${(highLightItem === 'username') ? styles.highLighted : ""}`} ref={scrollRefs?.current['username']}>{userName}</h3>
        <h3 className={`${styles.title}`}><span className={`${(highLightItem === 'calculationNumber') ? styles.highLighted : ""}`} ref={scrollRefs?.current['calculationNumber']}>{calculationForm.calculationNumber} </span><span className={`${(highLightItem === 'department') ? styles.highLighted : ""}`} ref={scrollRefs?.current['department']}>{calculationForm && calculationForm.department && calculationForm.department.departmentName}</span>{/*  <span className={`${(highLightItem === 'rate') ? styles.highLighted : ""}`}>XP</span> */}</h3>
        <div className={`${styles.title}`}>
          <p className={styles.calculationDate}>

            {(calculationForm && Object.keys(calculationForm).length && calculationForm.oos2days.firstDayDate) ? <>
              <span className={`${(highLightItem === 'day1weekday') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1weekday']}>{new Date(calculationForm.oos2days.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })}, </span>
              <span className={`${(highLightItem === 'day1month') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1month']}>{new Date(calculationForm.oos2days.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day1date') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1date']}>{new Date(calculationForm.oos2days.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day1year') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1year']}>{new Date(calculationForm.oos2days.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })}</span>
            </> : ""}
            {calculationForm && Object.keys(calculationForm).length && oos2days.secondDayDate && oos2days.firstDayDate && ' &  '}
            {(calculationForm && Object.keys(calculationForm).length && calculationForm.oos2days.secondDayDate) ? <>
              <span className={`${(highLightItem === 'day2weekday') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2weekday']}>{new Date(calculationForm.oos2days.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })}, </span>
              <span className={`${(highLightItem === 'day2month') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2month']}>{new Date(calculationForm.oos2days.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day2date') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2date']}>{new Date(calculationForm.oos2days.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day2year') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2year']}>{new Date(calculationForm.oos2days.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })}</span>
            </> : ""}
            {calculationForm && Object.keys(calculationForm).length && calculationForm.oos2days.thirdDayDate && '& '}
            {(calculationForm && Object.keys(calculationForm).length && calculationForm.oos2days.secondDayDate) ? <>
              <span className={`${(highLightItem === 'day3weekday') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day3weekday']}>{new Date(calculationForm.oos2days.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })}, </span>
              <span className={`${(highLightItem === 'day3month') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day3month']}>{new Date(calculationForm.oos2days.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day3date') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day3date']}>{new Date(calculationForm.oos2days.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day3year') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day3year']}>{new Date(calculationForm.oos2days.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })}</span>
            </> : ""}
          </p>
        </div>
        <CalculationOOSLongTwoDayParams
          commonValues={commonValues}
          department={department}
          outOfState={outOfState}
          oos2days={oos2days}
          unpDepartment={unpDepartment}
          selectedMiscCheckMarks={calculationForm.selectedMiscCheckMarks}
          calculationData={calculationForm}
        />
      </div >
    </div >
  );
};

