// Third party libraries
import { nanoid } from 'nanoid';
import useCollapse from 'react-collapsed';
import { getTruckFeeMin } from '../../util/utils';
import * as immutable from 'object-path-immutable';
import { useSelector } from 'react-redux';
import clockIcon from './../../img/clock.png';
// Styles
import styles from './CalculationTabThreeDays150Values.module.css';
import { ExtraOptionsValues } from '../extra-options-values/Extra-options-values';
import { MiscChechMarksValues } from '../misc-checkmarks-values/Misc-checkmarks-values';
import { AdditionalServicesValues } from '../additional-services-values/Additional-services-values';
import { useEffect, useState } from 'react';
import AdjustTime from '../adjust-time/Adjust-time';

export const CalculationTabThreeDays150Values = ({ rates, setCalculationFormValue, formData, formChange, resetLastTarget,
  sepCheckboxValueChange, templatePage, validationError, setValidationError, lastTarget, setLastTarget, checkMarkCheckboxValueChange,
  additionalServicesCheckboxValueChange, disabled }) => {
  const TWO_Trips_Or_OK_Day2 = useSelector(state => state.calculatedParams.TWO_Trips_Or_OK_Day2);
  const [showInputs, setShowInputs] = useState(false);
  const [showInputsSecond, setShowInputsSecond] = useState(false);
  const [showInputsThird, setShowInputsThird] = useState(false);
  const [showInputsUnpacking, setShowInputsUnpacking] = useState(false);
  const [twoTripsManuallyInserted, setTwoTripsManuallyInserted] = useState(false);

  const onValueChange = (evt) => {
    resetLastTarget();
    formChange(`threeDays150Miles.${evt.target.name}`, 'set', evt.target.value)
    if (typeof setValidationError === 'function') {
      if ((evt.target.name === 'moversToDrivesAndUnload') && parseInt(evt.target.value) > parseInt(formData.threeDays150Miles.moversToLoad)) {
        setValidationError({ ...validationError, [formData.calculationType]: 'Movers to drive and unload can not be more than Movers to load!' });
      } else
        if ((evt.target.name === 'moversToLoad') && parseInt(evt.target.value) < parseInt(formData.threeDays150Miles.moversToDrivesAndUnload)) {
          setValidationError({ ...validationError, [formData.calculationType]: 'Movers to drive and unload can not be more than Movers to load!' });
        } else {
          setValidationError('');
        }
    }
  }

  const isCuFtDepartment = formData.department?.type === 'cuft';

  const checkboxValueChange = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        return formChange(`threeDays150Miles.${evt.target.name}`, 'set', false);
      case 'false':
        return formChange(`threeDays150Miles.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };

  useEffect(() => {
    if (isCuFtDepartment) {
      formChange(`commonValues.unpackingRate`, 'set', formData.threeDays150Miles.firstDayRate);
    }
  }, []);

  const chargeCheckboxValueChangeDayOne = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.threeDays150Miles.selectedChargesNamesDay1];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`threeDays150Miles.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`threeDays150Miles.selectedChargesNamesDay1`, 'push', { key: evt.target.name, quantity: 1 });
      default:
        break;
    }
  };

  const chargeCheckboxValueChangeDayTwo = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.threeDays150Miles.selectedChargesNamesDay2];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`threeDays150Miles.selectedChargesNamesDay2`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`threeDays150Miles.selectedChargesNamesDay2`, 'push', { key: evt.target.name, quantity: 1 });
        break;
      default:
        break;
    }
  };

  const chargeCheckboxValueChangeDayThree = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.threeDays150Miles.selectedChargesNamesDay3];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`threeDays150Miles.selectedChargesNamesDay3`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`threeDays150Miles.selectedChargesNamesDay3`, 'push', { key: evt.target.name, quantity: 1 });
        break;
      default:
        break;
    }
  };

  const chargeQuantityChangeDayOne = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = JSON.parse(JSON.stringify(formData.threeDays150Miles.selectedChargesNamesDay1));
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`threeDays150Miles.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
    }
  }

  const chargeQuantityChangeDayTwo = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = JSON.parse(JSON.stringify(formData.threeDays150Miles.selectedChargesNamesDay2));
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`threeDays150Miles.selectedChargesNamesDay2`, 'set', newSelectedChargesNames);
    }
  }

  const chargeQuantityChangeDayThree = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = JSON.parse(JSON.stringify(formData.threeDays150Miles.selectedChargesNamesDay3));
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`threeDays150Miles.selectedChargesNamesDay3`, 'set', newSelectedChargesNames);
    }
  }

  const proceduresCheckboxValueChange = (evt) => {
    let threeDays150MilesTemp = { ...formData.threeDays150Miles };
    threeDays150MilesTemp.proceduresOnDay1 = !threeDays150MilesTemp.proceduresOnDay1;
    threeDays150MilesTemp.proceduresOnDay2 = !threeDays150MilesTemp.proceduresOnDay2;
    threeDays150MilesTemp.proceduresOnDay3 = !threeDays150MilesTemp.proceduresOnDay3;
    return formChange(`threeDays150Miles`, 'set', threeDays150MilesTemp);
  };

  const proceduresCheckboxValueChangeDay1 = (evt) => {
    resetLastTarget();
    let threeDays150MilesTemp = { ...formData.threeDays150Miles };
    if (!evt.target.checked) {
      threeDays150MilesTemp.proceduresOnDay1 = evt.target.checked;
    }
    else {
      threeDays150MilesTemp.proceduresOnDay1 = evt.target.checked;
      threeDays150MilesTemp.proceduresOnDay2 = !evt.target.checked;
      threeDays150MilesTemp.proceduresOnDay3 = !evt.target.checked;
    }
    return formChange(`threeDays150Miles`, 'set', threeDays150MilesTemp);
  }

  const proceduresCheckboxValueChangeDay2 = (evt) => {
    resetLastTarget();
    let threeDays150MilesTemp = { ...formData.threeDays150Miles };
    if (!evt.target.checked) {
      threeDays150MilesTemp.proceduresOnDay2 = evt.target.checked;
    } else {
      threeDays150MilesTemp.proceduresOnDay2 = evt.target.checked;
      threeDays150MilesTemp.proceduresOnDay1 = !evt.target.checked;
      threeDays150MilesTemp.proceduresOnDay3 = !evt.target.checked;
    }
    return formChange(`threeDays150Miles`, 'set', threeDays150MilesTemp);
  };

  const proceduresCheckboxValueChangeDay3 = (evt) => {
    resetLastTarget();
    let threeDays150MilesTemp = { ...formData.threeDays150Miles };
    if (!evt.target.checked) {
      threeDays150MilesTemp.proceduresOnDay3 = evt.target.checked;
    } else {
      threeDays150MilesTemp.proceduresOnDay3 = evt.target.checked;
      threeDays150MilesTemp.proceduresOnDay1 = !evt.target.checked;
      threeDays150MilesTemp.proceduresOnDay2 = !evt.target.checked;
    }
    return formChange(`threeDays150Miles`, 'set', threeDays150MilesTemp);
  };

  useEffect(() => {
    const baseLoadingHours = formData.commonValues.baseLoadingHours;
    const baseUnloadingHours = formData.commonValues.baseUnloadingHours;
    if ((baseUnloadingHours == 0 || baseLoadingHours == 0) && !(baseUnloadingHours == 0 && baseLoadingHours == 0)) {
      formChange(`threeDays150Miles.noTwoTrips`, 'set', true);
    } else {
      if (!formData.threeDays150Miles.noTwoTrips) {
        formChange(`threeDays150Miles.noTwoTrips`, 'set', false);
      }
    }
    if (!twoTripsManuallyInserted && baseUnloadingHours != 0 && baseLoadingHours != 0) {
      formChange(`threeDays150Miles.noTwoTrips`, 'set', false);
    }
  }, [formData.commonValues.baseUnloadingHours, formData.commonValues.baseLoadingHours]);

  const aSQuantityChange = (evt) => {
    setLastTarget(evt.target.name)
    var newSelectedASNames = JSON.parse(JSON.stringify(formData.commonValues.selectedAdditionalServices))
    var index = newSelectedASNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedASNames[index].quantity = Number(evt.target.value)
      return formChange(`commonValues.selectedAdditionalServices`, 'set', newSelectedASNames);
    }
  }

  return (
    <section className={styles.container}>
      <div className={styles.groupInput}>
        <div className={styles.decorateContainer1} style={{ flexDirection: 'column' }}>
          <label className={styles.commonLabel} style={{ display: 'inline-block', marginLeft: '0px' }}>
            1st date:
            <input
              className={styles.dateInput}
              type='date'
              name={'firstDayDate'}
              value={formData.threeDays150Miles.firstDayDate}
              onChange={onValueChange}
              disabled={disabled}
            />
          </label>
          <label className={styles.commonLabel} style={{ display: 'inline-block', marginLeft: '0px' }}>
            2nd date:
            <input
              className={styles.dateInput}
              type='date'
              name={'secondDayDate'}
              value={formData.threeDays150Miles.secondDayDate}
              onChange={onValueChange}
              disabled={disabled}
            />
          </label>
          <label className={styles.commonLabel} style={{ display: 'inline-block', marginLeft: '0px' }}>
            3rd date:
            <input
              className={styles.dateInput}
              type='date'
              name={'thirdDayDate'}
              value={formData.threeDays150Miles.thirdDayDate}
              onChange={onValueChange}
              disabled={disabled}
            />
          </label>


        </div>
        <label style={{ marginTop: "5px" }} >
          Additional amount:
          <input
            onWheel={(e) => e.target.blur()}
            style={{ width: "60px" }}
            type='number'
            name={'additionalAmount'}
            value={(formData.threeDays150Miles) ? formData.threeDays150Miles.additionalAmount : ''}
            placeholder={'ex: 2'}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
      </div>

      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", width: '630px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>DAY - 1 (Packing)</span>
            <img src={clockIcon} onClick={() => setShowInputs(!showInputs)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {showInputs && (
            <fieldset className={styles.groupInput}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeFirstDay'}
                  value={formData.commonValues.addTimeFirstDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Add time:'}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeFirstDay'}
                  value={formData.commonValues.removeTimeFirstDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Remove time:'}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          )}
        </div>
        <div style={{ padding: '5px' }}>
          <div style={{ display: 'flex' }}>
            {rates.length === 0 && <p className={styles.warning} style={{ margin: '0px', marginLeft: '20px' }}>Select Department!</p>}
            {rates.length > 0 &&
              <label className={styles.decorateContainer1}>
                Rate:
                <select
                  className={styles.select}
                  value={formData.threeDays150Miles ? formData.threeDays150Miles.firstDayRate : 'none'}
                  onChange={(evt) => {
                    resetLastTarget();

                    const newValue = evt.target.value;
                    const newState = immutable.set(formData, 'threeDays150Miles.firstDayRate', newValue);
                    setCalculationFormValue(newState);
                    if (isCuFtDepartment) {
                      const secondDayRatePath = 'threeDays150Miles.secondDayRate';
                      const secondDayRateState = immutable.set(newState, secondDayRatePath, newValue);
                      setCalculationFormValue(secondDayRateState);

                      const thirdDayRatePath = 'threeDays150Miles.thirdDayRate';
                      const thirdDayRateState = immutable.set(secondDayRateState, thirdDayRatePath, newValue);
                      setCalculationFormValue(thirdDayRateState);

                      const unpackingRatePath = 'commonValues.unpackingRate';
                      const unpackingRateState = immutable.set(thirdDayRateState, unpackingRatePath, newValue)
                      setCalculationFormValue(unpackingRateState)
                    }
                  }}
                  style={{ marginBottom: '10px' }}
                  disabled={disabled}
                >
                  <option value={''}>None</option>
                  {
                    rates.map((rate, index) => {
                      return (
                        <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                      );
                    })
                  }
                </select>
              </label>
            }


            {formData.commonValues?.shuttle &&
              <div style={{ display: 'flex' }} className={styles.groupInput}>
                <label className={styles.shuttleOnDayOne}>
                  Shuttle on Day 1
                  <input
                    type='checkbox'
                    name={'shuttleOnDayOne'}
                    value={(formData.threeDays150Miles) ? formData.threeDays150Miles.shuttleOnDayOne : false}
                    checked={(formData.threeDays150Miles) ? formData.threeDays150Miles.shuttleOnDayOne : false}
                    onChange={checkboxValueChange}
                    disabled={disabled}
                  />
                </label>
                {(formData.department?.extraOptions?.shuttle.isCountable && formData.threeDays150Miles.shuttleOnDayOne) &&
                  <label className={styles.inputContainer}>
                    Qty:
                    <input
                      type='number'
                      name={'quantityDay1'}
                      value={formData.threeDays150Miles.quantityDay1}
                      placeholder={'ex: 2'}
                      onChange={onValueChange}
                      onWheel={(e) => e.target.blur()}
                      disabled={disabled}
                    />
                  </label>
                }
              </div>
            }
          </div>
          {(formData.commonValues.procedures.length) ?
            <label className={styles.inputContainer}>
              Procedures on Day 1
              <input
                className={styles.mycheckbox}
                type='checkbox'
                name={'proceduresOnDay1'}
                value={(formData.threeDays150Miles) ? formData.threeDays150Miles.proceduresOnDay1 : false}
                checked={(formData.threeDays150Miles) ? formData.threeDays150Miles.proceduresOnDay1 : false}
                onChange={proceduresCheckboxValueChangeDay1}
                disabled={disabled}
              />
            </label>
            :
            ''}

          <fieldset className={styles.groupInput} style={{ marginTop: '10px' }}>
            <div className={styles.divide}>
              {formData.department && formData.department.truckFee && formData.department.truckFee.calcMethod === "different" &&
                <label className={styles.inputContainer1} style={{ display: 'inline-block', marginLeft: '20px' }}>
                  Truck fee:
                  <input
                    onWheel={(e) => e.target.blur()}
                    type='number'
                    name={'truckFee'}
                    value={(formData._id) ? (formData.threeDays150Miles) ? formData.threeDays150Miles.truckFee : '' : getTruckFeeMin(formData.department.truckFee.diffTruckReservations)}
                    placeholder={'ex: 40'}
                    onChange={onValueChange}
                    disabled={disabled}
                  />
                </label>
              }


              <label className={styles.inputContainer} >
                <span>Movers to pack:</span>
                <select
                  className={styles.commonInput}
                  name={'moversToPackDay1'}
                  value={(formData.threeDays150Miles) ? formData.threeDays150Miles.moversToPackDay1 : ''}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                </select>
              </label>

              <label className={styles.inputContainer} style={{ display: 'inline-block', marginLeft: '20px', width: templatePage ? '200px' : null, marginLeft: templatePage ? '20px' : null }}>
                <span>Add Trucks:</span>
                <select
                  className={styles.commonInput}
                  name={'addTrucksDay1'}
                  value={(formData.threeDays150Miles) ? formData.threeDays150Miles.addTrucksDay1 : ''}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>
              </label>
            </div>

          </fieldset>
          <fieldset className={styles.groupInput}>
            <div className={styles.decorateContainer1}>
              {(formData.department && formData.department.extraOptions && formData.department.extraOptions.charges.length) ?
                <>  <hr className={styles.hLine} />
                  <ExtraOptionsValues
                    charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                    chargeCheckboxValueChange={chargeCheckboxValueChangeDayOne}
                    selectedChargesNames={formData.threeDays150Miles.selectedChargesNamesDay1}

                    lastTarget={lastTarget}
                    chargeQuantityChange={chargeQuantityChangeDayOne}
                    disabled={disabled}
                  />
                </>
                : ''
              }
            </div>
          </fieldset>
        </div>
      </div>

      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", width: '630px', marginTop: '20px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>DAY - 2 (Loading→Parking)</span>
            <img src={clockIcon} onClick={() => setShowInputsSecond(!showInputsSecond)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {showInputsSecond && (
            <fieldset className={styles.groupInput} style={{ marginLeft: '20px' }}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeSecondDay'}
                  value={formData.commonValues.addTimeSecondDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Add time:'}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeSecondDay'}
                  value={formData.commonValues.removeTimeSecondDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Remove time:'}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          )}
        </div>
        <div style={{ padding: '5px' }}>
          <div style={{ display: 'flex' }}>
            {rates.length === 0 && <p className={styles.warning}>Select Department!</p>}
            {rates.length > 0 &&
              <label style={{ marginBottom: "10px" }}>
                Rate:
                <select
                  className={styles.select}
                  value={formData.threeDays150Miles ? formData.threeDays150Miles.secondDayRate : 'none'}
                  onChange={(evt) => {
                    resetLastTarget();
                    const newValue = evt.target.value;
                    const newState = immutable.set(formData, 'threeDays150Miles.secondDayRate', newValue);
                    setCalculationFormValue(newState);
                    if (isCuFtDepartment) {
                      const thirdDayRatePath = 'threeDays150Miles.thirdDayRate';
                      const thirdDayRateState = immutable.set(newState, thirdDayRatePath, newValue);
                      setCalculationFormValue(thirdDayRateState);
                      const unpackingRatePath = 'commonValues.unpackingRate';
                      const unpackingRateState = immutable.set(thirdDayRateState, unpackingRatePath, newValue)
                      setCalculationFormValue(unpackingRateState)
                    }
                  }}
                  disabled={isCuFtDepartment || disabled}
                >
                  <option value={''}>None</option>
                  {
                    rates.map((rate, index) => {
                      return (
                        <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                      );
                    })
                  }
                </select>
              </label>
            }

            {isCuFtDepartment &&
              <label style={{ marginLeft: '10px' }}>Type of place
                <select value={formData.commonValues.additionalServicesPlaceType}
                  className={styles.commonInput}
                  onChange={evt => formChange(`commonValues.additionalServicesPlaceType`, 'set', evt.target.value)}
                  disabled={disabled}
                >
                  <option>None</option>
                  {formData.department.additionalServices.placeTypes.length && formData.department.additionalServices.placeTypes.map((placeType, pcftIndex) => {
                    return <option>{placeType.placeTypeName}</option>
                  })}
                </select>
              </label>
            }

            {formData.commonValues?.shuttle && <div style={{ display: 'flex' }} className={styles.groupInput}>
              <label className={styles.shuttleOnDayOne}>
                Shuttle on Day 2
                <input
                  type='checkbox'
                  name={'shuttleOnDay2'}
                  value={(formData.threeDays150Miles) ? formData.threeDays150Miles.shuttleOnDay2 : false}
                  checked={(formData.threeDays150Miles) ? formData.threeDays150Miles.shuttleOnDay2 : false}
                  onChange={checkboxValueChange}
                  disabled={disabled}
                />
              </label>
              {(formData.department.extraOptions?.shuttle.isCountable && formData.threeDays150Miles.shuttleOnDay2) &&
                <label className={styles.inputContainer}>
                  Qty:
                  <input
                    type='number'
                    name={'quantityDay2'}
                    value={(formData.threeDays150Miles.quantityDay2)}
                    placeholder={'ex: 2'}
                    onChange={onValueChange}
                    onWheel={(e) => e.target.blur()}
                    disabled={disabled}
                  />
                </label>
              }
            </div>
            }
          </div>

          {(formData.commonValues.procedures.length) ?
            <label className={styles.inputContainer}>
              Procedures on Day 2
              <input
                className={styles.mycheckbox}
                type='checkbox'
                name={'proceduresOnDay2'}
                value={(formData.threeDays150Miles) ? formData.threeDays150Miles.proceduresOnDay2 : false}
                checked={(formData.threeDays150Miles) ? formData.threeDays150Miles.proceduresOnDay2 : false}
                onChange={proceduresCheckboxValueChangeDay2}
                disabled={disabled}
              />
            </label>
            :
            ''}


          <fieldset className={styles.groupInput}>
            <div style={{ display: "flex", marginTop: '10px' }}>
              <label className={styles.commonLabel} >
                <span>Movers:</span>
                <select
                  className={styles.commonInput}
                  name={'moversDay2'}
                  value={(formData.threeDays150Miles) ? formData.threeDays150Miles.moversDay2 : ''}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                </select>
              </label>



              <label className={styles.inputContainer} style={{ marginLeft: "15px" }}>
                {(isCuFtDepartment) ? 'Round Trip Moving Miles' : 'Miles'}
                <input
                  onWheel={(e) => e.target.blur()}
                  style={{ width: "45px" }}
                  type='number'
                  name={'milesDay2'}
                  value={(formData.threeDays150Miles) ? formData.threeDays150Miles.milesDay2 : ''}
                  placeholder={'ex: 2'}
                  onChange={onValueChange}
                  disabled={disabled}
                />
              </label>

              <label className={styles.commonLabel} style={{ marginLeft: "15px" }}>
                <span>Drive Time</span>
                <select
                  style={{ width: "65px", marginLeft: "3px" }}
                  className={styles.commonInput}
                  name={'driveTimeDay2'}
                  value={(formData.threeDays150Miles) ? formData.threeDays150Miles.driveTimeDay2 : ''}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option>0</option>
                  <option>0.05</option>
                  <option>0.10</option>
                  <option>0.16</option>
                  <option>0.25</option>
                  <option>0.33</option>
                  <option>0.5</option>
                  <option>0.75</option>
                  <option>1</option>
                  <option>1.25</option>
                  <option>1.5</option>
                  <option>1.75</option>
                  <option>2</option>
                  <option>2.25</option>
                  <option>2.5</option>
                  <option>2.75</option>
                  <option>3</option>
                  <option>3.25</option>
                  <option>3.5</option>
                  <option>3.75</option>
                  <option>4</option>
                  <option>4.25</option>
                  <option>4.5</option>
                  <option>4.75</option>
                  <option>5</option>
                  <option>5.25</option>
                  <option>5.5</option>
                  <option>5.75</option>
                  <option>6</option>
                  <option>6.25</option>
                  <option>6.5</option>
                  <option>6.75</option>
                  <option>7</option>
                  <option>7.25</option>
                  <option>7.5</option>
                  <option>7.75</option>
                  <option>8</option>
                  <option>8.25</option>
                  <option>8.5</option>
                  <option>8.75</option>
                  <option>9</option>
                  <option>9.25</option>
                  <option>9.5</option>
                  <option>9.75</option>
                  <option>10</option>
                  <option>10.25</option>
                  <option>10.5</option>
                  <option>10.75</option>
                  <option>11</option>
                  <option>11.25</option>
                  <option>11.5</option>
                  <option>11.75</option>
                  <option>12</option>
                </select>
              </label>
            </div>
            <div style={{ display: 'flex', marginTop: '10px' }}>
              <label className={styles.commonLabel}>
                <span>Add Trucks:</span>
                <select
                  className={styles.commonInput1}
                  name={'addTrucksDay2'}
                  value={formData.threeDays150Miles.addTrucksDay2}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option key='0'>0</option>
                  <option key='1'>1</option>
                  <option key='2'>2</option>
                  <option key='3'>3</option>
                  <option key='4'>4</option>
                  <option key='5'>5</option>
                  <option key='6'>6</option>
                  <option key='7'>7</option>
                  <option key='8'>8</option>
                  <option key='9'>9</option>
                  <option key='10'>10</option>
                </select>
              </label>

              <label className={styles.commonLabel} style={{ marginLeft: "15px" }}>
                <span>Remove Trucks:</span>
                <select
                  className={styles.commonInput1}
                  name={'removeTrucksDay2'}
                  value={formData.threeDays150Miles.removeTrucksDay2}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option key='0'>0</option>
                  <option key='1'>1</option>
                  <option key='2'>2</option>
                  <option key='3'>3</option>
                  <option key='4'>4</option>
                  <option key='5'>5</option>
                  <option key='6'>6</option>
                  <option key='7'>7</option>
                  <option key='8'>8</option>
                  <option key='9'>9</option>
                  <option key='10'>10</option>
                </select>
              </label>
            </div>
          </fieldset>


          {(formData.department && Object.keys(formData.department).length && formData.department && formData.department.extraOptions && formData.department.extraOptions.charges.length)
            ?
            <>
              <hr className={styles.hLine} />
              <ExtraOptionsValues
                charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                chargeCheckboxValueChange={chargeCheckboxValueChangeDayTwo}
                selectedChargesNames={formData.threeDays150Miles.selectedChargesNamesDay2}
                lastTarget={lastTarget}
                chargeQuantityChange={chargeQuantityChangeDayTwo}
                disabled={disabled}
              />
            </>
            : ''
          }
        </div>
      </div>


      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '630px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>DAY - 3 (Parking→Unloading)</span>
            <img src={clockIcon} onClick={() => setShowInputsThird(!showInputsThird)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {showInputsThird && (
            <fieldset className={styles.groupInput} style={{ marginLeft: '20px' }}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeThirdDay'}
                  value={formData.commonValues.addTimeThirdDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Add time:'}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeThirdDay'}
                  value={formData.commonValues.removeTimeThirdDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Remove time:'}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          )}
        </div>
        <div style={{ padding: '5px' }}>
          <fieldset className={styles.groupInput} >


            {rates.length === 0 && <p className={styles.warning1} style={{ margin: '0px', marginBottom: "10px" }}>Select Department!</p>}
            {rates.length > 0 &&
              <div >
                <label style={{ marginBottom: "10px" }}>
                  Rate:
                  <select
                    className={styles.select}
                    value={(formData.threeDays150Miles) ? formData.threeDays150Miles.thirdDayRate : 'none'}
                    onChange={(evt) => {
                      resetLastTarget();
                      formChange('threeDays150Miles.thirdDayRate', 'set', evt.target.value);
                    }}
                    disabled={isCuFtDepartment || disabled}
                  >
                    <option value={''}>None</option>
                    {
                      rates.map((rate, index) => {
                        return (
                          <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                        );
                      })
                    }
                  </select>
                </label>



                {(formData.commonValues.procedures.length > 0 && formData.commonValues.procedures[0] && formData.commonValues.procedures[0].procedureType !== "None" && formData.commonValues.procedures[0].procedureType !== "") ?
                  <label className={styles.proceduresOnDay1} style={{ marginLeft: '20px' }}>
                    Procedures on Day 3
                    <input
                      type='checkbox'
                      name={'proceduresOnDay3'}
                      value={(formData.threeDays150Miles) ? formData.threeDays150Miles.proceduresOnDay3 : false}
                      checked={(formData.threeDays150Miles) ? formData.threeDays150Miles.proceduresOnDay3 : false}
                      onChange={proceduresCheckboxValueChangeDay3}
                      disabled={disabled}
                    />
                  </label>
                  : ""}
              </div>
            }

            {formData.commonValues?.shuttle && <>
              <label className={styles.shuttleOnDayOne} >
                Shuttle on Day 3
                <input
                  type='checkbox'
                  name={'shuttleOnDay3'}
                  value={(formData.threeDays150Miles) ? formData.threeDays150Miles.shuttleOnDay3 : false}
                  checked={(formData.threeDays150Miles) ? formData.threeDays150Miles.shuttleOnDay3 : false}
                  onChange={checkboxValueChange}
                  disabled={disabled}
                />
              </label>
              {(formData.department.extraOptions?.shuttle.isCountable && formData.commonValues?.shuttle) && formData.threeDays150Miles.shuttleOnDay2 &&
                <label className={styles.inputContainer}>
                  Qty:
                  <input
                    type='number'
                    name={'quantityDay3'}
                    value={(formData.threeDays150Miles.quantityDay3)}
                    placeholder={'ex: 2'}
                    onChange={onValueChange}
                    onWheel={(e) => e.target.blur()}
                    disabled={disabled}
                  />
                </label>
              }</>
            }



            <div className={styles.groupInput}>
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <label className={styles.commonLabel}>
                  <span>Movers:</span>
                  <select
                    className={styles.commonInput}
                    name={'moversDay3'}
                    value={(formData.threeDays150Miles) ? formData.threeDays150Miles.moversDay3 : ''}
                    onChange={onValueChange}
                    disabled={disabled}
                  >
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                  </select>
                </label>

                <label className={styles.inputContainer} style={{ marginLeft: "20px" }}>
                  {(isCuFtDepartment) ? 'Round Trip Moving Miles' : 'Miles'}
                  <input
                    onWheel={(e) => e.target.blur()}
                    style={{ width: "45px" }}
                    type='number'
                    name={'milesDay3'}
                    value={(formData.threeDays150Miles) ? formData.threeDays150Miles.milesDay3 : ''}
                    placeholder={'ex: 2'}
                    onChange={onValueChange}
                    disabled={disabled}
                  />
                </label>

                <label className={styles.commonLabel} style={{ marginLeft: "15px" }}>
                  <span>Drive Time</span>
                  <select
                    style={{ width: "65px", marginLeft: "3px" }}
                    className={styles.commonInput}
                    name={'driveTimeDay3'}
                    value={(formData.threeDays150Miles) ? formData.threeDays150Miles.driveTimeDay3 : ''}
                    onChange={onValueChange}
                    disabled={disabled}
                  >
                    <option>0</option>
                    <option>0.05</option>
                    <option>0.10</option>
                    <option>0.16</option>
                    <option>0.25</option>
                    <option>0.33</option>
                    <option>0.5</option>
                    <option>0.75</option>
                    <option>1</option>
                    <option>1.25</option>
                    <option>1.5</option>
                    <option>1.75</option>
                    <option>2</option>
                    <option>2.25</option>
                    <option>2.5</option>
                    <option>2.75</option>
                    <option>3</option>
                    <option>3.25</option>
                    <option>3.5</option>
                    <option>3.75</option>
                    <option>4</option>
                    <option>4.25</option>
                    <option>4.5</option>
                    <option>4.75</option>
                    <option>5</option>
                    <option>5.25</option>
                    <option>5.5</option>
                    <option>5.75</option>
                    <option>6</option>
                    <option>6.25</option>
                    <option>6.5</option>
                    <option>6.75</option>
                    <option>7</option>
                    <option>7.25</option>
                    <option>7.5</option>
                    <option>7.75</option>
                    <option>8</option>
                    <option>8.25</option>
                    <option>8.5</option>
                    <option>8.75</option>
                    <option>9</option>
                    <option>9.25</option>
                    <option>9.5</option>
                    <option>9.75</option>
                    <option>10</option>
                    <option>10.25</option>
                    <option>10.5</option>
                    <option>10.75</option>
                    <option>11</option>
                    <option>11.25</option>
                    <option>11.5</option>
                    <option>11.75</option>
                    <option>12</option>
                  </select>
                </label>
              </div>


              <div style={{ display: 'flex', marginTop: '10px' }}>
                <label className={styles.commonLabel} >
                  <span>Add Trucks:</span>
                  <select
                    className={styles.commonInput1}
                    name={'addTrucksDay3'}
                    value={formData.threeDays150Miles.addTrucksDay3}
                    onChange={onValueChange}
                    disabled={disabled}
                  >
                    <option key='0'>0</option>
                    <option key='1'>1</option>
                    <option key='2'>2</option>
                    <option key='3'>3</option>
                    <option key='4'>4</option>
                    <option key='5'>5</option>
                    <option key='6'>6</option>
                    <option key='7'>7</option>
                    <option key='8'>8</option>
                    <option key='9'>9</option>
                    <option key='10'>10</option>
                  </select>
                </label>

                <label className={styles.commonLabel} style={{ marginLeft: "15px" }}>
                  <span>Remove Trucks:</span>
                  <select
                    className={styles.commonInput1}
                    name={'removeTrucksDay3'}
                    value={formData.threeDays150Miles.removeTrucksDay3}
                    onChange={onValueChange}
                    disabled={disabled}
                  >
                    <option key='0'>0</option>
                    <option key='1'>1</option>
                    <option key='2'>2</option>
                    <option key='3'>3</option>
                    <option key='4'>4</option>
                    <option key='5'>5</option>
                    <option key='6'>6</option>
                    <option key='7'>7</option>
                    <option key='8'>8</option>
                    <option key='9'>9</option>
                    <option key='10'>10</option>
                  </select>
                </label>
              </div>
            </div>

          </fieldset>

          {(formData.department && formData.department.extraOptions && formData.department.extraOptions.charges.length) ?
            <div>
              <hr className={styles.hLine} />
              <ExtraOptionsValues
                charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                chargeCheckboxValueChange={chargeCheckboxValueChangeDayThree}
                selectedChargesNames={formData.threeDays150Miles.selectedChargesNamesDay3}
                lastTarget={lastTarget}
                chargeQuantityChange={chargeQuantityChangeDayThree}
                disabled={disabled}
              />
            </div>
            :
            <hr className={styles.hLine} />}


          <div>
            {(formData.department && formData.department?.type === 'cuft') &&
              <>
                <hr className={styles.hLine} />
                <AdditionalServicesValues
                  additionalServices={(formData.department && formData.department.additionalServices) ? formData.department.additionalServices : []}
                  selectedAdditionalServices={formData.commonValues.selectedAdditionalServices}
                  additionalServicesCheckboxValueChange={additionalServicesCheckboxValueChange}
                  additionalServicesPlaceType={formData.commonValues.additionalServicesPlaceType}
                  const aSQuantityChange={aSQuantityChange}
                  formChange={formChange}
                  disabled={disabled}
                />
              </>
            }
          </div>
          <hr className={styles.hLine} />
          <MiscChechMarksValues
            miscCheckMarks={(formData.department && formData.department.miscCheckMarks && formData.department.miscCheckMarks.miscCheckMarks.length) ? formData.department.miscCheckMarks.miscCheckMarks : []}
            selectedMiscCheckMarks={formData.selectedMiscCheckMarks}
            checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
            disabled={disabled}
          />
        </div>
      </div>


      {formData.commonValues.unpacking === 'Separate Day' &&
        <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '630px' }}>
          <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>UNPACKING ON SEPARATE DAY</span>
              <img src={clockIcon} onClick={() => setShowInputsUnpacking(!showInputsUnpacking)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
            </div>
            {showInputsUnpacking && (
              <fieldset className={styles.groupInput}>
                <div className={styles.decorateContainer}>
                  <AdjustTime
                    name={'addTimeUnp'}
                    value={formData.commonValues.addTimeUnp}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Add time:'}
                  />
                  <AdjustTime
                    name={'removeTimeUnp'}
                    value={formData.commonValues.removeTimeUnp}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Remove time:'}
                  />
                </div>
              </fieldset>
            )}
          </div>
          <div style={{ padding: '5px' }}>



            <fieldset className={styles.groupInput}>
              {rates.length === 0 && <p className={styles.warning}>Select Department!</p>}
              {rates.length > 0 &&
                <label style={{ marginTop: '10px' }}>
                  Rate:
                  <select
                    className={styles.select}
                    value={formData.commonValues.unpackingRate}
                    onChange={(evt) => {
                      resetLastTarget();
                      formChange('commonValues.unpackingRate', 'set', evt.target.value);
                    }}
                    style={{ marginTop: '10px' }}
                    disabled={isCuFtDepartment || disabled}
                  >
                    <option value={''} key='none'>None</option>
                    {
                      rates.map((rate, index) => {
                        return (
                          <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                        );
                      })
                    }
                  </select>
                </label>
              }
              <label style={{ marginLeft: '40px', marginTop: "5px" }}>
                Additional amount:
                <input
                  onWheel={(e) => e.target.blur()}
                  style={{ width: "60px" }}
                  type='number'
                  name={'additionalAmountUnpacking'}
                  value={formData.commonValues.additionalAmountUnpacking}
                  placeholder={'ex: 2'}
                    onChange={(evt) => { formChange(`commonValues.additionalAmountUnpacking`, 'set', evt.target.value) }}
                  disabled={disabled}
                />
              </label>
            </fieldset>

            <fieldset className={styles.groupInput}>
              <label className={styles.checkLabel} style={{ width: "400px", justifyContent: "flex-start", marginTop: '10px' }} >
                <span>Movers to Unpack:</span>
                <select
                  className={styles.commonInput}
                  name={'moversToUnPackSeparate'}
                  value={(formData.commonValues.moversToUnPackSeparate) ? formData.commonValues.moversToUnPackSeparate : ''}
                  onChange={(evt) => {
                    resetLastTarget();
                    formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                  }
                  }
                >
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>
              </label>
            </fieldset>
            {formData.department && formData.department.truckFee && formData.department.truckFee.calcMethod === "different" &&
              <fieldset className={styles.groupInput} style={{ marginLeft: '-20px', marginTop: '10px' }}>
                <label className={styles.inputContainer} style={{ width: "400px", justifyContent: "flex-start" }}>
                  Truck fee:
                  <input
                    onWheel={(e) => e.target.blur()}
                    type='number'
                    name={'truckFeeSeparate'}
                    value={(formData._id) ? (formData.threeDays150Miles) ? formData.threeDays150Miles.truckFeeSeparate : '' : getTruckFeeMin(formData.department.truckFee.diffTruckReservations)}
                    placeholder={'ex: 40'}
                    onChange={onValueChange}
                  />
                </label>
              </fieldset>
            }


            {formData.commonValues?.shuttle &&
              <div style={{ display: 'flex' }} className={styles.groupInput}>
                <label className={styles.shuttleOnDayTwo} style={{ marginTop: '10px' }}>
                  Shuttle on Unpacking Day
                  <input
                    type='checkbox'
                    name={'shuttleOnUnpacking'}
                    value={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                    checked={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                    onChange={sepCheckboxValueChange}
                    style={{ marginRight: '20px' }}
                  />
                </label>
                {formData.commonValues.shuttleOnUnpacking &&
                  <label className={styles.inputContainer} style={{ marginTop: '10px' }}>
                    Qty:
                    <input
                      type='number'
                      name={'quantityUnpacking'}
                      value={formData.commonValues.quantityUnpacking}
                      placeholder={'ex: 2'}
                      onChange={(evt) => {
                        resetLastTarget();
                        formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                      }}
                      onWheel={(e) => e.target.blur()}
                    />
                  </label>
                }
              </div>
            }
          </div>
        </div>}
    </section>
  );
};