import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { SocketContext } from '../../context/socket';
import useCollapse from 'react-collapsed'

import { getCheckMarkLabelById, getCurrentRatePercent, getHigherNumberOfHours, getLowerNumberOfHours, getTagsOptions, replaceSpaces } from '../../util/utils';

// Styles
import styles from "./TemplateTwoDaysParams.module.css";
//Components
import { TemplateSeparateDayView } from '../template-separate-day-view/Template-separate-day-view';
import { useDispatch, useSelector } from 'react-redux';
import { getUserName } from '../../clientStore/authSlice/auth-slice';
import { CalculationTabTwoDaysValues } from '../calculation-tab-two-days-values/Calculation-tab-two-days-values';
import { TemplatesExtraOptionsResults } from '../templates-extra-options-results/Templates-extra-options-results';
import { TemplatesAdditionalServicesResults } from '../templates-additional-services-results/Templates-additional-services-results';
import { setTotal_cubesG, setTotalFragileBoxes, setTotalMediumBoxes, setTotalSmallBoxes } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { CalculationTwoDaysParams } from '../calculation-two-days-params/Calculation-two-days-params';

export const TemplateTwoDaysParams = ({
  highLightItem,
  company,
  department,
  templateForm,
  rates,
  onCalcFormValueChange,
  resetLastTarget,
  setOptions,
  users,
  departments,
  lastTarget,
  setLastTarget,
  checkMarkCheckboxValueChange,
  additionalServicesCheckboxValueChange
}) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const userName = useSelector(getUserName);
  const scrollRefs = useRef([]);
  const calculationForm = templateForm.templateCalcObj;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const unpDepartment = { ...calculationForm.unpDepartment };

  const scrollKeys = ['inventoryText',
    'username', 'department', 'truckloads', 'additionalStopsCount', 'movers',
    'moversToLoad', 'moversToDrivesAndUnload',
    'rate', 'rateCash', 'rateCashLoad', 'rateCardLoad', 'truckfee',
    'rateCardUnload', 'rateCashUnload', 'trucks', 'shuttle', 'doubleDrive', 'twoTrips',
    'biggestOrOk', 'lowerNumberOfHours', 'higherNumberOfHours', 'packingKit', 'packingType',
    'packingKitWithTax', 'smallBox', 'mediumBox', 'largeBox', 'rollOfPaper', 'rollOfBubble',
    'liftGate', 'hardwoodFloorCovers', 'carpetFloorCovers', 'notToExceedPrice',
    'notToExceedPricePercent', 'cashDiscount', 'sameDayUnpack', 'separateDayUnpack',
    'trucksUnpackingSeparateDay', 'moversUnpackingSeparateDay', 'unpackingSeparateDayLowerhours',
    'unpackingSeparateDayHigherhours', 'totalCardUnpackingSeparateDay', 'totalCashUnpackingSeparateDay',
    'rateCashUnpackingSeparateDay', 'rateCardUnpackingSeparateDay', 'truckFeeUnpackingSeparateDay',
    'truckFeeUnpackingSeparateDay', 'combinedMileage', 'calculationNumber', 'trucksPercent', 'truckloadsRoundDown', 'moversToPackDay1', 'shuttleQtyDay1'];
  if (company) {
    if (company.extraOptCharges) {
      company.extraOptCharges.forEach((charge, index) => {
        scrollKeys.push(`chIdName_${index}`)
        scrollKeys.push(`chIdAmount_${index}`)
      })
    }
    if (company.additProcedures) {
      company.additProcedures.forEach((procedure, index) => {
        scrollKeys.push(`pIdName_${index}`)
        scrollKeys.push(`pIdCost_${index}`)
        scrollKeys.push(`pIdQty_${index}`)
      })
    }
    if (company.miscCheckMarks) {
      company.miscCheckMarks.forEach((checkMark, index) => {
        scrollKeys.push(`mChId_${index}`)
      })
    }
  }
  scrollKeys.forEach(element => {
    scrollRefs.current[element] = React.createRef()
  });

  const commonValues = calculationForm.commonValues;
  const twoDaysPacking = calculationForm.twoDaysPacking;
  const [Total_cubes, setTotal_cubes] = useState(0);
  const [Total_small_boxes, setTotal_small_boxes] = useState(0);
  const [Total_medium_boxes, setTotal_medium_boxes] = useState(0)
  const [Total_fragile_boxes, setTotal_fragile_boxes] = useState(0)
  const [Total_box_cubes, setTotal_box_cubes] = useState(0)
  const [Truckloads, setTruckloads] = useState(0);
  const [Stop_CN_Truckload, setStop_CN_Truckload] = useState(0);
  const [Stop_1_Truckload, setStop_1_Truckload] = useState(0);
  const [Double_drive, setDouble_drive] = useState(0);
  const [Heavy_fee, setHeavy_fee] = useState(0);
  const [Packing_kit_Show, setPacking_kit_Show] = useState(0);
  const [Packing_kit_total_amount_with_taxes, setPacking_kit_total_amount_with_taxes] = useState(0);
  const [Movers_To_Pack_Day1, setMovers_To_Pack_Day1] = useState(0);
  const [Trucks_To_Pack_Day1, setTrucks_To_Pack_Day1] = useState(0);
  const [Driving_Time_Day1, setDriving_Time_Day1] = useState(0);
  const [Unloading_hours_Day1, setUnloading_hours_Day1] = useState(0);
  const [Loading_hours_Day_1, setLoading_hours_Day_1] = useState(0);
  const [Total_Hours_Day_1, setTotal_Hours_Day_1] = useState(0);
  const [Packing_Time_Day1, setPacking_Time_Day1] = useState(0);
  const [Wrapping_time_Day1, setWrapping_time_Day1] = useState(0);
  const [Adjusted_loading_time_Day2, setAdjusted_loading_time_Day2] = useState(0);
  const [Lower_Number_of_Hours_Day1, setLower_Number_of_Hours_Day1] = useState(0);
  const [Higher_Number_of_Hours_Day1, setHigher_Number_of_Hours_Day1] = useState(0);
  const [TWO_Trips_Or_OK_Day2, setTWO_Trips_Or_OK_Day2] = useState(0);
  const [Lower_Number_of_Hours_Day2, setLower_Number_of_Hours_Day2] = useState(0);
  const [Higher_Number_of_Hours_Day2, setHigher_Number_of_Hours_Day2] = useState(0);
  const [Total_Cash_Day2, setTotal_Cash_Day2] = useState(0);
  const [Total_Card_Day2, setTotal_Card_Day2] = useState(0);
  const [Loading_hours_Day2, setLoading_hours_Day2] = useState(0);
  const [Movers_Day2, setMovers_Day2] = useState(0);
  const [Rate_cash_Movers_To_Unload_Day2, setRate_cash_Movers_To_Unload_Day2] = useState(0);
  const [Rate_card_Movers_To_Unload_Day2, setRate_card_Movers_To_Unload_Day2] = useState(0);
  const [Total_Destinationfee_Day2, setTotal_Destinationfee_Day2] = useState(0);
  const [Rate_cash_Movers_To_Load_Day2, setRate_cash_Movers_To_Load_Day2] = useState(0);
  const [Rate_card_Movers_To_Load_Day2, setRate_card_Movers_To_Load_Day2] = useState(0);
  const [Truck_fee_Day2, setTruck_fee_Day2] = useState(0);
  const [Rate_cash_Day2, setRate_cash_Day2] = useState(0);
  const [Rate_card_Day2, setRate_card_Day2] = useState(0);
  const [Rate_cash_Day1, setRate_cash_Day1] = useState(0);
  const [Rate_card_Day1, setRate_card_Day1] = useState(0);
  const [Truck_fee_Day1, setTruck_fee_Day1] = useState(0);
  const [Trucks_Day2, setTrucks_Day2] = useState(0);
  const [Unloading_hours_Day2, setUnloading_hours_Day2] = useState(0);
  const [Total_Hours_Day2, setTotal_Hours_Day2] = useState(0);
  const [Drivetime_Day2, setDrivetime_Day2] = useState(0);
  const [cubicf_Day1, setcubicf_Day1] = useState(0);
  const [Total_Cash_Day1, setTotal_Cash_Day1] = useState(0);
  const [Total_Card_Day1, setTotal_Card_Day1] = useState(0);
  const [Total_Card_2Days_Packing, setTotal_Card_2Days_Packing] = useState(0);
  const [Total_Cash_2Days_Packing, setTotal_Cash_2Days_Packing] = useState(0);
  const [BIGGEST_or_OK_Day2, setBIGGEST_or_OK_Day2] = useState('');
  const [Packing_Charge, setPacking_Charge] = useState(0)
  const [Flat_rate_Card_2Days_Packing, setFlat_rate_Card_2Days_Packing] = useState(0)
  const [Flat_rate_Cash_2Days_Packing, setFlat_rate_Cash_2Days_Packing] = useState(0)

  const [Combined_mileage, setCombined_mileage] = useState(0)
  const [Trucks_Unpacking_Separate_Day, setTrucks_Unpacking_Separate_Day] = useState(0)
  const [Movers_Unpacking_Separate_Day, setMovers_Unpacking_Separate_Day] = useState(0)
  const [Unpacking_Separate_Day_Lower_hours, setUnpacking_Separate_Day_Lower_hours] = useState(0)
  const [Unpacking_Separate_Day_Higher_hours, setUnpacking_Separate_Day_Higher_hours] = useState(0)
  const [Total_Card_Unpacking_Separate_Day, setTotal_Card_Unpacking_Separate_Day] = useState(0)
  const [Total_Cash_Unpacking_Separate_Day, setTotal_Cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_cash_Unpacking_Separate_Day, setRate_cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_card_Unpacking_Separate_Day, setRate_card_Unpacking_Separate_Day] = useState(0)
  const [Truck_fee_Unpacking_Separate_Day, setTruck_fee_Unpacking_Separate_Day] = useState(0)

  const socket = useContext(SocketContext);
  const isCuFtDepartment = department?.type === 'cuft';

  useEffect(() => {

    socket.on('Total_cubes', (data) => { setTotal_cubes(data); dispatch(setTotal_cubesG(data)); });
    socket.on('Total_small_boxes', (data) => {
      setTotal_small_boxes(data)
      dispatch(setTotalSmallBoxes(data));
    });
    socket.on('Total_medium_boxes', (data) => {
      setTotal_medium_boxes(data)
      dispatch(setTotalMediumBoxes(data));
    });
    socket.on('Total_fragile_boxes', (data) => {
      setTotal_fragile_boxes(data)
      dispatch(setTotalFragileBoxes(data));
    });
    socket.on('Total_box_cubes', (data) => {
      setTotal_box_cubes(data)
    });
    socket.on('Truckloads', (data) => {
      setTruckloads(data)
    });
    socket.on('Stop_CN_Truckload', (data) => {
      setStop_CN_Truckload(data)
    });
    socket.on('Stop_1_Truckload', (data) => {
      setStop_1_Truckload(data)
    });
    socket.on('Double_drive', (data) => {
      setDouble_drive(data)
    });
    socket.on('Heavy_fee', (data) => {
      setHeavy_fee(data)
    });
    socket.on('Packing_kit_Show', (data) => {
      setPacking_kit_Show(data)
    });
    socket.on('Packing_kit_total_amount_with_taxes', (data) => {
      setPacking_kit_total_amount_with_taxes(data)
    });
    socket.on('Movers_To_Pack_Day1', (data) => {
      setMovers_To_Pack_Day1(data)
    });
    socket.on('Trucks_To_Pack_Day1', (data) => {
      setTrucks_To_Pack_Day1(data)
    });
    socket.on('Driving_Time_Day1', (data) => {
      setDriving_Time_Day1(data)
    });
    socket.on('Unloading_hours_Day1', (data) => {
      setUnloading_hours_Day1(data)
    });
    socket.on('Loading_hours_Day_1', (data) => {
      setLoading_hours_Day_1(data)
    });
    socket.on('Total_Hours_Day_1', (data) => {
      setTotal_Hours_Day_1(data)
    });
    socket.on('Packing_Time_Day1', (data) => {
      setPacking_Time_Day1(data)
    });
    socket.on('Wrapping_time_Day1', (data) => {
      setWrapping_time_Day1(data)
    });
    socket.on('Adjusted_loading_time_Day2', (data) => {
      setAdjusted_loading_time_Day2(data)
    });
    socket.on('Lower_Number_of_Hours_Day1', (data) => {
      setLower_Number_of_Hours_Day1(data)
    });
    socket.on('Higher_Number_of_Hours_Day1', (data) => {
      setHigher_Number_of_Hours_Day1(data)
    });
    socket.on('TWO_Trips_Or_OK_Day2', (data) => {
      setTWO_Trips_Or_OK_Day2(data)
    });
    socket.on('Lower_Number_of_Hours_Day2', (data) => {
      setLower_Number_of_Hours_Day2(data)
    });
    socket.on('Higher_Number_of_Hours_Day2', (data) => {
      setHigher_Number_of_Hours_Day2(data)
    });
    socket.on('Total_Cash_Day2', (data) => {
      setTotal_Cash_Day2(data)
    });
    socket.on('Total_Card_Day2', (data) => {
      setTotal_Card_Day2(data)
    });
    socket.on('Loading_hours_Day2', (data) => {
      setLoading_hours_Day2(data)
    });
    socket.on('Movers_Day2', (data) => {
      setMovers_Day2(data)
    });
    socket.on('Rate_cash_Movers_To_Unload_Day2', (data) => {
      setRate_cash_Movers_To_Unload_Day2(data)
    });
    socket.on('Rate_card_Movers_To_Unload_Day2', (data) => {
      setRate_card_Movers_To_Unload_Day2(data)
    });
    socket.on('Total_Destinationfee_Day2', (data) => {
      setTotal_Destinationfee_Day2(data)
    });
    socket.on('Rate_cash_Movers_To_Load_Day2', (data) => {
      setRate_cash_Movers_To_Load_Day2(data)
    });
    socket.on('Rate_card_Movers_To_Load_Day2', (data) => {
      setRate_card_Movers_To_Load_Day2(data)
    });
    socket.on('Truck_fee_Day2', (data) => {
      setTruck_fee_Day2(data)
    });
    socket.on('Rate_cash_Day2', (data) => {
      setRate_cash_Day2(data)
    });
    socket.on('Rate_card_Day2', (data) => {
      setRate_card_Day2(data)
    });
    socket.on('Rate_cash_Day1', (data) => {
      setRate_cash_Day1(data)
    });
    socket.on('Rate_card_Day1', (data) => {
      setRate_card_Day1(data)
    });
    socket.on('Truck_fee_Day1', (data) => {
      setTruck_fee_Day1(data)
    });
    socket.on('Trucks_Day2', (data) => {
      setTrucks_Day2(data)
    });
    socket.on('Unloading_hours_Day2', (data) => {
      setUnloading_hours_Day2(data)
    });
    socket.on('Total_Hours_Day2', (data) => {
      setTotal_Hours_Day2(data)
    });
    socket.on('Drivetime_Day2', (data) => {
      setDrivetime_Day2(data)
    });
    socket.on('cubicf_Day1', (data) => {
      setcubicf_Day1(data)
    });
    socket.on('Total_Cash_Day1', (data) => {
      setTotal_Cash_Day1(data)
    });
    socket.on('Total_Card_Day1', (data) => {
      setTotal_Card_Day1(data)
    });
    socket.on('Total_Card_2Days_Packing', (data) => {
      setTotal_Card_2Days_Packing(data)
    });
    socket.on('Total_Cash_2Days_Packing', (data) => {
      setTotal_Cash_2Days_Packing(data)
    });
    socket.on('BIGGEST_or_OK_Day2', (data) => {
      setBIGGEST_or_OK_Day2(data)
    });


    socket.on('Combined_mileage', (data) => { setCombined_mileage(data) });
    socket.on('Trucks_Unpacking_Separate_Day', (data) => setTrucks_Unpacking_Separate_Day(data));
    socket.on('Movers_Unpacking_Separate_Day', (data) => setMovers_Unpacking_Separate_Day(data));
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => setUnpacking_Separate_Day_Lower_hours(data));
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => setUnpacking_Separate_Day_Higher_hours(data));
    socket.on('Total_Card_Unpacking_Separate_Day', (data) => setTotal_Card_Unpacking_Separate_Day(data));
    socket.on('Total_Cash_Unpacking_Separate_Day', (data) => setTotal_Cash_Unpacking_Separate_Day(data));
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => setRate_cash_Unpacking_Separate_Day(data));
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => setRate_card_Unpacking_Separate_Day(data));
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => setTruck_fee_Unpacking_Separate_Day(data));
  }, [calculationForm])


  const sepCheckboxValueChange = (evt) => {
    resetLastTarget();

    switch (evt.target.value) {
      case 'true':
        return onCalcFormValueChange(`commonValues.${evt.target.name}`, 'set', false);
      case 'false':
        return onCalcFormValueChange(`commonValues.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };


  useEffect(() => {

    if (highLightItem) {
      try {
        scrollRefs.current[highLightItem].current.scrollIntoView({ block: 'nearest' });
      }
      catch {

      }
    }
  }, [highLightItem]);

  useEffect(() => {
    let currentOptions = getTagsOptions({
      company: company, templateCalcType: templateForm.templateCalcType, users: users, departments: departments, calculation: templateForm.templateCalcObj,
      calculatedParams: {
        Total_cubes,
        Total_small_boxes,
        Total_medium_boxes,
        Total_fragile_boxes,
        Total_box_cubes,
        Stop_1_Truckload,
        Stop_CN_Truckload,
        Truckloads,
        Double_drive,
        Heavy_fee,
        Packing_kit_Show,
        Packing_kit_total_amount_with_taxes,
        Combined_mileage
      }
    });

    setOptions(currentOptions);
  }, [templateForm.tags, templateForm.templateCalcObj]);

  return (
    <div className={styles.parentCont}>
      {(calculationForm && Object.keys(calculationForm).length) ?
        <CalculationTabTwoDaysValues
          rates={(rates.length > 0) ? rates : (calculationForm.department && calculationForm.department.rates) ? calculationForm.department.rates.ratesList : []}
          formData={calculationForm}
          formChange={onCalcFormValueChange}
          resetLastTarget={resetLastTarget}
          sepCheckboxValueChange={sepCheckboxValueChange}
          templatePage
          lastTarget={lastTarget}
          checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
          setLastTarget={setLastTarget}
        />
        : ""}

      <div>
        <h3 className={`${styles.title} ${(highLightItem === 'username') ? styles.highLighted : ""}`} ref={scrollRefs?.current['username']}>{userName}</h3>
        <h3 className={`${styles.title}`}><span className={`${(highLightItem === 'calculationNumber') ? styles.highLighted : ""}`} ref={scrollRefs?.current['calculationNumber']}>{calculationForm.calculationNumber} </span><span className={`${(highLightItem === 'department') ? styles.highLighted : ""}`} ref={scrollRefs?.current['department']}>{calculationForm && calculationForm.department && calculationForm.department.departmentName}</span>{/*  <span className={`${(highLightItem === 'rate') ? styles.highLighted : ""}`}>XP</span> */}</h3>
        <div className={`${styles.title}`}>
          <p className={styles.calculationDate}>

            {(calculationForm && Object.keys(calculationForm).length && calculationForm.twoDaysPacking.firstDayDate) ? <>
              <span className={`${(highLightItem === 'day1weekday') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1weekday']}>{new Date(calculationForm.twoDaysPacking.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })}, </span>
              <span className={`${(highLightItem === 'day1month') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1month']}>{new Date(calculationForm.twoDaysPacking.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day1date') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1date']}>{new Date(calculationForm.twoDaysPacking.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day1year') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1year']}>{new Date(calculationForm.twoDaysPacking.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })}</span>
            </> : ""}
            {calculationForm && Object.keys(calculationForm).length && twoDaysPacking.secondDayDate && twoDaysPacking.firstDayDate && ' &  '}
            {(calculationForm && Object.keys(calculationForm).length && calculationForm.twoDaysPacking.secondDayDate) ? <>
              <span className={`${(highLightItem === 'day2weekday') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2weekday']}>{new Date(calculationForm.twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })}, </span>
              <span className={`${(highLightItem === 'day2month') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2month']}>{new Date(calculationForm.twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day2date') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2date']}>{new Date(calculationForm.twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day2year') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2year']}>{new Date(calculationForm.twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })}</span>
            </> : ""}
          </p>
        </div>
        <CalculationTwoDaysParams
          commonValues={commonValues}
          twoDaysPacking={twoDaysPacking}
          department={department}
          unpDepartment={unpDepartment}
          selectedMiscCheckMarks={calculationForm.selectedMiscCheckMarks}
          calculationData={calculationForm}
          scrollRefs={scrollRefs}
          highLightItem={highLightItem}
        />
      </div>
    </div >
  );
};

