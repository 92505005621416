import { API_URL, Operators } from './const';

export const showAlterComponent = (condition, primaryComponent, alterComponent) => {
  return condition ? primaryComponent : alterComponent;
};

export const onChangeFormValue = (evt, form, handler) => {
  handler({ ...form, [evt.target.name]: evt.target.value });
};

export const fetchedData = async (url, method, sendData = undefined) => {
  const body = sendData ? JSON.stringify(sendData) : null;
  const response = await fetch(`${API_URL}/${url}`, {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body
  });
  const data = await response.json();
  if (!response.ok) { // Checks if status code is not in the range 200-299
    return {
      status: response.status,
      error: true,
      message: data.message || 'An error occurred', // Assuming the error message is in the response, otherwise a default message
    };
  }
  return {
    status: response.status,
    data
  };
};

export const parseName = (key) => {
  return key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
};

export const parseNameRemoveIndex = (key) => {
  return key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()).replace(/[0-9]/g, '');;
};

export const setPackingOptions = (calculationType) => {
  let packingOptions = [];
  switch (calculationType) {
    case "oneDayPacking":
      packingOptions = [
        <option key="No">No</option>,
        <option key="Yes">Yes</option>,
        <option key="Kitchen">Kitchen</option>,
        <option key="Partial">Partial</option>
      ];
      break;
    case "twoDaysPacking":
      packingOptions = [
        <option key="No">No</option>,
        <option key="Yes">Yes</option>,
        <option key="Kitchen">Kitchen</option>,
        <option key="Partial">Partial</option>
      ];
      break;
    default:
      packingOptions = [
        <option key="No">No</option>,
        <option key="Yes">Yes</option>,
        <option key="Kitchen">Kitchen</option>,
        <option key="Partial">Partial</option>
      ]
      break;
  }
  return packingOptions;
}

export const getSectionNames = (userRole) => {
  let SectionNames = {};
  switch (userRole) {
    case 'superuser':
      SectionNames = {
        settings: 'Settings',
        calculations: 'Calculations',
        users: 'Users',
        companies: 'Companies',
        /*  actions: 'Actions list' */
      }
      break;

    case 'companyuser':
      SectionNames = {
        settings: 'Settings',
        calculations: 'Calculations',
        users: 'Users',
      };
      break;

    case 'admin':
      SectionNames = {
        settings: 'Settings',
        calculations: 'Calculations',
        users: 'Users',
      };
      break;

    default:
      SectionNames = {
        calculations: 'Calculations',
      };
      break;
  }
  return SectionNames;
}

export const getChargesResText = (department = {}, selectedChargesNames = [], trucksCount) => {
  let chargesText = ''
  department.extraOptions.charges.forEach((charge, chargeIndex) => {
    let showCharge = false;
    showCharge = selectedChargesNames.some(item => item.key.includes(charge[`uId${chargeIndex}`]));
    if (showCharge && (charge[`chargeName${chargeIndex}`] !== '' || charge[`amount${chargeIndex}`] !== '')) {
      /*   chargesText += '• ' + charge[`chargeName${chargeIndex}`] +
          ((charge[`isCountable${chargeIndex}`]) ? ' x ' + selectedChargesNames.find(item => item.key === charge[`uId${chargeIndex}`]).quantity : '') +
          ': $' + (((charge[`isCountable${chargeIndex}`])) ? selectedChargesNames.find(item => item.key === charge[`uId${chargeIndex}`]).quantity * charge[`amount${chargeIndex}`] : charge[`amount${chargeIndex}`]) +
          ((charge[`period${chargeIndex}`] === 'hourly') ? '/h' : '').toString() +
          ((charge[`perTruck${chargeIndex}`]) ? ' (per truck)' : '') + '\n'; */
      chargesText += '• ' + charge[`chargeName${chargeIndex}`] + `($${charge[`amount${chargeIndex}`]})` +
        ((charge[`isCountable${chargeIndex}`]) ? ' x ' + selectedChargesNames.find(item => item.key === charge[`uId${chargeIndex}`]).quantity : '') +
        ': $' + (
          ((charge[`isCountable${chargeIndex}`]))
            ? selectedChargesNames.find(item => item.key === charge[`uId${chargeIndex}`]).quantity * charge[`amount${chargeIndex}`]
            : charge[`amount${chargeIndex}`]
        ) +
        (charge[`perTruck${chargeIndex}`] ? ' x ' + trucksCount + ' truck(s)=$' + (charge[`amount${chargeIndex}`]) * trucksCount * selectedChargesNames.find(item => item.key === charge[`uId${chargeIndex}`]).quantity : '') +
        ((charge[`period${chargeIndex}`] === 'hourly') ? '/h' : '') + '\n';

    }
  });
  return chargesText;
}

export const generateUnpackingText = (commonValues, department, isCuFtDepartment, Movers_Unpacking_Separate_Day, Rate_cash_Unpacking_Separate_Day, Rate_card_Unpacking_Separate_Day, Truck_fee_Unpacking_Separate_Day, Trucks_Unpacking_Separate_Day, Unpacking_Separate_Day_Lower_hours, Unpacking_Separate_Day_Higher_hours, UnPacking_Charge, Total_Cash_Unpacking_Separate_Day, Total_Card_Unpacking_Separate_Day, anyCalculationType, Flat_rate_Unpacking_Separate_Day_cash, Flat_rate_Unpacking_Separate_Day) => {
  let unpackingText = '';
  if (commonValues.unpacking === 'Separate Day') {
    unpackingText = `
Unpacking on separate day
Movers: ${Movers_Unpacking_Separate_Day}
${(!isCuFtDepartment) ? `Rate:${(department.rates && department.rates.calcMethod === 'deduct')
        ? "$" + Rate_cash_Unpacking_Separate_Day + "h /"
        + "$" + Rate_card_Unpacking_Separate_Day + '/h'
        + ((Truck_fee_Unpacking_Separate_Day !== 0) ? "+ $" + Truck_fee_Unpacking_Separate_Day.toString() + " truck(s) fee" : "").toString()
        :
        "$" + (Rate_card_Unpacking_Separate_Day).toString() + '/h' +
        ((Truck_fee_Unpacking_Separate_Day !== 0) ? "+ $" + Truck_fee_Unpacking_Separate_Day.toString() + " truck(s) fee" : "").toString()}` : ''
      }
${(isCuFtDepartment && Truck_fee_Unpacking_Separate_Day) ? `Truck Fee: $${Truck_fee_Unpacking_Separate_Day}` : ''}
Trucks: ${Trucks_Unpacking_Separate_Day}${(commonValues.shuttleOnUnpacking) ? "+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${commonValues.quantityUnpacking})` : '') : ''}
Total time range: ${getTotalTimeRange((department) ? department.minimumHours : 0, Unpacking_Separate_Day_Lower_hours, Unpacking_Separate_Day_Higher_hours)}
${(commonValues.additionalAmountUnpacking) ? (
        "Additional amount: $" + commonValues.additionalAmountUnpacking
      ) : ''
      }
${((isCuFtDepartment && commonValues.unpacking === 'Same Day') && UnPacking_Charge) ? `UnPacking Charge:${UnPacking_Charge}` : ''}
${(!isCuFtDepartment) ? `NOT TO EXCEED PRICE:${(department.rates && department.rates.calcMethod === 'deduct') ? Total_Cash_Unpacking_Separate_Day + ' cash / $' + Total_Card_Unpacking_Separate_Day + " card" : Total_Card_Unpacking_Separate_Day}` : ''}
${(!isCuFtDepartment && department.rates && department.rates.calcMethod !== 'deduct') ? `NOT TO EXCEED PRICE with ${getCurrentRatePercent(anyCalculationType, department)}% cash discount:$${Total_Cash_Unpacking_Separate_Day}` : ""}
${(isCuFtDepartment) ? `FLAT RATE: $${(department.rates && department.rates.calcMethod === 'deduct') ? Flat_rate_Unpacking_Separate_Day_cash + ' cash / $' + Flat_rate_Unpacking_Separate_Day + " card" : Flat_rate_Unpacking_Separate_Day}
${(department.rates && department.rates.calcMethod !== 'deduct') ? `FLAT RATE with ${getCurrentRatePercent(anyCalculationType, department)}% cash discount: $${Flat_rate_Unpacking_Separate_Day_cash}` : ""}` : ''}`
  }
  return unpackingText;
}

export const generateUnpackingLongText = (commonValues, anyCalculationType, unpDepartment, Trucks_Unpacking_Separate_Day, Movers_Unpacking_Separate_Day, Unpacking_Separate_Day_Lower_hours, Unpacking_Separate_Day_Higher_hours, Rate_cash_Unpacking_Separate_Day, Rate_card_Unpacking_Separate_Day, Total_Card_Unpacking_Separate_Day_Long_and_OOS, Truck_fee_Unpacking_Separate_Day) => {
  let unpackingLongText = '';
  if (commonValues.unpacking === 'Separate Day') {
    unpackingLongText = `Unpacking on separate day ${(commonValues.partialUnPacking) ? "PARTIAL" : ''}
Movers: ${Movers_Unpacking_Separate_Day}
Rate: ${(unpDepartment.rates && unpDepartment.rates.calcMethod === 'deduct') ? "$" + Rate_cash_Unpacking_Separate_Day + "h /"
        + "$" + Rate_card_Unpacking_Separate_Day
        + ((Truck_fee_Unpacking_Separate_Day !== 0) ? "+ $" + Truck_fee_Unpacking_Separate_Day.toString() + " truck(s) fee" : "").toString()
        :
        "$" + (Rate_card_Unpacking_Separate_Day).toString() + "/h" +
        ((Truck_fee_Unpacking_Separate_Day !== 0) ? "+ $" + Truck_fee_Unpacking_Separate_Day.toString() + " truck(s) fee" : "").toString()
      }
Trucks: ${Trucks_Unpacking_Separate_Day}${(commonValues.shuttleOnUnpacking) ? "+ SHUTTLE " + ((unpDepartment.extraOptions.shuttle.isCountable) ? `(x${commonValues.quantityUnpacking})` : '') : ''}
Total time range: ${getTotalTimeRange((unpDepartment) ? unpDepartment.minimumHours : 0, Unpacking_Separate_Day_Lower_hours, Unpacking_Separate_Day_Higher_hours)}
${(commonValues.additionalAmountUnpacking) ? (
        "Additional amount: $" + commonValues.additionalAmountUnpacking
      ) : ''
      }
FLAT RATE: $${Total_Card_Unpacking_Separate_Day_Long_and_OOS}`
  }
  return unpackingLongText;
}


export const generateNotIncludedServicesText = (department, Total_cubes, commonValues) => {
  let notIncludedServicesText = '';
  if (department && department.additionalServices) {
    notIncludedServicesText = `Additional Services not included in the estimate:
${department && department.additionalServices && department.additionalServices.services.map((service, index) => {
      if (service.serviceType === 'cuft') {
        for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
          let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
          if (performComparison(pricePerCuFt[`operator${pcftIndex}`], Number(Total_cubes), Number(pricePerCuFt[`cubicFtRange${pcftIndex}`]))) {
            return `\n- ${service.serviceName}: $${pricePerCuFt[`pricePerCuFt${pcftIndex}`]}`;
          }
        }
      } else if (service.serviceType === 'factor') {
        for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
          let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
          if (performComparison(pricePerCuFt[`operator${pcftIndex}`], commonValues.additionalServicesPlaceType, pricePerCuFt[`factorName${pcftIndex}`])) {
            return `\n- ${service.serviceName}: $${pricePerCuFt[`pricePerCuFt${pcftIndex}`]}`;
          }
        }
      }
      else {
        return null
      }
    }).filter(service => service !== null).join('')}
${(department.packingCharge) ? `- Full packing labor and supplies such as boxes, bubble wrap: $${department.packingCharge.fullPacking} per cubic foot
- Partial packing: $${department.packingCharge.partialPacking} per cubic foot
- Kitchen packing: $${department.packingCharge.kitchenPacking} per cubic foot
- $${department.packing.supplies.perOnePackedBox} per box if you'd only like to have us pack up a few things
- Heavy item over 300lbs: $${department.extraOptions.heavyItem}` : ''}`;
  }
  return notIncludedServicesText;
}

export const getAdditionalServicesResText = (department = {}, selectedAdditionalServices = [], trucksCount, additionalServicesPlaceType, Total_cubes) => {

  let servicesText = '';
  if (additionalServicesPlaceType && selectedAdditionalServices && selectedAdditionalServices.length) {
    servicesText = 'Additional Services:\n'
    department.additionalServices.services.forEach((service) => {
      let showAS = false;
      showAS = selectedAdditionalServices.some(item => item.key.includes(service.serviceName));
      if (showAS) {
        if (service.serviceType === 'cuft') {
          for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
            let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
            if (performComparison(pricePerCuFt[`operator${pcftIndex}`], Number(Total_cubes), Number(pricePerCuFt[`cubicFtRange${pcftIndex}`]))) {
              let totalCubes = service.fixedAmount ? 1 : Total_cubes;
              servicesText += `${service.serviceName}($${Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`])}${(!service.fixedAmount) ? '/cuft' : ''})` +
                ((service.isCountable) ? ' x ' + selectedAdditionalServices.find(item => item.key === service.serviceName).quantity : '') +
                `: $${((service.isCountable) ? selectedAdditionalServices.find(item => item.key === service.serviceName).quantity * Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`] * totalCubes) : Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]) * totalCubes)}` +
                ((service.perTruck) ? ` x ${trucksCount} truck(s)=$${(Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`])) * trucksCount * selectedAdditionalServices.find(item => item.key === service.serviceName).quantity * totalCubes}` : '') + '\n'
                ;
              break;
            }
          }
        } else if (service.serviceType === 'factor') {
          for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
            let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
            if (performComparison(pricePerCuFt[`operator${pcftIndex}`], additionalServicesPlaceType, pricePerCuFt[`factorName${pcftIndex}`])) {
              let totalCubes = service.fixedAmount ? 1 : Total_cubes;
              servicesText +=
                `${service.serviceName}($${Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`])})` +
                ((service.isCountable) ? ' x ' + selectedAdditionalServices.find(item => item.key === service.serviceName).quantity : '') +
                `: $${((service.isCountable) ? selectedAdditionalServices.find(item => item.key === service.serviceName).quantity * Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`] * totalCubes) : Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]) * totalCubes)}` +
                ((service.perTruck) ? ` x ${trucksCount} truck(s)=$${(Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`])) * trucksCount * selectedAdditionalServices.find(item => item.key === service.serviceName).quantity * totalCubes}` : '') + '\n'
                ;
              break;
            }
          }
        }
      }
    });
  }
  return servicesText;
}

export const getCurrentRatePercent = (currentDepartment, department) => {
  try {
    if (Object.keys(department).length !== 0 && department.rates) {
      let currentRate, currentRateIndex;
      if (currentDepartment.rate) {
        department.rates.ratesList.forEach((rate, index) => {
          if (rate[`rateName${index}`] === currentDepartment.rate) {
            currentRate = rate;
            currentRateIndex = index;
          }
        });
      }
      else {
        department.rates.ratesList.forEach((rate, index) => {
          if (rate[`rateName${index}`] === currentDepartment.secondDayRate) {
            currentRate = rate;
            currentRateIndex = index;
          }
        });
      }

      if (currentRate) {
        return currentRate[`cashDiscount${currentRateIndex}`];
      }
    }
  }
  catch (err) {
    console.log(err)
  }
}


{/* if any off Long distance extra(based on expenses-> rate) in rates filled then show */ }
{/* show based on departments->selected rate->Long distance extra*/ }
export const showDepartmentSelect = (department) => {
  let showDepartmentSelect = false;
  return true;
  /* if(department.) */
}

export const confirmDeletion = (text) => {
  //return confirm("Are you sure to delete " + text + "?");
}

export const getTruckFeeMin = (diffTruckReservations) => {
  let truckFeeMin = 0;
  if (diffTruckReservations.length) {
    truckFeeMin = Number(diffTruckReservations[0].fee);
    diffTruckReservations.forEach(diffTruckReservation => {
      if (Number(diffTruckReservation.fee) < truckFeeMin) {
        truckFeeMin = Number(diffTruckReservation.fee);
      }
    });
  }
  return truckFeeMin;
}

export const hasRole = (currentUserRole, requiredRoles) => {
  return requiredRoles.includes(currentUserRole);
};

export const getTagsOptions = (props) => {
  const { company, templateCalcType, users, departments, longdistances, calculation, calculatedParams } = props;
  var oneDayPacking = calculation.oneDayPacking;
  var department = calculation.department;
  var commonValues = calculation.commonValues;
  const isCuFtDepartment = department?.type === 'cuft';

  var additionalServicesOptions = [];


  let usersOptions = [
    { value: '', label: 'Please select' }
  ];
  let departmentOptions = [
    { value: '', label: 'Please select' }
  ];

  let longDistancesOptions = [
    { value: '', label: 'Please select' }
  ];

  let oosOptions = [
    { value: '', label: 'Please select' }
  ];
  if (users && users.length) {
    usersOptions = users.map((user) => ({ value: user._id, label: user.login }))
  }

  const options = [];

  if (isCuFtDepartment) {

    department.additionalServices.services.map((service) => {
      options.push({ value: `aSIdName_${service.serviceName}`, label: `${service.serviceName}`, type: 'selectable', selectOptions: [{ value: service.serviceName, label: service.serviceName }, { value: 'none', label: 'None' }] })
      options.push({ value: `aSIdAmount_${service.serviceName}`, label: `${service.serviceName} amount`, type: 'amount' })
      options.push({ value: `aSIdQty_${service.serviceName}`, label: `${service.serviceName} qty`, type: 'amount' })

    })
    //options.push({ value: `chIdName_${charge[`uId${index}`]}`, label: charge[`id${index}`], type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }]})

    options.push(
      { value: `fullPackingPrice`, label: `Full packing price`, type: 'amount' },
      { value: `partialPackingPrice`, label: `Partial packing price`, type: 'amount' },
      { value: `kitchenPackingPrice`, label: `Kitchen packing price`, type: 'amount' },
      { value: `perBoxPrice`, label: `Per box price`, type: 'amount' },
      { value: `unpackingDayRoundTripMiles`, label: `Unpacking day Round trip miles`, type: 'amount' },
    )
  }


  if (departments && departments.length) {
    departments.forEach(department => {
      departmentOptions.push({ value: department._id, label: department.departmentName });
    });
  }

  if (longdistances && longdistances.length) {
    longdistances.forEach(department => {
      departmentOptions.push({ value: department._id, label: department.departmentName });
    });
  }

  options.push(
    { value: 'username', label: 'UserName', type: 'selectable', selectOptions: usersOptions, color: 'blue' },
    { value: 'calculationNumber', label: 'Reference number', type: 'selectable', selectOptions: [{ value: 'calculationNumber', label: calculation.calculationNumber }, { value: 'none', label: 'None' }] },
  )

  options.push(
    { value: 'partial', label: 'PARTIAL', type: 'selectable', selectOptions: [{ value: 'partial', label: 'PARTIAL' }, { value: 'none', label: 'None' }] },
    { value: 'partialSeparate', label: 'PARTIAL(separate)', type: 'selectable', selectOptions: [{ value: 'partialSeparate', label: 'PARTIAL' }, { value: 'none', label: 'None' }] },
  )

  options.push(
    { value: 'totalCubicFt', label: 'Total Cubic ft', type: 'amount' },
    { value: 'cuftWithinBuilding', label: 'Cu ft within building', type: 'amount' },
    { value: 'cuftToMove', label: 'Cu ft to move', type: 'amount' },
    { value: 'additionalAmount', label: 'Additional amount', type: 'amount' },
    { value: 'additionalAmountUnpacking', label: 'Additional Unpacking amount', type: 'amount' },
    { value: 'inventoryText', label: 'Inventory', type: 'selectable', selectOptions: [{ value: 'inventoryText', label: 'Inventory' }, { value: 'none', label: 'None' }] },

  )




  switch (templateCalcType) {
    case 'oneDayPacking':

      if (company) {
        if (company.extraOptCharges) {
          company.extraOptCharges.forEach((charge, index) => {
            options.push({ value: `chIdName_${charge[`uId${index}`]}`, label: charge[`id${index}`], type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty', type: 'amount', color: 'purple' })
          });
        }

        if (company.additProcedures) {
          company.additProcedures.forEach((procedure, index) => {
            options.push({ value: `pIdName_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' amount', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty', type: 'amount', color: 'cyan' })
          });
        }

        if (company.miscCheckMarks) {
          company.miscCheckMarks.forEach((checkMark, index) => {
            options.push({ value: `mChId_${checkMark[`uId${index}`]}`, label: checkMark[`id${index}`], type: 'selectable', selectOptions: [{ value: checkMark[`id${index}`], label: checkMark[`id${index}`] }, { value: 'none', label: 'None' }], color: 'blue' })
          });
        }
      }



      switch (company.departmentRateType) {//calculated, custom
        case 'calculated':
          switch (company.departmentCalcMethod) {
            case 'discount':

              break;

            case 'deduct':
              break;

            default:
              break;
          }

          break;

        case 'custom':
          switch (company.departmentCalcMethod) {
            case 'discount':

              break;

            case 'deduct':

              break;

            default:
              break;
          }
          break;

        default:
          break;
      }



      options.push(
        { value: 'department', label: 'Department', type: 'selectable', selectOptions: departmentOptions },
      )

      options.push(
        {
          value: 'day1weekday', label: 'Day 1 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day1month', label: 'Day 1 month', type: 'amount' },
        { value: 'day1date', label: 'Day 1 date', type: 'amount' },
        { value: 'day1year', label: 'Day 1 year', type: 'amount' },
      )

      options.push(
        { value: 'truckloads', label: 'Truckloads', type: 'amount' },
        { value: 'trucksPercent', label: 'Trucks percent', type: 'amount' },
        { value: 'truckloadsRoundDown', label: 'Full trucks', type: 'amount' },
        { value: 'combinedMileage', label: 'Total miles', type: 'amount' },
        { value: 'additionalStopsCount', label: 'Additional stops count', type: 'amount' },
      )

      if (isCuFtDepartment) {

        options.push(
          { value: 'totalRoundTripMiles', label: 'Total round trip miles', type: 'amount' },
        )

        options.push(
          {
            value: 'additionalServices', label: 'Additional Services', type: 'selectable', selectOptions: [
              { value: 'additionalServices', label: 'Additional Services' },
              { value: 'none', label: 'None' }
            ]
          },
        )
        options.push(
          {
            value: 'extraOptions', label: 'Extra options', type: 'selectable', selectOptions: [
              { value: 'extraOptions', label: 'Extra options' },
              { value: 'none', label: 'None' }
            ]
          },
        )
        options.push(
          {
            value: 'procedures', label: 'Procedures', type: 'selectable', selectOptions: [
              { value: 'procedures', label: 'Procedures' },
              { value: 'none', label: 'None' }
            ]
          },
        )
        options.push(
          { value: 'packingCharge', label: 'Packing Charge', type: 'amount' },
          { value: 'unPackingCharge', label: 'UnPacking Charge', type: 'amount' },
          { value: 'flatRateOneDayCash', label: 'FLAT RATE Cash', type: 'amount' },
          { value: 'flatRateOneDayCard', label: 'FLAT RATE Card', type: 'amount' },

          { value: 'flatRateUnpackingSeparateDayCash', label: 'Flat Rate Unpacking Separate Day Cash', type: 'amount' },
          { value: 'flatRateUnpackingSeparateDayCard', label: 'Flat Rate Unpacking Separate Day Card', type: 'amount' },
        )
      }

      options.push(
        { value: 'moversToLoad', label: 'Movers to load', type: "amount" },
      )
      options.push(
        { value: 'rateCashLoad', label: 'Rate Cash load', type: 'amount' },
      )
      options.push(
        { value: 'rateCardLoad', label: 'Rate Card load', type: 'amount' },
      )

      options.push(
        { value: 'movers', label: 'Movers', type: "amount" },
        { value: 'rate', label: 'Rate card', type: 'amount' },
      )

      options.push(
        { value: 'rateCash', label: 'Rate cash', type: 'amount' },
      )

      options.push(
        { value: 'truckfee', label: 'Truck fee', type: 'amount' },
      )

      options.push(
        { value: 'moversToDrivesAndUnload', label: 'Movers to unload', type: "amount" },
        { value: 'rateCardUnload', label: 'Rate Card UnLoad', type: 'amount' },
      )

      options.push(
        { value: 'rateCashUnload', label: 'Rate Cash UnLoad', type: 'amount' },
      )

      options.push(
        { value: 'trucks', label: 'Trucks', type: 'amount' },
      )
      options.push(
        { value: 'shuttle', label: 'SHUTTLE', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQty', label: 'SHUTTLE qty', type: 'amount' },
      )

      options.push(
        { value: 'biggestOrOk', label: 'Biggest or ok', type: 'selectable', selectOptions: [{ value: 'BIGGEST TRUCK', label: 'BIGGEST TRUCK' }, { value: 'OK', label: 'OK' }] },
      )

      options.push(
        { value: 'twoTrips', label: 'Two trips', type: 'selectable', selectOptions: [{ value: '2 TRIPS', label: '2 TRIPS' }, { value: 'OK', label: 'OK' }] },
      )

      options.push(
        { value: 'doubleDrive', label: 'Double Drive', type: 'selectable', selectOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }] },
        { value: 'lowerNumberOfHours', label: 'Total Time Range Min', type: 'amount' },
        { value: 'higherNumberOfHours', label: 'Total Time Range Max', type: 'amount' },
      )
      options.push(
        { value: 'destinationFee', label: 'Destination fee', type: 'amount' },
      )

      options.push(
        { value: 'heavyFee', label: 'Extra Heavy Items Charge', type: 'amount' },
      )

      options.push(
        { value: 'liftGate', label: 'LIFTGATE ', type: 'selectable', selectOptions: [{ value: 'liftGate', label: 'LIFTGATE' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'packingKit', label: 'Packing Kit Amount', type: 'amount' },
        { value: 'packingType', label: 'Packing Type', type: 'selectable', selectOptions: [{ value: 'No', label: 'No' }, { value: 'Yes', label: "Full" }, { value: 'Kitchen', label: 'Kitchen' }, { value: 'Partial', label: 'Partial' }] },
        { value: 'packingKitWithTax', label: 'Packing with tax', type: 'amount' },
        { value: 'smallBox', label: 'Small box', type: 'amount' },
        { value: 'mediumBox', label: 'Medium box', type: 'amount' },
        { value: 'largeBox', label: 'Large box', type: 'amount' },
        { value: 'rollOfPaper', label: 'Roll of packing paper', type: 'amount' },
        { value: 'rollOfBubble', label: 'Roll of bubble wrap', type: 'amount' },
      )
      options.push(
        { value: 'notToExceedPrice', label: 'NOT TO EXCEED PRICE', type: 'amount' },
      )
      options.push(
        { value: 'notToExceedPricePercent', label: 'Cash discount percent', type: 'amount' },
      )
      options.push(
        { value: 'cashDiscount', label: 'NOT TO EXCEED PRICE cash', type: 'amount' },
      )
      options.push(
        { value: 'sameDayUnpack', label: 'Same day unpack', type: 'selectable', selectOptions: [{ value: 'sameDayUnpack', label: 'UNPACKING' }, { value: 'none', label: 'None' }] },
        { value: 'separateDayUnpack', label: 'Separate day unpack', type: 'selectable', selectOptions: [{ value: 'separateDayUnpack', label: 'Unpacking on separate day' }, { value: 'none', label: 'None' }] },
        { value: 'trucksUnpackingSeparateDay', label: 'Separate Trucks', type: 'amount' },
        { value: 'moversUnpackingSeparateDay', label: 'Separate Movers', type: 'amount' },
        { value: 'unpackingSeparateDayLowerhours', label: 'Separate Total time range lower', type: 'amount' },
        { value: 'unpackingSeparateDayHigherhours', label: 'Separate Total time range higher', type: 'amount' },
        { value: 'rateCardUnpackingSeparateDay', label: 'Separate Rate card', type: 'amount' },
        { value: 'totalCardUnpackingSeparateDay', label: 'Separate NOT TO EXCEED PRICE Card', type: 'amount' },
        { value: 'unpackingSeparatePercent', label: 'Separate Cash discount percent', type: 'amount' },
        { value: 'unpackingShuttle', label: 'SHUTTLE Separate Unpacking Day', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'unpackingShuttleQty', label: 'SHUTTLE Separate Unpacking Day qty', type: 'amount' },
      )

      //if (department.rates && department.rates.calcMethod === 'deduct') {
      options.push(
        { value: 'totalCashUnpackingSeparateDay', label: 'Separate NOT TO EXCEED PRICE Cash', type: 'amount' },
        { value: 'rateCashUnpackingSeparateDay', label: 'Separate Rate cash', type: 'amount' },
      )
      //}


      options.push(
        { value: 'truckFeeUnpackingSeparateDay', label: 'Separate Truck fee', type: 'amount' },
      )
      break;

    case 'twoDaysPacking':
      options.push(
        { value: 'department', label: 'Department', type: 'selectable', selectOptions: departmentOptions },
      )

      if (company) {
        if (company.extraOptCharges) {
          company.extraOptCharges.forEach((charge, index) => {
            options.push({ value: `chIdName1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 1)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'purple' })

            options.push({ value: `chIdName2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 2)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'purple' })
          });
        }

        if (company.additProcedures) {
          company.additProcedures.forEach((procedure, index) => {
            options.push({ value: `pIdName1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 1)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'cyan' })

            options.push({ value: `pIdName2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 2)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'cyan' })
          });
        }

        if (company.miscCheckMarks) {
          company.miscCheckMarks.forEach((checkMark, index) => {
            options.push({ value: `mChId_${checkMark[`uId${index}`]}`, label: checkMark[`id${index}`], type: 'selectable', selectOptions: [{ value: checkMark[`id${index}`], label: checkMark[`id${index}`] }, { value: 'none', label: 'None' }], color: 'blue' })
          });
        }
      }


      options.push(
        {
          value: 'day1weekday', label: 'Day 1 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day1month', label: 'Day 1 month', type: 'amount' },
        { value: 'day1date', label: 'Day 1 date', type: 'amount' },
        { value: 'day1year', label: 'Day 1 year', type: 'amount' },
      )


      options.push(
        {
          value: 'day2weekday', label: 'Day 2 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day2month', label: 'Day 2 month', type: 'amount' },
        { value: 'day2date', label: 'Day 2 date', type: 'amount' },
        { value: 'day2year', label: 'Day 2 year', type: 'amount' },
      )


      options.push(
        { value: 'truckloads', label: 'Truckloads', type: 'amount' },
        { value: 'trucksPercent', label: 'Trucks percent', type: 'amount' },
        { value: 'truckloadsRoundDown', label: 'Full trucks', type: 'amount' },
        { value: 'combinedMileage', label: 'Total miles', type: 'amount' },
        { value: 'additionalStopsCount', label: 'Additional stops count', type: 'amount' },
        { value: 'moversToPackDay1', label: 'Movers(day 1)', type: 'amount' },
        { value: 'rateCashDay1', label: 'Rate Cash(day 1)', type: 'amount' },
        { value: 'rateCardDay1', label: 'Rate Card(day 1)', type: 'amount' },
        { value: 'truckFeeOnDay1', label: 'Truck(s) fee(day 1)', type: 'amount' },
        { value: 'trucksToPackDay1', label: 'Trucks(day 1)', type: 'amount' },
        { value: 'shuttleDay1', label: 'SHUTTLE(day 1)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay1', label: 'SHUTTLE qty(day 1)', type: 'amount' },
        { value: 'cubicFtToMoveDay1', label: 'Cubic Ft to move(day 1)', type: 'amount' },
        { value: 'doubleDriveDay1', label: 'Double Drive(day 1)', type: 'selectable', selectOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }] },
        { value: 'lowerNumberOfHoursDay1', label: 'Lower Number Of Hours(day 1)', type: 'amount' },
        { value: 'higherNumberOfHoursDay1', label: 'Higher Number Of Hours(day1)', type: 'amount' },
        { value: 'packingKit', label: 'Packing kit', type: 'amount' },
        { value: 'packingKitWithTax', label: 'Packing kit with tax', type: 'amount' },
        { value: 'packingType', label: 'Packing Type', type: 'selectable', selectOptions: [{ value: 'No', label: 'No' }, { value: 'Yes', label: "Full" }, { value: 'Kitchen', label: 'Kitchen' }, { value: 'Partial', label: 'Partial' }] },
        { value: 'packingKitWithTax', label: 'Packing with tax', type: 'amount' },
        { value: 'smallBox', label: 'Small box', type: 'amount' },
        { value: 'mediumBox', label: 'Medium box', type: 'amount' },
        { value: 'largeBox', label: 'Large box', type: 'amount' },
        { value: 'rollOfPaper', label: 'Roll of packing paper', type: 'amount' },
        { value: 'rollOfBubble', label: 'Roll of bubble wrap', type: 'amount' },
        { value: 'moversToLoad', label: 'Movers to Load', type: 'amount' },
        { value: 'cashDiscountPercent', label: 'Cash discount percent', type: 'amount' },
        { value: 'rateCashMoversToLoadDay2', label: 'Rate Cash Movers To Load(day 2)', type: 'amount' },
        { value: 'rateCardMoversToLoadDay2', label: 'Rate Card Movers To Load(day2)', type: 'amount' },
        { value: 'truckFeeDay2', label: 'Truck fee(day 2)', type: 'amount' },
        { value: 'moversDay2', label: 'Movers(day 2)', type: 'amount' },
        { value: 'rateCashDay2', label: 'Rate cash(day 2)', type: 'amount' },
        { value: 'rateCardDay2', label: 'Rate card(day 2)', type: 'amount' },
        { value: 'moversToDrivesAndUnload', label: 'Movers to Unload', type: 'amount' },
        { value: 'rateCashMoversToUnloadDay2', label: 'Rate Cash Movers To Unload(day 2)', type: 'amount' },
        { value: 'rateCardMoversToUnloadDay2', label: 'Rate Card Movers To Unload(day 2)', type: 'amount' },
        { value: 'trucksDay2', label: 'Trucks(day 2)', type: 'amount' },
        { value: 'shuttleDay2', label: 'SHUTTLE(day 2)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay2', label: 'SHUTTLE qty(day 2)', type: 'amount' },
        { value: 'biggestOrOkDay2', label: 'Biggest or ok(day 2)', type: 'selectable', selectOptions: [{ value: 'BIGGEST TRUCK', label: 'BIGGEST TRUCK' }, { value: 'OK', label: 'OK' }] },
        { value: 'twoTripsOrOkDay2', label: 'Two trips(day 2)', type: 'selectable', selectOptions: [{ value: '2 TRIPS', label: '2 TRIPS' }, { value: 'OK', label: 'OK' }] },
        { value: 'doubleDriveDay2', label: 'Double Drive(day 2)', type: 'selectable', selectOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }] },
        { value: 'destinationFee', label: 'Destination fee:', type: 'amount' },
        { value: 'lowerNumberOfHoursDay2', label: 'Total time Range lower', type: 'amount' },
        { value: 'higherNumberOfHoursDay2', label: 'Total time Range higher', type: 'amount' },
        { value: 'heavyFee', label: 'Extra Heavy Items Charge', type: 'amount' },
      )

      if (isCuFtDepartment) {
        options.push(
          { value: 'totalRoundTripMiles', label: 'Total round trip miles', type: 'amount' },
        )

        options.push(
          {
            value: 'additionalServices', label: 'Additional Services', type: 'selectable', selectOptions: [
              { value: 'additionalServices', label: 'Additional Services' },
              { value: 'none', label: 'None' }
            ]
          },
        )
        options.push(
          {
            value: 'extraOptions', label: 'Extra options', type: 'selectable', selectOptions: [
              { value: 'extraOptions', label: 'Extra options' },
              { value: 'none', label: 'None' }
            ]
          },
        )
        options.push(
          {
            value: 'procedures', label: 'Procedures', type: 'selectable', selectOptions: [
              { value: 'procedures', label: 'Procedures' },
              { value: 'none', label: 'None' }
            ]
          },
        )
        options.push(
          {
            value: 'extraOptionsDay2', label: 'Extra options Day 2', type: 'selectable', selectOptions: [
              { value: 'extraOptionsDay2', label: 'Extra options' },
              { value: 'none', label: 'None' }
            ]
          },
        )
        options.push(
          {
            value: 'proceduresDay2', label: 'Procedures Day 2', type: 'selectable', selectOptions: [
              { value: 'proceduresDay2', label: 'Procedures Day 2' },
              { value: 'none', label: 'None' }
            ]
          },
        )

        options.push(
          { value: 'packingCharge', label: 'Packing Charge', type: 'amount' },
          { value: 'unPackingCharge', label: 'UnPacking Charge', type: 'amount' },
          { value: 'flatRateOneDayCash', label: 'FLAT RATE Cash', type: 'amount' },
          { value: 'flatRateOneDayCard', label: 'FLAT RATE Card', type: 'amount' },

          { value: 'flatRateUnpackingSeparateDayCash', label: 'Flat Rate Unpacking Separate Day Cash', type: 'amount' },
          { value: 'flatRateUnpackingSeparateDayCard', label: 'Flat Rate Unpacking Separate Day Card', type: 'amount' },
        )
      }

      options.push(
        { value: 'liftGate', label: 'LIFTGATE ', type: 'selectable', selectOptions: [{ value: 'liftGate', label: 'LIFTGATE' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'notToExceedPricePercent', label: 'Cash discount percent', type: 'amount' },
      )

      options.push(
        { value: 'totalCash2DaysPacking', label: 'NOT TO EXCEED PRICE cash', type: 'amount' },
        { value: 'totalCard2DaysPacking', label: 'NOT TO EXCEED PRICE card', type: 'amount' },
      )

      options.push(
        { value: 'sameDayUnpack', label: 'Same day unpack', type: 'selectable', selectOptions: [{ value: 'sameDayUnpack', label: 'UNPACKING' }, { value: 'none', label: 'None' }] },
        { value: 'separateDayUnpack', label: 'Separate day unpack', type: 'selectable', selectOptions: [{ value: 'separateDayUnpack', label: 'Unpacking on separate day' }, { value: 'none', label: 'None' }] },
        { value: 'trucksUnpackingSeparateDay', label: 'Separate Trucks', type: 'amount' },
        { value: 'moversUnpackingSeparateDay', label: 'Separate Movers', type: 'amount' },
        { value: 'unpackingSeparateDayLowerhours', label: 'Separate Total time range lower', type: 'amount' },
        { value: 'unpackingSeparateDayHigherhours', label: 'Separate Total time range higher', type: 'amount' },
        { value: 'rateCardUnpackingSeparateDay', label: 'Separate Rate card', type: 'amount' },
        { value: 'totalCardUnpackingSeparateDay', label: 'Separate NOT TO EXCEED PRICE Card', type: 'amount' },
        { value: 'unpackingSeparatePercent', label: 'Separate Cash discount percent', type: 'amount' },
        { value: 'unpackingShuttle', label: 'SHUTTLE Separate Unpacking Day', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'unpackingShuttleQty', label: 'SHUTTLE Separate Unpacking Day qty', type: 'amount' },
      )

      //if (department.rates && department.rates.calcMethod === 'deduct') {
      options.push(
        { value: 'totalCashUnpackingSeparateDay', label: 'Separate NOT TO EXCEED PRICE Cash', type: 'amount' },
        { value: 'rateCashUnpackingSeparateDay', label: 'Separate Rate cash', type: 'amount' },
      )
      //}

      options.push(
        { value: 'truckFeeUnpackingSeparateDay', label: 'Separate Truck fee', type: 'amount' },
      )

      break;

    case 'twoDays150Miles':
      options.push(
        { value: 'department', label: 'Department', type: 'selectable', selectOptions: departmentOptions },
      )

      if (company) {
        if (company.extraOptCharges) {
          company.extraOptCharges.forEach((charge, index) => {
            options.push({ value: `chIdName1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 1)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'purple' })

            options.push({ value: `chIdName2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 2)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'purple' })
          });
        }

        if (company.additProcedures) {
          company.additProcedures.forEach((procedure, index) => {
            options.push({ value: `pIdName1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 1)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'cyan' })

            options.push({ value: `pIdName2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 2)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'cyan' })
          });
        }

        if (company.miscCheckMarks) {
          company.miscCheckMarks.forEach((checkMark, index) => {
            options.push({ value: `mChId_${checkMark[`uId${index}`]}`, label: checkMark[`id${index}`], type: 'selectable', selectOptions: [{ value: checkMark[`id${index}`], label: checkMark[`id${index}`] }, { value: 'none', label: 'None' }], color: 'blue' })
          });
        }
      }


      options.push(
        {
          value: 'day1weekday', label: 'Day 1 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day1month', label: 'Day 1 month', type: 'amount' },
        { value: 'day1date', label: 'Day 1 date', type: 'amount' },
        { value: 'day1year', label: 'Day 1 year', type: 'amount' },
      )


      options.push(
        {
          value: 'day2weekday', label: 'Day 2 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day2month', label: 'Day 2 month', type: 'amount' },
        { value: 'day2date', label: 'Day 2 date', type: 'amount' },
        { value: 'day2year', label: 'Day 2 year', type: 'amount' },
      )

      options.push(
        { value: 'truckloads', label: 'Truckloads', type: 'amount' },
        { value: 'trucksPercent', label: 'Trucks percent', type: 'amount' },
        { value: 'truckloadsRoundDown', label: 'Full trucks', type: 'amount' },
        { value: 'combinedMileage', label: 'Total miles', type: 'amount' },
        { value: 'additionalStopsCount', label: 'Additional stops count', type: 'amount' },
        { value: 'movers150milesDay1', label: 'Movers(day 1)', type: 'amount' },
        { value: 'rateCash150milesDay1', label: 'Rate Cash(day 1)', type: 'amount' },
        { value: 'rateCard150milesDay1', label: 'Rate Card(day 1)', type: 'amount' },
        { value: 'truckFee150milesDay1', label: 'Truck(s) fee(day 1)', type: 'amount' },
        { value: 'trucks150milesDay1', label: 'Trucks(day 1)', type: 'amount' },
        { value: 'shuttleDay1', label: 'SHUTTLE(day 1)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay1', label: 'SHUTTLE qty(day 1)', type: 'amount' },
        { value: 'doubleDriveDay1', label: 'Double Drive(day 1)', type: 'selectable', selectOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }] },
        { value: 'lowerNumberOfHoursDay1', label: 'Lower Number Of Hours(day 1)', type: 'amount' },
        { value: 'higherNumberOfHoursDay1', label: 'Higher Number Of Hours(day1)', type: 'amount' },
        { value: 'packingKit', label: 'Packing kit', type: 'amount' },
        { value: 'packingKitWithTax', label: 'Packing kit with tax', type: 'amount' },
        { value: 'sameDayUnpack', label: 'Same day unpack', type: 'selectable', selectOptions: [{ value: 'sameDayUnpack', label: 'UNPACKING' }, { value: 'none', label: 'None' }] },
        { value: 'packingType', label: 'Packing Type', type: 'selectable', selectOptions: [{ value: 'No', label: 'No' }, { value: 'Yes', label: "Full" }, { value: 'Kitchen', label: 'Kitchen' }, { value: 'Partial', label: 'Partial' }] },
        { value: 'packingKitWithTax', label: 'Packing with tax', type: 'amount' },
        { value: 'smallBox', label: 'Small box', type: 'amount' },
        { value: 'mediumBox', label: 'Medium box', type: 'amount' },
        { value: 'largeBox', label: 'Large box', type: 'amount' },
        { value: 'rollOfPaper', label: 'Roll of packing paper', type: 'amount' },
        { value: 'rollOfBubble', label: 'Roll of bubble wrap', type: 'amount' },
        { value: 'moversDay2', label: 'Movers(day 2)', type: 'amount' },
        { value: 'rateCashDay2', label: 'Rate Cash(day 2)', type: 'amount' },
        { value: 'rateCardDay2', label: 'Rate Card(day2)', type: 'amount' },
        { value: 'truckFeeDay2', label: 'Truck fee(day 2)', type: 'amount' },
        { value: 'trucksDay2', label: 'Trucks(day 2)', type: 'amount' },
        { value: 'shuttleDay2', label: 'SHUTTLE(day 2)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay2', label: 'SHUTTLE qty(day 2)', type: 'amount' },
        { value: 'biggestOrOkDay2', label: 'Biggest or ok(day 2)', type: 'selectable', selectOptions: [{ value: 'BIGGEST TRUCK', label: 'BIGGEST TRUCK' }, { value: 'OK', label: 'OK' }] },
        { value: 'twoTripsOrOkDay2', label: 'Two trips(day 2)', type: 'selectable', selectOptions: [{ value: '2 TRIPS', label: '2 TRIPS' }, { value: 'OK', label: 'OK' }] },
        { value: 'doubleDriveDay2', label: 'Double Drive(day 2)', type: 'selectable', selectOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }] },
        { value: 'destinationFee', label: 'Destination fee', type: 'amount' },
        { value: 'lowerNumberOfHoursDay2', label: 'Total time Range lower(day 2)', type: 'amount' },
        { value: 'higherNumberOfHoursDay2', label: 'Total time Range higher(day 2)', type: 'amount' },
        { value: 'heavyFee', label: 'Extra Heavy Items Charge', type: 'amount' },
      )



      options.push(
        { value: 'liftGate', label: 'LIFTGATE ', type: 'selectable', selectOptions: [{ value: 'liftGate', label: 'LIFTGATE' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )
      options.push(
        { value: 'notToExceedPricePercent', label: 'Cash discount percent', type: 'amount' },
      )

      options.push(
        { value: 'totalCash2Days150miles', label: 'NOT TO EXCEED PRICE cash', type: 'amount' },
        { value: 'totalCard2Days150miles', label: 'NOT TO EXCEED PRICE card', type: 'amount' },
      )
      options.push(
        { value: 'totalCashUnpackingSeparateDay', label: 'Separate NOT TO EXCEED PRICE Cash', type: 'amount' },
        { value: 'rateCashUnpackingSeparateDay', label: 'Separate Rate cash', type: 'amount' },
      )

      console.log('isCuFtDepartment', isCuFtDepartment)
      if (isCuFtDepartment) {

        options.push(
          { value: 'totalRoundTripMiles', label: 'Total round trip miles', type: 'amount' },
        )

        options.push(
          {
            value: 'additionalServices', label: 'Additional Services', type: 'selectable', selectOptions: [
              { value: 'additionalServices', label: 'Additional Services' },
              { value: 'none', label: 'None' }
            ]
          },
        )
        options.push(
          {
            value: 'extraOptions', label: 'Extra options', type: 'selectable', selectOptions: [
              { value: 'extraOptions', label: 'Extra options' },
              { value: 'none', label: 'None' }
            ]
          },
        )
        options.push(
          {
            value: 'procedures', label: 'Procedures', type: 'selectable', selectOptions: [
              { value: 'procedures', label: 'Procedures' },
              { value: 'none', label: 'None' }
            ]
          },
        )
        options.push(
          {
            value: 'extraOptionsDay2', label: 'Extra options Day 2', type: 'selectable', selectOptions: [
              { value: 'extraOptionsDay2', label: 'Extra options' },
              { value: 'none', label: 'None' }
            ]
          },
        )
        options.push(
          {
            value: 'proceduresDay2', label: 'Procedures Day 2', type: 'selectable', selectOptions: [
              { value: 'proceduresDay2', label: 'Procedures Day 2' },
              { value: 'none', label: 'None' }
            ]
          },
        )
        options.push(
          { value: 'packingCharge', label: 'Packing Charge', type: 'amount' },
          { value: 'unPackingCharge', label: 'UnPacking Charge', type: 'amount' },

          { value: 'flatRateCash150Miles', label: 'FLAT RATE Cash', type: 'amount' },
          { value: 'flatRateCard150Miles', label: 'FLAT RATE Card', type: 'amount' },


          { value: 'flatRateUnpackingSeparateDayCash', label: 'Flat Rate Unpacking Separate Day Cash', type: 'amount' },
          { value: 'flatRateUnpackingSeparateDayCard', label: 'Flat Rate Unpacking Separate Day Card', type: 'amount' },
        )
      }

      options.push(
        { value: 'truckFeeUnpackingSeparateDay', label: 'Separate Truck fee', type: 'amount' },
        { value: 'separateDayUnpack', label: 'Separate day unpack', type: 'selectable', selectOptions: [{ value: 'separateDayUnpack', label: 'Unpacking on separate day' }, { value: 'none', label: 'None' }] },
        { value: 'trucksUnpackingSeparateDay', label: 'Separate Trucks', type: 'amount' },
        { value: 'moversUnpackingSeparateDay', label: 'Separate Movers', type: 'amount' },
        { value: 'unpackingSeparateDayLowerhours', label: 'Separate Total time range lower', type: 'amount' },
        { value: 'unpackingSeparateDayHigherhours', label: 'Separate Total time range higher', type: 'amount' },
        { value: 'rateCardUnpackingSeparateDay', label: 'Separate Rate card', type: 'amount' },
        { value: 'totalCardUnpackingSeparateDay', label: 'Separate NOT TO EXCEED PRICE Card', type: 'amount' },
        { value: 'unpackingSeparatePercent', label: 'Separate Cash discount percent', type: 'amount' },
        { value: 'unpackingShuttle', label: 'SHUTTLE Separate Unpacking Day', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'unpackingShuttleQty', label: 'SHUTTLE Separate Unpacking Day qty', type: 'amount' },
      )

      break;

    case 'threeDays150Miles':
      options.push(
        { value: 'department', label: 'Department', type: 'selectable', selectOptions: departmentOptions },
      );


      if (company) {
        if (company.extraOptCharges) {
          company.extraOptCharges.forEach((charge, index) => {
            options.push({ value: `chIdName1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 1)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'purple' })

            options.push({ value: `chIdName2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 2)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'purple' })

            options.push({ value: `chIdName3_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 3)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount3_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 3)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty3_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 3)', type: 'amount', color: 'purple' })
          });
        }

        if (company.additProcedures) {
          company.additProcedures.forEach((procedure, index) => {
            options.push({ value: `pIdName1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 1)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'cyan' })

            options.push({ value: `pIdName2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 2)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'cyan' })

            options.push({ value: `pIdName3_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 3)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost3_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 3)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty3_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 3)', type: 'amount', color: 'cyan' })
          });
        }

        if (company.miscCheckMarks) {
          company.miscCheckMarks.forEach((checkMark, index) => {
            options.push({ value: `mChId_${checkMark[`uId${index}`]}`, label: checkMark[`id${index}`], type: 'selectable', selectOptions: [{ value: checkMark[`id${index}`], label: checkMark[`id${index}`] }, { value: 'none', label: 'None' }], color: 'blue' })
          });
        }
      }


      options.push(
        {
          value: 'day1weekday', label: 'Day 1 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day1month', label: 'Day 1 month', type: 'amount' },
        { value: 'day1date', label: 'Day 1 date', type: 'amount' },
        { value: 'day1year', label: 'Day 1 year', type: 'amount' },
      )


      options.push(
        {
          value: 'day2weekday', label: 'Day 2 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day2month', label: 'Day 2 month', type: 'amount' },
        { value: 'day2date', label: 'Day 2 date', type: 'amount' },
        { value: 'day2year', label: 'Day 2 year', type: 'amount' },
      )

      options.push(
        {
          value: 'day3weekday', label: 'Day 3 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day3month', label: 'Day 3 month', type: 'amount' },
        { value: 'day3date', label: 'Day 3 date', type: 'amount' },
        { value: 'day3year', label: 'Day 3 year', type: 'amount' },
      )

      options.push(
        { value: 'truckloads', label: 'Truckloads', type: 'amount' },
        { value: 'trucksPercent', label: 'Trucks percent', type: 'amount' },
        { value: 'combinedMileage', label: 'Total miles', type: 'amount' },
        { value: `unpackingDayRoundTripMiles`, label: `Unpacking day Round trip miles`, type: 'amount' },
        { value: 'additionalStopsCount', label: 'Additional stops count', type: 'amount' },
        { value: 'moversDay1', label: 'Movers(day 1)', type: 'amount' },
        { value: 'truckFeeDay1', label: 'Truck fee(day 1)', type: 'amount' },
        { value: 'trucksDay1', label: 'Trucks(day 1)', type: 'amount' },
        { value: 'shuttleDay1', label: 'SHUTTLE(day 1)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay1', label: 'SHUTTLE qty(day 1)', type: 'amount' },
        { value: 'rateCashDay1', label: 'Rate Cash(day 1)', type: 'amount' },
        { value: 'rateCardDay1', label: 'Rate Card(day 1)', type: 'amount' },
        { value: 'cubicFtToMoveDay1', label: 'Cubic ft to move(day 1)', type: 'amount' },
        { value: 'doubleDrive', label: 'Double Drive', type: 'selectable', selectOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }] },
        { value: 'lowerNumberOfHoursDay1', label: 'Lower Number Of Hours(day 1)', type: 'amount' },
        { value: 'higherNumberOfHoursDay1', label: 'Higher Number Of Hours(day 1)', type: 'amount' },
        { value: 'packingKit', label: 'Packing kit', type: 'amount' },
        { value: 'packingKitWithTax', label: 'Packing kit with tax', type: 'amount' },
        { value: 'sameDayUnpack', label: 'Same day unpack', type: 'selectable', selectOptions: [{ value: 'sameDayUnpack', label: 'UNPACKING' }, { value: 'none', label: 'None' }] },
        { value: 'partial', label: 'PARTIAL', type: 'selectable', selectOptions: [{ value: 'partial', label: 'PARTIAL' }, { value: 'none', label: 'None' }] },
        { value: 'packingCharge', label: 'Packing Charge', type: 'amount' },
        { value: 'packingType', label: 'Packing Type', type: 'selectable', selectOptions: [{ value: 'No', label: 'No' }, { value: 'Yes', label: "Full" }, { value: 'Kitchen', label: 'Kitchen' }, { value: 'Partial', label: 'Partial' }] },
        { value: 'smallBox', label: 'Small box', type: 'amount' },
        { value: 'mediumBox', label: 'Medium box', type: 'amount' },
        { value: 'largeBox', label: 'Large box', type: 'amount' },
        { value: 'rollOfPaper', label: 'Roll of packing paper', type: 'amount' },
        { value: 'rollOfBubble', label: 'Roll of bubble wrap', type: 'amount' },
        { value: `perBoxPrice`, label: `Per box price`, type: 'amount' },
        { value: 'moversDay2', label: 'Movers(day 2)', type: 'amount' },
        { value: 'rateCashDay2', label: 'Rate Cash(day 2)', type: 'amount' },
        { value: 'rateCardDay2', label: 'Rate Card(day 2)', type: 'amount' },
        { value: 'truckFeeDay2', label: 'Truck fee(day 2)', type: 'amount' },
        { value: 'trucksDay2', label: 'Trucks(day 2)', type: 'amount' },
        { value: 'shuttleDay2', label: 'SHUTTLE(day 2)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay2', label: 'SHUTTLE qty(day 2)', type: 'amount' },
        { value: 'doubleDriveDay2', label: 'Double Drive(day 2)', type: 'selectable', selectOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }] },
        { value: 'lowerNumberOfHoursDay2', label: 'Total time Range lower(day 2)', type: 'amount' },
        { value: 'higherNumberOfHoursDay2', label: 'Total time Range higher(day 2)', type: 'amount' },
        { value: 'moversDay3', label: 'Movers(day 3)', type: 'amount' },
        { value: 'rateCashDay3', label: 'Rate Cash(day 3)', type: 'amount' },
        { value: 'rateCardDay3', label: 'Rate Card(day 3)', type: 'amount' },
        { value: 'truckFeeDay3', label: 'Truck fee(day 3)', type: 'amount' },
        { value: 'trucksDay3', label: 'Trucks(day 3)', type: 'amount' },
        { value: 'shuttleDay3', label: 'SHUTTLE(day 3)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay3', label: 'SHUTTLE qty(day 3)', type: 'amount' },
        { value: 'lowerNumberOfHoursDay3', label: 'Total time Range lower(day 3)', type: 'amount' },
        { value: 'higherNumberOfHoursDay3', label: 'Total time Range higher(day 3)', type: 'amount' },
        { value: 'heavyFee', label: 'Extra Heavy Items Charge', type: 'amount' },
        { value: 'liftGate', label: 'LIFTGATE ', type: 'selectable', selectOptions: [{ value: 'liftGate', label: 'LIFTGATE' }, { value: 'none', label: 'None' }] },
        { value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS' }, { value: 'none', label: 'None' }] },
        { value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS' }, { value: 'none', label: 'None' }] },
        { value: 'doubleDriveDay3', label: 'Double Drive(day 3)', type: 'selectable', selectOptions: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }] },
        { value: 'biggestOrOk', label: 'Biggest or ok', type: 'selectable', selectOptions: [{ value: 'BIGGEST TRUCK', label: 'BIGGEST TRUCK' }, { value: 'OK', label: 'OK' }] },
        { value: 'twoTripsOrOkDay3', label: 'Two trips(day 3)', type: 'selectable', selectOptions: [{ value: '2 TRIPS', label: '2 TRIPS' }, { value: 'OK', label: 'OK' }] },
        { value: 'destinationFeeDay3', label: 'Destination fee(day 3)', type: 'amount' },
        { value: 'totalCash3Days150miles', label: 'NOT TO EXCEED PRICE CASH', type: 'amount' },
        { value: 'totalCard3Days150miles', label: 'NOT TO EXCEED PRICE CARD', type: 'amount' },
        { value: 'cashDiscountPercent', label: 'Cash discount percent', type: 'amount' },
      )

      options.push(
        { value: 'truckFeeUnpackingSeparateDay', label: 'Separate Truck fee', type: 'amount' },
      )

      options.push(
        { value: 'separateDayUnpack', label: 'Separate day unpack', type: 'selectable', selectOptions: [{ value: 'separateDayUnpack', label: 'Unpacking on separate day' }, { value: 'none', label: 'None' }] },
        { value: 'trucksUnpackingSeparateDay', label: 'Separate Trucks', type: 'amount' },
        { value: 'moversUnpackingSeparateDay', label: 'Separate Movers', type: 'amount' },
        { value: 'unpackingSeparateDayLowerhours', label: 'Separate Total time range lower', type: 'amount' },
        { value: 'unpackingSeparateDayHigherhours', label: 'Separate Total time range higher', type: 'amount' },
        { value: 'rateCardUnpackingSeparateDay', label: 'Separate Rate card', type: 'amount' },
        { value: 'rateCashUnpackingSeparateDay', label: 'Separate Rate cash', type: 'amount' },
        { value: 'totalCardUnpackingSeparateDay', label: 'Separate NOT TO EXCEED PRICE Card', type: 'amount' },
        { value: 'totalCashUnpackingSeparateDay', label: 'Separate NOT TO EXCEED PRICE Cash', type: 'amount' },
        { value: 'unpackingSeparatePercent', label: 'Separate Cash discount percent', type: 'amount' },
        { value: 'unpackingShuttle', label: 'SHUTTLE Separate Unpacking Day', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'unpackingShuttleQty', label: 'SHUTTLE Separate Unpacking Day qty', type: 'amount' },
      )

      if (isCuFtDepartment) {
        options.push(
          { value: 'totalRoundTripMiles', label: 'Total round trip miles', type: 'amount' },
          { value: 'day1RoundTripMiles', label: 'Day 1 - Round trip miles', type: 'amount' },
          { value: 'additionalServices', label: 'Additional Services', type: 'selectable', selectOptions: [{ value: 'additionalServices', label: 'Additional Services' }, { value: 'none', label: 'None' }] },
          { value: 'flatRateCash3Days150miles', label: 'FLAT RATE Cash', type: 'amount' },
          { value: 'flatRateCard3Days150miles', label: 'FLAT RATE Card', type: 'amount' },
          { value: 'flatRateUnpackingSeparateDayCash', label: 'Flat Rate Unpacking Separate Day Cash', type: 'amount' },
          { value: 'flatRateUnpackingSeparateDayCard', label: 'Flat Rate Unpacking Separate Day Card', type: 'amount' },
        )
      }


      break;

    case 'longOneDayPacking':
      options.push(
        { value: 'department', label: 'Department', type: 'selectable', selectOptions: departmentOptions },
      )
      options.push(
        { value: 'direction', label: 'Direction', type: 'selectable', selectOptions: longDistancesOptions },
      )
      if (company) {
        if (company.extraOptCharges) {
          company.extraOptCharges.forEach((charge, index) => {
            options.push({ value: `chIdName1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 1)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'purple' })

            options.push({ value: `chIdName2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 2)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'purple' })

          });
        }

        if (company.additProcedures) {
          company.additProcedures.forEach((procedure, index) => {
            options.push({ value: `pIdName1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 1)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'cyan' })

            options.push({ value: `pIdName2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 2)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'cyan' })
          });
        }

        if (company.miscCheckMarks) {
          company.miscCheckMarks.forEach((checkMark, index) => {
            options.push({ value: `mChId_${checkMark[`uId${index}`]}`, label: checkMark[`id${index}`], type: 'selectable', selectOptions: [{ value: checkMark[`id${index}`], label: checkMark[`id${index}`] }, { value: 'none', label: 'None' }], color: 'blue' })
          });
        }
      }

      options.push(
        {
          value: 'day1weekday', label: 'Day 1 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day1month', label: 'Day 1 month', type: 'amount' },
        { value: 'day1date', label: 'Day 1 date', type: 'amount' },
        { value: 'day1year', label: 'Day 1 year', type: 'amount' },
      )

      options.push(
        {
          value: 'day2weekday', label: 'Day 2 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day2month', label: 'Day 2 month', type: 'amount' },
        { value: 'day2date', label: 'Day 2 date', type: 'amount' },
        { value: 'day2year', label: 'Day 2 year', type: 'amount' },
      )

      options.push(
        { value: 'packingKit', label: 'Packing Kit Amount', type: 'amount' },
        { value: 'truckloads', label: 'Truckloads', type: 'amount' },
        { value: 'trucksPercent', label: 'Trucks percent', type: 'amount' },
        { value: 'combinedMileage', label: 'Total miles', type: 'amount' },
        { value: 'additionalStopsCount', label: 'Additional stops count', type: 'amount' },
        { value: 'moversLongOneDayLoading', label: 'Movers loading', type: 'amount' },
        { value: 'trucksDay1', label: 'Trucks(day 1)', type: 'amount' },
        { value: 'shuttleDay1', label: 'SHUTTLE(day 1)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay1', label: 'SHUTTLE qty(day 1)', type: 'amount' },
        { value: 'shuttleDay2', label: 'SHUTTLE(day 2)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay2', label: 'SHUTTLE qty(day 2)', type: 'amount' },
        { value: 'biggestOrOk', label: 'Biggest or ok', type: 'selectable', selectOptions: [{ value: 'BIGGEST TRUCK', label: 'BIGGEST TRUCK' }, { value: 'OK', label: 'OK' }] },
        { value: 'lowerNumberOfHoursDayLoading', label: 'Total time Range lower', type: 'amount' },
        { value: 'higherNumberOfHoursDayLoading', label: 'Total time Range higher', type: 'amount' },
        { value: 'heavyFee', label: 'Extra Heavy Items Charge', type: 'amount' },
      )
      options.push(
        { value: 'liftGate', label: 'LIFTGATE ', type: 'selectable', selectOptions: [{ value: 'liftGate', label: 'LIFTGATE' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'sameDayUnpack', label: 'Same day unpack', type: 'selectable', selectOptions: [{ value: 'sameDayUnpack', label: 'UNPACKING' }, { value: 'none', label: 'None' }] },
        { value: 'packingType', label: 'Packing Type', type: 'selectable', selectOptions: [{ value: 'No', label: 'No' }, { value: 'Yes', label: "Full" }, { value: 'Kitchen', label: 'Kitchen' }, { value: 'Partial', label: 'Partial' }] },
        { value: 'packingKitWithTax', label: 'Packing with tax', type: 'amount' },
        { value: 'smallBox', label: 'Small box', type: 'amount' },
        { value: 'mediumBox', label: 'Medium box', type: 'amount' },
        { value: 'largeBox', label: 'Large box', type: 'amount' },
        { value: 'rollOfPaper', label: 'Roll of packing paper', type: 'amount' },
        { value: 'rollOfBubble', label: 'Roll of bubble wrap', type: 'amount' },
        { value: 'driveTime', label: 'Drive time', type: 'amount' },
        { value: 'arrivalTime', label: 'Arival time (days)', type: 'amount' },
      )

      options.push(
        { value: 'moversDay2', label: 'Movers(day 2)', type: 'amount' },
        { value: 'trucksDay2', label: 'Trucks', type: 'amount' },
        { value: 'lowerNumberOfHoursDayUnLoading', label: 'Total time Range lower(day 2)', type: 'amount' },
        { value: 'higherNumberOfHoursDayUnLoading', label: 'Total time Range higher(day 2)', type: 'amount' },

      )

      options.push(
        { value: 'totalCashLongOneDay', label: 'FLAT RATE Cash', type: 'amount' },
      )

      options.push(
        { value: 'totalCardLongOneDay', label: 'FLAT RATE Card', type: 'amount' },
      )

      options.push(
        { value: 'cashDiscountPercent', label: 'Cash discount percent', type: 'amount' },
      )

      options.push(
        { value: 'unpDepartment', label: 'Unpacking Department', type: 'selectable', selectOptions: departmentOptions },
      )

      options.push(
        { value: 'sameDayUnpack', label: 'Same day unpack', type: 'selectable', selectOptions: [{ value: 'sameDayUnpack', label: 'UNPACKING' }, { value: 'none', label: 'None' }] },
        { value: 'separateDayUnpack', label: 'Separate day unpack', type: 'selectable', selectOptions: [{ value: 'separateDayUnpack', label: 'Unpacking on separate day' }, { value: 'none', label: 'None' }] },
        { value: 'trucksUnpackingSeparateDay', label: 'Separate Trucks', type: 'amount' },
        { value: 'moversUnpackingSeparateDay', label: 'Separate Movers', type: 'amount' },
        { value: 'unpackingSeparateDayLowerhours', label: 'Separate Total time range lower', type: 'amount' },
        { value: 'unpackingSeparateDayHigherhours', label: 'Separate Total time range higher', type: 'amount' },
        { value: 'rateCardUnpackingSeparateDay', label: 'Separate Rate card', type: 'amount' },
        { value: 'totalCardUnpackingSeparateDay', label: 'Separate NOT TO EXCEED PRICE Card', type: 'amount' },
        { value: 'unpackingSeparatePercent', label: 'Separate Cash discount percent', type: 'amount' },
        { value: 'unpackingShuttle', label: 'SHUTTLE Separate Unpacking Day', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'unpackingShuttleQty', label: 'SHUTTLE Separate Unpacking Day qty', type: 'amount' },
      )

      options.push(
        {
          value: 'extraOptions', label: 'Extra options', type: 'selectable', selectOptions: [
            { value: 'extraOptions', label: 'Extra options' },
            { value: 'none', label: 'None' }
          ]
        },
      )
      options.push(
        {
          value: 'procedures', label: 'Procedures', type: 'selectable', selectOptions: [
            { value: 'procedures', label: 'Procedures' },
            { value: 'none', label: 'None' }
          ]
        },
      )
      options.push(
        {
          value: 'extraOptionsDay2', label: 'Extra options Day 2', type: 'selectable', selectOptions: [
            { value: 'extraOptionsDay2', label: 'Extra options' },
            { value: 'none', label: 'None' }
          ]
        },
      )
      options.push(
        {
          value: 'proceduresDay2', label: 'Procedures Day 2', type: 'selectable', selectOptions: [
            { value: 'proceduresDay2', label: 'Procedures Day 2' },
            { value: 'none', label: 'None' }
          ]
        },
      )
      break;

    case 'longTwoDaysPacking':

      options.push(
        { value: 'department', label: 'Department', type: 'selectable', selectOptions: departmentOptions },
      )
      options.push(
        { value: 'direction', label: 'Direction', type: 'selectable', selectOptions: longDistancesOptions },
      )
      if (company) {
        if (company.extraOptCharges) {
          company.extraOptCharges.forEach((charge, index) => {
            options.push({ value: `chIdName1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 1)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'purple' })

            options.push({ value: `chIdName2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 2)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'purple' })

            options.push({ value: `chIdName3_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 3)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount3_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 3)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty3_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 3)', type: 'amount', color: 'purple' })

          });
        }

        if (company.additProcedures) {
          company.additProcedures.forEach((procedure, index) => {
            options.push({ value: `pIdName1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 1)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'cyan' })

            options.push({ value: `pIdName2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 2)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'cyan' })
          });
        }

        if (company.miscCheckMarks) {
          company.miscCheckMarks.forEach((checkMark, index) => {
            options.push({ value: `mChId_${checkMark[`uId${index}`]}`, label: checkMark[`id${index}`], type: 'selectable', selectOptions: [{ value: checkMark[`id${index}`], label: checkMark[`id${index}`] }, { value: 'none', label: 'None' }], color: 'blue' })
          });
        }
      }



      options.push(
        {
          value: 'day1weekday', label: 'Day 1 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day1month', label: 'Day 1 month', type: 'amount' },
        { value: 'day1date', label: 'Day 1 date', type: 'amount' },
        { value: 'day1year', label: 'Day 1 year', type: 'amount' },
      )

      options.push(
        {
          value: 'day2weekday', label: 'Day 2 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day2month', label: 'Day 2 month', type: 'amount' },
        { value: 'day2date', label: 'Day 2 date', type: 'amount' },
        { value: 'day2year', label: 'Day 2 year', type: 'amount' },
      )

      options.push(
        {
          value: 'day3weekday', label: 'Day 3 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day3month', label: 'Day 3 month', type: 'amount' },
        { value: 'day3date', label: 'Day 3 date', type: 'amount' },
        { value: 'day3year', label: 'Day 3 year', type: 'amount' },
      )


      options.push(
        { value: 'truckloads', label: 'Truckloads', type: 'amount' },
        { value: 'trucksPercent', label: 'Trucks percent', type: 'amount' },

        { value: 'combinedMileage', label: 'Total miles', type: 'amount' },
        { value: 'additionalStopsCount', label: 'Additional stops count', type: 'amount' },
        { value: 'moversLongOneDayLoading', label: 'Movers loading', type: 'amount' },
        { value: 'moversDay1', label: 'Movers packing(day 1)', type: 'amount' },
        { value: 'lowerNumberOfHoursDay1', label: 'Lower number of hours(day 1)', type: 'amount' },
        { value: 'higherNumberOfHoursDay1', label: 'Higher number of hours(day 1)', type: 'amount' },
        { value: 'packingKit', label: 'Packing Kit Amount', type: 'amount' },

        { value: 'trucksDay1', label: 'Trucks(day 1)', type: 'amount' },
        { value: 'shuttleDay1', label: 'SHUTTLE(day 1)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay1', label: 'SHUTTLE qty(day 1)', type: 'amount' },
        { value: 'shuttleDay2', label: 'SHUTTLE(day 2)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay2', label: 'SHUTTLE qty(day 2)', type: 'amount' },
        { value: 'shuttleDay3', label: 'SHUTTLE(day 3)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay3', label: 'SHUTTLE qty(day 3)', type: 'amount' },
        { value: 'biggestOrOk', label: 'Biggest or ok', type: 'selectable', selectOptions: [{ value: 'BIGGEST TRUCK', label: 'BIGGEST TRUCK' }, { value: 'OK', label: 'OK' }] },
        { value: 'lowerNumberOfHoursDayLoading', label: 'Total time Range lower', type: 'amount' },
        { value: 'higherNumberOfHoursDayLoading', label: 'Total time Range higher', type: 'amount' },
        { value: 'heavyFee', label: 'Extra Heavy Items Charge', type: 'amount' },
      )
      options.push(
        { value: 'liftGate', label: 'LIFTGATE ', type: 'selectable', selectOptions: [{ value: 'liftGate', label: 'LIFTGATE' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'sameDayUnpack', label: 'Same day unpack', type: 'selectable', selectOptions: [{ value: 'sameDayUnpack', label: 'UNPACKING' }, { value: 'none', label: 'None' }] },
        { value: 'packingType', label: 'Packing Type', type: 'selectable', selectOptions: [{ value: 'No', label: 'No' }, { value: 'Yes', label: "Full" }, { value: 'Kitchen', label: 'Kitchen' }, { value: 'Partial', label: 'Partial' }] },
        { value: 'packingKitWithTax', label: 'Packing with tax', type: 'amount' },
        { value: 'smallBox', label: 'Small box', type: 'amount' },
        { value: 'mediumBox', label: 'Medium box', type: 'amount' },
        { value: 'largeBox', label: 'Large box', type: 'amount' },
        { value: 'rollOfPaper', label: 'Roll of packing paper', type: 'amount' },
        { value: 'rollOfBubble', label: 'Roll of bubble wrap', type: 'amount' },
        { value: 'driveTime', label: 'Drive time', type: 'amount' },
        { value: 'arrivalTime', label: 'Arival time (days)', type: 'amount' },
      )

      options.push(
        { value: 'moversDay2', label: 'Movers(day 2)', type: 'amount' },
        { value: 'moversDay3', label: 'Movers(day 3)', type: 'amount' },
        { value: 'trucksDay2', label: 'Trucks', type: 'amount' },
        { value: 'lowerNumberOfHoursDayUnLoading', label: 'Total time Range lower(day 2)', type: 'amount' },
        { value: 'higherNumberOfHoursDayUnLoading', label: 'Total time Range higher(day 2)', type: 'amount' },

        { value: 'lowerNumberOfHoursDay3', label: 'Total time Range lower(day 3)', type: 'amount' },
        { value: 'higherNumberOfHoursDay3', label: 'Total time Range higher(day 3)', type: 'amount' },

      )

      /*     options.push(
            { value: 'totalCashLong2DaysR', label: 'FLAT RATE Cash(ROUNDED)', type: 'amount' },
          ) */

      options.push(
        { value: 'totalCashLong2Days', label: 'FLAT RATE Cash', type: 'amount' },
      )

      /*      options.push(
             { value: 'totalCashLong2DaysR', label: 'FLAT RATE Card(ROUNDED)', type: 'amount' },
           ) */

      options.push(
        { value: 'totalCardLong2Days', label: 'FLAT RATE Card', type: 'amount' },
      )

      options.push(
        { value: 'cashDiscountPercent', label: 'Cash discount percent', type: 'amount' },
      )



      options.push(
        { value: 'unpDepartment', label: 'Unpacking Department', type: 'selectable', selectOptions: departmentOptions },
      )

      options.push(
        { value: 'sameDayUnpack', label: 'Same day unpack', type: 'selectable', selectOptions: [{ value: 'sameDayUnpack', label: 'UNPACKING' }, { value: 'none', label: 'None' }] },
        { value: 'separateDayUnpack', label: 'Separate day unpack', type: 'selectable', selectOptions: [{ value: 'separateDayUnpack', label: 'Unpacking on separate day' }, { value: 'none', label: 'None' }] },
        { value: 'trucksUnpackingSeparateDay', label: 'Separate Trucks', type: 'amount' },
        { value: 'moversUnpackingSeparateDay', label: 'Separate Movers', type: 'amount' },
        { value: 'unpackingSeparateDayLowerhours', label: 'Separate Total time range lower', type: 'amount' },
        { value: 'unpackingSeparateDayHigherhours', label: 'Separate Total time range higher', type: 'amount' },
        { value: 'rateCardUnpackingSeparateDay', label: 'Separate Rate card', type: 'amount' },
        { value: 'totalCardUnpackingSeparateDay', label: 'Separate NOT TO EXCEED PRICE Card', type: 'amount' },
        { value: 'unpackingSeparatePercent', label: 'Separate Cash discount percent', type: 'amount' },
        { value: 'unpackingShuttle', label: 'SHUTTLE Separate Unpacking Day', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'unpackingShuttleQty', label: 'SHUTTLE Separate Unpacking Day qty', type: 'amount' },
      )

      break;

    case 'oos1day':
      options.push(
        { value: 'department', label: 'Department', type: 'selectable', selectOptions: departmentOptions },
      )
      options.push(
        { value: 'direction', label: 'Direction', type: 'selectable', selectOptions: oosOptions },
      )
      if (company) {
        if (company.extraOptCharges) {
          company.extraOptCharges.forEach((charge, index) => {
            options.push({ value: `chIdName1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 1)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'purple' })

            options.push({ value: `chIdName2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 2)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'purple' })
          });
        }

        if (company.additProcedures) {
          company.additProcedures.forEach((procedure, index) => {
            options.push({ value: `pIdName1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 1)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'cyan' })

            options.push({ value: `pIdName2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 2)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'cyan' })
          });
        }

        if (company.miscCheckMarks) {
          company.miscCheckMarks.forEach((checkMark, index) => {
            options.push({ value: `mChId_${checkMark[`uId${index}`]}`, label: checkMark[`id${index}`], type: 'selectable', selectOptions: [{ value: checkMark[`id${index}`], label: checkMark[`id${index}`] }, { value: 'none', label: 'None' }], color: 'blue' })
          });
        }
      }



      options.push(
        {
          value: 'day1weekday', label: 'Day 1 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day1month', label: 'Day 1 month', type: 'amount' },
        { value: 'day1date', label: 'Day 1 date', type: 'amount' },
        { value: 'day1year', label: 'Day 1 year', type: 'amount' },
      )

      options.push(
        {
          value: 'day2weekday', label: 'Day 2 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day2month', label: 'Day 2 month', type: 'amount' },
        { value: 'day2date', label: 'Day 2 date', type: 'amount' },
        { value: 'day2year', label: 'Day 2 year', type: 'amount' },
      )

      options.push(
        { value: 'truckloads', label: 'Truckloads', type: 'amount' },
        { value: 'trucksPercent', label: 'Trucks percent', type: 'amount' },
        { value: 'truckloadsRoundDown', label: 'Full trucks', type: 'amount' },
        { value: 'combinedMileage', label: 'Total miles', type: 'amount' },
        { value: 'additionalStopsCount', label: 'Additional stops count', type: 'amount' },
        { value: 'moversDay1', label: 'Movers(day 1)', type: 'amount' },
        { value: 'trucksDay1', label: 'Trucks(day 1)', type: 'amount' },
        { value: 'shuttleDay1', label: 'SHUTTLE(day 1)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay1', label: 'SHUTTLE qty(day 1)', type: 'amount' },
        { value: 'lowerNumberOfHoursDay1', label: 'Lower Number Of Hours(day 1)', type: 'amount' },
        { value: 'higherNumberOfHoursDay1', label: 'Higher Number Of Hours(day1)', type: 'amount' },
      )

      options.push(
        { value: 'liftGate', label: 'LIFTGATE ', type: 'selectable', selectOptions: [{ value: 'liftGate', label: 'LIFTGATE' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'sameDayUnpack', label: 'Same day unpack', type: 'selectable', selectOptions: [{ value: 'sameDayUnpack', label: 'UNPACKING' }, { value: 'none', label: 'None' }] },
        { value: 'packingType', label: 'Packing Type', type: 'selectable', selectOptions: [{ value: 'No', label: 'No' }, { value: 'Yes', label: "Full" }, { value: 'Kitchen', label: 'Kitchen' }, { value: 'Partial', label: 'Partial' }] },
        { value: 'packingKitWithTax', label: 'Packing with tax', type: 'amount' },
        { value: 'smallBox', label: 'Small box', type: 'amount' },
        { value: 'mediumBox', label: 'Medium box', type: 'amount' },
        { value: 'largeBox', label: 'Large box', type: 'amount' },
        { value: 'rollOfPaper', label: 'Roll of packing paper', type: 'amount' },
        { value: 'rollOfBubble', label: 'Roll of bubble wrap', type: 'amount' },
        { value: 'driveTime', label: 'Drive time', type: 'amount' },
        { value: 'moversDay2', label: 'Movers(day 2)', type: 'amount' },
        { value: 'trucksDay2', label: 'Trucks(day 2)', type: 'amount' },
        { value: 'shuttleDay2', label: 'SHUTTLE(day 2)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay2', label: 'SHUTTLE qty(day 2)', type: 'amount' },
        { value: 'lowerNumberOfHoursDay2', label: 'Lower Number Of Hours(day 2)', type: 'amount' },
        { value: 'higherNumberOfHoursDay2', label: 'Higher Number Of Hours(day 2)', type: 'amount' },
        { value: 'packingKit', label: 'Packing Kit Amount', type: 'amount' },
      )

      options.push(
        { value: 'totalCardOOSDay1', label: 'FLAT RATE Card(day 1)', type: 'amount' },
      )

      options.push(
        { value: 'totalCashOOSDay1', label: 'FLAT RATE Cash(day 1)', type: 'amount' },
      )


      options.push(
        { value: 'cashDiscountPercent', label: 'Cash discount percent', type: 'amount' },
      )

      options.push(
        { value: 'unpDepartment', label: 'Unpacking Department', type: 'selectable', selectOptions: departmentOptions },
      )

      options.push(
        { value: 'sameDayUnpack', label: 'Same day unpack', type: 'selectable', selectOptions: [{ value: 'sameDayUnpack', label: 'UNPACKING' }, { value: 'none', label: 'None' }] },
        { value: 'separateDayUnpack', label: 'Separate day unpack', type: 'selectable', selectOptions: [{ value: 'separateDayUnpack', label: 'Unpacking on separate day' }, { value: 'none', label: 'None' }] },
        { value: 'trucksUnpackingSeparateDay', label: 'Separate Trucks', type: 'amount' },
        { value: 'moversUnpackingSeparateDay', label: 'Separate Movers', type: 'amount' },
        { value: 'unpackingSeparateDayLowerhours', label: 'Separate Total time range lower', type: 'amount' },
        { value: 'unpackingSeparateDayHigherhours', label: 'Separate Total time range higher', type: 'amount' },
        { value: 'rateCardUnpackingSeparateDay', label: 'Separate Rate card', type: 'amount' },
        { value: 'thirdDayDate', label: 'Separate NOT TO EXCEED PRICE Card', type: 'amount' },
        { value: 'unpackingSeparatePercent', label: 'Separate Cash discount percent', type: 'amount' },
        { value: 'unpackingShuttle', label: 'SHUTTLE Separate Unpacking Day', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'unpackingShuttleQty', label: 'SHUTTLE Separate Unpacking Day qty', type: 'amount' },
      )
      break;

    case 'oos2days':
      options.push(
        { value: 'department', label: 'Department', type: 'selectable', selectOptions: departmentOptions },
      )
      options.push(
        { value: 'direction', label: 'Direction', type: 'selectable', selectOptions: oosOptions },
      )
      if (company) {
        if (company.extraOptCharges) {
          company.extraOptCharges.forEach((charge, index) => {
            options.push({ value: `chIdName1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 1)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty1_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'purple' })

            options.push({ value: `chIdName2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 2)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty2_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'purple' })

            options.push({ value: `chIdName3_${charge[`uId${index}`]}`, label: charge[`id${index}`] + '(day 3)', type: 'selectable', selectOptions: [{ value: charge[`id${index}`], label: charge[`id${index}`] }, { value: 'none', label: 'None' }], color: 'purple' })
            options.push({ value: `chIdAmount3_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' amount(day 3)', type: 'amount', color: 'purple' })
            options.push({ value: `chIdQty3_${charge[`uId${index}`]}`, label: charge[`id${index}`] + ' qty(day 3)', type: 'amount', color: 'purple' })
          });
        }

        if (company.additProcedures) {
          company.additProcedures.forEach((procedure, index) => {
            options.push({ value: `pIdName1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 1)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 1)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty1_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 1)', type: 'amount', color: 'cyan' })

            options.push({ value: `pIdName2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '(day 2)', type: 'selectable', selectOptions: [{ value: procedure[`id${index}`], label: procedure[`id${index}`] }, { value: 'none', label: 'None' }], color: 'cyan' })
            options.push({ value: `pIdCost2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + '  amount(day 2)', type: 'amount', color: 'cyan' })
            options.push({ value: `pIdQty2_${procedure[`uId${index}`]}`, label: procedure[`id${index}`] + ' qty(day 2)', type: 'amount', color: 'cyan' })
          });
        }

        if (company.miscCheckMarks) {
          company.miscCheckMarks.forEach((checkMark, index) => {
            options.push({ value: `mChId_${checkMark[`uId${index}`]}`, label: checkMark[`id${index}`], type: 'selectable', selectOptions: [{ value: checkMark[`id${index}`], label: checkMark[`id${index}`] }, { value: 'none', label: 'None' }], color: 'blue' })
          });
        }
      }



      options.push(
        {
          value: 'day1weekday', label: 'Day 1 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day1month', label: 'Day 1 month', type: 'amount' },
        { value: 'day1date', label: 'Day 1 date', type: 'amount' },
        { value: 'day1year', label: 'Day 1 year', type: 'amount' },
      )

      options.push(
        {
          value: 'day2weekday', label: 'Day 2 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day2month', label: 'Day 2 month', type: 'amount' },
        { value: 'day2date', label: 'Day 2 date', type: 'amount' },
        { value: 'day2year', label: 'Day 2 year', type: 'amount' },
      )

      options.push(
        {
          value: 'day3weekday', label: 'Day 3 weekday', type: 'selectable', selectOptions: [
            { value: 'weekday', label: 'Select weekday' },
            { value: 'Sun', label: 'Sun' },
            { value: 'Mon', label: 'Mon' },
            { value: 'Tue', label: 'Tue' },
            { value: 'Wed', label: 'Wed' },
            { value: 'Thu', label: 'Thu' },
            { value: 'Fri', label: 'Fri' },
            { value: 'Sat', label: 'Sat' },
            { value: 'none', label: 'None' }
          ]
        },
        { value: 'day3month', label: 'Day 3 month', type: 'amount' },
        { value: 'day3date', label: 'Day 3 date', type: 'amount' },
        { value: 'day3year', label: 'Day 3 year', type: 'amount' },
      )


      options.push(
        { value: 'truckloads', label: 'Truckloads', type: 'amount' },
        { value: 'trucksPercent', label: 'Trucks percent', type: 'amount' },
        { value: 'truckloadsRoundDown', label: 'Full trucks', type: 'amount' },
        { value: 'combinedMileage', label: 'Total miles', type: 'amount' },
        { value: 'additionalStopsCount', label: 'Additional stops count', type: 'amount' },
        { value: 'moversDay1', label: 'Movers(day 1)', type: 'amount' },
        { value: 'trucksDay1', label: 'Trucks(day 1)', type: 'amount' },
        { value: 'shuttleDay1', label: 'SHUTTLE(day 1)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay1', label: 'SHUTTLE qty(day 1)', type: 'amount' },
        { value: 'lowerNumberOfHoursDay1', label: 'Lower Number Of Hours(day 1)', type: 'amount' },
        { value: 'higherNumberOfHoursDay1', label: 'Higher Number Of Hours(day1)', type: 'amount' },
      )

      options.push(
        { value: 'sameDayUnpack', label: 'Same day unpack', type: 'selectable', selectOptions: [{ value: 'sameDayUnpack', label: 'UNPACKING' }, { value: 'none', label: 'None' }] },
        { value: 'packingKit', label: 'Packing Kit Amount', type: 'amount' },
        { value: 'packingType', label: 'Packing Type', type: 'selectable', selectOptions: [{ value: 'No', label: 'No' }, { value: 'Yes', label: "Full" }, { value: 'Kitchen', label: 'Kitchen' }, { value: 'Partial', label: 'Partial' }] },
        { value: 'packingKitWithTax', label: 'Packing with tax', type: 'amount' },
        { value: 'smallBox', label: 'Small box', type: 'amount' },
        { value: 'mediumBox', label: 'Medium box', type: 'amount' },
        { value: 'largeBox', label: 'Large box', type: 'amount' },
        { value: 'rollOfPaper', label: 'Roll of packing paper', type: 'amount' },
        { value: 'rollOfBubble', label: 'Roll of bubble wrap', type: 'amount' },
        { value: 'driveTime', label: 'Drive time', type: 'amount' },
        { value: 'moversDay2', label: 'Movers(day 2)', type: 'amount' },
        { value: 'moversDay3', label: 'Movers(day 3)', type: 'amount' },
        { value: 'trucksDay2', label: 'Trucks(day 2)', type: 'amount' },
        { value: 'shuttleDay2', label: 'SHUTTLE(day 2)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay2', label: 'SHUTTLE qty(day 2)', type: 'amount' },
        { value: 'lowerNumberOfHoursDay2', label: 'Lower Number Of Hours(day 2)', type: 'amount' },
        { value: 'higherNumberOfHoursDay2', label: 'Higher Number Of Hours(day 2)', type: 'amount' },
      )
      options.push(
        { value: 'heavyFee', label: 'Extra Heavy Items Charge', type: 'amount' },
      )

      options.push(
        { value: 'liftGate', label: 'LIFTGATE ', type: 'selectable', selectOptions: [{ value: 'liftGate', label: 'LIFTGATE' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'hardwoodFloorCovers', label: 'HARDWOOD FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )

      options.push(
        { value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS', type: 'selectable', selectOptions: [{ value: 'carpetFloorCovers', label: 'CARPET FLOOR COVERS' }, { value: 'none', label: 'None' }] },
      )


      options.push(
        { value: 'moversDay2', label: 'Movers(day 2)', type: 'amount' },
        { value: 'trucksDay2', label: 'Trucks', type: 'amount' },
        { value: 'shuttleDay3', label: 'SHUTTLE(day 3)', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'shuttleQtyDay3', label: 'SHUTTLE qty(day 3)', type: 'amount' },
        { value: 'lowerNumberOfHoursDay3', label: 'Lower Number Of Hours(day 3)', type: 'amount' },
        { value: 'higherNumberOfHoursDay3', label: 'Higher Number Of Hours(day 3)', type: 'amount' },
      )

      options.push(
        { value: 'totalCardOOSDay2', label: 'FLAT RATE Card', type: 'amount' },
      )

      options.push(
        { value: 'totalCashOOSDay2', label: 'FLAT RATE Cash', type: 'amount' },
      )

      options.push(
        { value: 'cashDiscountPercent', label: 'Cash discount percent', type: 'amount' },
      )

      options.push(
        { value: 'unpDepartment', label: 'Unpacking Department', type: 'selectable', selectOptions: departmentOptions },
      )

      options.push(
        { value: 'sameDayUnpack', label: 'Same day unpack', type: 'selectable', selectOptions: [{ value: 'sameDayUnpack', label: 'UNPACKING' }, { value: 'none', label: 'None' }] },
        { value: 'separateDayUnpack', label: 'Separate day unpack', type: 'selectable', selectOptions: [{ value: 'separateDayUnpack', label: 'Unpacking on separate day' }, { value: 'none', label: 'None' }] },
        { value: 'trucksUnpackingSeparateDay', label: 'Separate Trucks', type: 'amount' },
        { value: 'moversUnpackingSeparateDay', label: 'Separate Movers', type: 'amount' },
        { value: 'unpackingSeparateDayLowerhours', label: 'Separate Total time range lower', type: 'amount' },
        { value: 'unpackingSeparateDayHigherhours', label: 'Separate Total time range higher', type: 'amount' },
        { value: 'rateCardUnpackingSeparateDay', label: 'Separate Rate card', type: 'amount' },
        { value: 'totalCardUnpackingSeparateDay', label: 'Separate NOT TO EXCEED PRICE Card', type: 'amount' },
        { value: 'unpackingSeparatePercent', label: 'Separate Cash discount percent', type: 'amount' },
        { value: 'unpackingShuttle', label: 'SHUTTLE Separate Unpacking Day', type: 'selectable', selectOptions: [{ value: 'shuttle', label: 'SHUTTLE' }, { value: 'none', label: 'None' }] },
        { value: 'unpackingShuttleQty', label: 'SHUTTLE Separate Unpacking Day qty', type: 'amount' },
      )
      break;

      break;

    default:
      break;
  }
  return options;
}

export const replaceSpaces = (str) => {
  return str.replace(/ /g, '');
}

export const getCheckMarkLabelById = (checkMarkId, miscCheckMarks) => {
  if (miscCheckMarks && miscCheckMarks.length) {
    for (let index = 0; index < miscCheckMarks.length; index++) {
      const chM = miscCheckMarks[index];
      if (chM[`uId${index}`] === checkMarkId) {
        return chM[`checkMarkName${index}`]
      }
    }
  }
}

export const getHigherNumberOfHours = (departmentMinHours, higherNumberOfHours) => {
  if (departmentMinHours) {
    if (higherNumberOfHours > departmentMinHours) { return higherNumberOfHours }
    else { return departmentMinHours }
  }
  else {
    return higherNumberOfHours
  }
}

export const getLowerNumberOfHours = (departmentMinHours, lowerNumberOfHours) => {
  if (departmentMinHours) {
    if (lowerNumberOfHours < departmentMinHours) { return '' }
    else { return lowerNumberOfHours + "-" }
  }
  else {
    return lowerNumberOfHours + "-"
  }
}

export const getLowerNumberOfHoursValueOnly = (departmentMinHours, lowerNumberOfHours) => {
  if (departmentMinHours) {
    if (lowerNumberOfHours < departmentMinHours) { return null }
    else { return lowerNumberOfHours }
  }
  else {
    return lowerNumberOfHours
  }
}

export const getTotalTimeRange = (departmentMinHours, lowerNumberOfHours, higherNumberOfHours) => {
  return getLowerNumberOfHours(departmentMinHours, lowerNumberOfHours) + getHigherNumberOfHours(departmentMinHours, higherNumberOfHours) + " hours"
}

export const removeByIndex = (arr, remIndex) => {
  arr.splice(remIndex, 1);
  return arr.map((element, index) => {
    let newObj = {};
    Object.keys(element).forEach((key) => {
      let oldKeyWithouIndex = key.match(/[a-zA-Z]+|[0-9]+/g)[0];
      newObj[`${oldKeyWithouIndex + index}`] = element[key];
    });
    return newObj;
  });
}

export const getSelectableResult = (condition, conditionIndex, calculation, selectableCondition) => {
  let result = '';
  condition[`selectOptions${conditionIndex}`].forEach(selectOption => {
    if (selectOption.value === selectableCondition) {
      result = selectOption.label
    }
  });
  return result
}

export const getAmountResult = (condition, conditionIndex, calculation, amountCondition) => {
  let result = amountCondition;
  return result
}

export const getCheckPointValue = (tag, condition, conditionIndex, isSelected, resultText, setTagsResultsTexts, tagsResultsTexts) => {
  if (condition[`conditionSelectValue${conditionIndex}`] == 'none') { isSelected = !isSelected; }
  switch (condition[`operator${conditionIndex}`]) {
    case '=':
      if (isSelected) {
        return [true, getCheckPointIfVal(tag, condition, conditionIndex, resultText, setTagsResultsTexts, tagsResultsTexts)]
      }
      else {
        if (setTagsResultsTexts instanceof Function) {
          setTagsResultsTexts({ [tag.tagName]: resultText })
        }
        return [false, getAmSelSwElseVal(tag)]
      }
      break;
    case '!=':
      if (!isSelected) {
        return [true, getCheckPointIfVal(tag, condition, conditionIndex, resultText, setTagsResultsTexts, tagsResultsTexts)]
      }
      else {
        if (setTagsResultsTexts instanceof Function) {
          setTagsResultsTexts({ [tag.tagName]: resultText })
        }
        return [false, getAmSelSwElseVal(tag)]
      }
      break;

    default:
      break;
  }
}

export const getSelectableValue = (tag, condition, conditionIndex, calculation, selectableCondition, setTagsResultsTexts, tagsResultsTexts) => {
  switch (condition[`operator${conditionIndex}`]) {
    case '=':
      if (selectableCondition === condition[`conditionSelectValue${conditionIndex}`] && selectableCondition) {
        return [true, getSelSwIfVal(tag, condition, conditionIndex, calculation, selectableCondition, setTagsResultsTexts, tagsResultsTexts)]
      }
      else {
        let result = getSelectableResult(condition, conditionIndex, calculation, selectableCondition);
        if (setTagsResultsTexts instanceof Function) {
          setTagsResultsTexts({ [tag.tagName]: result })
        }
        return [false, getAmSelSwElseVal(tag)]
      }
      break;
    case '!=':
      if (selectableCondition !== condition[`conditionSelectValue${conditionIndex}`] && selectableCondition) {
        return [true, getSelSwIfVal(tag, condition, conditionIndex, calculation, selectableCondition, setTagsResultsTexts, tagsResultsTexts)]
      }
      else {
        let result = getSelectableResult(condition, conditionIndex, calculation, selectableCondition);
        if (setTagsResultsTexts instanceof Function) {
          setTagsResultsTexts({ [tag.tagName]: result })
        }
        return [false, getAmSelSwElseVal(tag)]
      }
      break;

    default:
      break;
  }
}

export const getCheckPointIfVal = (tag, condition, conditionIndex, resultText, setTagsResultsTexts, tagsResultsTexts) => {
  switch (condition[`resultTextType${conditionIndex}`]) {//input, result, none, tag
    case 'input':
      return condition[`resultingText${conditionIndex}`]
      break;

    case 'result':
      let result = resultText;
      if (setTagsResultsTexts instanceof Function) {
        setTagsResultsTexts({ [tag.tagName]: result })
      }
      return result;
      break;

    case 'none':
      return '';
      break;

    case 'tag':
      if (condition[`selectedTag${conditionIndex}`] !== 'none') {
        return condition[`selectedTag${conditionIndex}`]
      }
      else {
        return ''
      }
      break;

    default:
      break;
  }
}

export const getSelSwIfVal = (tag, condition, conditionIndex, calculation, selectableCondition, setTagsResultsTexts, tagsResultsTexts) => {
  switch (condition[`resultTextType${conditionIndex}`]) {//input, result, none, tag
    case 'input':
      return condition[`resultingText${conditionIndex}`]
      break;

    case 'result':
      let result = getSelectableResult(condition, conditionIndex, calculation, selectableCondition);
      if (setTagsResultsTexts instanceof Function) {
        setTagsResultsTexts({ [tag.tagName]: result })
      }
      return result;
      break;

    case 'none':
      return '';
      break;

    case 'tag':
      if (condition[`selectedTag${conditionIndex}`] !== 'none') {
        return condition[`selectedTag${conditionIndex}`]
      }
      else {
        return ''
      }
      break;

    default:
      break;
  }
}

export const getAmSwIfVal = (tag, condition, conditionIndex, calculation, amountCondition, setTagsResultsTexts, tagsResultsTexts) => {
  switch (condition[`resultTextType${conditionIndex}`]) {//input, result, none, tag
    case 'input':
      return condition[`resultingText${conditionIndex}`]
      break;

    case 'result':
      let result = getAmountResult(condition, conditionIndex, calculation, amountCondition);
      if (setTagsResultsTexts instanceof Function) {
        setTagsResultsTexts({ [tag.tagName]: amountCondition })
      }
      return result;
      break;

    case 'none':
      return '';
      break;

    case 'tag':
      if (condition[`selectedTag${conditionIndex}`] !== 'none') {
        return condition[`selectedTag${conditionIndex}`]
      }
      else {
        return ''
      }
      break;

    default:
      break;
  }
}

export const getAmSelSwElseVal = (tag) => {
  switch (tag.elseTextType) {//input, none, tag
    case 'input':
      return tag.elseText
      break;

    case 'none':
      return '';
      break;

    case 'tag':
      if (tag.elseSelectedTag !== 'none') {
        return tag.elseSelectedTag
      }
      else {
        return ''
      }
      break;

    default:
      break;
  }
}

export const getAmountValue = (tag, condition, conditionIndex, calculation, amountCondition, setTagsResultsTexts, tagsResultsTexts, amountWOCondition) => {
  switch (condition[`operator${conditionIndex}`]) {
    case '=':
      if (condition[`conditionValueType${conditionIndex}`] === 'none' && !amountCondition) {
        return [true, getAmSwIfVal(tag, condition, conditionIndex, calculation, amountCondition, setTagsResultsTexts, tagsResultsTexts)]
      }
      if (condition[`conditionValueType${conditionIndex}`] !== 'none' && Number(amountCondition) === Number(condition[`conditionAmountValue${conditionIndex}`])) {
        return [true, getAmSwIfVal(tag, condition, conditionIndex, calculation, amountCondition, setTagsResultsTexts, tagsResultsTexts)]
      }
      else {
        if (setTagsResultsTexts instanceof Function) {
          setTagsResultsTexts({ [tag.tagName]: amountCondition })
        }
        return [false, getAmSelSwElseVal(tag)]
      }
      break;
    case '>':
      if (Number(amountCondition) > Number(condition[`conditionAmountValue${conditionIndex}`])) {
        return [true, getAmSwIfVal(tag, condition, conditionIndex, calculation, amountCondition, setTagsResultsTexts, tagsResultsTexts)]
      }
      else {
        if (setTagsResultsTexts instanceof Function) {
          setTagsResultsTexts({ [tag.tagName]: amountCondition })
        }
        return [false, getAmSelSwElseVal(tag)]
      }
      break;
    case '<':
      if (Number(amountCondition) < Number(condition[`conditionAmountValue${conditionIndex}`])) {
        return [true, getAmSwIfVal(tag, condition, conditionIndex, calculation, amountCondition, setTagsResultsTexts, tagsResultsTexts)]
      }
      else {
        if (setTagsResultsTexts instanceof Function) {
          setTagsResultsTexts({ [tag.tagName]: amountCondition })
        }
        return [false, getAmSelSwElseVal(tag)]
      }
      break;
    case '!=':
      if (condition[`conditionValueType${conditionIndex}`] === 'none') {
        if (amountCondition !== null) {
          return [true, getAmSwIfVal(tag, condition, conditionIndex, calculation, amountCondition, setTagsResultsTexts, tagsResultsTexts)]
        } else {
          return [false, getAmSelSwElseVal(tag)]
        }
      }
      if (Number(amountCondition) !== Number(condition[`conditionAmountValue${conditionIndex}`])) {
        return [true, getAmSwIfVal(tag, condition, conditionIndex, calculation, amountCondition, setTagsResultsTexts, tagsResultsTexts)]
      }
      else {
        if (setTagsResultsTexts instanceof Function) {
          setTagsResultsTexts({ [tag.tagName]: amountCondition })
        }
        return [false, getAmSelSwElseVal(tag)]
      }
      break;

    case 'Value':
      if (amountWOCondition) {
        return [true, amountWOCondition]
      } else { return [false, '!!No Output!!'] }
      break;

    default:
      break;
  }
}

export const performComparison = (operator, value1, value2) => {
  if (Operators.hasOwnProperty(operator)) {
    return Operators[operator](value1, value2);
  } else {
    console.log('Invalid operator');
    return false; // or throw an error, depending on your preference
  }
}


const getCrateCost = (crates, specialName) => {
  let crateCost;
  for (let i = 0; i < crates.length; i++) {
    const crate = crates[i];
    if (crate[`crateName${i}`] === specialName) {
      crateCost = crate[`crateCost${i}`];
      break; // Exit the loop once the specialName is found
    }
  }
  return crateCost
}

export const getTagValue = (tag, condition, conditionIndex, calculation, calculatedParams, setTagsResultsTexts, tagsResultsTexts, isPreviewPage, furniture, roomsAreas) => {

  switch (condition[`selectedItemObj${conditionIndex}`].value) {
    case 'fullPackingPrice':
      return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department?.packingCharge?.fullPacking) ? calculation.department.packingCharge.fullPacking : null, setTagsResultsTexts, tagsResultsTexts, calculation.department?.packingCharge?.fullPacking)
      break;
    case 'partialPackingPrice':
      return getAmountValue(tag, condition, conditionIndex, calculation, calculation.department?.packingCharge?.partialPacking, setTagsResultsTexts, tagsResultsTexts, calculation.department?.packingCharge?.partialPacking)

      break;
    case 'kitchenPackingPrice':
      return getAmountValue(tag, condition, conditionIndex, calculation, calculation.department?.packingCharge?.kitchenPacking, setTagsResultsTexts, tagsResultsTexts, calculation.department?.packingCharge?.kitchenPacking)
      break;
    case 'perBoxPrice':
      return getAmountValue(tag, condition, conditionIndex, calculation, calculation.department?.packing?.supplies.perOnePackedBox, setTagsResultsTexts, tagsResultsTexts, calculation.department?.packing?.supplies?.perOnePackedBox)
      break;

    case 'totalCubicFt':
      return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Total_cubes, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_cubes)
      break;

    case 'unpackingDayRoundTripMiles':
      return getAmountValue(tag, condition, conditionIndex, calculation, Number(calculation.commonValues.milesToParkingCuFt * 2), setTagsResultsTexts, tagsResultsTexts, Number(calculation.commonValues.milesToParkingCuFt * 2))
      break;

    case 'additionalAmountUnpacking':
      return getAmountValue(tag, condition, conditionIndex, calculation, calculation.commonValues.additionalAmountUnpacking, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.additionalAmountUnpacking)
      break;

    case 'inventoryText':
      var isSelected = false;
      let inventoryText = '';
      inventoryText = getFurnitureText(calculation, furniture, roomsAreas, 'furnitureGoes').inventoryText;
      if (inventoryText) {
        isSelected = true;
      }
      return getCheckPointValue(
        tag,
        condition,
        conditionIndex,
        isSelected,
        'INVENTORY',
        setTagsResultsTexts,
        tagsResultsTexts
      );
      break;
  }




  switch (calculation?.calculationType) {
    case 'oneDayPacking':
      try {
        if (condition[`selectedItemObj${conditionIndex}`].value.split('_').length > 1) {//additionals
          let splittedSelectedItem = condition[`selectedItemObj${conditionIndex}`].value.split('_')
          var numberOfProcedures = 0;
          var procedureIndex = null;
          var procedureType = null;

          if (calculation.commonValues.procedures.length) {
            try {
              for (let index = 0; index < calculation.commonValues.procedures.length; index++) {
                const element = calculation.commonValues.procedures[index];
                if (element.uId === splittedSelectedItem[1]) {
                  numberOfProcedures = Number(element.numberOfProcedures);
                  procedureIndex = index;
                  procedureType = element.procedureType;
                  break;
                }
              }
            }
            catch (err) {
              console.log(err)
            }
          }

          var foundItem;
          var foundItemIndex;

          try {
            calculation.department.extraOptions.charges.some((item, index) => {
              if ((item[`uId${index}`] === splittedSelectedItem[1] && calculation.oneDayPacking.selectedChargesNamesDay1.some(item => item.key === splittedSelectedItem[1]))) {
                foundItem = item;
                foundItemIndex = index;
                return true; // Exit the loop when a match is found
              }
              return false;
            });
          } catch (err) { }

          try {
            calculation.department.additionalServices.services.some((service, index) => {
              if (service.serviceName === splittedSelectedItem[1]) {
                foundItem = service;
                foundItemIndex = index;
                return true; // Exit the loop when a match is found
              }
              return false;
            })
          } catch (err) { }


          switch (splittedSelectedItem[0]) {
            case 'chIdName':
              var isSelected = false;
              if (calculation.oneDayPacking.selectedChargesNamesDay1.some(item => item.key === splittedSelectedItem[1])) {
                isSelected = true;
              }
              return getCheckPointValue(tag, condition, conditionIndex, isSelected, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem) ? foundItem[`amount${foundItemIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${foundItemIndex}`])
              break;

            case 'chIdQty':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oneDayPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1])) ? calculation.oneDayPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.oneDayPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'pIdName':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? procedureType : null, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'pIdCost':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? (getCrateCost(calculation.department.packing.cratings, procedureType) * numberOfProcedures) : null, setTagsResultsTexts, tagsResultsTexts, (getCrateCost(calculation.department.packing.cratings, procedureType) * numberOfProcedures))
              break;

            case 'pIdQty':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? numberOfProcedures : null, setTagsResultsTexts, tagsResultsTexts, numberOfProcedures)
              break;

            case 'mChId':
              var isSelected = false;
              if (calculation.selectedMiscCheckMarks.includes(splittedSelectedItem[1])) {
                isSelected = true;
              }
              return getCheckPointValue(tag, condition, conditionIndex, isSelected, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'aSIdName':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.selectedAdditionalServices.some(item => item.key === splittedSelectedItem[1])) ? splittedSelectedItem[1] : null, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'aSIdAmount':
              var aSIdAmount = null;
              var pricePerCuFtPrice;
              var selectedServiceName = splittedSelectedItem[1];
              const isSelectedServiceIncluded = calculation.commonValues.selectedAdditionalServices.some(item => item.key.includes(selectedServiceName));
              try {
                if (isSelectedServiceIncluded) {
                  calculation.department.additionalServices.services.map((service) => {
                    if (selectedServiceName === service.serviceName) {
                      if (service.serviceType === 'cuft') {
                        for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                          let pricePerCuFt = service.pricesPerCuFt[pcftIndex];

                          if (performComparison(pricePerCuFt[`operator${pcftIndex}`], Number(calculatedParams.Total_cubes), Number(pricePerCuFt[`cubicFtRange${pcftIndex}`]))) {
                            let totalCubes = service.fixedAmount ? 1 : calculatedParams.Total_cubes;
                            aSIdAmount = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]) * totalCubes;
                            pricePerCuFtPrice = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                          }
                        }
                      } else if (service.serviceType === 'factor') {
                        for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                          let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
                          if (performComparison(pricePerCuFt[`operator${pcftIndex}`], calculation.commonValues.additionalServicesPlaceType, pricePerCuFt[`factorName${pcftIndex}`])) {
                            let totalCubes = service.fixedAmount ? 1 : calculatedParams.Total_cubes;
                            aSIdAmount = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]) * totalCubes;
                            pricePerCuFtPrice = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                          }
                        }
                      }
                    }
                  })
                } else {
                  calculation.department.additionalServices.services.map((service) => {
                    if (selectedServiceName === service.serviceName) {
                      for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                        let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
                        if (performComparison(pricePerCuFt[`operator${pcftIndex}`], calculation.commonValues.additionalServicesPlaceType, pricePerCuFt[`factorName${pcftIndex}`])) {
                          pricePerCuFtPrice = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                        }
                      }
                    }
                  })
                }
              } catch (err) { }
              return getAmountValue(tag, condition, conditionIndex, calculation, (isSelectedServiceIncluded) ? aSIdAmount : null, setTagsResultsTexts, tagsResultsTexts, pricePerCuFtPrice)
              break;

            case 'aSIdQty':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.selectedAdditionalServices.find(item => item.key === splittedSelectedItem[1])) ? calculation.commonValues.selectedAdditionalServices.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.selectedAdditionalServices.find(item => item.key === splittedSelectedItem[1]).quantity)
              break;
          }
        }

        var formattedRateInfo = getFormattedRateInfo(calculation.department, calculatedParams.Total_Destinationfee_One_Day, calculatedParams.Rate_cash_One_Day, calculatedParams.Rate_card_One_Day, calculatedParams.Truck_fee_One_Day)
        switch (condition[`selectedItemObj${conditionIndex}`].value) {

          case 'additionalAmount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oneDayPacking.additionalAmount) ? calculation.oneDayPacking.additionalAmount : null, setTagsResultsTexts, tagsResultsTexts, '')
            break;

          case 'totalCubicFt':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Total_cubes, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_cubes)
            break;

          case 'additionalServices':
            return getCheckPointValue(tag, condition, conditionIndex, !!calculation.commonValues.selectedAdditionalServices.length, 'Additional Services', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'extraOptions':
            var isExtraOptionsSelected = false;
            if (calculation.oneDayPacking.selectedChargesNamesDay1.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.oneDayPacking.selectedChargesNamesDay1.some(item => item.key.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'procedures':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.procedures.length > 0 && calculation.commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '')) ? true : null, 'Procedures', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partial':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Same Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partialSeparate':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Separate Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'totalRoundTripMiles':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculatedParams.Round_trip_miles) ? calculatedParams.Round_trip_miles : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'username':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.userId, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'department':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.department._id, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'calculationNumber':
            return getCheckPointValue(tag, condition, conditionIndex, true, calculation.calculationNumber, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day1weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.oneDayPacking.firstDayDate) ? new Date(calculation.oneDayPacking.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttle':
            var isSelected = false;
            if (calculation.commonValues.shuttle) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQty':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.shuttle && calculation.department.extraOptions.shuttle.isCountable) ? calculation.oneDayPacking.quantityDay1 : null, setTagsResultsTexts, tagsResultsTexts, calculation.oneDayPacking.quantityDay1)
            break;

          case 'unpackingShuttle':
            var isSelected = false;
            if (calculation.commonValues.shuttleOnUnpacking) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'unpackingShuttleQty':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.shuttleOnUnpacking && calculation.department.extraOptions.shuttle.isCountable) ? calculation.commonValues.quantityUnpacking : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.quantityUnpacking)
            break;

          case 'doubleDrive':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.Double_drive, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'twoTrips':
            var isSelected = false;
            if (calculatedParams.TWO_Trips_Or_OK !== "OK" && condition[`conditionSelectValue${conditionIndex}`] === calculatedParams.TWO_Trips_Or_OK) {
              isSelected = true;
            }
            else {
              return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.TWO_Trips_Or_OK, setTagsResultsTexts, tagsResultsTexts)
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, '2 TRIPS', setTagsResultsTexts, tagsResultsTexts)
            //return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.TWO_Trips_Or_OK, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'biggestOrOk':
            var isSelected = false;
            if (calculatedParams.TWO_Trips_Or_OK !== "2 TRIPS" && calculatedParams.BIGGEST_or_OK !== 'OK' && condition[`conditionSelectValue${conditionIndex}`] === 'BIGGEST TRUCK') {
              isSelected = true;
            }
            else {
              return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.TWO_Trips_Or_OK, setTagsResultsTexts, tagsResultsTexts)
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'BIGGEST TRUCK', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'packingType':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.commonValues.packing, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'liftGate':
            var isSelected = false;
            if (calculation.commonValues.liftGate) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'LIFTGATE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'hardwoodFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.hardFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'HARDWOOD FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'carpetFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.filmFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'CARPET FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'sameDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Same Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'UNPACKING', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'separateDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Separate Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'Unpacking on separate day', setTagsResultsTexts, tagsResultsTexts)
            break;
          case 'truckloads':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, truckLoads, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truckloads)
            break;

          case 'trucksPercent':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0) ? Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100)) : null, setTagsResultsTexts, tagsResultsTexts, Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100)))
            break;

          case 'truckloadsRoundDown':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0) ? Number(Math.floor(truckLoads).toFixed(2)) : null, setTagsResultsTexts, tagsResultsTexts, Number(Math.floor(truckLoads).toFixed(2)))
            break;

          case 'additionalStopsCount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ? calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length)
            break;

          case 'movers':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_One_Day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_One_Day)
            break;

          case 'rate':
            return getAmountValue(tag, condition, conditionIndex, calculation, (!(Number(calculation.oneDayPacking.moversToLoad) > 0)) ? calculatedParams.Rate_card_One_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_One_Day)
            break;

          case 'truckfee':
            return getAmountValue(tag, condition, conditionIndex, calculation, (formattedRateInfo.isTruck_fee_One_DayShowed) ? calculatedParams.Truck_fee_One_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_One_Day)
            break;

          case 'rateCash':
            return getAmountValue(tag, condition, conditionIndex, calculation, (formattedRateInfo.isRate_cash_One_DayShowed) ? calculatedParams.Rate_cash_One_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_One_Day)
            break;

          case 'rateCashLoad':
            return getAmountValue(tag, condition, conditionIndex, calculation, (Number(calculation.oneDayPacking.moversToLoad) > 0) ? calculatedParams.Rate_cash_Movers_To_Load_One_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_Movers_To_Load_One_Day)
            break;

          case 'rateCardLoad':
            return getAmountValue(tag, condition, conditionIndex, calculation, (Number(calculation.oneDayPacking.moversToLoad) > 0) ? calculatedParams.Rate_card_Movers_To_Load_One_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_Movers_To_Load_One_Day)
            break;

          case 'moversToLoad':
            return getAmountValue(tag, condition, conditionIndex, calculation, (Number(calculation.oneDayPacking.moversToLoad) > 0) ? calculation.oneDayPacking.moversToLoad : null, setTagsResultsTexts, tagsResultsTexts, calculation.oneDayPacking.moversToLoad)
            break;

          case 'moversToDrivesAndUnload':
            return getAmountValue(tag, condition, conditionIndex, calculation, (Number(calculation.oneDayPacking.moversToDrivesAndUnload) > 0) ? calculation.oneDayPacking.moversToDrivesAndUnload : null, setTagsResultsTexts, tagsResultsTexts, calculation.oneDayPacking.moversToDrivesAndUnload)
            break;

          case 'rateCardUnload':
            return getAmountValue(tag, condition, conditionIndex, calculation, (Number(calculation.oneDayPacking.moversToDrivesAndUnload) > 0) ? calculatedParams.Rate_card_Movers_To_Unload_One_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_Movers_To_Unload_One_Day)
            break;

          case 'rateCashUnload':
            return getAmountValue(tag, condition, conditionIndex, calculation, (Number(calculation.oneDayPacking.moversToDrivesAndUnload) > 0 && calculation.department.rates && calculation.department.rates.calcMethod === 'deduct') ? calculatedParams.Rate_cash_Movers_To_Unload_One_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_Movers_To_Unload_One_Day)
            break;

          case 'trucks':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_One_Day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_One_Day)
            break;

          case 'lowerNumberOfHours':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_One_Day), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_One_Day))
            break;

          case 'higherNumberOfHours':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_One_Day), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_One_Day))
            break;

          case 'destinationFee':
            let destinationFee = 0
            if (calculation.department.truckFee.tfdfCombined) {
              destinationFee = calculatedParams.Total_Destinationfee_One_Day + calculatedParams.Truck_fee_One_Day
            } else {
              destinationFee = calculatedParams.Total_Destinationfee_One_Day
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculatedParams.Total_Destinationfee_One_Day > 0) ? destinationFee : null, setTagsResultsTexts, tagsResultsTexts, destinationFee)
            break;

          case 'packingKit':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_Show : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_Show)
            break;

          case 'packingKitWithTax':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_total_amount_with_taxes : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_total_amount_with_taxes)
            break;

          case 'smallBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.smallBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.smallBox)
            break;

          case 'mediumBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.mediumBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.mediumBox)
            break;

          case 'largeBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.largeBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.largeBox)
            break;

          case 'rollOfPaper':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.packingPapper : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.packingPapper)
            break;

          case 'rollOfBubble':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.bubbleWrap : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.bubbleWrap)
            break;

          case 'notToExceedPrice':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Total_Card_One_day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_One_day)
            break;

          case 'notToExceedPricePercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod !== 'deduct') ? getCurrentRatePercent(calculation.oneDayPacking, calculation.department) : null, setTagsResultsTexts, tagsResultsTexts, getCurrentRatePercent(calculation.oneDayPacking, calculation.department))
            break;

          case 'cashDiscount':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Total_Cash_One_day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Cash_One_day)
            break;

          case 'trucksUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Trucks_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_Unpacking_Separate_Day)
            break;

          case 'moversUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Movers_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_Unpacking_Separate_Day)
            break;

          case 'unpackingSeparateDayLowerhours':
            return getAmountValue(tag, condition, conditionIndex, calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)) ?
                getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)
                : null,
              setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours))
            break;

          case 'unpackingSeparateDayHigherhours':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours) : null, setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours))
            break;

          case 'totalCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day)
            break;

          case 'unpackingSeparatePercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getCurrentRatePercent(calculation.oneDayPacking, calculation.department) : null, setTagsResultsTexts, tagsResultsTexts, getCurrentRatePercent(calculation.oneDayPacking, calculation.department))
            break;

          case 'totalCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Cash_Unpacking_Separate_Day)
            break;

          case 'rateCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod === 'deduct') ? calculatedParams.Rate_cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_Unpacking_Separate_Day)
            break;

          case 'rateCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Rate_card_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_Unpacking_Separate_Day)
            break;

          case 'truckFeeUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && calculatedParams.Truck_fee_Unpacking_Separate_Day !== 0) ? calculatedParams.Truck_fee_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_Unpacking_Separate_Day)
            break;

          case 'totalCardUnpackingSeparateDayLongOOS':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS)
            break;
          case 'combinedMileage':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Combined_mileage, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Combined_mileage)
            break;

          case 'day1month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oneDayPacking.firstDayDate) ? Number(new Date(calculation.oneDayPacking.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oneDayPacking.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day1date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oneDayPacking.firstDayDate) ? Number(new Date(calculation.oneDayPacking.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oneDayPacking.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day1year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oneDayPacking.firstDayDate) ? Number(new Date(calculation.oneDayPacking.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oneDayPacking.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'heavyFee':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculatedParams.Heavy_fee !== 0) ? calculatedParams.Heavy_fee : null, setTagsResultsTexts, tagsResultsTexts, Number(calculation.department.extraOptions.heavyItem))
            break;

          case 'packingCharge':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Packing_Charge, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_Charge)
            break;

          case 'unPackingCharge':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.UnPacking_Charge, setTagsResultsTexts, tagsResultsTexts, calculatedParams.UnPacking_Charge)
            break;

          case 'flatRateOneDayCash':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Flat_rate_One_Day_cash, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_One_Day_cash)
            break;

          case 'flatRateOneDayCard':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Flat_rate_One_Day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_One_Day)
            break;

          case 'flatRateUnpackingSeparateDayCash':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.unpacking == 'Separate Day') ? calculatedParams.Flat_rate_Unpacking_Separate_Day_cash : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_Unpacking_Separate_Day_cash)
            break;

          case 'flatRateUnpackingSeparateDayCard':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.unpacking == 'Separate Day') ? calculatedParams.Flat_rate_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_Unpacking_Separate_Day)
            break;

        }

      }
      catch (err) {
        console.log(err)
      }
      break;

    case 'twoDaysPacking':

      try {
        if (condition[`selectedItemObj${conditionIndex}`].value.split('_').length > 1) {//additionals
          let splittedSelectedItem = condition[`selectedItemObj${conditionIndex}`].value.split('_')
          var numberOfProcedures = 0;
          var procedureIndex = null;
          var procedureType = null;
          var foundItem;
          var foundItemIndex;
          var isSelectedDay1 = false;
          var isSelectedDay2 = false;
          if (calculation.twoDaysPacking.selectedChargesNamesDay1.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay1 = true;
          }
          if (calculation.twoDaysPacking.selectedChargesNamesDay2.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay2 = true;
          }

          calculation.department.extraOptions.charges.some((item, index) => {
            if (item[`uId${index}`] === splittedSelectedItem[1]) {
              foundItem = item;
              foundItemIndex = index;
              return true; // Exit the loop when a match is found
            }
            return false;
          });
          if (calculation.commonValues.procedures.length) {
            try {
              for (let index = 0; index < calculation.commonValues.procedures.length; index++) {
                const element = calculation.commonValues.procedures[index];

                if (element.uId === splittedSelectedItem[1]) {
                  numberOfProcedures = Number(element.numberOfProcedures);
                  procedureIndex = index;
                  procedureType = element.procedureType;
                  break;
                }
              }
            }
            catch (err) {
              console.log(err)
            }
          }
          //console.log('numberOfProceduresDay1', numberOfProceduresDay1)

          switch (splittedSelectedItem[0]) {
            case 'chIdName1':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay1, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay1) ? foundItem[`amount${foundItemIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${foundItemIndex}`])
              break;
            case 'chIdQty1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1])) ? calculation.twoDaysPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.twoDaysPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'pIdName1':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.twoDaysPacking.proceduresOnDay1) ? procedureType : null, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'pIdCost1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.twoDaysPacking.proceduresOnDay1) ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures) : null, setTagsResultsTexts, tagsResultsTexts, (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures))
              break;

            case 'pIdQty1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.twoDaysPacking.proceduresOnDay1) ? numberOfProcedures : null, setTagsResultsTexts, tagsResultsTexts, numberOfProcedures)
              break;

            case 'chIdName2':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay2, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay2) ? foundItem[`amount${foundItemIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${foundItemIndex}`])
              break;

            case 'chIdQty2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1])) ? calculation.twoDaysPacking.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.twoDaysPacking.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'pIdName2':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.twoDaysPacking.proceduresOnDay2) ? procedureType : null, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'pIdCost2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.twoDaysPacking.proceduresOnDay2) ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures) : null, setTagsResultsTexts, tagsResultsTexts, (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures))
              break;

            case 'pIdQty2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.twoDaysPacking.proceduresOnDay2) ? numberOfProcedures : null, setTagsResultsTexts, tagsResultsTexts, numberOfProcedures)
              break;

            case 'mChId':
              var isSelected = false;
              if (calculation.selectedMiscCheckMarks.includes(splittedSelectedItem[1])) {
                isSelected = true;
              }
              return getCheckPointValue(tag, condition, conditionIndex, isSelected, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'aSIdName':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.selectedAdditionalServices.some(item => item.key === splittedSelectedItem[1])) ? splittedSelectedItem[1] : null, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'aSIdAmount':
              var aSIdAmount = null;
              var pricePerCuFtPrice;
              var selectedServiceName = splittedSelectedItem[1];
              const isSelectedServiceIncluded = calculation.commonValues.selectedAdditionalServices.some(item => item.key.includes(selectedServiceName));
              try {
                if (isSelectedServiceIncluded) {
                  calculation.department.additionalServices.services.map((service) => {
                    if (selectedServiceName === service.serviceName) {
                      if (service.serviceType === 'cuft') {
                        for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                          let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
                          if (performComparison(pricePerCuFt[`operator${pcftIndex}`], Number(calculatedParams.Total_cubes), Number(pricePerCuFt[`cubicFtRange${pcftIndex}`]))) {
                            aSIdAmount = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]) * calculatedParams.Total_cubes;
                            pricePerCuFtPrice = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                          }
                        }
                      } else if (service.serviceType === 'factor') {
                        for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                          let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
                          if (performComparison(pricePerCuFt[`operator${pcftIndex}`], calculation.commonValues.additionalServicesPlaceType, pricePerCuFt[`factorName${pcftIndex}`])) {
                            aSIdAmount = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                            pricePerCuFtPrice = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                          }
                        }
                      }
                    }
                  })
                } else {
                  calculation.department.additionalServices.services.map((service) => {
                    if (selectedServiceName === service.serviceName) {
                      for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                        let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
                        if (performComparison(pricePerCuFt[`operator${pcftIndex}`], calculation.commonValues.additionalServicesPlaceType, pricePerCuFt[`factorName${pcftIndex}`])) {
                          pricePerCuFtPrice = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                        }
                      }
                    }
                  })
                }
              } catch (err) { }
              return getAmountValue(tag, condition, conditionIndex, calculation, (isSelectedServiceIncluded) ? aSIdAmount : null, setTagsResultsTexts, tagsResultsTexts, pricePerCuFtPrice)
              break;

            case 'aSIdQty':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1])) ? calculation.twoDaysPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.twoDaysPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

          }
        }

        var formattedRateInfo = getFormattedRateInfoTwoDays(calculation.department, calculatedParams.Total_Destinationfee_Day2, calculatedParams.Rate_cash_Day1, calculatedParams.Rate_card_Day1, calculatedParams.Truck_fee_Day1)
        var formattedRateLoadInfo = getFormattedRateLoadInfoTwoDays(calculation.department, calculation.Total_Destinationfee_Day2, calculatedParams.Rate_card_Movers_To_Load_Day2, calculatedParams.Truck_fee_Day2)
        var formattedRateLoadInfoDay2 = getFormattedRateInfoTwoDaysDay2(calculation.department, calculatedParams.Total_Destinationfee_Day2, calculatedParams.Rate_cash_Day2, calculatedParams.Rate_card_Day2, calculatedParams.Truck_fee_Day2)

        switch (condition[`selectedItemObj${conditionIndex}`].value) {

          case 'additionalAmount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.additionalAmount) ? calculation.twoDaysPacking.additionalAmount : null, setTagsResultsTexts, tagsResultsTexts, '')
            break;

          case 'additionalServices':
            return getCheckPointValue(tag, condition, conditionIndex, !!calculation.commonValues.selectedAdditionalServices.length, 'Additional Services', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'extraOptions':
            var isExtraOptionsSelected = false;
            if (calculation.twoDaysPacking.selectedChargesNamesDay1.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.twoDaysPacking.selectedChargesNamesDay1.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'extraOptionsDay2':
            var isExtraOptionsSelected = false;
            if (calculation.twoDaysPacking.selectedChargesNamesDay2.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.twoDaysPacking.selectedChargesNamesDay2.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'procedures':
            return getCheckPointValue(tag, condition, conditionIndex, ((calculation.commonValues.procedures.length > 0 && calculation.commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '')) && (calculation.commonValues.procedures[0].procedureType !== "None" && calculation.commonValues.procedures[0].procedureType !== "") && calculation.twoDaysPacking.proceduresOnDay1) ? true : null, 'Procedures', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'proceduresDay2':
            return getCheckPointValue(tag, condition, conditionIndex, ((calculation.commonValues.procedures.length > 0 && calculation.commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '')) && (calculation.commonValues.procedures[0].procedureType !== "None" && calculation.commonValues.procedures[0].procedureType !== "") && calculation.twoDaysPacking.proceduresOnDay2) ? true : null, 'Procedures', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partial':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Same Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partialSeparate':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Separate Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'totalRoundTripMiles':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculatedParams.Round_trip_miles) ? calculatedParams.Round_trip_miles : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'username':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.userId, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'additionalServices':
            return getCheckPointValue(tag, condition, conditionIndex, !!calculation.commonValues.selectedAdditionalServices.length, 'Additional Services', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'department':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.department._id, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'calculationNumber':
            return getCheckPointValue(tag, condition, conditionIndex, true, calculation.calculationNumber, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day1year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.firstDayDate) ? Number(new Date(calculation.twoDaysPacking.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDaysPacking.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day1weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.firstDayDate) ? new Date(calculation.twoDaysPacking.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day1month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.firstDayDate) ? Number(new Date(calculation.twoDaysPacking.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDaysPacking.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day1date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.firstDayDate) ? Number(new Date(calculation.twoDaysPacking.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDaysPacking.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.secondDayDate) ? Number(new Date(calculation.twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.secondDayDate) ? new Date(calculation.twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day2month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.secondDayDate) ? Number(new Date(calculation.twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.secondDayDate) ? Number(new Date(calculation.twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.secondDayDate) ? Number(new Date(calculation.twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'truckloads':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, truckLoads, setTagsResultsTexts, tagsResultsTexts, truckLoads)
            break;

          case 'trucksPercent':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0) ? Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100)) : null, setTagsResultsTexts, tagsResultsTexts, Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100)))
            break;

          case 'truckloadsRoundDown':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0) ? Number(Math.floor(truckLoads).toFixed(2)) : null, setTagsResultsTexts, tagsResultsTexts, Number(Math.floor(truckLoads).toFixed(2)))
            break;

          case 'additionalStopsCount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ? calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length)
            break;

          case 'combinedMileage':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Combined_mileage, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Combined_mileage)
            break;

          case 'moversToPackDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_To_Pack_Day1, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_To_Pack_Day1)
            break;

          case 'moversToDrivesAndUnload':
            return getAmountValue(tag, condition, conditionIndex, calculation, (Number(calculation.twoDaysPacking.moversToDrivesAndUnload) > 0) ? calculation.twoDaysPacking.moversToDrivesAndUnload : null, setTagsResultsTexts, tagsResultsTexts, calculation.twoDaysPacking.moversToDrivesAndUnload)
            break;

          case 'rateCashDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, (formattedRateInfo.isRate_cash_Day1Showed) ? calculatedParams.Rate_cash_Day1 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_Day1)
            break;

          case 'rateCardDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Rate_card_Day1, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_Day1)
            break;

          case 'truckFeeOnDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, (formattedRateInfo.isTruck_fee_Day1Showed) ? calculatedParams.Truck_fee_Day1 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_Day1)
            break;

          case 'trucksToPackDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_To_Pack_Day1, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_To_Pack_Day1)
            break;

          case 'shuttleDay1':
            var isSelected = false;
            if (calculation.twoDaysPacking.shuttleOnDayOne) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDaysPacking.shuttleOnDayOne && calculation.department.extraOptions.shuttle.isCountable) ? calculation.twoDaysPacking.quantityDay1 : null, setTagsResultsTexts, tagsResultsTexts, calculation.twoDaysPacking.quantityDay1)
            break;

          case 'cubicFtToMoveDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculation.twoDaysPacking.cubicFtToMoveDay1, setTagsResultsTexts, tagsResultsTexts, calculation.twoDaysPacking.cubicFtToMoveDay1)
            break;

          case 'doubleDriveDay1':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculatedParams.cubicf_Day1 > 0) ? calculatedParams.Double_drive : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'lowerNumberOfHoursDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, (getLowerNumberOfHoursValueOnly((calculation.department) ? calculation.department.minimumHours : 0, calculatedParams.Lower_Number_of_Hours_Day1)) ? getLowerNumberOfHoursValueOnly((calculation.department) ? calculation.department.minimumHours : 0, calculatedParams.Lower_Number_of_Hours_Day1) : null, setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly((calculation.department) ? calculation.department.minimumHours : 0, calculatedParams.Lower_Number_of_Hours_Day1))
            break;

          case 'higherNumberOfHoursDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_Day1), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_Day1))
            break;

          case 'packingKit':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_Show : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_Show)
            break;

          case 'packingKitWithTax':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_total_amount_with_taxes : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_total_amount_with_taxes)
            break;

          case 'sameDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Same Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'UNPACKING', setTagsResultsTexts, tagsResultsTexts)
            break;
          case 'separateDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Separate Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'Unpacking on separate day', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'smallBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.smallBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.smallBox)
            break;

          case 'mediumBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.mediumBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.mediumBox)
            break;

          case 'largeBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.largeBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.largeBox)
            break;

          case 'rollOfPaper':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.packingPapper : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.packingPapper)
            break;

          case 'rollOfBubble':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.bubbleWrap : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.bubbleWrap)
            break;


          case 'packingType':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.commonValues.packing, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'moversToLoad':
            return getAmountValue(tag, condition, conditionIndex, calculation, (Number(calculation.twoDaysPacking.moversToLoad) > 0) ? calculation.twoDaysPacking.moversToLoad : null, setTagsResultsTexts, tagsResultsTexts, calculation.twoDaysPacking.moversToLoad)
            break;

          case 'rateCashMoversToLoadDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (formattedRateLoadInfo.isRate_cash_Movers_To_Load_Day2Showed) ? calculatedParams.Rate_cash_Movers_To_Load_Day2 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_Movers_To_Load_Day2, formattedRateLoadInfo.isRate_cash_Movers_To_Load_Day2Showed)
            break;

          case 'rateCardMoversToLoadDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (Number(calculation.twoDaysPacking.moversToLoad) > 0) ? calculatedParams.Rate_card_Movers_To_Load_Day2 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_Movers_To_Load_Day2)
            break;

          case 'truckFeeDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (formattedRateLoadInfoDay2.isTruckFeeDay2Showed) ? calculatedParams.Truck_fee_Day2 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_Day2)
            break;

          case 'moversDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (!(Number(calculation.twoDaysPacking.moversToLoad) > 0)) ? calculatedParams.Movers_Day2 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_Day2)
            break;

          case 'rateCashDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (!(Number(calculation.twoDaysPacking.moversToLoad) > 0) && formattedRateLoadInfoDay2.isRateCashDay2Showed) ? calculatedParams.Rate_cash_Day2 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_Day2)
            break;

          case 'rateCardDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (!(Number(calculation.twoDaysPacking.moversToLoad) > 0) && !formattedRateLoadInfoDay2.isTruckFeeCombined) ? calculatedParams.Rate_card_Day2 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_Day2)
            break;

          case 'rateCardMoversToUnloadDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (Number(calculation.twoDaysPacking.moversToDrivesAndUnload) > 0) ? calculatedParams.Rate_card_Movers_To_Unload_Day2 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_Movers_To_Unload_Day2)
            break;

          case 'trucksDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_Day2, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_Day2)
            break;

          case 'shuttleDay2':
            var isSelected = false;
            if (calculation.commonValues.shuttle) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.shuttle && calculation.department.extraOptions.shuttle.isCountable) ? calculation.twoDaysPacking.quantityDay2 : null, setTagsResultsTexts, tagsResultsTexts, calculation.twoDaysPacking.quantityDay2)
            break;

          case 'twoTripsOrOkDay2':
            var isSelected = false;
            if (calculatedParams.TWO_Trips_Or_OK_Day2 !== "OK" && condition[`conditionSelectValue${conditionIndex}`] === calculatedParams.TWO_Trips_Or_OK_Day2) {
              isSelected = true;
            }
            else {
              return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.TWO_Trips_Or_OK_Day2, setTagsResultsTexts, tagsResultsTexts)
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, '2 TRIPS', setTagsResultsTexts, tagsResultsTexts)
            //return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.TWO_Trips_Or_OK, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'biggestOrOkDay2':
            var isSelected = false;
            if (calculatedParams.TWO_Trips_Or_OK_Day2 !== "2 TRIPS" && calculatedParams.BIGGEST_or_OK_Day2 !== 'OK' && condition[`conditionSelectValue${conditionIndex}`] === 'BIGGEST TRUCK') {
              isSelected = true;
            }
            else {
              return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.TWO_Trips_Or_OK_Day2, setTagsResultsTexts, tagsResultsTexts)
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'BIGGEST TRUCK', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'doubleDriveDay2':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.Double_drive, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'destinationFee':
            let destinationFee = 0
            if (calculation.department.truckFee.tfdfCombined) {
              destinationFee = calculatedParams.Total_Destinationfee_Day2 + calculatedParams.Truck_fee_Day1 + calculatedParams.Truck_fee_Day2
            } else {
              destinationFee = calculatedParams.Total_Destinationfee_Day2
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculatedParams.Total_Destinationfee_Day2 > 0) ? destinationFee : null, setTagsResultsTexts, tagsResultsTexts, destinationFee)
            break;

          case 'lowerNumberOfHoursDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_Day2)) ? getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_Day2) : null, setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_Day2))
            break;

          case 'higherNumberOfHoursDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_Day2), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_Day2))
            break;

          case 'heavyFee':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculatedParams.Heavy_fee !== 0) ? calculatedParams.Heavy_fee : null, setTagsResultsTexts, tagsResultsTexts, Number(calculation.department.extraOptions.heavyItem))
            break;

          case 'liftGate':
            var isSelected = false;
            if (calculation.commonValues.liftGate) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'LIFTGATE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'hardwoodFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.hardFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'HARDWOOD FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'carpetFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.filmFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'CARPET FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'notToExceedPricePercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod !== 'deduct') ? getCurrentRatePercent(calculation.twoDaysPacking, calculation.department) : null, setTagsResultsTexts, tagsResultsTexts, getCurrentRatePercent(calculation.twoDaysPacking, calculation.department))
            break;

          case 'cashDiscountPercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculation.longDistance.cashPaymentDiscount, setTagsResultsTexts, tagsResultsTexts, calculation.longDistance.cashPaymentDiscount)
            break;

          case 'totalCash2DaysPacking':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Total_Cash_2Days_Packing, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Cash_2Days_Packing)
            break;

          case 'totalCard2DaysPacking':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Total_Card_2Days_Packing, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_2Days_Packing)
            break;

          case 'trucksUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Trucks_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_Unpacking_Separate_Day)
            break;

          case 'moversUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Movers_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_Unpacking_Separate_Day)
            break;

          case 'unpackingSeparateDayLowerhours':
            return getAmountValue(tag, condition, conditionIndex, calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)) ?
                getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)
                : null,
              setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours))
            break;

          case 'unpackingSeparateDayHigherhours':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours) : null, setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours))
            break;

          case 'totalCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day)
            break;

          case 'unpackingSeparatePercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getCurrentRatePercent(calculation.twoDaysPacking, calculation.department) : null, setTagsResultsTexts, tagsResultsTexts, getCurrentRatePercent(calculation.twoDaysPacking, calculation.department))
            break;

          case 'totalCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Cash_Unpacking_Separate_Day)
            break;

          case 'rateCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod === 'deduct') ? calculatedParams.Rate_cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_Unpacking_Separate_Day)
            break;

          case 'rateCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Rate_card_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_Unpacking_Separate_Day)
            break;

          case 'truckFeeUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && calculatedParams.Truck_fee_Unpacking_Separate_Day !== 0) ? calculatedParams.Truck_fee_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_Unpacking_Separate_Day)
            break;

          case 'unpackingShuttle':
            var isSelected = false;
            if (calculation.commonValues.shuttleOnUnpacking) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'unpackingShuttleQty':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.shuttleOnUnpacking && calculation.department.extraOptions.shuttle.isCountable) ? calculation.commonValues.quantityUnpacking : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.quantityUnpacking)
            break;

          case 'totalCardUnpackingSeparateDayLongOOS':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS)
            break;

          case 'packingCharge':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Packing_Charge, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_Charge)
            break;

          case 'unPackingCharge':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.UnPacking_Charge, setTagsResultsTexts, tagsResultsTexts, calculatedParams.UnPacking_Charge)
            break;

          case 'flatRateOneDayCash':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Flat_rate_Cash_2Days_Packing, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_Cash_2Days_Packing)
            break;

          case 'flatRateOneDayCard':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Flat_rate_Card_2Days_Packing, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_Card_2Days_Packing)
            break;

          case 'flatRateUnpackingSeparateDayCash':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.unpacking == 'Separate Day') ? calculatedParams.Flat_rate_Unpacking_Separate_Day_cash : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_Unpacking_Separate_Day_cash)
            break;

          case 'flatRateUnpackingSeparateDayCard':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.unpacking == 'Separate Day') ? calculatedParams.Flat_rate_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_Unpacking_Separate_Day)
            break;


        }
      } catch (err) {
        console.log(err)
      }
      break;
    case 'twoDays150Miles':
      try {
        var formattedRateInfoTwoDays150 = getFormattedRateInfoTwoDays150(calculation.department, calculatedParams.Total_Destinationfee_150miles, calculatedParams.Rate_cash_150miles_Day1, calculatedParams.Rate_card_150miles_Day1, calculatedParams.Truck_fee_150miles_Day1)
        var formattedRateInfoTwoDays150Day2 = getFormattedRateInfoTwoDays150Day2(calculation.department, calculatedParams.Total_Destinationfee_150miles, calculatedParams.Rate_cash_150miles_Day2, calculatedParams.Rate_card_150miles_Day2, calculatedParams.Truck_fee_150miles_Day2)

        if (condition[`selectedItemObj${conditionIndex}`].value.split('_').length > 1) {//additionals
          let splittedSelectedItem = condition[`selectedItemObj${conditionIndex}`].value.split('_')
          var numberOfProcedures = 0;
          var procedureIndex = null;
          var procedureType = null;
          var foundItem;
          var foundItemIndex;
          var isSelectedDay1 = false;
          var isSelectedDay2 = false;
          if (calculation.twoDays150Miles.selectedChargesNamesDay1.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay1 = true;
          }
          if (calculation.twoDays150Miles.selectedChargesNamesDay2.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay2 = true;
          }

          calculation.department.extraOptions.charges.some((item, index) => {
            if (item[`uId${index}`] === splittedSelectedItem[1]) {
              foundItem = item;
              foundItemIndex = index;
              return true; // Exit the loop when a match is found
            }
            return false;
          });

          if (calculation.commonValues.procedures.length) {
            try {
              for (let index = 0; index < calculation.commonValues.procedures.length; index++) {
                const element = calculation.commonValues.procedures[index];

                if (element.uId === splittedSelectedItem[1]) {
                  numberOfProcedures = Number(element.numberOfProcedures);
                  procedureIndex = index;
                  procedureType = element.procedureType;
                  break;
                }
              }
            }
            catch (err) {
              console.log(err)
            }
          }

          switch (splittedSelectedItem[0]) {
            case 'chIdName1':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay1, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay1) ? foundItem[`amount${foundItemIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${foundItemIndex}`])
              break;

            case 'chIdQty1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1])) ? calculation.twoDays150Miles.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.twoDays150Miles.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'pIdName1':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.twoDays150Miles.proceduresOnDay1) ? procedureType : null, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'pIdCost1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.twoDays150Miles.proceduresOnDay1) ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures) : null, setTagsResultsTexts, tagsResultsTexts, (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures))
              break;

            case 'pIdQty1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.twoDays150Miles.proceduresOnDay1) ? numberOfProcedures : null, setTagsResultsTexts, tagsResultsTexts, numberOfProcedures)
              break;

            case 'chIdName2':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay2, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay2) ? foundItem[`amount${foundItemIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${foundItemIndex}`])
              break;
            case 'chIdQty2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1])) ? calculation.twoDays150Miles.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.twoDays150Miles.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'pIdName2':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.twoDays150Miles.proceduresOnDay2) ? procedureType : null, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'pIdCost2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.twoDays150Miles.proceduresOnDay2) ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures) : null, setTagsResultsTexts, tagsResultsTexts, (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures))
              break;

            case 'pIdQty2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.twoDays150Miles.proceduresOnDay2) ? numberOfProcedures : null, setTagsResultsTexts, tagsResultsTexts, numberOfProcedures)
              break;

            case 'mChId':
              var isSelected = false;
              if (calculation.selectedMiscCheckMarks.includes(splittedSelectedItem[1])) {
                isSelected = true;
              }
              return getCheckPointValue(tag, condition, conditionIndex, isSelected, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'aSIdName':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.selectedAdditionalServices.some(item => item.key === splittedSelectedItem[1])) ? splittedSelectedItem[1] : null, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'aSIdAmount':
              var aSIdAmount = null;
              var pricePerCuFtPrice;
              var selectedServiceName = splittedSelectedItem[1];
              const isSelectedServiceIncluded = calculation.commonValues.selectedAdditionalServices.some(item => item.key.includes(selectedServiceName));
              try {
                if (isSelectedServiceIncluded) {
                  calculation.department.additionalServices.services.map((service) => {
                    if (selectedServiceName === service.serviceName) {
                      if (service.serviceType === 'cuft') {
                        for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                          let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
                          if (performComparison(pricePerCuFt[`operator${pcftIndex}`], Number(calculatedParams.Total_cubes), Number(pricePerCuFt[`cubicFtRange${pcftIndex}`]))) {
                            aSIdAmount = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]) * calculatedParams.Total_cubes;
                            pricePerCuFtPrice = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                          }
                        }
                      } else if (service.serviceType === 'factor') {
                        for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                          let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
                          if (performComparison(pricePerCuFt[`operator${pcftIndex}`], calculation.commonValues.additionalServicesPlaceType, pricePerCuFt[`factorName${pcftIndex}`])) {
                            aSIdAmount = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                            pricePerCuFtPrice = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                          }
                        }
                      }
                    }
                  })
                } else {
                  calculation.department.additionalServices.services.map((service) => {
                    if (selectedServiceName === service.serviceName) {
                      for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                        let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
                        if (performComparison(pricePerCuFt[`operator${pcftIndex}`], calculation.commonValues.additionalServicesPlaceType, pricePerCuFt[`factorName${pcftIndex}`])) {
                          pricePerCuFtPrice = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                        }
                      }
                    }
                  })
                }
              } catch (err) { }
              return getAmountValue(tag, condition, conditionIndex, calculation, (isSelectedServiceIncluded) ? aSIdAmount : null, setTagsResultsTexts, tagsResultsTexts, pricePerCuFtPrice)
              break;

            case 'aSIdQty':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.selectedAdditionalServices.find(item => item.key === splittedSelectedItem[1])) ? calculation.commonValues.selectedAdditionalServices.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.selectedAdditionalServices.find(item => item.key === splittedSelectedItem[1]).quantity)
              break;
          }


        }


        switch (condition[`selectedItemObj${conditionIndex}`].value) {

          case 'additionalAmount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.additionalAmount) ? calculation.twoDays150Miles.additionalAmount : null, setTagsResultsTexts, tagsResultsTexts, '')
            break;

          case 'additionalServices':
            return getCheckPointValue(tag, condition, conditionIndex, !!calculation.commonValues.selectedAdditionalServices.length, 'Additional Services', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'procedures':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.procedures.length > 0 && calculation.twoDays150Miles.proceduresOnDay1 && !calculation.twoDays150Miles.proceduresOnDay2 && calculation.commonValues.procedures.some(procedure => procedure !== 'None')) ? true : null, 'Procedures', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'proceduresDay2':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.procedures.length > 0 && calculation.twoDays150Miles.proceduresOnDay2) ? true : null, 'Procedures', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partial':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Same Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partialSeparate':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Separate Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'totalRoundTripMiles':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.milesDay1 || calculation.twoDays150Miles.milesDay2) ? Number(calculation.twoDays150Miles.milesDay1) + Number(calculation.twoDays150Miles.milesDay2) : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'username':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.userId, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'extraOptions':
            var isExtraOptionsSelected = false;
            if (calculation.twoDays150Miles.selectedChargesNamesDay1.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.twoDays150Miles.selectedChargesNamesDay1.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'extraOptionsDay2':
            var isExtraOptionsSelected = false;
            if (calculation.twoDays150Miles.selectedChargesNamesDay2.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.twoDays150Miles.selectedChargesNamesDay2.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'additionalServices':
            return getCheckPointValue(tag, condition, conditionIndex, !!calculation.commonValues.selectedAdditionalServices.length, 'Additional Services', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'department':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.department) ? calculation.department._id : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'calculationNumber':
            return getCheckPointValue(tag, condition, conditionIndex, true, calculation.calculationNumber, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day1weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.firstDayDate) ? new Date(calculation.twoDays150Miles.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day1year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.secondDayDate) ? Number(new Date(calculation.twoDays150Miles.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDays150Miles.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day1month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.firstDayDate) ? Number(new Date(calculation.twoDays150Miles.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDays150Miles.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day1date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.firstDayDate) ? Number(new Date(calculation.twoDays150Miles.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDays150Miles.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.secondDayDate) ? Number(new Date(calculation.twoDays150Miles.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDays150Miles.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.secondDayDate) ? new Date(calculation.twoDays150Miles.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day2month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.secondDayDate) ? Number(new Date(calculation.twoDays150Miles.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDays150Miles.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.secondDayDate) ? Number(new Date(calculation.twoDays150Miles.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDays150Miles.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.secondDayDate) ? Number(new Date(calculation.twoDays150Miles.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.twoDays150Miles.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'truckloads':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, truckLoads, setTagsResultsTexts, tagsResultsTexts, truckLoads)
            break;

          case 'trucksPercent':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0) ? Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100)) : null, setTagsResultsTexts, tagsResultsTexts, Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100)))
            break;

          case 'truckloadsRoundDown':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0) ? Number(Math.floor(truckLoads).toFixed(2)) : null, setTagsResultsTexts, tagsResultsTexts, Number(Math.floor(truckLoads).toFixed(2)))
            break;

          case 'additionalStopsCount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ? calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length)
            break;

          case 'combinedMileage':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Combined_mileage, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Combined_mileage)
            break;

          case 'movers150milesDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_150miles_Day1, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_150miles_Day1)
            break;


          case 'rateCash150milesDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, (formattedRateInfoTwoDays150.isRateCash150MilesDay1Showed) ? calculatedParams.Rate_cash_150miles_Day1 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_150miles_Day1)
            break;

          case 'rateCard150milesDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Rate_card_150miles_Day1, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_150miles_Day1)
            break;

          case 'truckFee150milesDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, (formattedRateInfoTwoDays150.isTruckFee150MilesDay1Showed) ? calculatedParams.Truck_fee_150miles_Day1 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_150miles_Day1)
            break;

          case 'trucks150milesDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_150miles_Day1, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_150miles_Day1)
            break;

          case 'shuttleDay1':
            var isSelected = false;
            if (calculation.twoDays150Miles.shuttleOnDay1) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.shuttleOnDay1 && calculation.department.extraOptions.shuttle.isCountable) ? calculation.twoDays150Miles.quantityDay1 : null, setTagsResultsTexts, tagsResultsTexts, calculation.twoDays150Miles.quantityDay1)
            break;

          case 'doubleDriveDay1':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.Double_drive_150miles_Day1, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'lowerNumberOfHoursDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHoursValueOnly((calculation.department) ? calculation.department.minimumHours : 0, calculatedParams.Lower_Number_of_Hours_150miles_Day1), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly((calculation.department) ? calculation.department.minimumHours : 0, calculatedParams.Lower_Number_of_Hours_150miles_Day1))
            break;

          case 'higherNumberOfHoursDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_150miles_Day1), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_150miles_Day1))
            break;

          case 'packingType':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.commonValues.packing, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'packingKit':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_Show : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_Show)
            break;

          case 'packingKitWithTax':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_total_amount_with_taxes : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_total_amount_with_taxes)
            break;

          case 'sameDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Same Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'UNPACKING', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'smallBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.smallBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.smallBox)
            break;

          case 'mediumBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.mediumBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.mediumBox)
            break;

          case 'largeBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.largeBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.largeBox)
            break;

          case 'rollOfPaper':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.packingPapper : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.packingPapper)
            break;

          case 'rollOfBubble':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.bubbleWrap : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.bubbleWrap)
            break;


          case 'moversDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_150miles_Day2, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_150miles_Day2)
            break;

          case 'rateCashDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (formattedRateInfoTwoDays150Day2.isRateCashDay2Showed) ? calculatedParams.Rate_cash_Movers_To_Load_Day2 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_Movers_To_Load_Day2)
            break;

          case 'rateCardDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Rate_card_150miles_Day2, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_150miles_Day2)
            break;

          case 'truckFeeDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (formattedRateInfoTwoDays150Day2.isTruckFeeDay2Showed) ? calculatedParams.Truck_fee_150miles_Day2 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_150miles_Day2)
            break;

          case 'trucksDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_150miles_Day2, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_150miles_Day2)
            break;

          case 'shuttleDay2':
            var isSelected = false;
            if (calculation.twoDays150Miles.shuttleOnDay2) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.twoDays150Miles.shuttleOnDay2 && calculation.department.extraOptions.shuttle.isCountable) ? calculation.twoDays150Miles.quantityDay2 : null, setTagsResultsTexts, tagsResultsTexts, calculation.twoDays150Miles.quantityDay2)
            break;

          case 'twoTripsOrOkDay2':
            var isSelected = false;
            if (calculatedParams.TWO_Trips_Or_OK_Day2 !== "OK" && condition[`conditionSelectValue${conditionIndex}`] === calculatedParams.TWO_Trips_Or_OK_Day2) {
              isSelected = true;
            }
            else {
              return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.TWO_Trips_Or_OK_Day2, setTagsResultsTexts, tagsResultsTexts)
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, '2 TRIPS', setTagsResultsTexts, tagsResultsTexts)
            //return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.TWO_Trips_Or_OK, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'biggestOrOkDay2':
            var isSelected = false;
            if (calculatedParams.TWO_Trips_Or_OK_Day2 !== "2 TRIPS" && calculatedParams.BIGGEST_or_OK !== 'OK' && condition[`conditionSelectValue${conditionIndex}`] === 'BIGGEST TRUCK') {
              isSelected = true;
            }
            else {
              return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.TWO_Trips_Or_OK_Day2, setTagsResultsTexts, tagsResultsTexts)
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'BIGGEST TRUCK', setTagsResultsTexts, tagsResultsTexts)
            // return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.BIGGEST_or_OK, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'doubleDriveDay2':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.Double_drive_150miles_Day2, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'destinationFee':
            let destinationFee = 0
            if (calculation.department.truckFee.tfdfCombined) {
              destinationFee = calculatedParams.Total_Destinationfee_150miles + calculatedParams.Truck_fee_150miles_Day1 + calculatedParams.Truck_fee_150miles_Day1
            } else {
              destinationFee = calculatedParams.Total_Destinationfee_150miles
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculatedParams.Destination_fee_150miles > 0) ? destinationFee : null, setTagsResultsTexts, tagsResultsTexts, destinationFee)
            break;

          case 'lowerNumberOfHoursDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_150miles_Day2), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_150miles_Day2))
            break;

          case 'higherNumberOfHoursDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_150miles_Day2), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_150miles_Day2))
            break;

          case 'heavyFee':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculatedParams.Heavy_fee !== 0) ? calculatedParams.Heavy_fee : null, setTagsResultsTexts, tagsResultsTexts, Number(calculation.department.extraOptions.heavyItem))
            break;

          case 'liftGate':
            var isSelected = false;
            if (calculation.commonValues.liftGate) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'LIFTGATE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'hardwoodFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.hardFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'HARDWOOD FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'carpetFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.filmFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'CARPET FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'notToExceedPricePercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod !== 'deduct') ? getCurrentRatePercent(calculation.twoDays150Miles, calculation.department) : null, setTagsResultsTexts, tagsResultsTexts, getCurrentRatePercent(calculation.twoDays150Miles, calculation.department))
            break;

          case 'totalCash2Days150miles':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Total_Cash_150miles_2Days, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Cash_150miles_2Days)
            break;

          case 'totalCard2Days150miles':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Total_Card_150miles_2Days, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_150miles_2Days)
            break;

          case 'unpackingShuttle':
            var isSelected = false;
            if (calculation.commonValues.shuttleOnUnpacking) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'unpackingShuttleQty':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.shuttleOnUnpacking && calculation.department.extraOptions.shuttle.isCountable) ? calculation.commonValues.quantityUnpacking : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.quantityUnpacking)
            break;

          case 'separateDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Separate Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'Unpacking on separate day', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'trucksUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Trucks_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_Unpacking_Separate_Day)
            break;

          case 'moversUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Movers_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_Unpacking_Separate_Day)
            break;

          case 'unpackingSeparateDayLowerhours':
            return getAmountValue(tag, condition, conditionIndex, calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)) ?
                getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)
                : null,
              setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours))
            break;

          case 'unpackingSeparateDayHigherhours':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours) : null, setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours))
            break;

          case 'totalCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day)
            break;

          case 'unpackingSeparatePercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getCurrentRatePercent(calculation.twoDays150Miles, calculation.department) : null, setTagsResultsTexts, tagsResultsTexts, getCurrentRatePercent(calculation.twoDays150Miles, calculation.department))
            break;

          case 'totalCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Cash_Unpacking_Separate_Day)
            break;

          case 'rateCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod === 'deduct') ? calculatedParams.Rate_cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_Unpacking_Separate_Day)
            break;

          case 'rateCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Rate_card_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_Unpacking_Separate_Day)
            break;

          case 'truckFeeUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && calculatedParams.Truck_fee_Unpacking_Separate_Day !== 0) ? calculatedParams.Truck_fee_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_Unpacking_Separate_Day)
            break;

          case 'totalCardUnpackingSeparateDayLongOOS':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS)
            break;

          case 'packingCharge':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Packing_Charge, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_Charge)
            break;

          case 'unPackingCharge':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.UnPacking_Charge, setTagsResultsTexts, tagsResultsTexts, calculatedParams.UnPacking_Charge)
            break;

          case 'flatRateCash150Miles':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Flat_rate_Cash_150_miles, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_Cash_150_miles)
            break;

          case 'flatRateCard150Miles':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Flat_rate_Card_150_miles, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_Card_150_miles)
            break;

          case 'flatRateUnpackingSeparateDayCash':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.unpacking == 'Separate Day') ? calculatedParams.Flat_rate_Unpacking_Separate_Day_cash : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_Unpacking_Separate_Day_cash)
            break;

          case 'flatRateUnpackingSeparateDayCard':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.unpacking == 'Separate Day') ? calculatedParams.Flat_rate_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_Unpacking_Separate_Day)
            break;



        }
      } catch (err) {
        console.log(err)
      }
      break;

    case 'threeDays150Miles':
      try {
        // First, we check if the user selected an "additional" item (like charges, procedures, or additional services).
        // This logic is similar to what you've done in other cases (twoDays150Miles, oneDayPacking, etc.)

        if (condition[`selectedItemObj${conditionIndex}`].value.split('_').length > 1) {
          let splittedSelectedItem = condition[`selectedItemObj${conditionIndex}`].value.split('_');
          let numberOfProcedures = 0;
          let procedureIndex = null;
          let procedureType = null;
          let foundItem;
          let foundItemIndex;
          let isSelectedDay1 = false;
          let isSelectedDay2 = false;
          let isSelectedDay3 = false;

          // Mark which day(s) this item is selected for
          if (calculation.threeDays150Miles.selectedChargesNamesDay1.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay1 = true;
          }
          if (calculation.threeDays150Miles.selectedChargesNamesDay2.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay2 = true;
          }
          if (calculation.threeDays150Miles.selectedChargesNamesDay3.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay3 = true;
          }

          // Look up the item in department.extraOptions.charges
          calculation.department.extraOptions.charges.some((item, index) => {
            if (item[`uId${index}`] === splittedSelectedItem[1]) {
              foundItem = item;
              foundItemIndex = index;
              return true;
            }
            return false;
          });

          // Check for procedures
          if (calculation.commonValues.procedures.length) {
            try {
              for (let idx = 0; idx < calculation.commonValues.procedures.length; idx++) {
                const element = calculation.commonValues.procedures[idx];
                if (element.uId === splittedSelectedItem[1]) {
                  numberOfProcedures = Number(element.numberOfProcedures);
                  procedureIndex = idx;
                  procedureType = element.procedureType;
                  break;
                }
              }
            } catch (err) {
              console.log(err);
            }
          }

          switch (splittedSelectedItem[0]) {
            /* ----------- Day 1 charges ----------- */
            case 'chIdName1':
              return getCheckPointValue(
                tag,
                condition,
                conditionIndex,
                isSelectedDay1,
                condition[`selectedItemObj${conditionIndex}`].label,
                setTagsResultsTexts,
                tagsResultsTexts
              );

            case 'chIdAmount1':
              return getAmountValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                (foundItem && isSelectedDay1) ? foundItem[`amount${foundItemIndex}`] : null,
                setTagsResultsTexts,
                tagsResultsTexts,
                foundItem ? foundItem[`amount${foundItemIndex}`] : null
              );

            case 'chIdQty1':
              return getAmountValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                calculation.threeDays150Miles.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1])
                  ? calculation.threeDays150Miles.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity
                  : null,
                setTagsResultsTexts,
                tagsResultsTexts,
                calculation.threeDays150Miles.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1])?.quantity
              );

            case 'pIdName1':
              return getSelectableValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                (numberOfProcedures && calculation.threeDays150Miles.proceduresOnDay1) ? procedureType : null,
                setTagsResultsTexts,
                tagsResultsTexts
              );

            case 'pIdCost1':
              return getAmountValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                (numberOfProcedures && calculation.threeDays150Miles.proceduresOnDay1)
                  ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures)
                  : null,
                setTagsResultsTexts,
                tagsResultsTexts,
                (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures)
              );

            case 'pIdQty1':
              return getAmountValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                (numberOfProcedures && calculation.threeDays150Miles.proceduresOnDay1) ? numberOfProcedures : null,
                setTagsResultsTexts,
                tagsResultsTexts,
                numberOfProcedures
              );

            /* ----------- Day 2 charges ----------- */
            case 'chIdName2':
              return getCheckPointValue(
                tag,
                condition,
                conditionIndex,
                isSelectedDay2,
                condition[`selectedItemObj${conditionIndex}`].label,
                setTagsResultsTexts,
                tagsResultsTexts
              );

            case 'chIdAmount2':
              return getAmountValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                (foundItem && isSelectedDay2) ? foundItem[`amount${foundItemIndex}`] : null,
                setTagsResultsTexts,
                tagsResultsTexts,
                foundItem ? foundItem[`amount${foundItemIndex}`] : null
              );

            case 'chIdQty2':
              return getAmountValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                calculation.threeDays150Miles.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1])
                  ? calculation.threeDays150Miles.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1]).quantity
                  : null,
                setTagsResultsTexts,
                tagsResultsTexts,
                calculation.threeDays150Miles.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1])?.quantity
              );

            case 'pIdName2':
              return getSelectableValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                (numberOfProcedures && calculation.threeDays150Miles.proceduresOnDay2) ? procedureType : null,
                setTagsResultsTexts,
                tagsResultsTexts
              );

            case 'pIdCost2':
              return getAmountValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                (numberOfProcedures && calculation.threeDays150Miles.proceduresOnDay2)
                  ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures)
                  : null,
                setTagsResultsTexts,
                tagsResultsTexts,
                (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures)
              );

            case 'pIdQty2':
              return getAmountValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                (numberOfProcedures && calculation.threeDays150Miles.proceduresOnDay2) ? numberOfProcedures : null,
                setTagsResultsTexts,
                tagsResultsTexts,
                numberOfProcedures
              );

            /* ----------- Day 3 charges ----------- */
            case 'chIdName3':
              return getCheckPointValue(
                tag,
                condition,
                conditionIndex,
                isSelectedDay3,
                condition[`selectedItemObj${conditionIndex}`].label,
                setTagsResultsTexts,
                tagsResultsTexts
              );

            case 'chIdAmount3':
              return getAmountValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                (foundItem && isSelectedDay3) ? foundItem[`amount${foundItemIndex}`] : null,
                setTagsResultsTexts,
                tagsResultsTexts,
                foundItem ? foundItem[`amount${foundItemIndex}`] : null
              );

            case 'chIdQty3':
              return getAmountValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                calculation.threeDays150Miles.selectedChargesNamesDay3.find(item => item.key === splittedSelectedItem[1])
                  ? calculation.threeDays150Miles.selectedChargesNamesDay3.find(item => item.key === splittedSelectedItem[1]).quantity
                  : null,
                setTagsResultsTexts,
                tagsResultsTexts,
                calculation.threeDays150Miles.selectedChargesNamesDay3.find(item => item.key === splittedSelectedItem[1])?.quantity
              );

            case 'pIdName3':
              return getSelectableValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                (numberOfProcedures && calculation.threeDays150Miles.proceduresOnDay3) ? procedureType : null,
                setTagsResultsTexts,
                tagsResultsTexts
              );

            case 'pIdCost3':
              return getAmountValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                (numberOfProcedures && calculation.threeDays150Miles.proceduresOnDay3)
                  ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures)
                  : null,
                setTagsResultsTexts,
                tagsResultsTexts,
                (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures)
              );

            case 'pIdQty3':
              return getAmountValue(
                tag,
                condition,
                conditionIndex,
                calculation,
                (numberOfProcedures && calculation.threeDays150Miles.proceduresOnDay3) ? numberOfProcedures : null,
                setTagsResultsTexts,
                tagsResultsTexts,
                numberOfProcedures
              );

            case 'aSIdName':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.selectedAdditionalServices.some(item => item.key === splittedSelectedItem[1])) ? splittedSelectedItem[1] : null, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'aSIdAmount':
              var aSIdAmount = null;
              var pricePerCuFtPrice;
              var selectedServiceName = splittedSelectedItem[1];
              const isSelectedServiceIncluded = calculation.commonValues.selectedAdditionalServices.some(item => item.key.includes(selectedServiceName));
              try {
                if (isSelectedServiceIncluded) {
                  calculation.department.additionalServices.services.map((service) => {
                    if (selectedServiceName === service.serviceName) {
                      if (service.serviceType === 'cuft') {
                        for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                          let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
                          if (performComparison(pricePerCuFt[`operator${pcftIndex}`], Number(calculatedParams.Total_cubes), Number(pricePerCuFt[`cubicFtRange${pcftIndex}`]))) {
                            let totalCubes = service.fixedAmount ? 1 : calculatedParams.Total_cubes;
                            aSIdAmount = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]) * totalCubes;
                            pricePerCuFtPrice = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                          }
                        }
                      } else if (service.serviceType === 'factor') {
                        for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                          let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
                          if (performComparison(pricePerCuFt[`operator${pcftIndex}`], calculation.commonValues.additionalServicesPlaceType, pricePerCuFt[`factorName${pcftIndex}`])) {
                            let totalCubes = service.fixedAmount ? 1 : calculatedParams.Total_cubes;
                            aSIdAmount = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]) * totalCubes;
                            pricePerCuFtPrice = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                          }
                        }
                      }
                    }
                  })
                } else {
                  calculation.department.additionalServices.services.map((service) => {
                    if (selectedServiceName === service.serviceName) {
                      for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                        let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
                        if (performComparison(pricePerCuFt[`operator${pcftIndex}`], calculation.commonValues.additionalServicesPlaceType, pricePerCuFt[`factorName${pcftIndex}`])) {
                          pricePerCuFtPrice = Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]);
                        }
                      }
                    }
                  })
                }
              } catch (err) { }
              return getAmountValue(tag, condition, conditionIndex, calculation, (isSelectedServiceIncluded) ? aSIdAmount : null, setTagsResultsTexts, tagsResultsTexts, pricePerCuFtPrice)
              break;
            case 'aSIdQty':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.selectedAdditionalServices.find(item => item.key === splittedSelectedItem[1])) ? calculation.commonValues.selectedAdditionalServices.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.selectedAdditionalServices.find(item => item.key === splittedSelectedItem[1]).quantity)
              break;

            /* ----------- Misc Check Marks ----------- */
            case 'mChId':
              var isSelected = false;
              if (calculation.selectedMiscCheckMarks.includes(splittedSelectedItem[1])) {
                isSelected = true;
              }
              return getCheckPointValue(
                tag,
                condition,
                conditionIndex,
                isSelected,
                condition[`selectedItemObj${conditionIndex}`].label,
                setTagsResultsTexts,
                tagsResultsTexts
              );
          }
        }

        // If not an "additional" item, handle the normal tag values used specifically in `threeDays150Miles`.
        // This includes day1, day2, day3 logic for miles, trucks, hours, procedures, partial, etc.
        var formattedRateInfo = getFormattedRateInfoTwoDays(calculation.department, calculatedParams.Total_Destinationfee_3days_150, calculatedParams.Rate_cash_3days_150_Day1, calculatedParams.Rate_card_3days_150_Day1, calculatedParams.Truck_fee_3days_150_Day1)
        switch (condition[`selectedItemObj${conditionIndex}`].value) {

          case 'calculationNumber':
            return getCheckPointValue(tag, condition, conditionIndex, true, calculation.calculationNumber, setTagsResultsTexts, tagsResultsTexts)
            break;

          // ~~~~~~~~~~~~~~~~~~~~~
          // Date / weekday tags
          // ~~~~~~~~~~~~~~~~~~~~~
          case 'day1weekday':
            return getSelectableValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.firstDayDate)
                ? new Date(calculation.threeDays150Miles.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })
                : null,
              setTagsResultsTexts,
              tagsResultsTexts
            );

          case 'day1month':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.firstDayDate)
                ? Number(new Date(calculation.threeDays150Miles.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' }))
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.threeDays150Miles.firstDayDate
            );

          case 'day1date':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.firstDayDate)
                ? Number(new Date(calculation.threeDays150Miles.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' }))
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.threeDays150Miles.firstDayDate
            );

          case 'day1year':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.firstDayDate)
                ? Number(new Date(calculation.threeDays150Miles.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' }))
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.threeDays150Miles.firstDayDate
            );

          case 'day2weekday':
            return getSelectableValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.secondDayDate)
                ? new Date(calculation.threeDays150Miles.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })
                : null,
              setTagsResultsTexts,
              tagsResultsTexts
            );

          case 'day2month':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.secondDayDate)
                ? Number(new Date(calculation.threeDays150Miles.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' }))
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.threeDays150Miles.secondDayDate
            );

          case 'day2date':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.secondDayDate)
                ? Number(new Date(calculation.threeDays150Miles.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' }))
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.threeDays150Miles.secondDayDate
            );

          case 'day2year':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.secondDayDate)
                ? Number(new Date(calculation.threeDays150Miles.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' }))
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.threeDays150Miles.secondDayDate
            );

          case 'day3weekday':
            return getSelectableValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.thirdDayDate)
                ? new Date(calculation.threeDays150Miles.thirdDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })
                : null,
              setTagsResultsTexts,
              tagsResultsTexts
            );

          case 'day3month':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.thirdDayDate)
                ? Number(new Date(calculation.threeDays150Miles.thirdDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' }))
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.threeDays150Miles.thirdDayDate
            );

          case 'day3date':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.thirdDayDate)
                ? Number(new Date(calculation.threeDays150Miles.thirdDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' }))
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.threeDays150Miles.thirdDayDate
            );

          case 'day3year':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.thirdDayDate)
                ? Number(new Date(calculation.threeDays150Miles.thirdDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' }))
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.threeDays150Miles.thirdDayDate
            );


            break;

          case 'rateCashDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, (formattedRateInfo.isRate_cash_Day1Showed) ? calculatedParams.Rate_cash_3days_150_Day1 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_3days_150_Day1)
            break;

          case 'rateCardDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Rate_card_3days_150_Day1, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_3days_150_Day1)
            break;

          case 'truckFeeOnDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, (formattedRateInfo.isTruck_fee_Day1Showed) ? calculatedParams.Truck_fee_Day1 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_Day1)
            break;

          case 'additionalServices':
            return getCheckPointValue(tag, condition, conditionIndex, !!calculation.commonValues.selectedAdditionalServices.length, 'Additional Services', setTagsResultsTexts, tagsResultsTexts)
            break;
          // ~~~~~~~~~~~~~~~~~~~~~
          // Truckloads, mileage
          // ~~~~~~~~~~~~~~~~~~~~~
          case 'truckloads':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              calculatedParams.Truckloads,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Truckloads
            );

          case 'trucksPercent':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0)
                ? Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100))
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100))
            );

          case 'combinedMileage':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              calculatedParams.Combined_mileage,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Combined_mileage
            );

          case 'unpackingDayRoundTripMiles':
            return getAmountValue(tag, condition, conditionIndex, calculation, Number(calculation.commonValues.milesToParkingCuFt * 2), setTagsResultsTexts, tagsResultsTexts, Number(calculation.commonValues.milesToParkingCuFt * 2))
            break;

          case 'cubicFtToMoveDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Cubic_Ft_To_Move_Day1, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Cubic_Ft_To_Move_Day1)
            break;

          case 'packingKitWithTax':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_total_amount_with_taxes : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_total_amount_with_taxes)
            break;

          case 'packingType':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.commonValues.packing, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'smallBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.smallBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.smallBox)
            break;

          case 'mediumBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.mediumBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.mediumBox)
            break;

          case 'largeBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.largeBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.largeBox)
            break;

          case 'rollOfPaper':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.packingPapper : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.packingPapper)
            break;

          case 'rollOfBubble':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.bubbleWrap : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.bubbleWrap)
            break;

          case 'perBoxPrice':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculation.department?.packing?.supplies.perOnePackedBox, setTagsResultsTexts, tagsResultsTexts, calculation.department?.packing?.supplies?.perOnePackedBox)
            break;

          case 'moversDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_3days_150_Day2, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_3days_150_Day2)
            break;

          case 'rateCashDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod === 'deduct') ? calculatedParams.Rate_cash_3days_150_Day2 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_3days_150_Day2)
            break;

          case 'rateCardDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod !== 'deduct') ? calculatedParams.Rate_card_3days_150_Day2 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_3days_150_Day2)
            break;

          case 'truckFeeDay2':
            const isTruckFeeDay2Showed = !(calculatedParams.Total_Destinationfee_3days_150_Day2 > 0 && calculation.department.truckFee.tfdfCombined) && calculatedParams.Truck_fee_3days_150_Day2 !== 0;
            return getAmountValue(tag, condition, conditionIndex, calculation, (isTruckFeeDay2Showed) ? calculatedParams.Truck_fee_3days_150_Day2 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_3days_150_Day2)
            break;

          case 'trucksDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_3days_150_Day2, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_3days_150_Day2)
            break;


          case 'doubleDriveDay2':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.Double_drive_3days_150_Day2, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'lowerNumberOfHoursDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_3days_150_Day2), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_3days_150_Day2))
            break;

          case 'higherNumberOfHoursDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_3days_150_Day2), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_3days_150_Day2))
            break;

          case 'moversDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_3days_150_Day3, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_3days_150_Day3)
            break;

          case 'rateCashDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod === 'deduct') ? calculatedParams.Rate_cash_3days_150_Day3 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_3days_150_Day3)
            break;

          case 'rateCardDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod !== 'deduct') ? calculatedParams.Rate_card_3days_150_Day3 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_3days_150_Day3)
            break;

          case 'truckFeeDay3':
            const isTruckFeeDay3Showed = calculatedParams.Truck_fee_3days_150_Day3 !== 0 &&
              (
                calculatedParams.Total_Destinationfee_3days_150_Day2 <= 0
                || !calculation.department?.truckFee?.tfdfCombined
              );

            return getAmountValue(tag, condition, conditionIndex, calculatedParams, (isTruckFeeDay3Showed) ? calculatedParams.Truck_fee_3days_150_Day3 : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_3days_150_Day3)
            break;

          case 'trucksDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_3days_150_Day3, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_3days_150_Day3)
            break;


          case 'lowerNumberOfHoursDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_3days_150_Day3), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_3days_150_Day3))
            break;

          case 'higherNumberOfHoursDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_3days_150_Day3), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_3days_150_Day3))
            break;

          case 'liftGate':
            var isSelected = false;
            if (calculation.commonValues.liftGate) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'LIFTGATE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'hardwoodFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.hardFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'HARDWOOD FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'carpetFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.filmFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'CARPET FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'doubleDriveDay3':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.Double_drive_3days_150_Day3, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'twoTripsOrOkDay3':
            var isSelected = false;
            if (calculatedParams.Two_trips_or_OK_3days_150_Day3 !== 'OK' && condition[`conditionSelectValue${conditionIndex}`] === 'TWO TRIPS') {
              isSelected = true;
            }
            else {
              return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.Two_trips_or_OK_3days_150_Day3, setTagsResultsTexts, tagsResultsTexts)
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'TWO TRIPS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'destinationFeeDay3':
            const Total_Destinationfee_3days_150_Day3 = (calculation.department.truckFee.tfdfCombined) ?
              calculatedParams.Total_Destinationfee_3days_150 + calculatedParams.Truck_fee_3days_150_Day1 + calculatedParams.Truck_fee_3days_150_Day2 + calculatedParams.Truck_fee_3days_150_Day3
              : calculatedParams.Total_Destinationfee_3days_150;
            return getAmountValue(tag, condition, conditionIndex, calculation, Total_Destinationfee_3days_150_Day3, setTagsResultsTexts, Total_Destinationfee_3days_150_Day3)
            break;


          case 'biggestOrOk':
            var isSelected = false;
            if (calculatedParams.BIGGEST_or_OK !== 'OK' && condition[`conditionSelectValue${conditionIndex}`] === 'BIGGEST TRUCK') {
              isSelected = true;
            }
            else {
              return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.BIGGEST_or_OK, setTagsResultsTexts, tagsResultsTexts)
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'BIGGEST TRUCK', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'additionalStopsCount':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.commonValues.extraStops.filter((extraStop, idx) =>
                extraStop[`baseLoadingHours${idx}`] !== 0 && Number(extraStop[`baseUnLoadingHours${idx}`]) !== 0
              ).length > 0)
                ? calculation.commonValues.extraStops.filter((extraStop, idx) =>
                  extraStop[`baseLoadingHours${idx}`] !== 0 && Number(extraStop[`baseUnLoadingHours${idx}`]) !== 0
                ).length
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.commonValues.extraStops.filter((extraStop, idx) =>
                extraStop[`baseLoadingHours${idx}`] !== 0 && Number(extraStop[`baseUnLoadingHours${idx}`]) !== 0
              ).length
            );

          // ~~~~~~~~~~~~~~~~~~~~~
          // Shuttle logic
          // ~~~~~~~~~~~~~~~~~~~~~
          case 'shuttleDay1':
            {
              var isSelected = false;
              if (calculation.threeDays150Miles.shuttleOnDayOne) {
                isSelected = true;
              }
              return getCheckPointValue(
                tag,
                condition,
                conditionIndex,
                isSelected,
                'SHUTTLE',
                setTagsResultsTexts,
                tagsResultsTexts
              );
            }

          case 'shuttleQtyDay1':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.shuttleOnDayOne && calculation.department.extraOptions.shuttle.isCountable)
                ? calculation.threeDays150Miles.quantityDay1
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.threeDays150Miles.quantityDay1
            );

          case 'shuttleDay2':
            {
              var isSelected = false;
              if (calculation.threeDays150Miles.shuttleOnDay2) {
                isSelected = true;
              }
              return getCheckPointValue(
                tag,
                condition,
                conditionIndex,
                isSelected,
                'SHUTTLE',
                setTagsResultsTexts,
                tagsResultsTexts
              );
            }

          case 'shuttleQtyDay2':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.shuttleOnDay2 && calculation.department.extraOptions.shuttle.isCountable)
                ? calculation.threeDays150Miles.quantityDay2
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.threeDays150Miles.quantityDay2
            );

          case 'shuttleDay3':
            {
              var isSelected = false;
              if (calculation.threeDays150Miles.shuttleOnDay3) {
                isSelected = true;
              }
              return getCheckPointValue(
                tag,
                condition,
                conditionIndex,
                isSelected,
                'SHUTTLE',
                setTagsResultsTexts,
                tagsResultsTexts
              );
            }

          case 'shuttleQtyDay3':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.threeDays150Miles.shuttleOnDay3 && calculation.department.extraOptions.shuttle.isCountable)
                ? calculation.threeDays150Miles.quantityDay3
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.threeDays150Miles.quantityDay3
            );

          // ~~~~~~~~~~~~~~~~~~~~~
          // Movers, truck fees, hours
          // ~~~~~~~~~~~~~~~~~~~~~
          case 'moversDay1':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              calculatedParams.Movers_To_Pack_3days_150_Day1,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Movers_To_Pack_3days_150_Day1
            );
            break;

          case 'truckFeeDay1':

            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculatedParams.Truck_fee_3days_150_Day1 && formattedRateInfo.isTruck_fee_Day1Showed)
                ? calculatedParams.Truck_fee_3days_150_Day1
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Truck_fee_3days_150_Day1
            );

          case 'trucksDay1':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              calculatedParams.Trucks_To_Pack_3days_150_Day1,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Trucks_To_Pack_3days_150_Day1
            );

          case 'rateCashDay1':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              calculatedParams.Rate_cash_Day1,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Rate_cash_Day1
            );

          case 'rateCardDay1':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              calculatedParams.Rate_card_Day1,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Rate_card_Day1
            );

          case 'doubleDrive':
            return getSelectableValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              calculatedParams.Double_drive,
              setTagsResultsTexts,
              tagsResultsTexts
            );

          case 'lowerNumberOfHoursDay1':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              getLowerNumberOfHoursValueOnly(
                (calculation.department) ? calculation.department.minimumHours : 0,
                calculatedParams.Lower_Number_of_Hours_3days_150_Day1
              ),
              setTagsResultsTexts,
              tagsResultsTexts,
              getLowerNumberOfHoursValueOnly(
                (calculation.department) ? calculation.department.minimumHours : 0,
                calculatedParams.Lower_Number_of_Hours_3days_150_Day1
              )
            );

          case 'higherNumberOfHoursDay1':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              getHigherNumberOfHours(
                (calculation.department) ? calculation.department.minimumHours : 0,
                calculatedParams.Higher_Number_of_Hours_3days_150_Day1
              ),
              setTagsResultsTexts,
              tagsResultsTexts,
              getHigherNumberOfHours(
                (calculation.department) ? calculation.department.minimumHours : 0,
                calculatedParams.Higher_Number_of_Hours_3days_150_Day1
              )
            );
            break;

          // ~~~~~~~~~~~~~~~~~~~~~
          // partial, packingKit, supplies, heavyFee
          // ~~~~~~~~~~~~~~~~~~~~~
          case 'partial':
            return getCheckPointValue(
              tag,
              condition,
              conditionIndex,
              (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Same Day')
                ? calculation.commonValues.partialUnPacking
                : null,
              'PARTIAL',
              setTagsResultsTexts,
              tagsResultsTexts
            );

          case 'partialSeparate':
            return getCheckPointValue(
              tag,
              condition,
              conditionIndex,
              (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Separate Day')
                ? calculation.commonValues.partialUnPacking
                : null,
              'PARTIAL',
              setTagsResultsTexts,
              tagsResultsTexts
            );

          case 'packingKit':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.commonValues && calculation.commonValues.packing !== "No")
                ? calculatedParams.Packing_kit_Show
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Packing_kit_Show
            );

          case 'heavyFee':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculatedParams.Heavy_fee !== 0) ? calculatedParams.Heavy_fee : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.department.extraOptions.heavyItem
            );

          case 'packingCharge':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              calculatedParams.Packing_Charge,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Packing_Charge
            );

          case 'unPackingCharge':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              calculatedParams.UnPacking_Charge,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.UnPacking_Charge
            );

          // ~~~~~~~~~~~~~~~~~~~~~
          // sameDayUnpack / separateDayUnpack logic
          // ~~~~~~~~~~~~~~~~~~~~~
          case 'sameDayUnpack':
            {
              var isSelected = false;
              if (calculation.commonValues.unpacking === 'Same Day') {
                isSelected = true;
              }
              return getCheckPointValue(
                tag,
                condition,
                conditionIndex,
                isSelected,
                'UNPACKING',
                setTagsResultsTexts,
                tagsResultsTexts
              );
            }

          case 'separateDayUnpack':
            {
              var isSelected = false;
              if (calculation.commonValues.unpacking === 'Separate Day') {
                isSelected = true;
              }
              return getCheckPointValue(
                tag,
                condition,
                conditionIndex,
                isSelected,
                'Unpacking on separate day',
                setTagsResultsTexts,
                tagsResultsTexts
              );
            }

          case 'unpackingShuttle':
            {
              var isSelected = false;
              if (calculation.commonValues.shuttleOnUnpacking) {
                isSelected = true;
              }
              return getCheckPointValue(
                tag,
                condition,
                conditionIndex,
                isSelected,
                'SHUTTLE Separate Unpacking Day',
                setTagsResultsTexts,
                tagsResultsTexts
              );
            }

          case 'unpackingShuttleQty':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.commonValues.shuttleOnUnpacking && calculation.department.extraOptions.shuttle.isCountable)
                ? calculation.commonValues.quantityUnpacking
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculation.commonValues.quantityUnpacking
            );


          case 'truckFeeUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && calculatedParams.Truck_fee_Unpacking_Separate_Day !== 0) ? calculatedParams.Truck_fee_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_Unpacking_Separate_Day)
            break;

          // ~~~~~~~~~~~~~~~~~~~~~
          // threeDays150Miles Flat Rates, NOT TO EXCEED, etc.
          // ~~~~~~~~~~~~~~~~~~~~~
          case 'flatRateCash3Days150miles':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              calculatedParams.Flat_rate_Cash_3days_150_Day3,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Flat_rate_Cash_3days_150_Day3
            );

          case 'flatRateCard3Days150miles':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              calculatedParams.Flat_rate_Card_3days_150_Day3,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Flat_rate_Card_3days_150_Day3
            );

          case 'totalCash3Days150miles':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              calculatedParams.Total_Cash_threeDays150Miles,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Total_Cash_threeDays150Miles
            );

          case 'totalCard3Days150miles':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              calculatedParams.Total_Card_threeDays150Miles,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Total_Card_threeDays150Miles
            );

          case 'cashDiscountPercent':
            let cashDiscountPercent = Number(getCurrentRatePercent(calculation.threeDays150Miles, calculation.department));
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              cashDiscountPercent,
              setTagsResultsTexts,
              tagsResultsTexts,
              cashDiscountPercent
            );

          case 'lowerNumberOfHoursDay3':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              getLowerNumberOfHoursValueOnly(
                calculation.department.minimumHours,
                calculatedParams.Lower_NumberOfHours_Day3_3Days150miles
              ),
              setTagsResultsTexts,
              tagsResultsTexts,
              getLowerNumberOfHoursValueOnly(
                calculation.department.minimumHours,
                calculatedParams.Lower_NumberOfHours_Day3_3Days150miles
              )
            );

          case 'department':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.department._id, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'higherNumberOfHoursDay3':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              getHigherNumberOfHours(
                calculation.department.minimumHours,
                calculatedParams.Higher_NumberOfHours_Day3_3Days150miles
              ),
              setTagsResultsTexts,
              tagsResultsTexts,
              getHigherNumberOfHours(
                calculation.department.minimumHours,
                calculatedParams.Higher_NumberOfHours_Day3_3Days150miles
              )
            );

          // ~~~~~~~~~~~~~~~~~~~~~
          // separate day unpacking fields
          // ~~~~~~~~~~~~~~~~~~~~~
          case 'trucksUnpackingSeparateDay':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day')
                ? calculatedParams.Trucks_Unpacking_Separate_Day
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Trucks_Unpacking_Separate_Day
            );

          case 'moversUnpackingSeparateDay':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day')
                ? calculatedParams.Movers_Unpacking_Separate_Day
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Movers_Unpacking_Separate_Day
            );

          case 'unpackingSeparateDayLowerhours':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day')
                ? getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)
            );

          case 'unpackingSeparateDayHigherhours':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day')
                ? getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours)
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours)
            );

          case 'rateCardUnpackingSeparateDay':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day')
                ? calculatedParams.Rate_card_Unpacking_Separate_Day
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Rate_card_Unpacking_Separate_Day
            );

          case 'rateCashUnpackingSeparateDay':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.department.rates && calculation.department.rates.calcMethod === 'deduct')
                ? calculatedParams.Rate_cash_Unpacking_Separate_Day
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Rate_cash_Unpacking_Separate_Day
            );

          case 'totalCardUnpackingSeparateDay':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day')
                ? calculatedParams.Total_Card_Unpacking_Separate_Day
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Total_Card_Unpacking_Separate_Day
            );

          case 'unpackingSeparatePercent':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day')
                ? getCurrentRatePercent(calculation.threeDays150Miles, calculation.department)
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              getCurrentRatePercent(calculation.threeDays150Miles, calculation.department)
            );

          case 'totalCashUnpackingSeparateDay':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day')
                ? calculatedParams.Total_Cash_Unpacking_Separate_Day
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Total_Cash_Unpacking_Separate_Day
            );

          case 'truckFeeUnpackingSeparateDay':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && calculatedParams.Truck_fee_Unpacking_Separate_Day !== 0)
                ? calculatedParams.Truck_fee_Unpacking_Separate_Day
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Truck_fee_Unpacking_Separate_Day
            );

          case 'totalCardUnpackingSeparateDayLongOOS':
            return getAmountValue(
              tag,
              condition,
              conditionIndex,
              calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day')
                ? calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS
                : null,
              setTagsResultsTexts,
              tagsResultsTexts,
              calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS
            );

          case 'flatRateUnpackingSeparateDayCash':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.unpacking == 'Separate Day') ? calculatedParams.Flat_rate_Unpacking_Separate_Day_cash : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_Unpacking_Separate_Day_cash)
            break;

          case 'flatRateUnpackingSeparateDayCard':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.unpacking == 'Separate Day') ? calculatedParams.Flat_rate_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Flat_rate_Unpacking_Separate_Day)
            break;

          default:
            break;
        }
      } catch (err) {
        console.log(err);
      }
      break;
    case 'longOneDayPacking':
      try {

        if (condition[`selectedItemObj${conditionIndex}`].value.split('_').length > 1) {//additionals
          let splittedSelectedItem = condition[`selectedItemObj${conditionIndex}`].value.split('_')
          var numberOfProcedures = 0;
          var procedureIndex = null;
          var procedureType = null;
          var foundItem;
          var foundItemIndex;
          var isSelectedDay1 = false;
          var isSelectedDay2 = false;
          if (calculation.longOneDayPacking.selectedChargesNamesDay1.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay1 = true;
          }
          if (calculation.longOneDayPacking.selectedChargesNamesDay2.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay2 = true;
          }


          calculation.department.extraOptions.charges.some((item, index) => {
            if (item[`uId${index}`] === splittedSelectedItem[1]) {
              foundItem = item;
              foundItemIndex = index;
              return true; // Exit the loop when a match is found
            }
            return false;
          });
          if (calculation.commonValues.procedures.length) {
            try {
              for (let index = 0; index < calculation.commonValues.procedures.length; index++) {
                const element = calculation.commonValues.procedures[index];

                if (element.uId === splittedSelectedItem[1]) {
                  numberOfProcedures = Number(element.numberOfProcedures);
                  procedureIndex = index;
                  procedureType = element.procedureType;
                  break;
                }
              }
            }
            catch (err) {
              console.log(err)
            }
          }

          switch (splittedSelectedItem[0]) {
            case 'chIdName1':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay1, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay1) ? foundItem[`amount${foundItemIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${foundItemIndex}`])
              break;
            case 'chIdQty1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1])) ? calculation.longOneDayPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.longOneDayPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'pIdName1':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? procedureType : null, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'pIdCost1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures) : null, setTagsResultsTexts, tagsResultsTexts, (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures))
              break;

            case 'pIdQty1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? numberOfProcedures : null, setTagsResultsTexts, tagsResultsTexts, numberOfProcedures)
              break;

            case 'chIdName2':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay2, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay2) ? foundItem[`amount${foundItemIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${foundItemIndex}`])
              break;
            case 'chIdQty2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1])) ? calculation.longOneDayPacking.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.longOneDayPacking.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'pIdName2':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? procedureType : null, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'pIdCost2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures) : null, setTagsResultsTexts, tagsResultsTexts, (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures))
              break;

            case 'pIdQty2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? numberOfProcedures : null, setTagsResultsTexts, tagsResultsTexts, numberOfProcedures)
              break;

            case 'mChId':
              var isSelected = false;
              if (calculation.selectedMiscCheckMarks.includes(splittedSelectedItem[1])) {
                isSelected = true;
              }
              return getCheckPointValue(tag, condition, conditionIndex, isSelected, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
          }

        }


        switch (condition[`selectedItemObj${conditionIndex}`].value) {

          case 'additionalAmount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.additionalAmount) ? calculation.longOneDayPacking.additionalAmount : null, setTagsResultsTexts, tagsResultsTexts, '')
            break;

          case 'extraOptions':
            var isExtraOptionsSelected = false;
            if (calculation.longOneDayPacking.selectedChargesNamesDay1.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.longOneDayPacking.selectedChargesNamesDay1.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'extraOptionsDay2':
            var isExtraOptionsSelected = false;
            if (calculation.longOneDayPacking.selectedChargesNamesDay2.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.longOneDayPacking.selectedChargesNamesDay2.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'procedures':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.procedures.length > 0 && calculation.commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '')) ? true : null, 'Procedures', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partial':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Same Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partialSeparate':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Separate Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'username':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.userId, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'department':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.department._id, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'calculationNumber':
            return getCheckPointValue(tag, condition, conditionIndex, true, calculation.calculationNumber, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day1weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.firstDayDate) ? new Date(calculation.longOneDayPacking.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day1year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.secondDayDate) ? Number(new Date(calculation.longOneDayPacking.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.longOneDayPacking.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day1month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.firstDayDate) ? Number(new Date(calculation.longOneDayPacking.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.longOneDayPacking.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day1date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.firstDayDate) ? Number(new Date(calculation.longOneDayPacking.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.longOneDayPacking.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.secondDayDate) ? Number(new Date(calculation.longOneDayPacking.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.longOneDayPacking.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.secondDayDate) ? new Date(calculation.longOneDayPacking.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day2month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.secondDayDate) ? Number(new Date(calculation.longOneDayPacking.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.longOneDayPacking.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.secondDayDate) ? Number(new Date(calculation.longOneDayPacking.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.longOneDayPacking.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.secondDayDate) ? Number(new Date(calculation.longOneDayPacking.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.longOneDayPacking.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'truckloads':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, truckLoads, setTagsResultsTexts, tagsResultsTexts, truckLoads)
            break;

          case 'trucksPercent':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0) ? Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100)) : null, setTagsResultsTexts, tagsResultsTexts, Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100)))
            break;

          case 'truckloadsRoundDown':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0) ? Number(Math.floor(truckLoads).toFixed(2)) : null, setTagsResultsTexts, tagsResultsTexts, Number(Math.floor(truckLoads).toFixed(2)))
            break;

          case 'combinedMileage':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Combined_mileage, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Combined_mileage)
            break;

          case 'additionalStopsCount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ? calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length)
            break;

          case 'moversLongOneDayLoading':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_LONGOne_Day_Loading, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_LONGOne_Day_Loading)
            break;

          case 'trucksDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_LONGOne_Day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_LONGOne_Day)
            break;

          case 'shuttleDay1':
            var isSelected = false;
            if (calculation.longOneDayPacking.shuttleOnDay1) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.shuttleOnDay1 && calculation.department.extraOptions.shuttle.isCountable) ? calculation.longOneDayPacking.quantityDay1 : null, setTagsResultsTexts, tagsResultsTexts, calculation.longOneDayPacking.quantityDay1)
            break;

          case 'shuttleDay2':
            var isSelected = false;
            if (calculation.longOneDayPacking.shuttleOnDay2) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longOneDayPacking.shuttleOnDay2 && calculation.department.extraOptions.shuttle.isCountable) ? calculation.longOneDayPacking.quantityDay2 : null, setTagsResultsTexts, tagsResultsTexts, calculation.longOneDayPacking.quantityDay2)
            break;

          case 'biggestOrOk':
            var isSelected = false;
            if (calculatedParams.BIGGEST_or_OK !== 'OK' && condition[`conditionSelectValue${conditionIndex}`] === 'BIGGEST TRUCK') {
              isSelected = true;
            }
            else {
              return getSelectableValue(tag, condition, conditionIndex, calculation, calculatedParams.BIGGEST_or_OK, setTagsResultsTexts, tagsResultsTexts)
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'BIGGEST TRUCK', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'lowerNumberOfHoursDayLoading':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Loading_Lower_number), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Loading_Lower_number))
            break;

          case 'higherNumberOfHoursDayLoading':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Loading), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Loading))
            break;

          case 'heavyFee':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculatedParams.Heavy_fee !== 0) ? calculatedParams.Heavy_fee : null, setTagsResultsTexts, tagsResultsTexts, Number(calculation.department.extraOptions.heavyItem))
            break;

          case 'liftGate':
            var isSelected = false;
            if (calculation.commonValues.liftGate) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'LIFTGATE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'hardwoodFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.hardFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'HARDWOOD FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'carpetFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.filmFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'CARPET FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'sameDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Same Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'UNPACKING', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'separateDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Separate Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'Unpacking on separate day', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'packingType':

            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.commonValues.packing, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'packingKitWithTax':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_total_amount_with_taxes : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_total_amount_with_taxes)
            break;

          case 'smallBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.smallBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.smallBox)
            break;

          case 'mediumBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.mediumBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.mediumBox)
            break;

          case 'largeBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.largeBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.largeBox)
            break;

          case 'rollOfPaper':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.packingPapper : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.packingPapper)
            break;

          case 'rollOfBubble':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.bubbleWrap : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.bubbleWrap)
            break;

          case 'driveTime':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Drivetime_LONGOne_Day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Drivetime_LONGOne_Day)
            break;

          case 'arrivalTime':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculation.longDistance.arrivalTimeDaysRD, setTagsResultsTexts, tagsResultsTexts, calculation.longDistance.arrivalTimeDaysRD)
            break;

          case 'moversDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_LONGOne_Day_Unloading, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_LONGOne_Day_Unloading)
            break;

          case 'trucksDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_LONGOne_Day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_LONGOne_Day)
            break;

          case 'lowerNumberOfHoursDayUnLoading':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Unloading_Lower_number), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Unloading_Lower_number))
            break;

          case 'higherNumberOfHoursDayUnLoading':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Unloading), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Unloading))
            break;

          case 'totalCashLongOneDay':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && calculation.longDistance.roundToFull && calculation.longDistance.directionType === 'expences') ? calculatedParams.Total_Cash_LONGOne_Day_R : calculatedParams.Total_Cash_LONGOne_Day, setTagsResultsTexts, tagsResultsTexts, (truckLoads > 1 && calculation.longDistance.roundToFull && calculation.longDistance.directionType === 'expences') ? calculatedParams.Total_Cash_LONGOne_Day_R : calculatedParams.Total_Cash_LONGOne_Day)
            break;

          case 'totalCardLongOneDay':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && calculation.longDistance.roundToFull && calculation.longDistance.directionType === 'expences') ? calculatedParams.Total_Card_LONGOne_Day_R : calculatedParams.Total_Card_LONGOne_Day, setTagsResultsTexts, tagsResultsTexts, (truckLoads > 1 && calculation.longDistance.roundToFull && calculation.longDistance.directionType === 'expences') ? calculatedParams.Total_Card_LONGOne_Day_R : calculatedParams.Total_Card_LONGOne_Day)
            break;

          case 'cashDiscountPercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculation.longDistance.cashPaymentDiscount, setTagsResultsTexts, tagsResultsTexts, calculation.longDistance.cashPaymentDiscount)
            break;

          case 'unpackingShuttle':
            var isSelected = false;
            if (calculation.commonValues.shuttleOnUnpacking) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'unpackingShuttleQty':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.shuttleOnUnpacking && calculation.department.extraOptions.shuttle.isCountable) ? calculation.commonValues.quantityUnpacking : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.quantityUnpacking)
            break;

          case 'unpDepartment':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.unpDepartment._id, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'packingKit':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_Show : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_Show)
            break;

          case 'trucksUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Trucks_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_Unpacking_Separate_Day)
            break;

          case 'totalCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS)
            break;

          case 'moversUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Movers_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_Unpacking_Separate_Day)
            break;

          case 'unpackingSeparateDayLowerhours':
            return getAmountValue(tag, condition, conditionIndex, calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)) ?
                getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)
                : null,
              setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours))
            break;

          case 'unpackingSeparateDayHigherhours':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours) : null, setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours))
            break;

          case 'totalCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day)
            break;

          case 'unpackingSeparatePercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getCurrentRatePercent(calculation.oneDayPacking, calculation.department) : null, setTagsResultsTexts, tagsResultsTexts, getCurrentRatePercent(calculation.oneDayPacking, calculation.department))
            break;

          case 'totalCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Cash_Unpacking_Separate_Day)
            break;

          case 'rateCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod === 'deduct') ? calculatedParams.Rate_cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_Unpacking_Separate_Day)
            break;

          case 'rateCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Rate_card_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_Unpacking_Separate_Day)
            break;

          case 'truckFeeUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && calculatedParams.Truck_fee_Unpacking_Separate_Day !== 0) ? calculatedParams.Truck_fee_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_Unpacking_Separate_Day)
            break;

          case 'totalCardUnpackingSeparateDayLongOOS':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS)
            break;
        }


      } catch (err) {
        console.log(err)
      }
      break;

    case 'longTwoDaysPacking':
      try {

        if (condition[`selectedItemObj${conditionIndex}`].value.split('_').length > 1) {//additionals
          let splittedSelectedItem = condition[`selectedItemObj${conditionIndex}`].value.split('_')
          var numberOfProcedures = 0;
          var procedureIndex = null;
          var procedureType = null;
          var foundItem;
          var chargeIndex;
          var isSelectedDay1 = false;
          var isSelectedDay2 = false;
          var isSelectedDay3 = false;
          if (calculation.longTwoDaysPacking.selectedChargesNamesDay1.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay1 = true;
          }
          if (calculation.longTwoDaysPacking.selectedChargesNamesDay2.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay2 = true;
          }
          if (calculation.longTwoDaysPacking.selectedChargesNamesDay3.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay3 = true;
          }

          calculation.department.extraOptions.charges.some((item, index) => {
            if (item[`uId${index}`] === splittedSelectedItem[1]) {
              foundItem = item;
              chargeIndex = index;
              return true; // Exit the loop when a match is found
            }
            return false;
          });
          if (calculation.commonValues.procedures.length) {
            try {
              for (let index = 0; index < calculation.commonValues.procedures.length; index++) {
                const element = calculation.commonValues.procedures[index];

                if (element.uId === splittedSelectedItem[1]) {
                  numberOfProcedures = Number(element.numberOfProcedures);
                  procedureIndex = index;
                  procedureType = element.procedureType;
                  break;
                }
              }
            }
            catch (err) {
              console.log(err)
            }
          }

          switch (splittedSelectedItem[0]) {
            case 'chIdName1':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay1, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay1) ? foundItem[`amount${chargeIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${chargeIndex}`])
              break;
            case 'chIdQty1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1])) ? calculation.longTwoDaysPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'pIdName1':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.longTwoDaysPacking.proceduresOnDay1) ? procedureType : null, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'pIdCost1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.longTwoDaysPacking.proceduresOnDay1) ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures) : null, setTagsResultsTexts, tagsResultsTexts, (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures))
              break;

            case 'pIdQty1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.longTwoDaysPacking.proceduresOnDay1) ? numberOfProcedures : null, setTagsResultsTexts, tagsResultsTexts, numberOfProcedures)
              break;

            case 'chIdName2':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay2, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay2) ? foundItem[`amount${chargeIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${chargeIndex}`])
              break;
            case 'chIdQty2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1])) ? calculation.longTwoDaysPacking.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'chIdName3':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay3, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount3':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay3) ? foundItem[`amount${chargeIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${chargeIndex}`])
              break;
            case 'chIdQty3':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.selectedChargesNamesDay3.find(item => item.key === splittedSelectedItem[1])) ? calculation.longTwoDaysPacking.selectedChargesNamesDay3.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.selectedChargesNamesDay3.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'pIdName2':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.longTwoDaysPacking.proceduresOnDay2) ? procedureType : null, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'pIdCost2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.longTwoDaysPacking.proceduresOnDay2) ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures) : null, setTagsResultsTexts, tagsResultsTexts, (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures))
              break;

            case 'pIdQty2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.longTwoDaysPacking.proceduresOnDay2) ? numberOfProcedures : null, setTagsResultsTexts, tagsResultsTexts, numberOfProcedures)
              break;

            case 'mChId':
              var isSelected = false;
              if (calculation.selectedMiscCheckMarks.includes(splittedSelectedItem[1])) {
                isSelected = true;
              }
              return getCheckPointValue(tag, condition, conditionIndex, isSelected, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
          }
        }


        switch (condition[`selectedItemObj${conditionIndex}`].value) {
          case 'additionalAmount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.additionalAmount) ? calculation.longTwoDaysPacking.additionalAmount : null, setTagsResultsTexts, tagsResultsTexts, '')
            break;

          case 'extraOptions':
            var isExtraOptionsSelected = false;
            if (calculation.longTwoDaysPacking.selectedChargesNamesDay1.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.longTwoDaysPacking.selectedChargesNamesDay1.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'extraOptionsDay2':
            var isExtraOptionsSelected = false;
            if (calculation.longTwoDaysPacking.selectedChargesNamesDay2.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.longTwoDaysPacking.selectedChargesNamesDay2.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'extraOptionsDay3':
            var isExtraOptionsSelected = false;
            if (calculation.longTwoDaysPacking.selectedChargesNamesDay3.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.longTwoDaysPacking.selectedChargesNamesDay3.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'procedures':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.procedures.length > 0 && calculation.commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '') && calculation.longTwoDaysPacking.proceduresOnDay1) ? true : null, 'Procedures', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'proceduresDay2':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.procedures.length > 0 && calculation.commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '') && calculation.longTwoDaysPacking.proceduresOnDay2 && !calculation.longTwoDaysPacking.proceduresOnDay1) ? true : null, 'Procedures', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partial':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Same Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partialSeparate':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Separate Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'username':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.userId, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'department':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.department._id, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'calculationNumber':
            return getCheckPointValue(tag, condition, conditionIndex, true, calculation.calculationNumber, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day1weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.firstDayDate) ? new Date(calculation.longTwoDaysPacking.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day1year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.firstDayDate) ? Number(new Date(calculation.longTwoDaysPacking.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.firstDayDate)
            break;

          case 'day1month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.firstDayDate) ? Number(new Date(calculation.longTwoDaysPacking.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.firstDayDate)
            break;

          case 'day1date':

            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.firstDayDate) ? Number(new Date(calculation.longTwoDaysPacking.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.firstDayDate)
            break;

          case 'day2year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.secondDayDate) ? Number(new Date(calculation.longTwoDaysPacking.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.secondDayDate)
            break;

          case 'day2weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.secondDayDate) ? new Date(calculation.longTwoDaysPacking.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day2month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.secondDayDate) ? Number(new Date(calculation.longTwoDaysPacking.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.secondDayDate)
            break;

          case 'day2date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.secondDayDate) ? Number(new Date(calculation.longTwoDaysPacking.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.secondDayDate)
            break;

          case 'day3year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.thirdDayDate) ? Number(new Date(calculation.longTwoDaysPacking.thirdDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.thirdDayDate)
            break;

          case 'day3weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.thirdDayDate) ? new Date(calculation.longTwoDaysPacking.thirdDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day3month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.thirdDayDate) ? Number(new Date(calculation.longTwoDaysPacking.thirdDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.thirdDayDate)
            break;

          case 'day3date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.thirdDayDate) ? Number(new Date(calculation.longTwoDaysPacking.thirdDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.thirdDayDate)
            break;

          case 'truckloads':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, truckLoads, setTagsResultsTexts, tagsResultsTexts, truckLoads)
            break;

          case 'combinedMileage':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Combined_mileage, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Combined_mileage)
            break;

          case 'additionalStopsCount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ? calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length)
            break;

          case 'moversDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_To_Pack_LONG_Day1, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_To_Pack_LONG_Day1)
            break;

          case 'trucksDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, 1, setTagsResultsTexts, tagsResultsTexts, 1)
            break;

          case 'shuttleDay1':
            var isSelected = false;
            if (calculation.longTwoDaysPacking.shuttleOnDay1) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.shuttleOnDay1 && calculation.department.extraOptions.shuttle.isCountable) ? calculation.longTwoDaysPacking.quantityDay1 : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.quantityDay1)
            break;

          case 'lowerNumberOfHoursDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_LONG_Day1), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_LONG_Day1))
            break;

          case 'higherNumberOfHoursDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_LONG_Day1), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_LONG_Day1))
            break;

          case 'liftGate':
            var isSelected = false;
            if (calculation.commonValues.liftGate) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'LIFTGATE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'hardwoodFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.hardFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'HARDWOOD FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'carpetFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.filmFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'CARPET FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'sameDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Same Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'UNPACKING', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'packingType':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.commonValues.packing, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'packingKitWithTax':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_total_amount_with_taxes : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_total_amount_with_taxes)
            break;

          case 'smallBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.smallBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.smallBox)
            break;

          case 'mediumBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.mediumBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.mediumBox)
            break;

          case 'largeBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.largeBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.largeBox)
            break;

          case 'rollOfPaper':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.packingPapper : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.packingPapper)
            break;

          case 'rollOfBubble':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.bubbleWrap : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.bubbleWrap)
            break;

          case 'moversDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_LONG2DAYS_Loading, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_LONG2DAYS_Loading)
            break;

          case 'trucksDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_LONG2DAYS, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_LONG2DAYS)
            break;

          case 'shuttleDay2':
            var isSelected = false;
            if (calculation.longTwoDaysPacking.shuttleOnDay2) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.shuttleOnDay2 && calculation.department.extraOptions.shuttle.isCountable) ? calculation.longTwoDaysPacking.quantityDay2 : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.quantityDay2)
            break;

          case 'lowerNumberOfHoursDayUnLoading':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Total_Hours_LONG2DAYS_Loading_Lower_number), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Total_Hours_LONG2DAYS_Loading_Lower_number))
            break;

          case 'higherNumberOfHoursDayUnLoading':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONG2DAYS_Loading), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONG2DAYS_Loading))
            break;

          case 'heavyFee':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculatedParams.Heavy_fee !== 0) ? calculatedParams.Heavy_fee : null, setTagsResultsTexts, tagsResultsTexts, Number(calculation.department.extraOptions.heavyItem))
            break;

          case 'liftGate':
            var isSelected = false;
            if (calculation.commonValues.liftGate) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'LIFTGATE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'hardwoodFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.hardFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'HARDWOOD FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'carpetFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.filmFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'CARPET FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'driveTime':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Drivetime_LONG2DAYS, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Drivetime_LONG2DAYS)
            break;

          case 'moversDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_LONG2DAYS_Unloading, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_LONG2DAYS_Unloading)
            break;

          case 'trucksDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_LONG2DAYS, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_LONG2DAYS)
            break;

          case 'shuttleDay3':
            var isSelected = false;
            if (calculation.longTwoDaysPacking.shuttleOnDay3) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.longTwoDaysPacking.shuttleOnDay3 && calculation.department.extraOptions.shuttle.isCountable) ? calculation.longTwoDaysPacking.quantityDay3 : null, setTagsResultsTexts, tagsResultsTexts, calculation.longTwoDaysPacking.quantityDay3)
            break;

          case 'lowerNumberOfHoursDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Total_Hours_LONG2DAYS_Unloading_Lower_number), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Total_Hours_LONG2DAYS_Unloading_Lower_number))
            break;

          case 'higherNumberOfHoursDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONG2DAYS_Unloading), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONG2DAYS_Unloading))
            break;

          case 'packingKit':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_Show : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_Show)
            break;

          case 'totalCashLong2Days':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && calculation.longDistance.roundToFull && calculation.longDistance.directionType === 'expences') ? calculatedParams.Total_Cash_LONG2DAYS_R : calculatedParams.Total_Cash_LONG2DAYS, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Cash_LONG2DAYS)
            break;

          case 'totalCardLong2Days':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && calculation.longDistance.roundToFull && calculation.longDistance.directionType === 'expences') ? calculatedParams.Total_Card_LONG2DAYS_R : calculatedParams.Total_Card_LONG2DAYS, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_LONG2DAYS)
            break;

          case 'cashDiscountPercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculation.longDistance.cashPaymentDiscount, setTagsResultsTexts, tagsResultsTexts, calculation.longDistance.cashPaymentDiscount)
            break;

          case 'trucksPercent':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0) ? Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100)) : null, setTagsResultsTexts, tagsResultsTexts, (Math.floor(Number(truckLoads)) - Number(truckLoads)).toFixed(2) * 100)
            break;

          case 'separateDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Separate Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'Unpacking on separate day', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'unpackingShuttle':
            var isSelected = false;
            if (calculation.commonValues.shuttleOnUnpacking) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'unpackingShuttleQty':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.shuttleOnUnpacking && calculation.department.extraOptions.shuttle.isCountable) ? calculation.commonValues.quantityUnpacking : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.quantityUnpacking)
            break;

          case 'unpDepartment':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.unpDepartment._id, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'trucksUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Trucks_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_Unpacking_Separate_Day)
            break;

          case 'moversUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Movers_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_Unpacking_Separate_Day)
            break;

          case 'unpackingSeparateDayLowerhours':
            return getAmountValue(tag, condition, conditionIndex, calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)) ?
                getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)
                : null,
              setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours))
            break;

          case 'unpackingSeparateDayHigherhours':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours) : null, setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours))
            break;

          case 'totalCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS)
            break;

          case 'unpackingSeparatePercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getCurrentRatePercent(calculation.oneDayPacking, calculation.department) : null, setTagsResultsTexts, tagsResultsTexts, getCurrentRatePercent(calculation.oneDayPacking, calculation.department))
            break;

          case 'totalCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Cash_Unpacking_Separate_Day)
            break;

          case 'rateCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod === 'deduct') ? calculatedParams.Rate_cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_Unpacking_Separate_Day)
            break;

          case 'rateCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Rate_card_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_Unpacking_Separate_Day)
            break;

          case 'truckFeeUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && calculatedParams.Truck_fee_Unpacking_Separate_Day !== 0) ? calculatedParams.Truck_fee_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_Unpacking_Separate_Day)
            break;

          case 'totalCardUnpackingSeparateDayLongOOS':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS)
            break;
        }

      } catch (err) {
        console.log(err)
      }
      break;

    case 'oos1day':
      try {
        if (condition[`selectedItemObj${conditionIndex}`].value.split('_').length > 1) {//additionals
          let splittedSelectedItem = condition[`selectedItemObj${conditionIndex}`].value.split('_')
          var numberOfProcedures = 0;
          var procedureIndex = null;
          var procedureType = null;
          var foundItem;
          var chargeIndex;
          var isSelectedDay1 = false;
          var isSelectedDay2 = false;
          if (calculation.oos1day.selectedChargesNamesDay1.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay1 = true;
          }
          if (calculation.oos1day.selectedChargesNamesDay2.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay2 = true;
          }

          calculation.department.extraOptions.charges.some((item, index) => {
            if (item[`uId${index}`] === splittedSelectedItem[1]) {
              foundItem = item;
              chargeIndex = index;
              return true; // Exit the loop when a match is found
            }
            return false;
          });
          if (calculation.commonValues.procedures.length) {
            try {
              for (let index = 0; index < calculation.commonValues.procedures.length; index++) {
                const element = calculation.commonValues.procedures[index];
                if (element.uId === splittedSelectedItem[1]) {
                  numberOfProcedures = Number(element.numberOfProcedures);
                  procedureIndex = index;
                  procedureType = element.procedureType;
                  break;
                }
              }
            }
            catch (err) {
              console.log(err)
            }
          }

          //console.log('numberOfProceduresDay1', numberOfProceduresDay1)

          switch (splittedSelectedItem[0]) {
            case 'chIdName1':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay1, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay1) ? foundItem[`amount${chargeIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${chargeIndex}`])
              break;
            case 'chIdQty1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos1day.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1])) ? calculation.oos1day.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.oos1day.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'pIdName1':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? procedureType : null, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'pIdCost1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures) : null, setTagsResultsTexts, tagsResultsTexts, (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures))
              break;

            case 'pIdQty1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? numberOfProcedures : null, setTagsResultsTexts, tagsResultsTexts, numberOfProcedures)
              break;

            case 'chIdName2':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay2, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay2) ? foundItem[`amount${chargeIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${chargeIndex}`])
              break;
            case 'chIdQty2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos1day.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1])) ? calculation.oos1day.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.oos1day.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'pIdName2':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? procedureType : null, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'pIdCost2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures) : null, setTagsResultsTexts, tagsResultsTexts, (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures))
              break;

            case 'pIdQty2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures) ? numberOfProcedures : null, setTagsResultsTexts, tagsResultsTexts, numberOfProcedures)
              break;

            case 'mChId':
              var isSelected = false;
              if (calculation.selectedMiscCheckMarks.includes(splittedSelectedItem[1])) {
                isSelected = true;
              }
              return getCheckPointValue(tag, condition, conditionIndex, isSelected, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
          }
        }


        switch (condition[`selectedItemObj${conditionIndex}`].value) {
          case 'additionalAmount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos1day.additionalAmount) ? calculation.oos1day.additionalAmount : null, setTagsResultsTexts, tagsResultsTexts, '')
            break;

          case 'extraOptions':
            var isExtraOptionsSelected = false;
            if (calculation.oos1day.selectedChargesNamesDay1.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.oos1day.selectedChargesNamesDay1.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'extraOptionsDay2':
            var isExtraOptionsSelected = false;
            if (calculation.oos1day.selectedChargesNamesDay2.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.oos1day.selectedChargesNamesDay2.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'procedures':
            return getCheckPointValue(tag, condition, conditionIndex, ((calculation.commonValues.procedures.length > 0 && calculation.commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '')) && (calculation.commonValues.procedures[0].procedureType !== "None" || calculation.commonValues.procedures[0].procedureType !== "")) ? true : null, 'Procedures', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partial':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Same Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partialSeparate':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Separate Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'username':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.userId, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'department':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.department._id, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'calculationNumber':
            return getCheckPointValue(tag, condition, conditionIndex, true, calculation.calculationNumber, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day1weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.oos1day.firstDayDate) ? new Date(calculation.oos1day.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day1year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos1day.secondDayDate) ? Number(new Date(calculation.oos1day.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos1day.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day1month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos1day.firstDayDate) ? Number(new Date(calculation.oos1day.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos1day.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day1date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos1day.firstDayDate) ? Number(new Date(calculation.oos1day.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos1day.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.oos1day.secondDayDate) ? new Date(calculation.oos1day.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day2month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos1day.secondDayDate) ? Number(new Date(calculation.oos1day.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos1day.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos1day.secondDayDate) ? Number(new Date(calculation.oos1day.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos1day.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos1day.secondDayDate) ? Number(new Date(calculation.oos1day.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos1day.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'truckloads':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, truckLoads, setTagsResultsTexts, tagsResultsTexts, truckLoads)
            break;

          case 'trucksPercent':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0) ? Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100)) : null, setTagsResultsTexts, tagsResultsTexts, (Math.floor(Number(truckLoads)) - Number(truckLoads)).toFixed(2) * 100)
            break;

          case 'truckloadsRoundDown':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0) ? Number(Math.floor(truckLoads).toFixed(2)) : null, setTagsResultsTexts, tagsResultsTexts, Number(Math.floor(truckLoads).toFixed(2)))
            break;

          case 'combinedMileage':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Combined_mileage, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Combined_mileage)
            break;

          case 'additionalStopsCount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ? calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length)
            break;

          case 'moversDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_OOSOne_Day_Loading, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_OOSOne_Day_Loading)
            break;

          case 'trucksDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_OOSOne_Day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_OOSOne_Day)
            break;

          case 'shuttleDay1':
            var isSelected = false;
            if (calculation.oos1day.shuttleOnDay1) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos1day.shuttleOnDay1 && calculation.department.extraOptions.shuttle.isCountable) ? calculation.oos1day.quantityDay1 : null, setTagsResultsTexts, tagsResultsTexts, calculation.oos1day.quantityDay1)
            break;

          case 'lowerNumberOfHoursDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Total_Hours_OOSOne_Day_Loading_Lower_number), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Total_Hours_OOSOne_Day_Loading_Lower_number))
            break;

          case 'higherNumberOfHoursDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_OOSOne_Day_Loading), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_OOSOne_Day_Loading))
            break;

          case 'liftGate':
            var isSelected = false;
            if (calculation.commonValues.liftGate) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'LIFTGATE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'hardwoodFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.hardFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'HARDWOOD FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'carpetFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.filmFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'CARPET FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'sameDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Same Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'UNPACKING', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'packingType':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.commonValues.packing, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'packingKit':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_Show : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_Show)
            break;

          case 'packingKitWithTax':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_total_amount_with_taxes : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_total_amount_with_taxes)
            break;

          case 'smallBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.smallBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.smallBox)
            break;

          case 'mediumBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.mediumBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.mediumBox)
            break;

          case 'largeBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.largeBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.largeBox)
            break;

          case 'rollOfPaper':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.packingPapper : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.packingPapper)
            break;

          case 'rollOfBubble':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.bubbleWrap : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.bubbleWrap)
            break;

          case 'driveTime':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Drivetime_OOSOne_Day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Drivetime_OOSOne_Day)
            break;

          case 'moversDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_OOSOne_Day_Unloading, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_OOSOne_Day_Unloading)
            break;

          case 'trucksDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_OOSOne_Day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_OOSOne_Day)
            break;

          case 'shuttleDay2':
            var isSelected = false;
            if (calculation.oos1day.shuttleOnDay2) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos1day.shuttleOnDay2 && calculation.department.extraOptions.shuttle.isCountable) ? calculation.oos1day.quantityDay1 : null, setTagsResultsTexts, tagsResultsTexts, calculation.oos1day.quantityDay1)
            break;

          case 'lowerNumberOfHoursDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Unloading_Lower_number), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Unloading_Lower_number))
            break;

          case 'higherNumberOfHoursDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Unloading), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Unloading))
            break;

          case 'totalCardOOSDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Total_Card_OOSOne_Day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_OOSOne_Day)
            break;

          case 'totalCashOOSDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Total_Cash_OOSOne_Day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Cash_OOSOne_Day)
            break;

          case 'cashDiscountPercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculation.outOfState.cashPaymentDiscount, setTagsResultsTexts, tagsResultsTexts, calculation.outOfState.cashPaymentDiscount)
            break;

          case 'separateDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Separate Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'Unpacking on separate day', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'unpackingShuttle':
            var isSelected = false;
            if (calculation.commonValues.shuttleOnUnpacking) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'unpackingShuttleQty':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.shuttleOnUnpacking && calculation.department.extraOptions.shuttle.isCountable) ? calculation.commonValues.quantityUnpacking : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.quantityUnpacking)
            break;

          case 'unpDepartment':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.unpDepartment._id, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'trucksUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Trucks_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_Unpacking_Separate_Day)
            break;

          case 'moversUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Movers_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_Unpacking_Separate_Day)
            break;

          case 'unpackingSeparateDayLowerhours':
            return getAmountValue(tag, condition, conditionIndex, calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)) ?
                getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)
                : null,
              setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours))
            break;

          case 'unpackingSeparateDayHigherhours':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours) : null, setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours))
            break;

          case 'totalCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS)
            break;

          case 'unpackingSeparatePercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getCurrentRatePercent(calculation.oneDayPacking, calculation.department) : null, setTagsResultsTexts, tagsResultsTexts, getCurrentRatePercent(calculation.oneDayPacking, calculation.department))
            break;

          case 'totalCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Cash_Unpacking_Separate_Day)
            break;

          case 'rateCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod === 'deduct') ? calculatedParams.Rate_cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_Unpacking_Separate_Day)
            break;

          case 'rateCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Rate_card_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_Unpacking_Separate_Day)
            break;

          case 'truckFeeUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && calculatedParams.Truck_fee_Unpacking_Separate_Day !== 0) ? calculatedParams.Truck_fee_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_Unpacking_Separate_Day)
            break;

          case 'totalCardUnpackingSeparateDayLongOOS':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS)
            break;
        }


      } catch (err) {
        console.log(err)
      }
      break;

    case 'oos2days':
      try {

        if (condition[`selectedItemObj${conditionIndex}`].value.split('_').length > 1) {//additionals
          let splittedSelectedItem = condition[`selectedItemObj${conditionIndex}`].value.split('_')
          var numberOfProcedures = 0;
          var procedureIndex = null;
          var procedureType = null;
          var foundItem;
          var chargeIndex;
          var isSelectedDay1 = false;
          var isSelectedDay2 = false;
          var isSelectedDay3 = false;
          if (calculation.oos2days.selectedChargesNamesDay1.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay1 = true;
          }
          if (calculation.oos2days.selectedChargesNamesDay2.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay2 = true;
          }
          if (calculation.oos2days.selectedChargesNamesDay3.some(item => item.key === splittedSelectedItem[1])) {
            isSelectedDay3 = true;
          }

          calculation.department.extraOptions.charges.some((item, index) => {
            if (item[`uId${index}`] === splittedSelectedItem[1]) {
              foundItem = item;
              chargeIndex = index;
              return true; // Exit the loop when a match is found
            }
            return false;
          });
          if (calculation.commonValues.procedures.length) {
            try {
              for (let index = 0; index < calculation.commonValues.procedures.length; index++) {
                const element = calculation.commonValues.procedures[index];

                if (element.uId === splittedSelectedItem[1]) {
                  numberOfProcedures = Number(element.numberOfProcedures);
                  procedureIndex = index;
                  procedureType = element.procedureType;
                  break;
                }
              }
            }
            catch (err) {
              console.log(err)
            }
          }

          switch (splittedSelectedItem[0]) {
            case 'chIdName1':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay1, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay1) ? foundItem[`amount${chargeIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${chargeIndex}`])
              break;
            case 'chIdQty1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1])) ? calculation.oos2days.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.oos2days.selectedChargesNamesDay1.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'pIdName1':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.oos2days.proceduresOnDay1) ? procedureType : null, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'pIdCost1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.oos2days.proceduresOnDay1) ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures) : null, setTagsResultsTexts, tagsResultsTexts, (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures))
              break;

            case 'pIdQty1':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.oos2days.proceduresOnDay1) ? numberOfProcedures : null, setTagsResultsTexts, tagsResultsTexts, numberOfProcedures)
              break;

            case 'chIdName2':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay2, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay2) ? foundItem[`amount${chargeIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${chargeIndex}`])
              break;
            case 'chIdQty2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1])) ? calculation.oos2days.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.oos2days.selectedChargesNamesDay2.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'chIdName3':
              return getCheckPointValue(tag, condition, conditionIndex, isSelectedDay3, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
            case 'chIdAmount3':
              return getAmountValue(tag, condition, conditionIndex, calculation, (foundItem && isSelectedDay3) ? foundItem[`amount${chargeIndex}`] : null, setTagsResultsTexts, tagsResultsTexts, foundItem[`amount${chargeIndex}`])
              break;
            case 'chIdQty3':
              return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.selectedChargesNamesDay3.find(item => item.key === splittedSelectedItem[1])) ? calculation.oos2days.selectedChargesNamesDay3.find(item => item.key === splittedSelectedItem[1]).quantity : null, setTagsResultsTexts, tagsResultsTexts, calculation.oos2days.selectedChargesNamesDay3.find(item => item.key === splittedSelectedItem[1]).quantity)
              break

            case 'pIdName2':
              return getSelectableValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.oos2days.proceduresOnDay2) ? procedureType : null, setTagsResultsTexts, tagsResultsTexts)
              break;

            case 'pIdCost2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.oos2days.proceduresOnDay2) ? (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures) : null, setTagsResultsTexts, tagsResultsTexts, (Number(getCrateCost(calculation.department.packing.cratings, procedureType)) * numberOfProcedures))
              break;

            case 'pIdQty2':
              return getAmountValue(tag, condition, conditionIndex, calculation, (numberOfProcedures && calculation.oos2days.proceduresOnDay2) ? numberOfProcedures : null, setTagsResultsTexts, tagsResultsTexts, numberOfProcedures)
              break;

            case 'mChId':
              var isSelected = false;
              if (calculation.selectedMiscCheckMarks.includes(splittedSelectedItem[1])) {
                isSelected = true;
              }
              return getCheckPointValue(tag, condition, conditionIndex, isSelected, condition[`selectedItemObj${conditionIndex}`].label, setTagsResultsTexts, tagsResultsTexts)
              break;
          }
        }


        switch (condition[`selectedItemObj${conditionIndex}`].value) {
          case 'additionalAmount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.additionalAmount) ? calculation.oos2days.additionalAmount : null, setTagsResultsTexts, tagsResultsTexts, '')
            break;

          case 'extraOptions':
            var isExtraOptionsSelected = false;
            if (calculation.oos2days.selectedChargesNamesDay1.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.oos2days.selectedChargesNamesDay1.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'extraOptionsDay2':
            var isExtraOptionsSelected = false;
            if (calculation.oos2days.selectedChargesNamesDay2.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.oos2days.selectedChargesNamesDay2.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'extraOptionsDay3':
            var isExtraOptionsSelected = false;
            if (calculation.oos2days.selectedChargesNamesDay3.length) {
              for (let chargeIndex = 0; chargeIndex < calculation.department.extraOptions.charges.length; chargeIndex++) {
                const charge = calculation.department.extraOptions.charges[chargeIndex];
                isExtraOptionsSelected = calculation.oos2days.selectedChargesNamesDay3.some(item => item?.key?.includes(charge[`uId${chargeIndex}`]));
                if (isExtraOptionsSelected) { break }
              }
            }
            return getCheckPointValue(tag, condition, conditionIndex, isExtraOptionsSelected, 'Extra Options', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'procedures':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.procedures.length > 0 && calculation.commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '') && calculation.oos2days.proceduresOnDay1) ? true : null, 'Procedures', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'proceduresDay2':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.procedures.length > 0 && calculation.commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '') && calculation.oos2days.proceduresOnDay2) ? true : null, 'Procedures', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partial':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Same Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'partialSeparate':
            return getCheckPointValue(tag, condition, conditionIndex, (calculation.commonValues.partialUnPacking && calculation.commonValues.unpacking === 'Separate Day') ? calculation.commonValues.partialUnPacking : null, 'PARTIAL', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'username':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.userId, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'department':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.department._id, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'calculationNumber':
            return getCheckPointValue(tag, condition, conditionIndex, true, calculation.calculationNumber, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day1weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.firstDayDate) ? new Date(calculation.oos2days.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day1year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.secondDayDate) ? Number(new Date(calculation.oos2days.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos2days.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day1month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.firstDayDate) ? Number(new Date(calculation.oos2days.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos2days.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day1date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.firstDayDate) ? Number(new Date(calculation.oos2days.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos2days.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.secondDayDate) ? new Date(calculation.oos2days.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts, new Date(calculation.oos2days.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString())
            break;

          case 'day2month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.secondDayDate) ? Number(new Date(calculation.oos2days.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos2days.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.secondDayDate) ? Number(new Date(calculation.oos2days.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos2days.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day2year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.secondDayDate) ? Number(new Date(calculation.oos2days.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos2days.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day3weekday':
            return getSelectableValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.thirdDayDate) ? new Date(calculation.oos2days.thirdDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }).toString() : null, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'day3month':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.thirdDayDate) ? Number(new Date(calculation.oos2days.thirdDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos2days.thirdDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day3date':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.thirdDayDate) ? Number(new Date(calculation.oos2days.thirdDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos2days.thirdDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })))
            break;

          case 'day3year':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.thirdDayDate) ? Number(new Date(calculation.oos2days.thirdDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })) : null, setTagsResultsTexts, tagsResultsTexts, Number(new Date(calculation.oos2days.thirdDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })))
            break;

          case 'truckloads':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, truckLoads, setTagsResultsTexts, tagsResultsTexts, truckLoads)
            break;

          case 'moversDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_OOS2DAYS_Loading, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_OOS2DAYS_Loading)
            break;

          case 'trucksPercent':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0) ? Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100)) : null, setTagsResultsTexts, tagsResultsTexts, Math.floor(Number((Number(truckLoads) - Math.floor(truckLoads)).toFixed(2) * 100)))
            break;

          case 'cashDiscountPercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculation.outOfState.cashPaymentDiscount, setTagsResultsTexts, tagsResultsTexts, calculation.outOfState.cashPaymentDiscount)
            break;

          case 'truckloadsRoundDown':
            var truckLoads = calculatedParams.Truckloads;
            if (calculatedParams.Total_cubes_Within_Building > 0) {
              truckLoads = calculatedParams.Truckloads_to_move;
            }
            return getAmountValue(tag, condition, conditionIndex, calculation, (truckLoads > 1 && (Math.floor(Number(truckLoads))) - Number(truckLoads) < 0) ? Number(Math.floor(truckLoads).toFixed(2)) : null, setTagsResultsTexts, tagsResultsTexts, Number(Math.floor(truckLoads).toFixed(2)))
            break;

          case 'combinedMileage':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Combined_mileage, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Combined_mileage)
            break;

          case 'additionalStopsCount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ? calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length)
            break;

          case 'combinedMileage':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Combined_mileage, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Combined_mileage)
            break;

          case 'additionalStopsCount':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ? calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length)
            break;

          case 'moversDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_To_Pack_OOS_Day1, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_To_Pack_OOS_Day1)
            break;

          case 'trucksDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_To_Pack_OOS_Day1, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_To_Pack_OOS_Day1)
            break;

          case 'notToExceedPricePercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod !== 'deduct') ? getCurrentRatePercent(calculation.oneDayPacking, calculation.department) : null, setTagsResultsTexts, tagsResultsTexts, getCurrentRatePercent(calculation.oneDayPacking, calculation.department))
            break;


          case 'totalCardOOSDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Total_Card_OOS2DAYS, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_OOS2DAYS)
            break;

          case 'totalCashOOSDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Total_Cash_OOS2DAYS, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Cash_OOS2DAYS)
            break;

          case 'shuttleDay1':
            var isSelected = false;
            if (calculation.oos2days.shuttleOnDay1) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.shuttleOnDay1 && calculation.department.extraOptions.shuttle.isCountable) ? calculation.oos2days.quantityDay1 : null, setTagsResultsTexts, tagsResultsTexts, calculation.oos2days.quantityDay1)
            break;

          case 'lowerNumberOfHoursDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_OOS_Day1), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Lower_Number_of_Hours_OOS_Day1))
            break;

          case 'higherNumberOfHoursDay1':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_OOS_Day1), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Higher_Number_of_Hours_OOS_Day1))
            break;

          case 'lowerNumberOfHoursDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Total_Hours_OOS2DAYS_Loading_Lower_number), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Total_Hours_OOS2DAYS_Loading_Lower_number))
            break;

          case 'higherNumberOfHoursDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_OOS2DAYS_Loading), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_OOS2DAYS_Loading))
            break;

          case 'sameDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Same Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'UNPACKING', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'packingKit':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_Show : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_Show)
            break;

          case 'packingType':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.commonValues.packing, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'packingKitWithTax':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculatedParams.Packing_kit_total_amount_with_taxes : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Packing_kit_total_amount_with_taxes)
            break;

          case 'smallBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.smallBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.smallBox)
            break;

          case 'mediumBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.mediumBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.mediumBox)
            break;

          case 'largeBox':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.largeBox : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.largeBox)
            break;

          case 'rollOfPaper':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.packingPapper : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.packingPapper)
            break;

          case 'rollOfBubble':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.packing !== "No") ? calculation.department.packing.supplies.bubbleWrap : null, setTagsResultsTexts, tagsResultsTexts, calculation.department.packing.supplies.bubbleWrap)
            break;

          case 'driveTime':
            return getAmountValue(tag, condition, conditionIndex, calculation, (!calculatedParams.Drivetime_OOSOne_Day || calculatedParams.Drivetime_OOSOne_Day === 0) ? "Not specified" : calculatedParams.Drivetime_OOSOne_Day, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Drivetime_OOSOne_Day)
            break;

          case 'heavyFee':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculatedParams.Heavy_fee !== 0) ? calculatedParams.Heavy_fee : null, setTagsResultsTexts, tagsResultsTexts, Number(calculation.department.extraOptions.heavyItem))
            break;

          case 'liftGate':
            var isSelected = false;
            if (calculation.commonValues.liftGate) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'LIFTGATE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'hardwoodFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.hardFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'HARDWOOD FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'carpetFloorCovers':
            var isSelected = false;
            if (calculation.commonValues.filmFloorCovers) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'CARPET FLOOR COVERS', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'moversDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Movers_LONG2DAYS_Unloading, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_LONG2DAYS_Unloading)
            break;

          case 'trucksDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, calculatedParams.Trucks_OOS2DAYS, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_OOS2DAYS)
            break;

          case 'shuttleDay2':
            var isSelected = false;
            if (calculation.oos2days.shuttleOnDay2) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay2':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.shuttleOnDay2 && calculation.department.extraOptions.shuttle.isCountable) ? calculation.oos2days.quantityDay2 : null, setTagsResultsTexts, tagsResultsTexts, calculation.oos2days.quantityDay2)
            break;

          case 'shuttleDay3':
            var isSelected = false;
            if (calculation.oos2days.shuttleOnDay3) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'shuttleQtyDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.oos2days.shuttleOnDay3 && calculation.department.extraOptions.shuttle.isCountable) ? calculation.oos2days.quantityDay3 : null, setTagsResultsTexts, tagsResultsTexts, calculation.oos2days.quantityDay3)
            break;

          case 'lowerNumberOfHoursDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, getLowerNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Loading_Lower_number), setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Loading_Lower_number))
            break;

          case 'higherNumberOfHoursDay3':
            return getAmountValue(tag, condition, conditionIndex, calculation, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Loading_Lower_number), setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Total_Hours_LONGOne_Day_Loading_Lower_number))
            break;

          case 'separateDayUnpack':
            var isSelected = false;
            if (calculation.commonValues.unpacking === 'Separate Day') {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'Unpacking on separate day', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'unpackingShuttle':
            var isSelected = false;
            if (calculation.commonValues.shuttleOnUnpacking) {
              isSelected = true;
            }
            return getCheckPointValue(tag, condition, conditionIndex, isSelected, 'SHUTTLE', setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'unpackingShuttleQty':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues.shuttleOnUnpacking && calculation.department.extraOptions.shuttle.isCountable) ? calculation.commonValues.quantityUnpacking : null, setTagsResultsTexts, tagsResultsTexts, calculation.commonValues.quantityUnpacking)
            break;

          case 'unpDepartment':
            return getSelectableValue(tag, condition, conditionIndex, calculation, calculation.unpDepartment._id, setTagsResultsTexts, tagsResultsTexts)
            break;

          case 'trucksUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Trucks_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Trucks_Unpacking_Separate_Day)
            break;

          case 'moversUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Movers_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Movers_Unpacking_Separate_Day)
            break;

          case 'unpackingSeparateDayLowerhours':
            return getAmountValue(tag, condition, conditionIndex, calculation,
              (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)) ?
                getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours)
                : null,
              setTagsResultsTexts, tagsResultsTexts, getLowerNumberOfHoursValueOnly(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Lower_hours))
            break;

          case 'unpackingSeparateDayHigherhours':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours) : null, setTagsResultsTexts, tagsResultsTexts, getHigherNumberOfHours(calculation.department.minimumHours, calculatedParams.Unpacking_Separate_Day_Higher_hours))
            break;

          case 'totalCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS)
            break;

          case 'unpackingSeparatePercent':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? getCurrentRatePercent(calculation.oneDayPacking, calculation.department) : null, setTagsResultsTexts, tagsResultsTexts, getCurrentRatePercent(calculation.oneDayPacking, calculation.department))
            break;

          case 'totalCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Cash_Unpacking_Separate_Day)
            break;

          case 'rateCashUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.department.rates && calculation.department.rates.calcMethod === 'deduct') ? calculatedParams.Rate_cash_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_cash_Unpacking_Separate_Day)
            break;

          case 'rateCardUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Rate_card_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Rate_card_Unpacking_Separate_Day)
            break;

          case 'truckFeeUnpackingSeparateDay':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day' && calculatedParams.Truck_fee_Unpacking_Separate_Day !== 0) ? calculatedParams.Truck_fee_Unpacking_Separate_Day : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Truck_fee_Unpacking_Separate_Day)
            break;

          case 'totalCardUnpackingSeparateDayLongOOS':
            return getAmountValue(tag, condition, conditionIndex, calculation, (calculation.commonValues && calculation.commonValues.unpacking === 'Separate Day') ? calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS : null, setTagsResultsTexts, tagsResultsTexts, calculatedParams.Total_Card_Unpacking_Separate_Day_Long_and_OOS)
            break;
        }


      } catch (err) {
        console.log(err)
      }
      break;

    default:
      break;
  }


}

export const getTagsValues = ({ calculation, tags, calculatedParams, setTagsResultsTexts, tagsResultsTexts, isPreviewPage, furniture, roomsAreas }) => {
  let tagValues = {};
  /*   switch (calculation.calculationType) {
      case 'oneDayPacking': */
  if (tags) {
    tags.forEach(tag => {

      for (let conditionIndex = 0; conditionIndex < tag.conditions.length; conditionIndex++) {
        const condition = tag.conditions[conditionIndex];
        let tagValueArr = getTagValue(tag, condition, conditionIndex, calculation, calculatedParams, setTagsResultsTexts, tagsResultsTexts, isPreviewPage, furniture, roomsAreas);

        if (tagValueArr) {

          if (isPreviewPage) {
            switch (tag.conditions[0]['selectedItemObj0'].value) {
              case 'moversToLoad':
                tagValues[`\|{${tag.tagName}}`] = '<span style="color: #4db6ac">' + tagValueArr[1] + '</span>'
                break;
              case 'rateCardLoad':
                tagValues[`\|{${tag.tagName}}`] = '<span style="color: #4db6ac">' + tagValueArr[1] + '</span>'
                break;
              case 'rateCashLoad':
                tagValues[`\|{${tag.tagName}}`] = '<span style="color: #4db6ac">' + tagValueArr[1] + '</span>'
                break;
              case 'moversToDrivesAndUnload':
                tagValues[`\|{${tag.tagName}}`] = '<span style="color: #4db6ac">' + tagValueArr[1] + '</span>'
                break;
              case 'rateCardUnload':
                tagValues[`\|{${tag.tagName}}`] = '<span style="color: #4db6ac">' + tagValueArr[1] + '</span>'
                break;
              case 'rateCashUnload':
                tagValues[`\|{${tag.tagName}}`] = '<span style="color: #4db6ac">' + tagValueArr[1] + '</span>'
                break;
              default:
                tagValues[`\|{${tag.tagName}}`] = tagValueArr[1]
                break;
            }
          }
          else {
            tagValues[`\|{${tag.tagName}}`] = tagValueArr[1]
          }
          if (tagValueArr[0]) {
            break
          }
        }
      }
    });
  }
  /*       break;
  
      case 'twoDaysPacking':
  
        break;
      case 'twoDays150Miles':
  
        break;
  
      case 'longOneDayPacking':
  
        break;
  
      case 'longTwoDaysPacking':
  
        break;
  
      case 'oos1day':
  
        break;
  
      case 'oos2days':
  
        break;
      default:
        break;
    } */

  // tagValues[`\|{inventory}`] = 'Inentory'

  tagValues[`|{inventory}`] = getFurnitureText(calculation, furniture, roomsAreas, 'furnitureGoes').inventoryText;
  tagValues[`|{stays}`] = getFurnitureText(calculation, furniture, roomsAreas, 'furnitureStays').inventoryText;
  //furnitureStays ,furnitureGoes
  return tagValues
}


export const calculateInventoryTotalValues = (calculation, furniture, roomsAreas) => {
  let cuftPerStop = [];
  let totalFurnitureAndWB = 0;
  let totalFragileQty = 0;
  let totalSmallQty = 0;
  let totalMediumQty = 0;
  let totalWBQty = 0;
  let totalBoxesCuFt = 0;

  calculation.inventory?.extraStops.forEach((stop, index) => {
    let totalFurniture = 0;
    let totalFragile = 0;
    let totalSmall = 0;
    let totalMedium = 0;
    let totalWB = 0;
    let totalCuFt = 0;

    calculation.inventory.rooms.forEach((room, roomIndex) => {
      room.furnitureGoes.forEach((furnitureGoesItem) => {
        if (furnitureGoesItem.stopIndex == index) {
          totalFurniture += furnitureGoesItem.cuft * furnitureGoesItem.qty;
        }
      });
      if (room.boxes[index]) {
        totalFragile += Number(room.boxes[index].fragile);
        totalSmall += Number(room.boxes[index].small);
        totalMedium += Number(room.boxes[index].medium);
        totalWB += Number(room.boxes[index].WB);
      }
    });

    totalCuFt = totalFurniture + totalFragile * 1.5 + totalSmall * 1.5 + totalMedium * 3 + totalWB * 10;
    totalFurnitureAndWB += totalFurniture + totalWB * 10;
    totalFragileQty += Number(totalFragile);
    totalSmallQty += Number(totalSmall);
    totalMediumQty += Number(totalMedium);
    totalWBQty += Number(totalWB);
    totalBoxesCuFt += totalFragile * 1.5 + totalSmall * 1.5 + totalMedium * 3;

    cuftPerStop.push({ totalFurniture, totalFragile, totalSmall, totalMedium, totalWB, totalCuFt });
  });

  return {
    cuftPerStop,
    totalFurnitureAndWB,
    totalFragileQty,
    totalSmallQty,
    totalMediumQty,
    totalWBQty,
    totalBoxesCuFt,
  };
};

export const getFurnitureText = (calculation, furniture, roomsAreas, furnitureType) => {
  let symbolBeforeFurniture = '- ';
  let inventoryText = '';
  let isFurnitureBold = false;
  let isRoomsBold = false;
  let isFurnitureItalic = false;
  let isRoomsItalic = false;
  let isFurnitureUnderline = false;
  let isRoomsUnderline = false;
  let includeCuFtPerItem = false;
  let insertLineBreakAN = false;
  let departmenId = 'default';




  let cuftText = '';
  let boxesText = '';
  let hangingClothesText = '';
  let includeBoxesOnInv = false;
  let includeWBBoxesOnInv = false;
  let combinePBOPBMBoxes = false;
  let convertToMediumBoxesWB = false;
  let adjustedCuFt = 0;
  let adjustedCuFtTotal = 0;
  let adjustTotalCuFt = false;
  let adjustTotalCuFtWB = false;
  let roomFontFamily = '';
  let roomFontSize = '';
  let furnitureFontFamily = '';
  let furnitureFontSize = '';

  if (furniture && Object.keys(furniture).length > 0) {
    if (calculation?.departmentId && (calculation.departmentId in furniture.inventoryListOptions)) {
      departmenId = calculation.departmentId;
    }
    if (furniture.italic) {
      isFurnitureItalic = true;
    }
    if (furniture.bold) {
      isFurnitureBold = true;
    }
    if (furniture.underline) {
      isFurnitureUnderline = true;
    }
    if (furniture.fontFamily) {
      furnitureFontFamily = furniture.fontFamily;
    }
    if (furniture.fontSize) {
      furnitureFontSize = furniture.fontSize;
    }
    if (furniture.useSymbolBeforeItems) {
      symbolBeforeFurniture = furniture.furnitureItemsSymbol;
    }
    if ((furniture.inventoryListOptions?.[departmenId]?.includeCuFtPerItem && calculation?.inventory?.cuFtOption.include == false && calculation.inventory?.cuFtOption.remove == false)
      || (!furniture.inventoryListOptions?.[departmenId]?.includeCuFtPerItem && calculation.inventory?.cuFtOption.include == true && calculation.inventory?.cuFtOption.remove == false)
      || (furniture.inventoryListOptions?.[departmenId]?.includeCuFtPerItem && calculation.inventory?.cuFtOption.include == true && calculation.inventory?.cuFtOption.remove == false)
    ) {
      includeCuFtPerItem = true;
    }
    if (furniture.inventoryListOptions[departmenId]?.cuftText) {
      cuftText = furniture.inventoryListOptions[departmenId]?.cuftText
    }
    if (furniture.inventoryListOptions[departmenId]?.boxesText) {
      boxesText = furniture.inventoryListOptions[departmenId]?.boxesText
    }
    if (furniture.inventoryListOptions[departmenId]?.WBBoxesText) {
      hangingClothesText = furniture.inventoryListOptions[departmenId]?.WBBoxesText
    }
    if (furniture.inventoryListOptions[departmenId]?.includeBoxesOnInv) {
      includeBoxesOnInv = true;
    }
    if (furniture.inventoryListOptions[departmenId]?.includeWBBoxesOnInv) {
      includeWBBoxesOnInv = true;
    }
    if (furniture.inventoryListOptions[departmenId]?.combinePBOPBMBoxes) {
      combinePBOPBMBoxes = true;
    }
    if (furniture.inventoryListOptions[departmenId]?.convertToMediumBoxesWB) {
      convertToMediumBoxesWB = true;
    }
    if (furniture.inventoryListOptions[departmenId]?.adjustTotalCuFt) {
      adjustTotalCuFt = true;
    }
    if (furniture.inventoryListOptions[departmenId]?.adjustTotalCuFtWB) {
      adjustTotalCuFtWB = true;
    }


  }
  if (roomsAreas) {
    if (roomsAreas.italic) {
      isRoomsItalic = true;
    }
    if (roomsAreas.bold) {
      isRoomsBold = true;
    }
    if (roomsAreas.underline) {
      isRoomsUnderline = true;
    }
    if (roomsAreas.insertLineBreakAN) {
      insertLineBreakAN = true;
    }
    if (roomsAreas.fontFamily) {
      roomFontFamily = roomsAreas.fontFamily;
    }
    if (roomsAreas.fontSize) {
      roomFontSize = roomsAreas.fontSize;
    }


  }


  if (calculation && calculation.inventory) {
    if (calculation.inventory.rooms.length > 0) {

      calculation.inventory.rooms.forEach((room, index) => {
        let boxedSmallerItems = 0;
        let hangingClothes = 0;

        let totalPBOBoxes = 0;
        if (room[furnitureType].length) {
          inventoryText += `<span style="${isRoomsItalic ? 'font-style: italic;' : ''}${isRoomsBold ? 'font-weight: bold;' : ''}${isRoomsUnderline ? 'text-decoration: underline;' : ''} ${roomFontFamily ? `font-family: ${roomFontFamily};` : ''}${roomFontSize ? `font-size: ${roomFontSize}px;` : ''} ">${insertLineBreakAN ? '<br>' : ''}${room.roomName}</span><br>`
          let groupedFurniture = ''
          if (includeCuFtPerItem) {
            groupedFurniture = room[furnitureType].reduce((acc, item) => {
              // Normalize the name to lowercase for case-insensitive comparison
              let normalizedName = item.name.toLowerCase();
              // Create a unique key by combining name and cuft
              let uniqueKey = `${normalizedName}_${item.cuft}`;
              if (combinePBOPBMBoxes && item.name === 'Boxes') {
                totalPBOBoxes += item.qty * item.cuft
              }
              if (acc[uniqueKey]) {
                // If the item already exists, update the total quantity
                acc[uniqueKey].qty += item.qty;
                acc[uniqueKey].stopIndexes = Array.from(new Set([...acc[uniqueKey].stopIndexes, item.stopIndex]));
              } else {
                // If the item does not exist, add it with its quantity and cuft
                acc[uniqueKey] = {
                  name: item.name, // Keep the original name for display
                  qty: item.qty,
                  cuft: item.cuft,
                  stopIndexes: [item.stopIndex]
                };
              }
              return acc;
            }, {});

            Object.values(groupedFurniture).forEach((item) => {
              const stopNames = item.stopIndexes.map(index => {
                const stop = calculation.inventory.extraStops[index];
                return stop ? stop.name : index;
              }).join(', ');
              let qtyText = item.qty > 1 ? ` x ${item.qty}` : '';
              const stopNamesText = stopNames ? ` (${stopNames})` : ''; // Conditionally add parentheses
              if (combinePBOPBMBoxes && item.name === 'Boxes') {
                inventoryText += '';

              } else {

                inventoryText += `<span style="${isFurnitureItalic ? 'font-style: italic;' : ''}
              ${isFurnitureBold ? 'font-weight: bold;' : ''}
              ${isFurnitureUnderline ? 'text-decoration: underline;' : ''}
              ${furnitureFontFamily ? `font-family: ${furnitureFontFamily};` : ''}
              ${furnitureFontSize ? `font-size: ${furnitureFontSize}px;` : ''}
              ">
              ${symbolBeforeFurniture}${item.name}${qtyText}${furnitureType !== "furnitureStays" ? `: ${(item.qty > 1) ? item.cuft * item.qty : item.cuft} ${cuftText}${stopNamesText}` : ''}
              </span><br>`;
              }
              /*    } */
            });
          }
          else {
            let groupedFurniture = room[furnitureType].reduce((acc, item) => {

              // Normalize the name to lowercase for case-insensitive comparison
              if (combinePBOPBMBoxes && item.name !== 'Boxes') {
                let normalizedName = item.name.toLowerCase();

                if (acc[normalizedName]) {
                  // If the item already exists, update the total quantity
                  acc[normalizedName].qty += item.qty;
                } else {
                  // If the item does not exist, add it with its quantity
                  acc[normalizedName] = {
                    name: item.name, // Keep the original name for display
                    qty: item.qty
                  };
                }
              } else if (!combinePBOPBMBoxes) {
                let normalizedName = item.name.toLowerCase();

                if (acc[normalizedName]) {
                  // If the item already exists, update the total quantity
                  acc[normalizedName].qty += item.qty;
                } else {
                  // If the item does not exist, add it with its quantity
                  acc[normalizedName] = {
                    name: item.name, // Keep the original name for display
                    qty: item.qty
                  };
                }
              }
              return acc;
            }, {});




            Object.values(groupedFurniture).forEach((item) => {
              let qtyText = item.qty > 1 ? ` x ${item.qty}` : '';
              inventoryText += `<span style="${isFurnitureItalic ? 'font-style: italic;' : ''}
              ${isFurnitureBold ? 'font-weight: bold;' : ''}
              ${isFurnitureUnderline ? 'text-decoration: underline;' : ''}
              ${furnitureFontFamily ? `font-family: ${furnitureFontFamily};` : ''}
              ${furnitureFontSize ? `font-size: ${furnitureFontSize}px;` : ''}
              ">
              ${symbolBeforeFurniture}${item.name}${qtyText}
              </span><br>`;
            });


          }

          boxedSmallerItems += totalPBOBoxes
          room.boxes.forEach((box, index) => {
            boxedSmallerItems += box.fragile * 1.5 + box.small * 1.5 + box.medium * 3
            hangingClothes += box.WB * 10
          });

          if ((boxedSmallerItems > 0 || hangingClothes > 0) && includeBoxesOnInv && furnitureType === 'furnitureGoes') {
            if (furniture.inventoryListOptions[departmenId]?.convertToMediumBoxes) {
              if (convertToMediumBoxesWB) {
                boxedSmallerItems += hangingClothes
              }

              let roundedBoxedSmallerItems = Math.round(boxedSmallerItems / 3);

              adjustedCuFt = roundedBoxedSmallerItems * 3 - boxedSmallerItems;
              //console.log('roundedBoxedSmallerItems', room.roomName, roundedBoxedSmallerItems * 3, boxedSmallerItems, adjustedCuFt, furnitureType)
              adjustedCuFtTotal += adjustedCuFt;

              boxedSmallerItems = Math.round(boxedSmallerItems / 3);
            }
            inventoryText += `<span style="${isFurnitureItalic ? 'font-style: italic;' : ''}
              ${isFurnitureBold ? 'font-weight: bold;' : ''}
              ${isFurnitureUnderline ? 'text-decoration: underline;' : ''}
              ${furnitureFontFamily ? `font-family: ${furnitureFontFamily};` : ''}
              ${furnitureFontSize ? `font-size: ${furnitureFontSize}px;` : ''}
              ">
               ${symbolBeforeFurniture}Boxed smaller items${(includeCuFtPerItem) ? `: ${boxedSmallerItems} ${boxesText}` : ''} 
              </span><br>`;
          }

          if (hangingClothes > 0 && includeWBBoxesOnInv && !convertToMediumBoxesWB) {
            inventoryText += `<span style="${isFurnitureItalic ? 'font-style: italic;' : ''}
              ${isFurnitureBold ? 'font-weight: bold;' : ''}
              ${isFurnitureUnderline ? 'text-decoration: underline;' : ''}
              ${furnitureFontFamily ? `font-family: ${furnitureFontFamily};` : ''}
              ${furnitureFontSize ? `font-size: ${furnitureFontSize}px;` : ''}
              ">
               ${symbolBeforeFurniture}Hanging Clothes${(includeCuFtPerItem) ? `: ${hangingClothes} ${hangingClothesText}` : ''}
              </span><br>`;
          }

        }
      });
    }
  }

  if (includeCuFtPerItem) {
    let totalValues = calculateInventoryTotalValues(calculation, furniture, roomsAreas);
    let totalCuFt = totalValues.totalFurnitureAndWB + totalValues.totalBoxesCuFt;
    if (adjustTotalCuFt || adjustTotalCuFtWB) {
      totalCuFt += adjustedCuFtTotal;
    }
    let totalCuFtText = (totalCuFt > 0) ? `<br>Total Cu Ft: ${totalCuFt} <br>` : '';

    inventoryText = (furnitureType === 'furnitureGoes') ? (totalCuFtText + inventoryText) : inventoryText
  }

  return { inventoryText, adjustedCuFtTotal };
}

export const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}


export const removeStylesFromTagsStr = (str) => {
  try {
    let reg1 = /\|(.*?)\{/g;
    let reg2 = /\|{(.*?)\}/g;
    let reg3 = /<(.*?)>/g;// /<(.*?)\>/g;
    let newString = "";
    let a, c, f;
    newString = str;
    do {
      a = reg1.exec(newString);
      if (a) {
        let b =
          newString.substr(0, a.index) +
          newString.substr(a.index + 1, a[1].length) +
          "|{" +
          newString.substr(a[1].length + a.index + 2, newString.length);
        newString = b;
      }
    } while (a);
    do {
      c = reg2.exec(newString);
      if (c) {
        let e = c[0];
        do {
          f = reg3.exec(e);
          if (f) {
            e =
              f[0] +
              e.substr(0, f.index) +
              e.substr(f.index + f[0].length, e.length);
          }
        } while (f);
        let b =
          newString.substr(0, c.index) +
          e +
          newString.substr(c.index + e.length, newString.length);
        newString = b;
      }
    } while (c);
    return newString;
  } catch (err) {
    console.log(err)
  }
}

export const replaceTagsByValues = (text, tagValues) => {
  try {
    let re = /\|{(.*?)\}/g;
    let result = removeStylesFromTagsStr(text);
    let tagMatches = [...result.matchAll(re)];
    tagMatches.forEach((matchedTag) => {
      let reg = new RegExp(`\\${matchedTag[0]}`, "g");
      let a = tagValues[matchedTag[0]];
      if (a) {
        result = result.replace(reg, a);
      }
      else {
        result = result.replace(reg, '');
      }
    })
    tagMatches = [...result.matchAll(re)];
    if (tagMatches.length) {
      result = replaceTagsByValues(result, tagValues)
    }
    return result;
  } catch (err) {
    console.log(err)
  }
}

export const replaceOptionsTagsByValues = (text, tagValues) => {
  try {
    let re = /\|{(.*?)\}/g;
    let result = removeStylesFromTagsStr(text);
    const regex = /\|{optStart}(.*?)\|{optEnd}/s;
    const match = result.match(regex);

    // Extracted text
    var extractedText = match ? match[1] : null;
    if (extractedText) {
      let tagMatches = [...extractedText.matchAll(re)];
      tagMatches.forEach((matchedTag) => {
        let reg = new RegExp(`\\${matchedTag[0]}`, "g");
        let a = tagValues[matchedTag[0]];
        if (a) {
          extractedText = extractedText.replace(reg, a);
        }
        else {
          extractedText = extractedText.replace(reg, '');
        }
      })
      tagMatches = [...extractedText.matchAll(re)];
      if (tagMatches.length) {
        extractedText = replaceTagsByValues(extractedText, tagValues)
      }
      return extractedText;
    }
  } catch (err) {
    console.log(err)
  }

}


export const getFormattedRateInfo = (department, Total_Destinationfee_One_Day, Rate_cash_One_Day, Rate_card_One_Day, Truck_fee_One_Day, highLightItem, scrollRefs, styles) => {
  if (department && Object.keys(department).length > 0) {
    const isTotalDestinationFeeOneDayGreaterThanZero = Total_Destinationfee_One_Day > 0;
    const isTruckFeeCombined = department.truckFee.tfdfCombined;
    const isCalcMethodDeduct = department.rates && department.rates.calcMethod === 'deduct';
    const isTruckFeeOneDayNonZero = Truck_fee_One_Day !== 0;
    const formattedRate = isTotalDestinationFeeOneDayGreaterThanZero
      ? isTruckFeeCombined
        ? isCalcMethodDeduct
          ? <><span className={`${(highLightItem === 'rateCash') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCash']}>${Rate_cash_One_Day}h</span> / <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_One_Day}/h</span> </>
          : <span className={`${(highLightItem === 'rate') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rate']}>${Rate_card_One_Day}/h</span>
        : isCalcMethodDeduct
          ? <><span className={`${(highLightItem === 'rateCash') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCash']}>${Rate_cash_One_Day}h</span> / <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_One_Day}/h</span> ${isTruckFeeOneDayNonZero ? <>+ <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_One_Day}</span> truck(s) fee</> : ""}</>
          : <><span className={`${(highLightItem === 'rate') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rate']}>${Rate_card_One_Day}/h</span> {isTruckFeeOneDayNonZero ? <>+ <span className={`${(highLightItem === 'truckfee') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckfee']}>${Truck_fee_One_Day}</span> truck(s) fee</> : ""}</>
      : isCalcMethodDeduct
        ? <><span className={`${(highLightItem === 'rateCash') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCash']}>${Rate_cash_One_Day}h</span> / <span className={`${(highLightItem === 'rate') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rate']}>${Rate_card_One_Day}/h</span> {isTruckFeeOneDayNonZero ? <>+ <span className={`${(highLightItem === 'truckfee') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckfee']}>${Truck_fee_One_Day}</span> truck(s) fee</> : ""}</>
        : <><span className={`${(highLightItem === 'rate') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rate']}>${Rate_card_One_Day}/h</span> {isTruckFeeOneDayNonZero ? <>+ <span className={`${(highLightItem === 'truckfee') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckfee']}>${Truck_fee_One_Day}</span> truck(s) fee</> : ""}</>;

    const formattedRateTextOnly = isTotalDestinationFeeOneDayGreaterThanZero
      ? isTruckFeeCombined
        ? isCalcMethodDeduct
          ? `$${Rate_cash_One_Day}h / ${Rate_card_One_Day}/h`
          : `$${Rate_card_One_Day}/h`
        : isCalcMethodDeduct
          ? `$${Rate_cash_One_Day}h / $${Rate_card_One_Day}/h${isTruckFeeOneDayNonZero ? ` + ${Truck_fee_One_Day} truck(s) fee` : ""}`
          : `$${Rate_card_One_Day}/h${isTruckFeeOneDayNonZero ? ` + ${Truck_fee_One_Day} truck(s) fee` : ""}`
      : isCalcMethodDeduct
        ? `$${Rate_cash_One_Day}h / $${Rate_card_One_Day}/h${isTruckFeeOneDayNonZero ? ` + ${Truck_fee_One_Day} truck(s) fee` : ""}`
        : `$${Rate_card_One_Day}/h${isTruckFeeOneDayNonZero ? ` + ${Truck_fee_One_Day} truck(s) fee` : ""}`;


    const isRate_cash_One_DayShowed = isTotalDestinationFeeOneDayGreaterThanZero && isTruckFeeCombined && isCalcMethodDeduct;
    const isTruck_fee_One_DayShowed = Truck_fee_One_Day !== 0 && isTruckFeeCombined && !(Total_Destinationfee_One_Day > 0);
    return {
      formattedRate,
      formattedRateTextOnly,
      isRate_cash_One_DayShowed,
      isTruck_fee_One_DayShowed,
    };

  }
  else {
    return ''
  }

}

export const generateRandomColor = () => {
  // Define a maximum value that is less than 255 to avoid colors close to white.
  // For example, using 200 ensures no color component is too bright.
  const maxColorValue = 200; // This can be adjusted as needed.

  // Generate each color component with a maximum value of 200 instead of 255.
  const red = Math.floor(Math.random() * maxColorValue).toString(16);
  const green = Math.floor(Math.random() * maxColorValue).toString(16);
  const blue = Math.floor(Math.random() * maxColorValue).toString(16);

  // Ensure each component has two digits by padding with zero if necessary.
  const color = `#${red.padStart(2, '0')}${green.padStart(2, '0')}${blue.padStart(2, '0')}`;

  return color;
}

export const getFormattedRateLoadInfo = (department, Total_Destinationfee_One_Day, Rate_cash_Movers_To_Load_One_Day,
  Rate_card_Movers_To_Load_One_Day, Truck_fee_One_Day, highLightItem, scrollRefs, styles) => {
  const isTotalDestinationFeeOneDayGreaterThanZero = Total_Destinationfee_One_Day > 0;
  const isCalcMethodDeduct = department.rates && department.rates.calcMethod === 'deduct';

  const formattedRate = isTotalDestinationFeeOneDayGreaterThanZero
    ? isCalcMethodDeduct
      ? <><span className={`${(highLightItem === 'rateCashLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCashLoad']}>${Rate_cash_Movers_To_Load_One_Day}/h</span> / $ <span className={`${(highLightItem === 'rateCardLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardLoad']}>${Rate_card_Movers_To_Load_One_Day}/h</span> </>
      : <span className={`${(highLightItem === 'rateCardLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardLoad']}>${Rate_card_Movers_To_Load_One_Day}/h</span>
    : isCalcMethodDeduct
      ? <><span className={`${(highLightItem === 'rateCashLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCashLoad']}>${Rate_cash_Movers_To_Load_One_Day}/h</span> / <span className={`${(highLightItem === 'rateCardLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardLoad']}>${Rate_card_Movers_To_Load_One_Day}/h</span> + <span className={`${(highLightItem === 'truckfee') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckfee']}>${Truck_fee_One_Day}</span> truck fee</>
      : <><span className={`${(highLightItem === 'rateCardLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardLoad']}>${Rate_card_Movers_To_Load_One_Day}/h</span> + <span className={`${(highLightItem === 'truckfee') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckfee']}>${Truck_fee_One_Day}</span> truck fee</>;

  const formattedRateTextOnly = isTotalDestinationFeeOneDayGreaterThanZero
    ? isCalcMethodDeduct
      ? `$${Rate_cash_Movers_To_Load_One_Day}/h / $${Rate_card_Movers_To_Load_One_Day}/h`
      : `${Rate_card_Movers_To_Load_One_Day}/h`
    : isCalcMethodDeduct
      ? `$${Rate_cash_Movers_To_Load_One_Day}/h / $${Rate_card_Movers_To_Load_One_Day}/h + ${Truck_fee_One_Day} truck fee`
      : `$${Rate_card_Movers_To_Load_One_Day}/h + $${Truck_fee_One_Day} truck fee`;

  const isTruckFeeShowed = Truck_fee_One_Day !== 0 && !isTotalDestinationFeeOneDayGreaterThanZero;

  return {
    formattedRate,
    isTruckFeeShowed,
    formattedRateTextOnly,
    isRate_cash_Movers_To_Load_One_DayShowed: isCalcMethodDeduct
  };
}

export const getFormattedRateInfoTwoDays = (
  department,
  Total_Destinationfee_Day2,
  Rate_cash_Day1,
  Rate_card_Day1,
  Truck_fee_Day1,
  highLightItem, scrollRefs, styles
) => {
  if (department && Object.keys(department).length > 0) {
    const isTotalDestinationFeeOneDayGreaterThanZero = Total_Destinationfee_Day2 > 0;
    const isTruckFeeCombined = department.truckFee.tfdfCombined;
    const isCalcMethodDeduct = department.rates && department.rates.calcMethod === 'deduct';
    const isTruckFeeOneDayNonZero = Truck_fee_Day1 !== 0;

    const formattedRate = isTotalDestinationFeeOneDayGreaterThanZero
      ? isTruckFeeCombined
        ? isCalcMethodDeduct
          ? <><span className={`${(highLightItem === 'rateCashDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCashDay1']}>${Rate_cash_Day1}h</span> / <span className={`${(highLightItem === 'rateCardDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay1']}>${Rate_card_Day1}/h</span> </>
          : <span >${Rate_card_Day1}/h </span>
        : isCalcMethodDeduct
          ? <><span className={`${(highLightItem === 'rateCashDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCashDay1']}>${Rate_cash_Day1}h</span> / <span className={`${(highLightItem === 'rateCardDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay1']}>${Rate_card_Day1}/h</span> {isTruckFeeOneDayNonZero ? <>+ <span className={`${(highLightItem === 'truckFeeDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay1']}>${Truck_fee_Day1}</span> truck(s) fee</> : ""}</>
          : <><span className={`${(highLightItem === 'rateCardDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay1']}>${Rate_card_Day1}/h</span> {isTruckFeeOneDayNonZero ? <>+ <span className={`${(highLightItem === 'truckFeeDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay1']}>${Truck_fee_Day1}</span> truck(s) fee</> : ""}</>
      : isCalcMethodDeduct
        ? <><span className={`${(highLightItem === 'rateCashDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCashDay1']}>${Rate_cash_Day1}h</span> / <span className={`${(highLightItem === 'rateCardDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay1']}>${Rate_card_Day1}/h</span> {isTruckFeeOneDayNonZero ? <>+ <span className={`${(highLightItem === 'truckFeeDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay1']}>${Truck_fee_Day1}</span> truck(s) fee</> : ""}</>
        : <><span className={`${(highLightItem === 'rateCardDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay1']}>${Rate_card_Day1}/h</span> {isTruckFeeOneDayNonZero ? <>+ <span className={`${(highLightItem === 'truckFeeDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay1']}>${Truck_fee_Day1}</span> truck(s) fee</> : ""}</>;

    const formattedRateTextOnly = isTotalDestinationFeeOneDayGreaterThanZero
      ? isTruckFeeCombined
        ? isCalcMethodDeduct
          ? `$${Rate_cash_Day1}h / $${Rate_card_Day1}/h`
          : `$${Rate_card_Day1}/h`
        : isCalcMethodDeduct
          ? `$${Rate_cash_Day1}h / $${Rate_card_Day1}/h${isTruckFeeOneDayNonZero ? ` + $${Truck_fee_Day1} truck(s) fee` : ""}`
          : `$${Rate_card_Day1}/h${isTruckFeeOneDayNonZero ? ` + $${Truck_fee_Day1} truck(s) fee` : ""}`
      : isCalcMethodDeduct
        ? `$${Rate_cash_Day1}h / $${Rate_card_Day1}/h${isTruckFeeOneDayNonZero ? ` + $${Truck_fee_Day1} truck(s) fee` : ""}`
        : `$${Rate_card_Day1}/h${isTruckFeeOneDayNonZero ? ` + $${Truck_fee_Day1} truck(s) fee` : ""}`;


    const isRate_cash_Day1Showed =
      isTotalDestinationFeeOneDayGreaterThanZero &&
      isTruckFeeCombined &&
      isCalcMethodDeduct;
    const isTruck_fee_Day1Showed =
      Truck_fee_Day1 !== 0 &&
      isTruckFeeCombined &&
      !isTotalDestinationFeeOneDayGreaterThanZero;

    return {
      formattedRate,
      formattedRateTextOnly,
      isRate_cash_Day1Showed,
      isTruck_fee_Day1Showed,
    };
  } else {
    return '';
  }
};


export const getFormattedRateLoadInfoTwoDays = (
  department,
  Total_Destinationfee_Day2,
  Rate_cash_Movers_To_Load_Day2,
  Rate_card_Movers_To_Load_Day2,
  Truck_fee_Day2,
  highLightItem, scrollRefs, styles
) => {
  const isTotalDestinationFeeDay2GreaterThanZero = Total_Destinationfee_Day2 > 0;
  const isCalcMethodDeduct = department.rates && department.rates.calcMethod === 'deduct';

  const formattedRate = isTotalDestinationFeeDay2GreaterThanZero
    ? isCalcMethodDeduct
      ? <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_cash_Movers_To_Load_Day2}/h</span> / <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_Movers_To_Load_Day2}/h</span></>
      : <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_Movers_To_Load_Day2}/h</span>
    : isCalcMethodDeduct
      ? <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_cash_Movers_To_Load_Day2}/h</span> / <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_Movers_To_Load_Day2}/h</span> + <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_Day2}</span> truck(s) fee</>
      : <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_Movers_To_Load_Day2}/h</span> + <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_Day2}</span> truck(s) fee</>

  const formattedRateTextOnly = isTotalDestinationFeeDay2GreaterThanZero
    ? isCalcMethodDeduct
      ? `$${Rate_cash_Movers_To_Load_Day2}/h / $${Rate_card_Movers_To_Load_Day2}/h`
      : `$${Rate_card_Movers_To_Load_Day2}/h`
    : isCalcMethodDeduct
      ? `$${Rate_cash_Movers_To_Load_Day2}/h / $${Rate_card_Movers_To_Load_Day2}/h + $${Truck_fee_Day2} truck(s) fee`
      : `$${Rate_card_Movers_To_Load_Day2}/h + $${Truck_fee_Day2} truck(s) fee`;


  const isTruckFeeDay2Showed =
    Truck_fee_Day2 !== 0 &&
    !isTotalDestinationFeeDay2GreaterThanZero;

  return {
    formattedRate,
    formattedRateTextOnly,
    isTruckFeeDay2Showed,
    isRate_cash_Movers_To_Load_Day2Showed: isCalcMethodDeduct,
  };
};

export const getFormattedRateInfoTwoDaysDay2 = (
  department,
  Total_Destinationfee_Day2,
  Rate_cash_Day2,
  Rate_card_Day2,
  Truck_fee_Day2,
  highLightItem, scrollRefs, styles
) => {
  const isTotalDestinationFeeDay2GreaterThanZero = Total_Destinationfee_Day2 > 0;
  const isTruckFeeCombined = department && department.truckFee.tfdfCombined;
  const isCalcMethodDeduct = department && department.rates && department.rates.calcMethod === 'deduct';
  const formattedRateDay2 = isTotalDestinationFeeDay2GreaterThanZero
    ? isTruckFeeCombined
      ? isCalcMethodDeduct
        ? <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_cash_Day2}/h</span> / <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_Day2}/h</span></>
        : <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_Day2}/h</span>
      : isCalcMethodDeduct
        ? <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_cash_Day2}/h</span> / <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_Day2}/h</span> ${Truck_fee_Day2 !== 0 ? <>+ <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_Day2}</span> truck(s) fee</> : ""}</>
        : <>${Rate_card_Day2}/h ${Truck_fee_Day2 !== 0 ? <> + <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_Day2}</span> truck(s) fee</> : ""}</>
    : isCalcMethodDeduct
      ? <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_cash_Day2}/h</span> / <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_Day2}/h</span> + {Truck_fee_Day2 !== 0 ? <> + <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_Day2}</span> truck(s) fee</> : ""}</>
      : <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_Day2}/h</span>  {Truck_fee_Day2 !== 0 ? <> + <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_Day2}</span> truck(s) fee</> : ""}</>;

  const formattedRateDay2TextOnly = isTotalDestinationFeeDay2GreaterThanZero
    ? isTruckFeeCombined
      ? isCalcMethodDeduct
        ? `$${Rate_cash_Day2}/h / $${Rate_card_Day2}/h`
        : `$${Rate_card_Day2}/h`
      : isCalcMethodDeduct
        ? `$${Rate_cash_Day2}/h / $${Rate_card_Day2}/h${Truck_fee_Day2 !== 0 ? ` + $${Truck_fee_Day2} truck(s) fee` : ""}`
        : `$${Rate_card_Day2}/h${Truck_fee_Day2 !== 0 ? ` + $${Truck_fee_Day2} truck(s) fee` : ""}`
    : isCalcMethodDeduct
      ? `$${Rate_cash_Day2}/h / $${Rate_card_Day2}/h${Truck_fee_Day2 !== 0 ? ` + $${Truck_fee_Day2} truck(s) fee` : ""}`
      : `$${Rate_card_Day2}/h${Truck_fee_Day2 !== 0 ? ` + $${Truck_fee_Day2} truck(s) fee` : ""}`;

  const isRateCashDay2Showed =
    isTotalDestinationFeeDay2GreaterThanZero &&
    isTruckFeeCombined &&
    isCalcMethodDeduct;

  const isTruckFeeDay2Showed =
    Truck_fee_Day2 !== 0 &&
    isTruckFeeCombined &&
    !(Total_Destinationfee_Day2 > 0);

  return {
    formattedRateDay2,
    formattedRateDay2TextOnly,
    isRateCashDay2Showed,
    isTruckFeeDay2Showed,
  };
};

export const getFormattedRateInfoTwoDays150 = (
  department,
  Total_Destinationfee_150miles,
  Rate_cash_150miles_Day1,
  Rate_card_150miles_Day1,
  Truck_fee_150miles_Day1,
  highLightItem, scrollRefs, styles
) => {
  const isTotalDestinationFee150MilesGreaterThanZero = Total_Destinationfee_150miles > 0;
  const isTruckFeeCombined = department && department.truckFee.tfdfCombined;
  const isCalcMethodDeduct = department && department.rates && department.rates.calcMethod === 'deduct';

  const formattedRate150MilesDay1 = isTotalDestinationFee150MilesGreaterThanZero
    ? isTruckFeeCombined
      ? isCalcMethodDeduct
        ? <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_cash_150miles_Day1}/h</span> / <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_150miles_Day1}/h</span></>
        : <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_150miles_Day1}/h</span></>
      : isCalcMethodDeduct
        ? <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_cash_150miles_Day1}/h</span> / <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_150miles_Day1}/h</span>{Truck_fee_150miles_Day1 !== 0 ? <> + <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_150miles_Day1}</span> truck(s) fee</> : ""}</>
        : <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_150miles_Day1}/h</span>{Truck_fee_150miles_Day1 !== 0 ? <>+ $<span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_150miles_Day1}</span> truck(s) fee</> : ""}</>
    : isCalcMethodDeduct
      ? <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_cash_150miles_Day1}/h</span> / <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_150miles_Day1}/h</span>{Truck_fee_150miles_Day1 !== 0 ? <> + <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_150miles_Day1}</span> truck(s) fee</> : ""}</>
      : <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_150miles_Day1}/h</span> {Truck_fee_150miles_Day1 !== 0 ? <> + <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_150miles_Day1}</span> truck(s) fee</> : ""}</>;

  const isRateCash150MilesDay1Showed =
    isTotalDestinationFee150MilesGreaterThanZero &&
    isTruckFeeCombined &&
    isCalcMethodDeduct;

  const isTruckFee150MilesDay1Showed =
    Truck_fee_150miles_Day1 !== 0 &&
    isTruckFeeCombined &&
    !(Total_Destinationfee_150miles > 0);

  return {
    formattedRate150MilesDay1,
    isRateCash150MilesDay1Showed,
    isTruckFee150MilesDay1Showed,
  };
};

export const getFormattedRateInfoTwoDays150Day2 = (
  department,
  Total_Destinationfee_150miles,
  Rate_cash_150miles_Day2,
  Rate_card_150miles_Day2,
  Truck_fee_150miles_Day2,
  highLightItem, scrollRefs, styles
) => {
  const isTotalDestinationFee150MilesGreaterThanZero = Total_Destinationfee_150miles > 0;
  const isTruckFeeCombined = department && department.truckFee.tfdfCombined;
  const isCalcMethodDeduct = department && department.rates && department.rates.calcMethod === 'deduct';

  const formattedRate150MilesDay2 = isTotalDestinationFee150MilesGreaterThanZero
    ? isTruckFeeCombined
      ? isCalcMethodDeduct
        ? <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_cash_150miles_Day2}/h</span> / <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_150miles_Day2}/h</span></>
        : <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_150miles_Day2}/h</span>
      : isCalcMethodDeduct
        ? <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_cash_150miles_Day2}/h</span> / <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_150miles_Day2}/h</span> {Truck_fee_150miles_Day2 !== 0 ? <> + <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_150miles_Day2}</span> truck(s) fee</> : ""}</>
        : <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_150miles_Day2}/h</span> {Truck_fee_150miles_Day2 !== 0 ? <> + <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_150miles_Day2}</span> truck(s) fee</> : ""}</>
    : isCalcMethodDeduct
      ? <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_cash_150miles_Day2}/h</span> / <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_150miles_Day2}/h</span> {Truck_fee_150miles_Day2 !== 0 ? <> + <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_150miles_Day2}</span> truck(s) fee</> : ""}</>
      : <><span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Rate_card_150miles_Day2}/h</span> {Truck_fee_150miles_Day2 !== 0 ? <> + <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>${Truck_fee_150miles_Day2}</span> truck(s) fee</> : ""}</>;

  const isRateCashDay2Showed =
    isTotalDestinationFee150MilesGreaterThanZero &&
    isTruckFeeCombined &&
    isCalcMethodDeduct;

  const isTruckFeeDay2Showed =
    Truck_fee_150miles_Day2 !== 0 &&
    isTruckFeeCombined &&
    !(Total_Destinationfee_150miles > 0);

  return {
    formattedRate150MilesDay2,
    isRateCashDay2Showed,
    isTruckFeeDay2Showed,
  };
};
