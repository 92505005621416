import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { SocketContext } from '../../context/socket';
import useCollapse from 'react-collapsed'

import { getCheckMarkLabelById, getCurrentRatePercent, getHigherNumberOfHours, getLowerNumberOfHours, getTagsOptions, hasRole, replaceSpaces } from '../../util/utils';

// Styles
import styles from "./TemplateThreeDays150Params.module.css";
//Components
import { TemplateSeparateDayView } from '../template-separate-day-view/Template-separate-day-view';
import { useDispatch, useSelector } from 'react-redux';
import { getUserName, getUserRole } from '../../clientStore/authSlice/auth-slice';

import { CalculationTabTwoDays150Values } from '../calculation-tab-two-days-150-values/Calculation-tab-two-days-150-values';
import { TemplatesExtraOptionsResults } from '../templates-extra-options-results/Templates-extra-options-results';
import { setTotalFragileBoxes, setTotalMediumBoxes, setTotalSmallBoxes } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { CalculationThreeDays150Params } from '../calculation-three-days-150-params/Calculation-three-days-150-params';
import { Role } from '../../util/const';
import { CalculationTabThreeDays150Values } from '../calculation-tab-three-days-150-values/Calculation-tab-three-days-150-values';

export const TemplateThreeDays150Params = ({
  highLightItem,
  company,
  department,
  templateForm,
  rates,
  onCalcFormValueChange,
  resetLastTarget,
  setOptions,
  users,
  departments,
  checkMarkCheckboxValueChange,
  setLastTarget,
  additionalServicesCheckboxValueChange,
  lastTarget,
  setCalculationFormValue
}) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const userName = useSelector(getUserName);
  const scrollRefs = useRef([]);
  const currentUserRole = useSelector(getUserRole);
  const calculationForm = templateForm.templateCalcObj;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const scrollKeys = ['inventoryText','username', 'department', 'calculationNumber', 'day1weekday', 'day1month', 'day1date', 'day1year', 'day2weekday',
    'day2month', 'day2date', 'day2year', 'day3weekday', 'day3month', 'day3date', 'day3year', 'totalCubicFt', 'cuftWithinBuilding',
    'cuftToMove', 'additionalAmount', 'day1RoundTripMiles', 'totalRoundTripMiles', 'unpackingDayRoundTripMiles', 'combinedMileage', 'additionalStopsCount',
    'moversDay1', 'truckFeeDay1', 'rateCashDay1', 'rateCardDay1', 'trucksDay1', 'shuttleDay1', 'shuttleQtyDay1', 'cubicFtToMoveDay1', 'doubleDrive',
    'lowerNumberOfHoursDay1', 'higherNumberOfHoursDay1', 'packingKit', 'packingKitWithTax',
    'sameDayUnpack', 'partial', 'packingCharge', 'packingType', 'smallBox', 'mediumBox', 'largeBox', 'rollOfPaper', 'rollOfBubble', 'perBoxPrice',
    'moversDay2', 'rateCashDay2', 'rateCardDay2', 'additionalServices', 'trucksDay2', 'shuttleDay2', 'shuttleQtyDay2', 'doubleDriveDay2',
    'lowerNumberOfHoursDay2', 'higherNumberOfHoursDay2', 'moversDay3', 'rateCashDay3', 'rateCardDay3', 'trucksDay3', 'shuttleDay3', 'shuttleQtyDay3',
    'lowerNumberOfHoursDay3', 'higherNumberOfHoursDay3', 'heavyFee', 'liftGate', 'hardwoodFloorCovers', 'carpetFloorCovers', 'doubleDriveDay3', 'biggestOrOk',
    'twoTripsOrOkDay3', 'destinationFeeDay3', 'totalCash3Days150miles', 'totalCard3Days150miles', 'cashDiscountPercent'
  ];

  if (company) {
    if (company.extraOptCharges) {
      company.extraOptCharges.forEach((charge, index) => {

        scrollKeys.push(`chIdName1_${charge[`uId${index}`]}`)
        scrollKeys.push(`chIdQty1_${charge[`uId${index}`]}`)
        scrollKeys.push(`chIdAmount1_${charge[`uId${index}`]}`)

        scrollKeys.push(`chIdName2_${charge[`uId${index}`]}`)
        scrollKeys.push(`chIdQty2_${charge[`uId${index}`]}`)
        scrollKeys.push(`chIdAmount2_${charge[`uId${index}`]}`)

        scrollKeys.push(`chIdName3_${charge[`uId${index}`]}`)
        scrollKeys.push(`chIdQty3_${charge[`uId${index}`]}`)
        scrollKeys.push(`chIdAmount3_${charge[`uId${index}`]}`)
      })
    }
    if (company.additProcedures) {
      company.additProcedures.forEach((procedure, index) => {

        scrollKeys.push(`pIdName1_${procedure[`uId${index}`]}`)
        scrollKeys.push(`pIdCost1_${procedure[`uId${index}`]}`)
        scrollKeys.push(`pIdQty1_${procedure[`uId${index}`]}`)

        scrollKeys.push(`pIdName2_${procedure[`uId${index}`]}`)
        scrollKeys.push(`pIdCost2_${procedure[`uId${index}`]}`)
        scrollKeys.push(`pIdQty2_${procedure[`uId${index}`]}`)

        scrollKeys.push(`pIdName3_${procedure[`uId${index}`]}`)
        scrollKeys.push(`pIdCost3_${procedure[`uId${index}`]}`)
        scrollKeys.push(`pIdQty3_${procedure[`uId${index}`]}`)
      })

    }
    if (company.miscCheckMarks) {
      company.miscCheckMarks.forEach((checkMark, index) => {
        scrollKeys.push(`mChId_${index}`)
      })
    }
  }
  scrollKeys.forEach(element => {
    scrollRefs.current[element] = React.createRef()
  });

  const commonValues = calculationForm.commonValues;
  const threeDays150Miles = calculationForm.threeDays150Miles;


  useEffect(() => {

    if (highLightItem) {
      try {
        scrollRefs.current[highLightItem].current.scrollIntoView({ block: 'nearest' });
      }
      catch {

      }
    }
  }, [highLightItem]);

  useEffect(() => {
    let currentOptions = getTagsOptions({
      company: company, templateCalcType: templateForm.templateCalcType, users: users, departments: departments, calculation: templateForm.templateCalcObj,
      calculatedParams: {}
    });

    setOptions(currentOptions);
  }, [templateForm.tags, templateForm.templateCalcObj]);

  const sepCheckboxValueChange = (evt) => {
    resetLastTarget();

    switch (evt.target.value) {
      case 'true':
        return onCalcFormValueChange(`commonValues.${evt.target.name}`, 'set', false);
      case 'false':
        return onCalcFormValueChange(`commonValues.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };

  const isCuFtDepartment = department?.type === 'cuft';

  return (
    <div className={styles.parentCont}>
      {(calculationForm && Object.keys(calculationForm).length) ?
        <CalculationTabThreeDays150Values
          rates={(rates.length > 0) ? rates : (calculationForm.department && calculationForm.department.rates) ? calculationForm.department.rates.ratesList : []}
          formData={calculationForm}
          formChange={onCalcFormValueChange}
          lastTarget={lastTarget}
          setLastTarget={setLastTarget}
          resetLastTarget={resetLastTarget}
          sepCheckboxValueChange={sepCheckboxValueChange}
          /*      validationError={validationError}
               setValidationError={setValidationError} */
          checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
          additionalServicesCheckboxValueChange={additionalServicesCheckboxValueChange}
          setCalculationFormValue={setCalculationFormValue}
          disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
        />
        : ""}

      <div>
        <h3 className={`${styles.title} ${(highLightItem === 'username') ? styles.highLighted : ""}`} ref={scrollRefs?.current['username']}>{userName}</h3>
        <h3 className={`${styles.title}`}><span className={`${(highLightItem === 'calculationNumber') ? styles.highLighted : ""}`} ref={scrollRefs?.current['calculationNumber']}>{calculationForm.calculationNumber} </span><span className={`${(highLightItem === 'department') ? styles.highLighted : ""}`} ref={scrollRefs?.current['department']}>{calculationForm && calculationForm.department && calculationForm.department.departmentName}</span>{/*  <span className={`${(highLightItem === 'rate') ? styles.highLighted : ""}`}>XP</span> */}</h3>
        <div className={`${styles.title}`}>
          <p className={styles.calculationDate}>

            {(calculationForm && Object.keys(calculationForm).length && calculationForm.threeDays150Miles.firstDayDate) ? <>
              <span className={`${(highLightItem === 'day1weekday') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1weekday']}>{new Date(calculationForm.threeDays150Miles.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })}, </span>
              <span className={`${(highLightItem === 'day1month') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1month']}>{new Date(calculationForm.threeDays150Miles.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day1date') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1date']}>{new Date(calculationForm.threeDays150Miles.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day1year') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1year']}>{new Date(calculationForm.threeDays150Miles.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })}</span>
            </> : ""}
            {calculationForm && Object.keys(calculationForm).length && threeDays150Miles.secondDayDate && threeDays150Miles.firstDayDate && ' &  '}
            {(calculationForm && Object.keys(calculationForm).length && calculationForm.threeDays150Miles.secondDayDate) ? <>
              <span className={`${(highLightItem === 'day2weekday') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2weekday']}>{new Date(calculationForm.threeDays150Miles.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })}, </span>
              <span className={`${(highLightItem === 'day2month') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2month']}>{new Date(calculationForm.threeDays150Miles.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day2date') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2date']}>{new Date(calculationForm.threeDays150Miles.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day2year') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2year']}>{new Date(calculationForm.threeDays150Miles.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })}</span>
            </> : ""}
            {calculationForm && Object.keys(calculationForm).length && threeDays150Miles.thirdDayDate && threeDays150Miles.secondDayDate && ' &  '}
            {(calculationForm && Object.keys(calculationForm).length && calculationForm.threeDays150Miles.thirdDayDate) ? <>
              <span className={`${(highLightItem === 'day3weekday') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day3weekday']}>{new Date(calculationForm.threeDays150Miles.thirdDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })}, </span>
              <span className={`${(highLightItem === 'day3month') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day3month']}>{new Date(calculationForm.threeDays150Miles.thirdDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day3date') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day3date']}>{new Date(calculationForm.threeDays150Miles.thirdDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day3year') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day3year']}>{new Date(calculationForm.threeDays150Miles.thirdDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })}</span>
            </> : ""}

          </p>
        </div>

        <CalculationThreeDays150Params
          commonValues={commonValues}
          threeDays150Miles={threeDays150Miles}
          department={department}
          selectedMiscCheckMarks={calculationForm.selectedMiscCheckMarks}
          calculationData={calculationForm}
          scrollRefs={scrollRefs}
          highLightItem={highLightItem}
        />

      </div>
    </div >
  );
};

