import React, { useState, useContext, useCallback, useEffect } from 'react';
import { SocketContext } from '../../context/socket';
import useCollapse from 'react-collapsed'
// Calc util functions

import { getCurrentRatePercent, getHigherNumberOfHours, getLowerNumberOfHours, getTotalTimeRange } from '../../util/utils';
// Styles
import styles from "./CalculationSeparateDayView.module.css";

export const CalculationSeparateDayView = ({
  commonValues,
  anyCalculationType,
  department,
  Trucks_Unpacking_Separate_Day,
  Movers_Unpacking_Separate_Day,
  Unpacking_Separate_Day_Lower_hours,
  Unpacking_Separate_Day_Higher_hours,
  Total_Card_Unpacking_Separate_Day,
  Total_Cash_Unpacking_Separate_Day,
  Rate_cash_Unpacking_Separate_Day,
  Rate_card_Unpacking_Separate_Day,
  Truck_fee_Unpacking_Separate_Day,
  Flat_rate_Unpacking_Separate_Day,
  Flat_rate_Unpacking_Separate_Day_cash,
  isCuFtDepartment,
  UnPacking_Charge,
  scrollRefs,
  highLightItem
}) => {

  return (

    <ul className={styles.paramsSection} >
      {commonValues.unpacking === 'Separate Day' && <>
        <h2 className={styles.title}>Unpacking on separate day <span>{commonValues.partialUnPacking && "PARTIAL"}</span></h2>
        {(true) ? '' : "Please Fill Out Unpacking Fields"}
        <div className={styles.selectedInfo}>
          {department && <p className={styles.departmentName}>{department.departmentName}</p>}
          {commonValues.unpackingRate && <p className={styles.rateName}><span>{commonValues.unpackingRate}</span></p>}
        </div>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Movers:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            <span className={`${(highLightItem === 'moversUnpackingSeparateDay') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversUnpackingSeparateDay']}>{Movers_Unpacking_Separate_Day}</span>
          </p>
        </li>
        {!isCuFtDepartment &&
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Rate:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {
                (department.rates && department.rates.calcMethod === 'deduct')
                  ? <> <span className={`${(highLightItem === 'rateCashUnpackingSeparateDay') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCashUnpackingSeparateDay']}>${Rate_cash_Unpacking_Separate_Day}h</span> / <span className={`${(highLightItem === 'rateCardUnpackingSeparateDay') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardUnpackingSeparateDay']}>${Rate_card_Unpacking_Separate_Day}h</span>
                    {(Truck_fee_Unpacking_Separate_Day !== 0) ? <>+ <span className={`${(highLightItem === 'truckFeeUnpackingSeparateDay') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeUnpackingSeparateDay']}>${Truck_fee_Unpacking_Separate_Day}</span> truck(s) fee</> : ""}
                  </>
                  : <><span className={`${(highLightItem === 'rateCardUnpackingSeparateDay') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardUnpackingSeparateDay']}>${Rate_card_Unpacking_Separate_Day}/h</span>{(Truck_fee_Unpacking_Separate_Day !== 0) ? <>+ <span className={`${(highLightItem === 'truckFeeUnpackingSeparateDay') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeUnpackingSeparateDay']}>${Truck_fee_Unpacking_Separate_Day}</span> truck(s) fee</> : ""}</>
              }
            </p>
          </li>
        }
        {(isCuFtDepartment && Truck_fee_Unpacking_Separate_Day) ?
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Truck Fee:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === 'truckFeeUnpackingSeparateDay') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeUnpackingSeparateDay']}>{Truck_fee_Unpacking_Separate_Day}</span>
            </p>
          </li>
          : ''
        }

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Trucks:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            <span>{Trucks_Unpacking_Separate_Day}</span>
            {(commonValues.shuttleOnUnpacking) && <>+ <span className={`${(highLightItem === 'unpackingShuttle') ? styles.highLighted : ""}`} ref={scrollRefs?.current['unpackingShuttle']}>SHUTTLE</span> {((department.extraOptions.shuttle.isCountable) ? <span className={`${(highLightItem === 'unpackingShuttleQty') ? styles.highLighted : ""}`} ref={scrollRefs?.current['unpackingShuttleQty']}>(x{commonValues.quantityUnpacking})</span> : '')}</>}
          </p>
        </li>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total time range:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            <span className={`${(highLightItem === 'unpackingSeparateDayLowerhours') ? styles.highLighted : ""}`} ref={scrollRefs?.current['unpackingSeparateDayLowerhours']}>{getLowerNumberOfHours(department.minimumHours, Unpacking_Separate_Day_Lower_hours)}</span>
            <span className={`${(highLightItem === 'unpackingSeparateDayHigherhours') ? styles.highLighted : ""}`} ref={scrollRefs?.current['unpackingSeparateDayHigherhours']}>{getHigherNumberOfHours(department.minimumHours, Unpacking_Separate_Day_Higher_hours)}</span>
          </p>
        </li>


        {(isCuFtDepartment && commonValues.unpacking === 'Same Day') && UnPacking_Charge &&
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>UnPacking Charge:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === 'unPackingCharge') ? styles.highLighted : ""}`} ref={scrollRefs?.current['unPackingCharge']}>${UnPacking_Charge}</span>
            </p>
          </li>
        }

        {(commonValues.additionalAmountUnpacking) ? (
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Additional amount:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === 'additionalAmountUnpacking') ? styles.highLighted : ""}`} ref={scrollRefs?.current['additionalAmountUnpacking']}>${commonValues.additionalAmountUnpacking}</span>
            </p>
          </li>) : ''
        }


        {!isCuFtDepartment && <li className={styles.paramsItem}>
          <p className={styles.paramsName}>NOT TO EXCEED PRICE:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>$
            {(department.rates && department.rates.calcMethod === 'deduct') ?
              <><span className={`${(highLightItem === 'totalCashUnpackingSeparateDay') ? styles.highLighted : ""}`} ref={scrollRefs?.current['totalCashUnpackingSeparateDay']}>{Total_Cash_Unpacking_Separate_Day}</span> cash / <span className={`${(highLightItem === 'totalCardUnpackingSeparateDay') ? styles.highLighted : ""}`} ref={scrollRefs?.current['totalCardUnpackingSeparateDay']}>${Total_Card_Unpacking_Separate_Day}</span> card</>
              :
              <>
                <span className={`${(highLightItem === 'totalCardUnpackingSeparateDay') ? styles.highLighted : ""}`} ref={scrollRefs?.current['totalCardUnpackingSeparateDay']}>{Total_Card_Unpacking_Separate_Day}</span>
              </>}
          </p>
        </li>
        }

        {(!isCuFtDepartment && department.rates && department.rates.calcMethod !== 'deduct') ?
          <div style={{ paddingLeft: '30px' }}>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>NOT TO EXCEED PRICE with <span className={`${(highLightItem === 'unpackingSeparatePercent') ? styles.highLighted : ""}`} ref={scrollRefs?.current['unpackingSeparatePercent']}>{getCurrentRatePercent(anyCalculationType, department)}%</span> cash discount:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'totalCashUnpackingSeparateDay') ? styles.highLighted : ""}`} ref={scrollRefs?.current['totalCashUnpackingSeparateDay']}>${Total_Cash_Unpacking_Separate_Day}</span>
              </p>
            </li>
          </div>
          : ""}


        {isCuFtDepartment && <>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>FLAT RATE:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>$
              {(department.rates && department.rates.calcMethod === 'deduct') ?
                <>
                  <span className={`${(highLightItem === 'flatRateUnpackingSeparateDayCash') ? styles.highLighted : ""}`} ref={scrollRefs?.current['flatRateUnpackingSeparateDayCash']}>${Flat_rate_Unpacking_Separate_Day_cash}</span> cash / <span className={`${(highLightItem === 'flatRateUnpackingSeparateDayCard') ? styles.highLighted : ""}`} ref={scrollRefs?.current['flatRateUnpackingSeparateDayCard']}>${Flat_rate_Unpacking_Separate_Day}</span> card
                </>
                :
                <>
                  <span className={`${(highLightItem === 'flatRateUnpackingSeparateDayCard') ? styles.highLighted : ""}`} ref={scrollRefs?.current['flatRateUnpackingSeparateDayCard']}>{Flat_rate_Unpacking_Separate_Day}</span>
                </>}
            </p>
          </li>
          {(department.rates && department.rates.calcMethod !== 'deduct') ?
            <div style={{ paddingLeft: '30px' }}>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>FLAT RATE with <span className={`${(highLightItem === 'unpackingSeparatePercent') ? styles.highLighted : ""}`} ref={scrollRefs?.current['unpackingSeparatePercent']}>{getCurrentRatePercent(anyCalculationType, department)}%</span> cash discount:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === 'flatRateUnpackingSeparateDayCash') ? styles.highLighted : ""}`} ref={scrollRefs?.current['flatRateUnpackingSeparateDayCash']}>${Flat_rate_Unpacking_Separate_Day_cash}</span>
                </p>
              </li>
            </div>
            : ""}
        </>}
      </>}

    </ul>

  );
};
