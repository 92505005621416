import React, { useState, useContext, useCallback, useEffect } from 'react';
import useCollapse from 'react-collapsed'
import { SocketContext } from '../../context/socket';
// Calc util functions
import { generateNotIncludedServicesText, generateUnpackingText, getAdditionalServicesResText, getChargesResText, getCheckMarkLabelById, getCurrentRatePercent, getFormattedRateInfoTwoDays, getFormattedRateInfoTwoDaysDay2, getFormattedRateLoadInfoTwoDays, getFurnitureText, getHigherNumberOfHours, getLowerNumberOfHours, getTagsValues, getTotalTimeRange, replaceOptionsTagsByValues, replaceTagsByValues } from '../../util/utils';
// Styles
import styles from "./CalculationThreeDays150Params.module.css";
//Components
import { CalculationSeparateDayView } from '../calculation-separate-day-view/Calculation-separate-day-view';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import CopyIcon from '../results-copy-icon/Results-copy-icon';
import { ExtraOptionsResults } from '../extra-options-results/Extra-options-results';
import { AdditionalServicesResults } from '../additional-services-results/Additional-services-results';
import { setTWOTripsOrOK_Day2, setTotalFragileBoxes, setTotalMediumBoxes, setTotalSmallBoxes, setTotal_cubesG } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { useDispatch } from 'react-redux';
import { AdditionalServicesEstimateInfo } from '../additional-services-estimate-info/Additional-services-estimate-info';
import TruckloadsView from '../truckloads/TruckloadsView';
import PackingSuppliesOnTheGo from '../packingSuppliesOnTheGo/Packing-supplies-on-the-go';
import ProceduresResults from '../procedures-results/Procedures-results';

export const CalculationThreeDays150Params = ({
  commonValues,
  department,
  threeDays150Miles,
  selectedMiscCheckMarks,
  checkboxValueChange,
  editorState,
  calculationData,
  tags,
  setPreviewText, setOptionsPreviewText,
  popUpShown,
  setShowGenerateEmail, furniture, roomsAreas,
  scrollRefs,
  highLightItem, setTagsValuesDefault, customTagsValues, showCustomTags
}) => {
  const dispatch = useDispatch();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const socket = useContext(SocketContext);
  const [showCopyIcon, setShowCopyIcon] = useState(false);
  const isCuFtDepartment = department?.type === 'cuft';
  const [Total_small_boxes, setTotal_small_boxes] = useState(0);
  const [Total_medium_boxes, setTotal_medium_boxes] = useState(0)
  const [Total_fragile_boxes, setTotal_fragile_boxes] = useState(0)
  const [Total_box_cubes, setTotal_box_cubes] = useState(0)
  const [Time_to_pack, setTime_to_pack] = useState(0)
  const [Stop_1_Truckload, setStop_1_Truckload] = useState(0)
  const [Stop_CN_Truckload, setStop_CN_Truckload] = useState(0)
  const [Loading_hours_3days_150_Day1, setLoading_hours_3days_150_Day1] = useState(0)
  const [Driving_Time_3days_150_Day1, setDriving_Time_3days_150_Day1] = useState(0)
  const [Unloading_hours_3days_150_Day1, setUnloading_hours_3days_150_Day1] = useState(0)
  const [Packing_Time_3days_150_Day1, setPacking_Time_3days_150_Day1] = useState(0)
  const [Wrapping_time_3days_150_Day1, setWrapping_time_3days_150_Day1] = useState(0)
  const [Total_Hours_3days_150_Day1, setTotal_Hours_3days_150_Day1] = useState(0)
  const [Total_Cash_3days_150_Day1, setTotal_Cash_3days_150_Day1] = useState(0)
  const [Total_Card_3days_150_Day1, setTotal_Card_3days_150_Day1] = useState(0)
  const [Loading_hours_3days_150_Day2, setLoading_hours_3days_150_Day2] = useState(0)
  const [Driving_time_with_DDT_3days_150_Day2, setDriving_time_with_DDT_3days_150_Day2] = useState(0)
  const [Total_Hours_3days_150_Day2, setTotal_Hours_3days_150_Day2] = useState(0)
  const [Total_Cash_3days_150_Day2, setTotal_Cash_3days_150_Day2] = useState(0)
  const [Total_Card_3days_150_Day2, setTotal_Card_3days_150_Day2] = useState(0)
  const [Unloading_hours_3days_150_Day3, setUnloading_hours_3days_150_Day3] = useState(0)
  const [Driving_time_with_DDT_3days_150_Day3, setDriving_time_with_DDT_3days_150_Day3] = useState(0)
  const [Total_Hours_3days_150_Day3, setTotal_Hours_3days_150_Day3] = useState(0)
  const [Total_Cash_3days_150_Day3, setTotal_Cash_3days_150_Day3] = useState(0)
  const [Total_Card_3days_150_Day3, setTotal_Card_3days_150_Day3] = useState(0)
  const [Movers_To_Pack_3days_150_Day1, setMovers_To_Pack_3days_150_Day1] = useState(0)
  const [Total_Destinationfee_3days_150, setTotal_Destinationfee_3days_150] = useState(0)
  const [Rate_cash_3days_150_Day1, setRate_cash_3days_150_Day1] = useState(0)
  const [Rate_card_3days_150_Day1, setRate_card_3days_150_Day1] = useState(0)
  const [Truck_fee_3days_150_Day1, setTruck_fee_3days_150_Day1] = useState(0)
  const [Trucks_To_Pack_3days_150_Day1, setTrucks_To_Pack_3days_150_Day1] = useState(0)
  const [cubicf_3days_150_Day1, setCubicf_3days_150_Day1] = useState(0)
  const [Double_drive, setDouble_drive] = useState(0)
  const [Lower_Number_of_Hours_3days_150_Day1, setLower_Number_of_Hours_3days_150_Day1] = useState(0)
  const [Higher_Number_of_Hours_3days_150_Day1, setHigher_Number_of_Hours_3days_150_Day1] = useState(0)
  const [Packing_kit_Show, setPacking_kit_Show] = useState(0);
  const [Packing_kit_total_amount_with_taxes, setPacking_kit_total_amount_with_taxes] = useState(0);
  const [Packing_Charge, setPacking_Charge] = useState(0);
  const [Total_cubes, setTotal_cubes] = useState(0);
  const [Total_cubes_Within_Building, setTotal_cubes_Within_Building] = useState(0);
  const [Truckloads, setTruckloads] = useState(0);
  const [Combined_mileage, setCombined_mileage] = useState(0);
  const [Truckloads_to_move, setTruckloads_to_move] = useState(0);
  const [Round_trip_miles, setRound_trip_miles] = useState(0);
  const [Movers_3days_150_Day2, setMovers_3days_150_Day2] = useState(0);
  const [Total_Destinationfee_3days_150_Day2, setTotal_Destinationfee_3days_150_Day2] = useState(0);
  const [Rate_card_3days_150_Day2, setRate_card_3days_150_Day2] = useState(0);
  const [Rate_cash_3days_150_Day2, setRate_cash_3days_150_Day2] = useState(0);
  const [Truck_fee_3days_150_Day2, setTruck_fee_3days_150_Day2] = useState(0);
  const [Trucks_3days_150_Day2, setTrucks_3days_150_Day2] = useState(0);
  const [Double_drive_3days_150_Day2, setDouble_drive_3days_150_Day2] = useState(0);
  const [Lower_Number_of_Hours_3days_150_Day2, setLower_Number_of_Hours_3days_150_Day2] = useState(0);
  const [Higher_Number_of_Hours_3days_150_Day2, setHigher_Number_of_Hours_3days_150_Day2] = useState(0);
  const [Movers_3days_150_Day3, setMovers_3days_150_Day3] = useState(0);
  const [Rate_cash_3days_150_Day3, setRate_cash_3days_150_Day3] = useState(0);
  const [Rate_card_3days_150_Day3, setRate_card_3days_150_Day3] = useState(0);
  const [Truck_fee_3days_150_Day3, setTruck_fee_3days_150_Day3] = useState(0);
  const [Trucks_3days_150_Day3, setTrucks_3days_150_Day3] = useState(0);
  const [BIGGEST_or_OK, setBIGGEST_or_OK] = useState(0);
  const [TWO_Trips_Or_OK_3days_150_Day3, setTWO_Trips_Or_OK_3days_150_Day3] = useState(0);
  const [Double_drive_3days_150_Day3, setDouble_drive_3days_150_Day3] = useState(0);
  const [Destination_fee_3days_150_Day3, setDestination_fee_3days_150_Day3] = useState(0);
  const [Lower_Number_of_Hours_3days_150_Day3, setLower_Number_of_Hours_3days_150_Day3] = useState(0);
  const [Higher_Number_of_Hours_3days_150_Day3, setHigher_Number_of_Hours_3days_150_Day3] = useState(0);
  const [Heavy_fee, setHeavy_fee] = useState(0);
  const [Flat_rate_Cash_3days_150_Day3, setFlat_rate_Cash_3days_150_Day3] = useState(0);
  const [Flat_rate_Card_3days_150_Day3, setFlat_rate_Card_3days_150_Day3] = useState(0);
  const [Trucks_Unpacking_Separate_Day, setTrucks_Unpacking_Separate_Day] = useState(0);
  const [Movers_Unpacking_Separate_Day, setMovers_Unpacking_Separate_Day] = useState(0);
  const [Unpacking_Separate_Day_Lower_hours, setUnpacking_Separate_Day_Lower_hours] = useState(0);
  const [Unpacking_Separate_Day_Higher_hours, setUnpacking_Separate_Day_Higher_hours] = useState(0);
  const [Total_Card_Unpacking_Separate_Day, setTotal_Card_Unpacking_Separate_Day] = useState(0);
  const [Total_Cash_Unpacking_Separate_Day, setTotal_Cash_Unpacking_Separate_Day] = useState(0);
  const [Rate_cash_Unpacking_Separate_Day, setRate_cash_Unpacking_Separate_Day] = useState(0);
  const [Rate_card_Unpacking_Separate_Day, setRate_card_Unpacking_Separate_Day] = useState(0);
  const [Truck_fee_Unpacking_Separate_Day, setTruck_fee_Unpacking_Separate_Day] = useState(0);
  const [Flat_rate_Unpacking_Separate_Day, setFlat_rate_Unpacking_Separate_Day] = useState(0);
  const [Flat_rate_Unpacking_Separate_Day_cash, setFlat_rate_Unpacking_Separate_Day_cash] = useState(0);
  const [Total_Card_threeDays150Miles, setTotal_Card_threeDays150Miles] = useState(0);
  const [Total_Cash_threeDays150Miles, setTotal_Cash_threeDays150Miles] = useState(0);
  const [UnPacking_Charge, setUnPacking_Charge] = useState(0);

  useEffect(() => {
    socket.emit('getFormulasValues', { calculationType: 'threeDays150Miles', commonValues: commonValues, threeDays150Miles: threeDays150Miles, department: department })

    socket.on('Total_Cash_threeDays150Miles', (data) => { setTotal_Cash_threeDays150Miles(data) });
    socket.on('Total_Card_threeDays150Miles', (data) => { setTotal_Card_threeDays150Miles(data) });
    socket.on('Trucks_3days_150_Day3', (data) => { setTrucks_3days_150_Day3(data) });
    socket.on('UnPacking_Charge', (data) => { setUnPacking_Charge(data) });
    socket.on('Flat_rate_Unpacking_Separate_Day_cash', (data) => { setFlat_rate_Unpacking_Separate_Day_cash(data) });
    socket.on('Flat_rate_Unpacking_Separate_Day', (data) => { setFlat_rate_Unpacking_Separate_Day(data) });
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => { setTruck_fee_Unpacking_Separate_Day(data) });
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => { setRate_card_Unpacking_Separate_Day(data) });
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => { setRate_cash_Unpacking_Separate_Day(data) });
    socket.on('Total_Cash_Unpacking_Separate_Day', (data) => { setTotal_Cash_Unpacking_Separate_Day(data) });
    socket.on('Total_Card_Unpacking_Separate_Day', (data) => { setTotal_Card_Unpacking_Separate_Day(data) });
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => { setUnpacking_Separate_Day_Higher_hours(data) });
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => { setUnpacking_Separate_Day_Lower_hours(data) });
    socket.on('Movers_Unpacking_Separate_Day', (data) => { setMovers_Unpacking_Separate_Day(data) });
    socket.on('Trucks_Unpacking_Separate_Day', (data) => { setTrucks_Unpacking_Separate_Day(data) });
    socket.on('Flat_rate_Card_3days_150_Day3', (data) => { setFlat_rate_Card_3days_150_Day3(data) });
    socket.on('Flat_rate_Cash_3days_150_Day3', (data) => { setFlat_rate_Cash_3days_150_Day3(data) });
    socket.on('Higher_Number_of_Hours_3days_150_Day3', (data) => { setHigher_Number_of_Hours_3days_150_Day3(data) });
    socket.on('Heavy_fee', (data) => { setHeavy_fee(data) });
    socket.on('Lower_Number_of_Hours_3days_150_Day3', (data) => { setLower_Number_of_Hours_3days_150_Day3(data) });
    socket.on('Destination_fee_3days_150_Day3', (data) => { setDestination_fee_3days_150_Day3(data) });
    socket.on('Double_drive_3days_150_Day3', (data) => { setDouble_drive_3days_150_Day3(data) });
    socket.on('TWO_Trips_Or_OK_3days_150_Day3', (data) => { setTWO_Trips_Or_OK_3days_150_Day3(data) });
    socket.on('BIGGEST_or_OK', (data) => { setBIGGEST_or_OK(data) });
    socket.on('Truck_fee_3days_150_Day3', (data) => { setTruck_fee_3days_150_Day3(data) });
    socket.on('Rate_card_3days_150_Day3', (data) => { setRate_card_3days_150_Day3(data) });
    socket.on('Rate_cash_3days_150_Day3', (data) => { setRate_cash_3days_150_Day3(data) });
    socket.on('Movers_3days_150_Day3', (data) => { setMovers_3days_150_Day3(data) });
    socket.on('Higher_Number_of_Hours_3days_150_Day2', (data) => { setHigher_Number_of_Hours_3days_150_Day2(data) });
    socket.on('Lower_Number_of_Hours_3days_150_Day2', (data) => { setLower_Number_of_Hours_3days_150_Day2(data) });
    socket.on('Double_drive_3days_150_Day2', (data) => { setDouble_drive_3days_150_Day2(data) });
    socket.on('Trucks_3days_150_Day2', (data) => { setTrucks_3days_150_Day2(data) });
    socket.on('Truck_fee_3days_150_Day2', (data) => { setTruck_fee_3days_150_Day2(data) });
    socket.on('Rate_cash_3days_150_Day2', (data) => { setRate_cash_3days_150_Day2(data) });
    socket.on('Rate_card_3days_150_Day2', (data) => { setRate_card_3days_150_Day2(data) });
    socket.on('Total_Destinationfee_3days_150_Day2', (data) => { setTotal_Destinationfee_3days_150_Day2(data) });
    socket.on('Movers_3days_150_Day2', (data) => { setMovers_3days_150_Day2(data) });
    socket.on('Round_trip_miles', (data) => { setRound_trip_miles(data) });
    socket.on('Truckloads_to_move', (data) => { setTruckloads_to_move(data) });
    socket.on('Combined_mileage', (data) => { setCombined_mileage(data) });
    socket.on('Truckloads', (data) => { setTruckloads(data) });
    socket.on('Total_cubes_Within_Building', (data) => { setTotal_cubes_Within_Building(data) });
    socket.on('Total_cubes', (data) => { setTotal_cubes(data); dispatch(setTotal_cubesG(data)); });
    socket.on('Packing_Charge', (data) => { setPacking_Charge(data) });
    socket.on('Packing_kit_total_amount_with_taxes', (data) => { setPacking_kit_total_amount_with_taxes(data) });
    socket.on('Packing_kit_Show', (data) => { setPacking_kit_Show(data) });
    socket.on('Higher_Number_of_Hours_3days_150_Day1', (data) => { setHigher_Number_of_Hours_3days_150_Day1(data) });
    socket.on('Lower_Number_of_Hours_3days_150_Day1', (data) => { setLower_Number_of_Hours_3days_150_Day1(data) });
    socket.on('Double_drive', (data) => { setDouble_drive(data) });
    socket.on('Cubicf_3days_150_Day1', (data) => { setCubicf_3days_150_Day1(data) });
    socket.on('Trucks_To_Pack_3days_150_Day1', (data) => { setTrucks_To_Pack_3days_150_Day1(data) });
    socket.on('Truck_fee_3days_150_Day1', (data) => { setTruck_fee_3days_150_Day1(data) });
    socket.on('Rate_card_3days_150_Day1', (data) => { setRate_card_3days_150_Day1(data) });
    socket.on('Total_small_boxes', (data) => { setTotal_small_boxes(data); dispatch(setTotalSmallBoxes(data)); });
    socket.on('Total_medium_boxes', (data) => { setTotal_medium_boxes(data); dispatch(setTotalMediumBoxes(data)); });
    socket.on('Total_fragile_boxes', (data) => { setTotal_fragile_boxes(data); dispatch(setTotalFragileBoxes(data)); });
    socket.on('Total_box_cubes', (data) => { setTotal_box_cubes(data) });
    socket.on('Time_to_pack', (data) => { setTime_to_pack(data) });
    socket.on('Stop_1_Truckload', (data) => { setStop_1_Truckload(data) });
    socket.on('Stop_CN_Truckload', (data) => { setStop_CN_Truckload(data) });
    socket.on('Loading_hours_3days_150_Day1', (data) => { setLoading_hours_3days_150_Day1(data) });
    socket.on('Driving_Time_3days_150_Day1', (data) => { setDriving_Time_3days_150_Day1(data) });
    socket.on('Unloading_hours_3days_150_Day1', (data) => { setUnloading_hours_3days_150_Day1(data) });
    socket.on('Packing_Time_3days_150_Day1', (data) => { setPacking_Time_3days_150_Day1(data) });
    socket.on('Wrapping_time_3days_150_Day1', (data) => { setWrapping_time_3days_150_Day1(data) });
    socket.on('Total_Hours_3days_150_Day1', (data) => { setTotal_Hours_3days_150_Day1(data) });
    socket.on('Total_Cash_3days_150_Day1', (data) => { setTotal_Cash_3days_150_Day1(data) });
    socket.on('Total_Card_3days_150_Day1', (data) => { setTotal_Card_3days_150_Day1(data) });
    socket.on('Loading_hours_3days_150_Day2', (data) => { setLoading_hours_3days_150_Day2(data) });
    socket.on('Driving_time_with_DDT_3days_150_Day2', (data) => { setDriving_time_with_DDT_3days_150_Day2(data) });
    socket.on('Total_Hours_3days_150_Day2', (data) => { setTotal_Hours_3days_150_Day2(data) });
    socket.on('Total_Cash_3days_150_Day2', (data) => { setTotal_Cash_3days_150_Day2(data) });
    socket.on('Total_Card_3days_150_Day2', (data) => { setTotal_Card_3days_150_Day2(data) });
    socket.on('Unloading_hours_3days_150_Day3', (data) => { setUnloading_hours_3days_150_Day3(data) });
    socket.on('Driving_time_with_DDT_3days_150_Day3', (data) => { setDriving_time_with_DDT_3days_150_Day3(data) });
    socket.on('Total_Hours_3days_150_Day3', (data) => { setTotal_Hours_3days_150_Day3(data) });
    socket.on('Total_Cash_3days_150_Day3', (data) => { setTotal_Cash_3days_150_Day3(data) });
    socket.on('Total_Card_3days_150_Day3', (data) => { setTotal_Card_3days_150_Day3(data) });
    socket.on('Movers_To_Pack_3days_150_Day1', (data) => { setMovers_To_Pack_3days_150_Day1(data) });
    socket.on('Total_Destinationfee_3days_150', (data) => { setTotal_Destinationfee_3days_150(data) });
    socket.on('Rate_cash_3days_150_Day1', (data) => { setRate_cash_3days_150_Day1(data) });
  }, [commonValues, threeDays150Miles, department])

  useEffect(() => {
    if (editorState) {
      let templateText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      let tagValues = getTagsValues({
        calculation: calculationData, tags,
        calculatedParams: {
          Total_small_boxes,
          Total_medium_boxes,
          Total_fragile_boxes,
          Total_box_cubes,
          Time_to_pack,
          Stop_1_Truckload,
          Stop_CN_Truckload,
          Loading_hours_3days_150_Day1,
          Driving_Time_3days_150_Day1,
          Unloading_hours_3days_150_Day1,
          Packing_Time_3days_150_Day1,
          Wrapping_time_3days_150_Day1,
          Total_Hours_3days_150_Day1,
          Total_Cash_3days_150_Day1,
          Total_Card_3days_150_Day1,
          Loading_hours_3days_150_Day2,
          Driving_time_with_DDT_3days_150_Day2,
          Total_Hours_3days_150_Day2,
          Total_Cash_3days_150_Day2,
          Total_Card_3days_150_Day2,
          Unloading_hours_3days_150_Day3,
          Driving_time_with_DDT_3days_150_Day3,
          Total_Hours_3days_150_Day3,
          Total_Cash_3days_150_Day3,
          Total_Card_3days_150_Day3,
          Movers_To_Pack_3days_150_Day1,
          Total_Destinationfee_3days_150,
          Rate_cash_3days_150_Day1,
          Rate_card_3days_150_Day1,
          Truck_fee_3days_150_Day1,
          Trucks_To_Pack_3days_150_Day1,
          cubicf_3days_150_Day1,
          Double_drive,
          Lower_Number_of_Hours_3days_150_Day1,
          Higher_Number_of_Hours_3days_150_Day1,
          Packing_kit_Show,
          Packing_kit_total_amount_with_taxes,
          Packing_Charge,
          Total_cubes,
          Total_cubes_Within_Building,
          Truckloads,
          Combined_mileage,
          Truckloads_to_move,
          Round_trip_miles,
          Movers_3days_150_Day2,
          Total_Destinationfee_3days_150_Day2,
          Rate_card_3days_150_Day2,
          Rate_cash_3days_150_Day2,
          Truck_fee_3days_150_Day2,
          Trucks_3days_150_Day2,
          Double_drive_3days_150_Day2,
          Lower_Number_of_Hours_3days_150_Day2,
          Higher_Number_of_Hours_3days_150_Day2,
          Movers_3days_150_Day3,
          Rate_cash_3days_150_Day3,
          Rate_card_3days_150_Day3,
          Truck_fee_3days_150_Day3,
          Trucks_3days_150_Day3,
          BIGGEST_or_OK,
          TWO_Trips_Or_OK_3days_150_Day3,
          Double_drive_3days_150_Day3,
          Destination_fee_3days_150_Day3,
          Lower_Number_of_Hours_3days_150_Day3,
          Higher_Number_of_Hours_3days_150_Day3,
          Heavy_fee,
          Flat_rate_Cash_3days_150_Day3,
          Flat_rate_Card_3days_150_Day3,
          Trucks_Unpacking_Separate_Day,
          Movers_Unpacking_Separate_Day,
          Unpacking_Separate_Day_Lower_hours,
          Unpacking_Separate_Day_Higher_hours,
          Total_Card_Unpacking_Separate_Day,
          Total_Cash_Unpacking_Separate_Day,
          Rate_cash_Unpacking_Separate_Day,
          Rate_card_Unpacking_Separate_Day,
          Truck_fee_Unpacking_Separate_Day,
          Flat_rate_Unpacking_Separate_Day,
          Flat_rate_Unpacking_Separate_Day_cash,
          Total_Card_threeDays150Miles,
          Total_Cash_threeDays150Miles,
          UnPacking_Charge
        }, furniture, roomsAreas
      })
      const tagValuesCopy = JSON.parse(JSON.stringify(tagValues));
      if (typeof setTagsValuesDefault === 'function') {
        setTagsValuesDefault(prevState => ({
          ...prevState,
          [calculationData.calculationType]: tagValuesCopy
        }));
      }
      if (customTagsValues && Object.keys(customTagsValues).length > 0) {
        for (const key in customTagsValues) {
          tagValues[`|{${key}}`] = customTagsValues[key];
        }
      }
      setPreviewText(replaceTagsByValues(templateText, tagValues))
      setOptionsPreviewText(replaceOptionsTagsByValues(templateText, tagValues))
    }
  }, [popUpShown, tags, showCustomTags]);


  const handleResCopy = (evt) => {
    evt.preventDefault();

    // ============ DAY 1 TEXT ============ //
    let proceduresTextDay1 = '';
    if (commonValues.procedures?.length > 0 && threeDays150Miles.proceduresOnDay1) {
      proceduresTextDay1 = 'Procedures (Day 1):\n';
      commonValues.procedures.forEach((procedure) => {
        if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
          proceduresTextDay1 += `• ${procedure.procedureType} x ${procedure.numberOfProcedures}:`;
          if (Object.keys(department).length !== 0 && department.packing?.cratings) {
            department.packing.cratings.forEach((crating, index) => {
              if (procedure.procedureType === crating[`crateName${index}`]) {
                proceduresTextDay1 += ` $${Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`])}`;
              }
            });
          }
          proceduresTextDay1 += '\n';
        }
      });
    }
    const chargesTextDay1 = getChargesResText(department, threeDays150Miles.selectedChargesNamesDay1, Trucks_To_Pack_3days_150_Day1);

    let day1Text = `DAY 1 (Packing)\n
  Movers: ${Movers_To_Pack_3days_150_Day1}
  ${!isCuFtDepartment
        ? `Rate: ${department.truckFee?.tfdfCombined
          ? (department.rates?.calcMethod === 'deduct')
            ? '$' + Rate_cash_3days_150_Day1 + '/h / $' + Rate_card_3days_150_Day1 + '/h'
            : '$' + Rate_card_3days_150_Day1 + '/h'
          : (department.rates?.calcMethod === 'deduct')
            ? '$' + Rate_cash_3days_150_Day1 + '/h / $' + Rate_card_3days_150_Day1 + '/h'
            + ((Truck_fee_3days_150_Day1 !== 0) ? ` + $${Truck_fee_3days_150_Day1} truck fee` : '')
            : '$' + Rate_card_3days_150_Day1 + '/h'
            + ((Truck_fee_3days_150_Day1 !== 0) ? ` + $${Truck_fee_3days_150_Day1} truck fee` : '')
        }`
        : (Truck_fee_3days_150_Day1 ? `Truck fee: $${Truck_fee_3days_150_Day1}` : '')
      }
  Trucks: ${Trucks_To_Pack_3days_150_Day1} ${threeDays150Miles.shuttleOnDayOne
        ? '+ SHUTTLE ' + (department.extraOptions?.shuttle?.isCountable ? `(x${threeDays150Miles.quantityDay1})` : '')
        : ''
      }
  ${chargesTextDay1 ? `Extra options:\n${chargesTextDay1}` : ''}
  ${(!isCuFtDepartment && cubicf_3days_150_Day1 > 0)
        ? `Double Drive: ${Double_drive}`
        : ''
      }
  Total Time Range: ${getTotalTimeRange(
        department?.minimumHours || 0,
        Lower_Number_of_Hours_3days_150_Day1,
        Higher_Number_of_Hours_3days_150_Day1
      )}
  ${proceduresTextDay1}
  ${(commonValues.packing !== "No" && !isCuFtDepartment)
        ? (commonValues.packingKitOverride > 0)
          ? `Packing Kit: $${Packing_kit_Show} ${commonValues.packing} [$${Packing_kit_total_amount_with_taxes} with tax]`
          : `Packing Kit: $${Packing_kit_Show} ${(commonValues.packing === "Yes") ? "Full" : commonValues.packing} [$${Packing_kit_total_amount_with_taxes} with tax]`
        : ''
      }
  ${(isCuFtDepartment && Packing_Charge) ? `Packing Charge: $${Packing_Charge}` : ''}
  ${(commonValues.unpacking === 'Same Day')
        ? 'UNPACKING' + (commonValues.partialUnPacking ? ' PARTIAL' : '')
        : ''
      }
  `;

    // ============ DAY 2 TEXT ============ //
    let proceduresTextDay2 = '';
    if (commonValues.procedures?.length > 0 && threeDays150Miles.proceduresOnDay2) {
      proceduresTextDay2 = 'Procedures (Day 2):\n';
      commonValues.procedures.forEach((procedure) => {
        if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
          proceduresTextDay2 += `• ${procedure.procedureType} x ${procedure.numberOfProcedures}:`;
          if (Object.keys(department).length !== 0 && department.packing?.cratings) {
            department.packing.cratings.forEach((crating, index) => {
              if (procedure.procedureType === crating[`crateName${index}`]) {
                proceduresTextDay2 += ` $${Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`])}`;
              }
            });
          }
          proceduresTextDay2 += '\n';
        }
      });
    }
    const chargesTextDay2 = getChargesResText(department, threeDays150Miles.selectedChargesNamesDay2, Trucks_3days_150_Day2);

    let day2Text = `DAY 2 (Loading→Parking lot)\n
  Movers: ${Movers_3days_150_Day2}
  ${!isCuFtDepartment
        ? `Rate: ${(Total_Destinationfee_3days_150_Day2 > 0)
          ? (department.truckFee?.tfdfCombined)
            ? (department.rates?.calcMethod === 'deduct')
              ? '$' + Rate_cash_3days_150_Day2 + '/h / $' + Rate_card_3days_150_Day2 + '/h'
              : '$' + Rate_card_3days_150_Day2 + '/h'
            : (department.rates?.calcMethod === 'deduct')
              ? '$' + Rate_cash_3days_150_Day2 + '/h / $' + Rate_card_3days_150_Day2 + '/h'
              + ((Truck_fee_3days_150_Day2 !== 0) ? ` + $${Truck_fee_3days_150_Day2} truck fee` : '')
              : '$' + Rate_card_3days_150_Day2 + '/h'
              + ((Truck_fee_3days_150_Day2 !== 0) ? ` + $${Truck_fee_3days_150_Day2} truck fee` : '')
          : (department.rates?.calcMethod === 'deduct')
            ? '$' + Rate_cash_3days_150_Day2 + '/h / $' + Rate_card_3days_150_Day2 + '/h'
            + ((Truck_fee_3days_150_Day2 !== 0) ? ` + $${Truck_fee_3days_150_Day2} truck fee` : '')
            : '$' + Rate_card_3days_150_Day2 + '/h'
            + ((Truck_fee_3days_150_Day2 !== 0) ? ` + $${Truck_fee_3days_150_Day2} truck fee` : '')
        }`
        : (Truck_fee_3days_150_Day2 ? `Truck fee: $${Truck_fee_3days_150_Day2}` : '')
      }
  Trucks: ${Trucks_3days_150_Day2} ${threeDays150Miles.shuttleOnDay2
        ? '+ SHUTTLE ' + (department.extraOptions?.shuttle?.isCountable ? `(x${threeDays150Miles.quantityDay2})` : '')
        : ''
      }
  ${chargesTextDay2 ? `Extra options:\n${chargesTextDay2}` : ''}
  ${isCuFtDepartment
        ? getAdditionalServicesResText(
          department,
          commonValues.selectedAdditionalServices,
          Trucks_3days_150_Day2,
          commonValues.additionalServicesPlaceType,
          Total_cubes
        )
        : ''
      }
  ${(!isCuFtDepartment) ? `Double Drive: ${Double_drive_3days_150_Day2}` : ''}
  Total Time Range: ${getTotalTimeRange(
        department?.minimumHours || 0,
        Lower_Number_of_Hours_3days_150_Day2,
        Higher_Number_of_Hours_3days_150_Day2
      )}
  ${proceduresTextDay2}
  `;

    // ============ DAY 3 TEXT ============ //
    let proceduresTextDay3 = '';
    if (commonValues.procedures?.length > 0 && threeDays150Miles.proceduresOnDay3) {
      proceduresTextDay3 = 'Procedures (Day 3):\n';
      commonValues.procedures.forEach((procedure) => {
        if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
          proceduresTextDay3 += `• ${procedure.procedureType} x ${procedure.numberOfProcedures}:`;
          if (Object.keys(department).length !== 0 && department.packing?.cratings) {
            department.packing.cratings.forEach((crating, index) => {
              if (procedure.procedureType === crating[`crateName${index}`]) {
                proceduresTextDay3 += ` $${Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`])}`;
              }
            });
          }
          proceduresTextDay3 += '\n';
        }
      });
    }
    const chargesTextDay3 = getChargesResText(department, threeDays150Miles.selectedChargesNamesDay3, Trucks_3days_150_Day3);

    let day3Text = `DAY 3 (Parking lot→Unloading)\n
  Movers: ${Movers_3days_150_Day3}
  ${!isCuFtDepartment
        ? `Rate: ${department.truckFee?.tfdfCombined
          ? (department.rates?.calcMethod === 'deduct')
            ? '$' + Rate_cash_3days_150_Day3 + '/h / $' + Rate_card_3days_150_Day3 + '/h'
            : '$' + Rate_card_3days_150_Day3 + '/h'
          : (department.rates?.calcMethod === 'deduct')
            ? '$' + Rate_cash_3days_150_Day3 + '/h / $' + Rate_card_3days_150_Day3 + '/h'
            + ((Truck_fee_3days_150_Day3 !== 0) ? ` + $${Truck_fee_3days_150_Day3} truck fee` : '')
            : '$' + Rate_card_3days_150_Day3 + '/h'
            + ((Truck_fee_3days_150_Day3 !== 0) ? ` + $${Truck_fee_3days_150_Day3} truck fee` : '')
        }`
        : (Truck_fee_3days_150_Day3 ? `Truck fee: $${Truck_fee_3days_150_Day3}` : '')
      }
  Trucks: ${Trucks_3days_150_Day3} ${threeDays150Miles.shuttleOnDay3
        ? '+ SHUTTLE ' + (department.extraOptions?.shuttle?.isCountable ? `(x${threeDays150Miles.quantityDay3})` : '')
        : ''
      }
  ${(BIGGEST_or_OK !== 'OK') ? BIGGEST_or_OK : ''}
  ${(TWO_Trips_Or_OK_3days_150_Day3 !== 'OK') ? TWO_Trips_Or_OK_3days_150_Day3 : ''}
  ${chargesTextDay3 ? `Extra options:\n${chargesTextDay3}` : ''}
  ${isCuFtDepartment
        ? getAdditionalServicesResText(
          department,
          commonValues.selectedAdditionalServices,
          Trucks_3days_150_Day3,
          commonValues.additionalServicesPlaceType,
          Total_cubes
        )
        : ''
      }
  ${(!isCuFtDepartment) ? `Double Drive: ${Double_drive_3days_150_Day3}` : ''}
  ${(Destination_fee_3days_150_Day3 > 0) ? `Destination fee: $${Destination_fee_3days_150_Day3}` : ''}
  Total Time Range: ${getTotalTimeRange(
        department?.minimumHours || 0,
        Lower_Number_of_Hours_3days_150_Day3,
        Higher_Number_of_Hours_3days_150_Day3
      )}
  ${proceduresTextDay3}
  ${(Heavy_fee > 0) ? `Extra Heavy Items Charge: $${Heavy_fee}` : ''}
  ${commonValues.liftGate ? ' | LIFTGATE' : ''
      }${commonValues.hardFloorCovers ? ' | HARDWOOD FLOOR COVERS' : ''}${commonValues.filmFloorCovers ? ' | CARPET FLOOR COVERS' : ''}
  ${selectedMiscCheckMarks.map(
        (checkMark) => ` | ${getCheckMarkLabelById(checkMark, department.miscCheckMarks?.miscCheckMarks).toUpperCase()}`
      ).join('')}
  ${threeDays150Miles.additionalAmount ? `Additional amount: $${threeDays150Miles.additionalAmount}` : ''}
  `;

    // ============ PACKING SUPPLIES ON THE GO ============ //
    // This snippet replicates the textual breakdown from the "PackingSuppliesOnTheGo" concept.
    let suppliesPrices = ` • Small box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.smallBox : ''}
• Medium box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.mediumBox : ''}
• Large box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.largeBox : ''}
• Roll of packing paper: ${(Object.keys(department).length !== 0) ? department.packing.supplies.packingPapper : ''}
• Roll of bubble wrap: ${(Object.keys(department).length !== 0) ? department.packing.supplies.bubbleWrap : ''}`;


    // ============ FINAL PRICE TEXT (NOT TO EXCEED or FLAT RATE) ============ //
    let finalPriceText = '';
    if (!isCuFtDepartment) {
      // Not to exceed price logic for non-cuFt department
      finalPriceText = `
  NOT TO EXCEED PRICE: ${(department.rates?.calcMethod === 'deduct')
          ? `$${Total_Cash_threeDays150Miles} cash / $${Total_Card_threeDays150Miles} card`
          : `$${Total_Card_threeDays150Miles}`
        }
  ${(department.rates && department.rates.calcMethod !== 'deduct')
          ? `NOT TO EXCEED PRICE with ${getCurrentRatePercent(threeDays150Miles, department)}% cash discount: $${Total_Cash_threeDays150Miles}`
          : ''
        }`;
    } else {
      // CuFt department => flat rate
      finalPriceText = `
  FLAT RATE: ${(department.rates?.calcMethod === 'deduct')
          ? `$${Flat_rate_Cash_3days_150_Day3} cash / $${Flat_rate_Card_3days_150_Day3} card`
          : `$${Flat_rate_Card_3days_150_Day3}`
        }
  ${(department.rates && department.rates.calcMethod !== 'deduct')
          ? `FLAT RATE with ${getCurrentRatePercent(threeDays150Miles, department)}% cash discount: $${Flat_rate_Cash_3days_150_Day3}`
          : ''
        }`;
    }

    // ============ UNPACKING (Separate Day) ============ //
    let unpackingText = generateUnpackingText(
      commonValues,
      department,
      isCuFtDepartment,
      Movers_Unpacking_Separate_Day,
      Rate_cash_Unpacking_Separate_Day,
      Rate_card_Unpacking_Separate_Day,
      Truck_fee_Unpacking_Separate_Day,
      Trucks_Unpacking_Separate_Day,
      Unpacking_Separate_Day_Lower_hours,
      Unpacking_Separate_Day_Higher_hours,
      UnPacking_Charge,
      Total_Cash_Unpacking_Separate_Day,
      Total_Card_Unpacking_Separate_Day,
      threeDays150Miles,
      Flat_rate_Unpacking_Separate_Day_cash,
      Flat_rate_Unpacking_Separate_Day
    );

    // ============ BUILD THE FULL TEXT ============ //
    const invoiceText = `${(getFurnitureText(calculationData, furniture, roomsAreas, 'furnitureGoes').inventoryText) ? `INVENTORY` : ''}
  Total Cubic ft: ${Total_cubes.toFixed(2)} cu ft.
  ${(Total_cubes_Within_Building > 0)
        ? `Cubic ft within building: ${parseFloat(Total_cubes_Within_Building).toFixed(2)} cu ft.
  Cubic ft to move: ${parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)} cu ft.`
        : ''
      }
  Truckloads: ${(Total_cubes_Within_Building > 0) ? Truckloads_to_move : Truckloads}
  ${!isCuFtDepartment
        ? `Total miles: ${parseFloat(Combined_mileage).toFixed(1)}`
        : `Total round trip miles: ${parseFloat(Round_trip_miles).toFixed(1)}`
      }
  ${(commonValues.extraStops?.filter((extraStop, idx) =>
        extraStop[`baseLoadingHours${idx}`] !== 0 && Number(extraStop[`baseUnLoadingHours${idx}`]) !== 0
      ).length > 0)
        ? `There will be ${commonValues.extraStops.filter((extraStop, idx) =>
          extraStop[`baseLoadingHours${idx}`] !== 0 && Number(extraStop[`baseUnLoadingHours${idx}`]) !== 0
        ).length
        } additional stops`
        : ''
      }
  
  ${day1Text}
${(commonValues.packing !== "No" && (isCuFtDepartment ? department.packing.packingKits.enabled : true)) ?
        `Packing Supplies On The Go:` + `${department.packing.supplies.calculationMethod === 'suppliesPrices' ? suppliesPrices : ''}
${(!isCuFtDepartment) ? suppliesPrices : ''}
${department.packing.supplies.calculationMethod === 'perBox' ?
          `Per 1 packed box price: $${department.packing.supplies.perOnePackedBox}`
          : ''}`
        : ''
      }
  ${day2Text}
  ${day3Text}
  ${finalPriceText}
  ${unpackingText}
  `.replace(/\n{3,}/g, '\n\n'); // optional: condense big blank gaps

    // ============ COPY TO CLIPBOARD ============ //
    const textArea = document.createElement("textarea");
    textArea.value = invoiceText.trim() + '\n';
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };


  if (typeof setShowGenerateEmail === 'function') {
    if (department && Object.keys(department).length > 0 && threeDays150Miles.firstDayRate && threeDays150Miles.secondDayRate
      && threeDays150Miles.thirdDayRate) {
      setShowGenerateEmail(true);
    } else {
      setShowGenerateEmail(false);
    }
  }


  return (
    <ul className={styles.paramsSection}>
      <button {...getToggleProps()} className={styles.btnSubmit}>
        <i className={isExpanded ? styles.up : styles.down}></i>
      </button>
      <section {...getCollapseProps()}>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Small boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_small_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Medium boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_medium_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Fragile boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_fragile_boxes}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Boxes cubic ft:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_box_cubes.toFixed(2) + " cu ft."}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Time to pack for 1 mover:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Time_to_pack).toFixed(2) + " h"}
          </p>
        </li>
        <li className={styles.paramsItem} >
          <p className={styles.paramsName}>Truckloads from Stop 1:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_1_Truckload).toFixed(3)}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>
            Truckloads from Additional stops:
          </p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_CN_Truckload).toFixed(3)}
          </p>
        </li>
        <h4>DAY - 1 (Packing)</h4>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Loading hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Loading_hours_3days_150_Day1).toFixed(3)} {' h'}
          </p>
        </li>
        {(Driving_Time_3days_150_Day1 > 0) ?
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Drive Time:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Driving_Time_3days_150_Day1} {' h'}
            </p>
          </li>
          : ""}
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Unloading Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Unloading_hours_3days_150_Day1).toFixed(3)} {' h'}
          </p>
        </li>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Packing Time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Packing_Time_3days_150_Day1).toFixed(3)} {' h'}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Wrapping Time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Wrapping_time_3days_150_Day1).toFixed(3)} {' h'}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_Hours_3days_150_Day1 + " hours"}
          </p>
        </li>
        {(!isCuFtDepartment) ?
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Day 1:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {(department?.rates && department?.rates.calcMethod === 'deduct') ?
                '$' + Total_Cash_3days_150_Day1 + ' cash / $' + Total_Card_3days_150_Day1 + " card"
                :
                <>
                  {'$' + Total_Card_3days_150_Day1}
                </>}
            </p>
          </li>
          : ''}
        {/* EOF 1 day */}


        <h4>DAY - 2 (Loading→Parking)</h4>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Loading hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Loading_hours_3days_150_Day2).toFixed(3)} {' h'}
          </p>
        </li>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Drive Time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Driving_time_with_DDT_3days_150_Day2} {' h'}
          </p>
        </li>


        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_Hours_3days_150_Day2} {' hours'}
          </p>
        </li>
        {!isCuFtDepartment &&
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Day 2:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {(department?.rates && department?.rates.calcMethod === 'deduct') ?
                '$' + Total_Cash_3days_150_Day2 + ' cash / $' + Total_Card_3days_150_Day2 + " card"
                :
                <>
                  {'$' + Total_Card_3days_150_Day2}
                </>}
            </p>
          </li>
        }

        <h4>DAY - 3 (Parking→Unloading)</h4>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Drive Time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Driving_time_with_DDT_3days_150_Day3} {' h'}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Unloading Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Unloading_hours_3days_150_Day3).toFixed(3)}{' h'}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_Hours_3days_150_Day3} {' hours'}
          </p>
        </li>

        {!isCuFtDepartment &&
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Day 3:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {(department?.rates && department?.rates.calcMethod === 'deduct') ?
                '$' + Total_Cash_3days_150_Day3 + ' cash / $' + Total_Card_3days_150_Day3 + " card"
                :
                <>
                  {'$' + Total_Card_3days_150_Day3}
                </>}
            </p>
          </li>
        }



      </section>
      {(department && Object.keys(department).length > 0 && threeDays150Miles.firstDayRate
        && threeDays150Miles.secondDayRate && threeDays150Miles.thirdDayRate) ?
        <div>
          <h2 className={styles.titleCenter}><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Results  <CopyIcon onClick={handleResCopy} style={{ marginLeft: '5px' }} /></div>{(Total_box_cubes > 0 && commonValues.packing === 'No') ?
            <>
              <span style={{ color: 'red' }}> - No Packing
              </span>
              <label className={styles.commonLabeling} style={{ marginLeft: '5px' }}>
                Confirm
                <input
                  className={styles.mycheckbox}
                  type='checkbox'
                  name={'isNoPackingConfirmed'}
                  checked={commonValues.isNoPackingConfirmed}
                  value={commonValues.isNoPackingConfirmed}
                  onChange={checkboxValueChange}
                />
              </label>
            </> : ''}</h2>
          {(getFurnitureText(calculationData, furniture, roomsAreas, 'furnitureGoes').inventoryText) &&
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>
                <span className={`${(highLightItem === 'inventoryText') ? styles.highLighted : ""}`} ref={scrollRefs?.current['inventoryText']}>
                  INVENTORY
                </span>
              </p>
            </li>
          }
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Cubic ft:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === 'totalCubicFt') ? styles.highLighted : ""}`} ref={scrollRefs?.current['totalCubicFt']}>{Total_cubes.toFixed(2)}</span> cu ft.
            </p>
          </li>
          {(Total_cubes_Within_Building > 0) ? <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic ft within building:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'cuftWithinBuilding') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftWithinBuilding']}>{parseFloat(Total_cubes_Within_Building).toFixed(2)}</span> cu ft.
              </p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic ft to move:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'cuftToMove') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftToMove']}>{parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)}</span> cu ft.
              </p>
            </li>
          </> : ''}
          <TruckloadsView
            Truckloads={Truckloads}
            Truckloads_to_move={Truckloads_to_move}
            Total_cubes_Within_Building={Total_cubes_Within_Building}
            scrollRefs={scrollRefs}
            highLightItem={highLightItem}
          />

          {(isCuFtDepartment) ?
            <>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Day 1 - Round trip miles:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}><span className={`${(highLightItem === 'day1RoundTripMiles') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1RoundTripMiles']}>{parseFloat(commonValues.milesFromParkingCuFt * 2).toFixed(1)}</span></p>
              </li>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Total round trip miles:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}><span className={`${(highLightItem === 'totalRoundTripMiles') ? styles.highLighted : ""}`} ref={scrollRefs?.current['totalRoundTripMiles']}>{parseFloat(Round_trip_miles).toFixed(1)}</span></p>
              </li>
              {commonValues.unpacking === 'Separate Day' &&
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Unpacking day Round trip miles:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}><span className={`${(highLightItem === 'unpackingDayRoundTripMiles') ? styles.highLighted : ""}`} ref={scrollRefs?.current['unpackingDayRoundTripMiles']}>{parseFloat(commonValues.milesToParkingCuFt * 2).toFixed(1)}</span></p>
                </li>
              }
            </>
            :
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Total miles:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}><span className={`${(highLightItem === 'combinedMileage') ? styles.highLighted : ""}`} ref={scrollRefs?.current['combinedMileage']}>{parseFloat(Combined_mileage).toFixed(1)}</span></p>
            </li>
          }
          {
            (commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ?
              <li className={styles.paramsItem}><div>
                There will be <span className={`${(highLightItem === 'additionalStopsCount') ? styles.highLighted : ""}`} ref={scrollRefs?.current['additionalStopsCount']}>{commonValues.extraStops.filter((extraStop, index) =>
                  extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
                ).length}</span> additional stops</div>
              </li>
              : ''
          }
          <h2 className={styles.titleCenter}>Day 1 - Packing</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}><span className={`${(highLightItem === 'moversDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversDay1']}>{Movers_To_Pack_3days_150_Day1}</span></p>
          </li>

          {!isCuFtDepartment &&
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Rate:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {getFormattedRateInfoTwoDays(department, Total_Destinationfee_3days_150, Rate_cash_3days_150_Day1, Rate_card_3days_150_Day1, Truck_fee_3days_150_Day1, highLightItem, scrollRefs, styles).formattedRate}
              </p>
            </li>
          }

          {(isCuFtDepartment && Truck_fee_3days_150_Day1) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Truck fee:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'truckFeeDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay1']}>{Truck_fee_3days_150_Day1}$</span>
              </p>
            </li>
            : ''
          }

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === 'trucksDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['trucksDay1']}>{Trucks_To_Pack_3days_150_Day1}</span>
              {threeDays150Miles.shuttleOnDayOne && <>  <span className={`${(highLightItem === 'shuttleDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttleDay1']}>+ SHUTTLE</span> {((department.extraOptions?.shuttle.isCountable) ? <span className={`${(highLightItem === 'shuttleQtyDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttleQtyDay1']}>(x{threeDays150Miles.quantityDay1})</span> : '')}</>}
            </p>
          </li>

          {(threeDays150Miles.cubicFtToMoveDay1) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic Ft to move:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'cubicFtToMoveDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cubicFtToMoveDay1']}>
                  {threeDays150Miles.cubicFtToMoveDay1 + " cu ft."}
                </span>
              </p>

            </li>
            : ''}

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={threeDays150Miles.selectedChargesNamesDay1}
            trucksCount={Trucks_To_Pack_3days_150_Day1}
            scrollRefs={scrollRefs}
            highLightItem={highLightItem}
            day='1'
          />


          {(cubicf_3days_150_Day1 > 0 && !isCuFtDepartment) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Double Drive:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'doubleDrive') ? styles.highLighted : ""}`} ref={scrollRefs?.current['doubleDrive']}>
                  {Double_drive}
                </span>
              </p>
            </li>
            : ""}

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === 'lowerNumberOfHoursDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['lowerNumberOfHoursDay1']}>
                {getLowerNumberOfHours((department) ? department.minimumHours : 0, Lower_Number_of_Hours_3days_150_Day1)}
              </span>
              <span className={`${(highLightItem === 'higherNumberOfHoursDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['higherNumberOfHoursDay1']}>
                {getHigherNumberOfHours((department) ? department.minimumHours : 0, Higher_Number_of_Hours_3days_150_Day1)}
              </span>
            </p>
          </li>

          <ProceduresResults
            commonValues={commonValues}
            highLightItem={highLightItem}
            scrollRefs={scrollRefs}
            department={department}
            procedures={threeDays150Miles.proceduresOnDay1}
            day='1'
          />

          {(commonValues.packing !== "No" && !isCuFtDepartment) ?
            (commonValues.packingKitOverride > 0) ?
              <li className={styles.paramsItem} style={{ color: "red" }}>
                <p className={styles.paramsName}>Packing Kit:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === 'packingKit') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKit']}>{'$' + Packing_kit_Show} {commonValues.packing}</span>  <span className={`${(highLightItem === 'packingKitWithTax') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKitWithTax']}>[${Packing_kit_total_amount_with_taxes}</span> with tax]
                </p>
              </li>
              :
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Packing Kit:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === 'packingKit') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKit']}>{'$' + Packing_kit_Show}</span>  {(commonValues.packing === "Yes") ? "Full" : commonValues.packing} <span className={`${(highLightItem === 'packingKitWithTax') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKitWithTax']}>[${Packing_kit_total_amount_with_taxes}</span> with tax]
                </p>
              </li>
            : ""}

          {(commonValues.unpacking === 'Same Day') &&
            <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
              <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'sameDayUnpack') ? styles.highLighted : ""}`} ref={scrollRefs?.current['sameDayUnpack']}>UNPACKING</span>  <span className={`${(highLightItem === 'partial') ? styles.highLighted : ""}`} ref={scrollRefs?.current['partial']}>{commonValues.partialUnPacking && "PARTIAL"}</span></span>
            </div>
          }

          {(isCuFtDepartment && Packing_Charge) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Packing Charge:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'packingCharge') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingCharge']}>${Packing_Charge}</span>
              </p>
            </li>
            : ''
          }
          <PackingSuppliesOnTheGo
            department={department}
            highLightItem={highLightItem}
            scrollRefs={scrollRefs}
            commonValues={commonValues}
            isCuFtDepartment={isCuFtDepartment}
          />
          {(isCuFtDepartment) ? <AdditionalServicesEstimateInfo
            commonValues={commonValues}
            department={department}
            totalCubes={Total_cubes}
            scrollRefs={scrollRefs}
            highLightItem={highLightItem} /> : ''}

          <h2 className={styles.titleCenter}>Day 2 (Loading→Parking lot)</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === 'moversDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversDay2']}>
                {Movers_3days_150_Day2}
              </span>
            </p>
          </li>
          {(!isCuFtDepartment) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Rate:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {(Total_Destinationfee_3days_150_Day2 > 0)
                  ?
                  (department.truckFee.tfdfCombined)
                    ? (department.rates && department.rates.calcMethod === 'deduct')
                      ? <> <span className={`${(highLightItem === 'rateCashDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCashDay2']}></span>${Rate_cash_3days_150_Day2}/h /<span className={`${(highLightItem === 'rateCardDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay2']}>${Rate_card_3days_150_Day2}/h</span>
                      </> :
                      <span className={`${(highLightItem === 'rateCardDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay2']}>${Rate_card_3days_150_Day2}/h</span>
                    : (department.rates && department.rates.calcMethod === 'deduct') ? <>  <span className={`${(highLightItem === 'rateCashDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCashDay2']}>${Rate_cash_3days_150_Day2}/h</span> /
                      <span className={`${(highLightItem === 'rateCardDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay2']}>${Rate_card_3days_150_Day2}/h</span>
                      {((Truck_fee_3days_150_Day2 !== 0) ? <>+ <span className={`${(highLightItem === 'truckFeeDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay2']}></span>${Truck_fee_3days_150_Day2} truck(s) fee</> : "")}
                    </> : <>
                      <span className={`${(highLightItem === 'rateCardDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay2']}>${Rate_card_3days_150_Day2}/h</span>
                      {((Truck_fee_3days_150_Day2 !== 0) ? <>+ <span className={`${(highLightItem === 'truckFeeDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay2']}></span>${Truck_fee_3days_150_Day2} truck(s) fee</> : "")}</>
                  : (department.rates && department.rates.calcMethod === 'deduct')
                    ? <><span className={`${(highLightItem === 'rateCashDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCashDay2']}></span>${Rate_cash_3days_150_Day2}/h /<span className={`${(highLightItem === 'rateCardDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay2']}>${Rate_card_3days_150_Day2}/h</span>
                      {((Truck_fee_3days_150_Day2 !== 0) ? <>+ <span className={`${(highLightItem === 'truckFeeDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay2']}></span>${Truck_fee_3days_150_Day2} truck(s) fee</> : "")}
                    </>
                    : <>
                      <span className={`${(highLightItem === 'rateCardDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay2']}>${Rate_card_3days_150_Day2}/h</span>
                      {((Truck_fee_3days_150_Day2 !== 0) ? <>+ <span className={`${(highLightItem === 'truckFeeDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay2']}></span>${Truck_fee_3days_150_Day2} truck(s) fee</> : "")}
                    </>
                }
              </p>
            </li>
            : <> {(Truck_fee_3days_150_Day2) ? <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Truck fee:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'truckFeeDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay2']}>
                  ${Truck_fee_3days_150_Day2}
                </span>
              </p>
            </li>
              : ''}
            </>
          }

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === 'trucksDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['trucksDay2']}>
                {Trucks_3days_150_Day2}
              </span>
              {threeDays150Miles.shuttleOnDay2 && <> <span className={`${(highLightItem === 'shuttleDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttleDay2']}>+ SHUTTLE</span>{((department.extraOptions.shuttle.isCountable) ? <span className={`${(highLightItem === 'shuttleQtyDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttleQtyDay2']}>(x{threeDays150Miles.quantityDay2})</span> : '')} </>}
            </p>
          </li>

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={threeDays150Miles.selectedChargesNamesDay2}
            trucksCount={Trucks_3days_150_Day2}
            scrollRefs={scrollRefs}
            highLightItem={highLightItem}
            day='2'
          />

          {isCuFtDepartment &&
            <AdditionalServicesResults
              selectedAdditionalServices={commonValues.selectedAdditionalServices}
              department={department}
              additionalServicesPlaceType={commonValues.additionalServicesPlaceType}
              Total_cubes={Total_cubes}
              trucksCount={Trucks_3days_150_Day2}
            />
          }


          {(!isCuFtDepartment) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Double Drive:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'doubleDriveDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['doubleDriveDay2']}>
                  {Double_drive_3days_150_Day2}
                </span>
              </p>
            </li>
            : ''}

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === 'lowerNumberOfHoursDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['lowerNumberOfHoursDay2']}>
                {getLowerNumberOfHours((department) ? department.minimumHours : 0, Lower_Number_of_Hours_3days_150_Day2)}
              </span>
              <span className={`${(highLightItem === 'higherNumberOfHoursDay2') ? styles.highLighted : ""}`} ref={scrollRefs?.current['higherNumberOfHoursDay2']}>
                {getHigherNumberOfHours((department) ? department.minimumHours : 0, Higher_Number_of_Hours_3days_150_Day2)}
              </span>
            </p>
          </li>

          <ProceduresResults
            commonValues={commonValues}
            highLightItem={highLightItem}
            scrollRefs={scrollRefs}
            department={department}
            procedures={threeDays150Miles.proceduresOnDay2}
            day='2'
          />



          <h2 className={styles.titleCenter}>Day 3 (Parking lot→Unloading)</h2>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === 'moversDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversDay3']}>
                {Movers_3days_150_Day3}
              </span>
            </p>
          </li>

          {(!isCuFtDepartment) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Rate:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {(Total_Destinationfee_3days_150_Day2 > 0)
                  ?
                  (department.truckFee.tfdfCombined)
                    ? <>{(department && (Object.keys(department).length !== 0) && department.rates && department.rates.calcMethod === 'deduct') ? <><span className={`${(highLightItem === 'rateCashDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCashDay3']}>${Rate_cash_3days_150_Day3}/h</span> /
                      <span className={`${(highLightItem === 'rateCardDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay3']}>${Rate_card_3days_150_Day3}/h</span>
                    </>
                      : <span className={`${(highLightItem === 'rateCardDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay3']}>${Rate_card_3days_150_Day3}/h</span>}
                    </>
                    : (department.rates && department.rates.calcMethod === 'deduct') ? <> <span className={`${(highLightItem === 'rateCashDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCashDay3']}>${Rate_cash_3days_150_Day3}/h</span> /
                      <span className={`${(highLightItem === 'rateCardDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay3']}>${Rate_card_3days_150_Day3}/h</span>
                      {((Truck_fee_3days_150_Day3 !== 0) ? <>+ <span className={`${(highLightItem === 'truckFeeDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay3']}>${Truck_fee_3days_150_Day3}</span> truck(s) fee</> : "")}
                    </>
                      : <>
                        <span className={`${(highLightItem === 'rateCardDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay3']}>${Rate_card_3days_150_Day3}/h</span>
                        {((Truck_fee_3days_150_Day3 !== 0) ? <>+ <span className={`${(highLightItem === 'truckFeeDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay3']}>${Truck_fee_3days_150_Day3}</span> truck(s) fee</> : "")}
                      </>
                  : (department.rates && department.rates.calcMethod === 'deduct') ? <><span className={`${(highLightItem === 'rateCashDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCashDay3']}>${Rate_cash_3days_150_Day3}/h</span> /
                    <span className={`${(highLightItem === 'rateCardDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay3']}>${Rate_card_3days_150_Day3}/h</span>
                    {((Truck_fee_3days_150_Day3 !== 0) ? <>+ <span className={`${(highLightItem === 'truckFeeDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay3']}>${Truck_fee_3days_150_Day3}</span> truck(s) fee</> : "")}
                  </>
                    : <>
                      <span className={`${(highLightItem === 'rateCardDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardDay3']}>${Rate_card_3days_150_Day3}/h</span>
                      {((Truck_fee_3days_150_Day3 !== 0) ? <>+ <span className={`${(highLightItem === 'truckFeeDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay3']}>${Truck_fee_3days_150_Day3}</span> truck(s) fee</> : "")}
                    </>

                }

              </p>
            </li>
            : <>{(Truck_fee_3days_150_Day3) ? <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Truck fee:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'truckFeeDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckFeeDay3']}>
                  ${Truck_fee_3days_150_Day3}
                </span>
              </p>
            </li>
              : ''}</>
          }

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === 'trucksDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['trucksDay3']}>{Trucks_3days_150_Day3}</span>
              {threeDays150Miles.shuttleOnDay3 && <>  <span className={`${(highLightItem === 'shuttleDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttleDay3']}>+ SHUTTLE</span>{((department.extraOptions?.shuttle.isCountable) ? <span className={`${(highLightItem === 'shuttleQtyDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttleQtyDay3']}>(x{threeDays150Miles.quantityDay3})</span> : '')}</>}
            </p>
          </li>
          {(BIGGEST_or_OK !== 'OK') ?
            <li className={styles.paramsItem}>
              <span className={`${(highLightItem === 'biggestOrOk') ? styles.highLighted : ""}`} ref={scrollRefs?.current['biggestOrOk']}>
                {BIGGEST_or_OK}
              </span>
            </li>
            : ""
          }
          {(TWO_Trips_Or_OK_3days_150_Day3 !== 'OK') ?
            <li className={styles.paramsItem}>
              <span className={`${(highLightItem === 'twoTripsOrOkDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['twoTripsOrOkDay3']}>
                {TWO_Trips_Or_OK_3days_150_Day3}
              </span>
            </li>
            : ""
          }

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={threeDays150Miles.selectedChargesNamesDay3}
            trucksCount={Trucks_3days_150_Day3}
            scrollRefs={scrollRefs}
            highLightItem={highLightItem}
            day='3'
          />

          {(!isCuFtDepartment) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Double Drive:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'doubleDriveDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['doubleDriveDay3']}>
                  {Double_drive_3days_150_Day3}
                </span>
              </p>
            </li>
            : ''}

          {(Destination_fee_3days_150_Day3 > 0) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Destination fee:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>$<span className={`${(highLightItem === 'destinationFeeDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['destinationFeeDay3']}>
                {(department.truckFee.tfdfCombined) ?
                  Total_Destinationfee_3days_150 + Truck_fee_3days_150_Day1 + Truck_fee_3days_150_Day2 + Truck_fee_3days_150_Day3
                  : Total_Destinationfee_3days_150
                }
              </span>
              </p>
            </li>
            : ""}

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === 'lowerNumberOfHoursDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['lowerNumberOfHoursDay3']}>
                {getLowerNumberOfHours((department) ? department.minimumHours : 0, Lower_Number_of_Hours_3days_150_Day3)}
              </span>
              <span className={`${(highLightItem === 'higherNumberOfHoursDay3') ? styles.highLighted : ""}`} ref={scrollRefs?.current['higherNumberOfHoursDay3']}>
                {getHigherNumberOfHours((department) ? department.minimumHours : 0, Higher_Number_of_Hours_3days_150_Day3)}
              </span>
            </p>
          </li>

          <ProceduresResults
            commonValues={commonValues}
            highLightItem={highLightItem}
            scrollRefs={scrollRefs}
            department={department}
            procedures={threeDays150Miles.proceduresOnDay3}
            day='3'
          />

          {(Heavy_fee !== 0) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Extra Heavy Items Charge:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'heavyFee') ? styles.highLighted : ""}`} ref={scrollRefs?.current['heavyFee']}>{'$' + Heavy_fee}</span>
              </p>
            </li>
            : ""}

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'liftGate') ? styles.highLighted : ""}`} ref={scrollRefs?.current['liftGate']}>{commonValues.liftGate && ' | LIFTGATE'}</span></span>
            <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'hardwoodFloorCovers') ? styles.highLighted : ""}`} ref={scrollRefs?.current['hardwoodFloorCovers']}>{commonValues.hardFloorCovers && " | HARDWOOD FLOOR COVERS"}</span></span>
            <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'carpetFloorCovers') ? styles.highLighted : ""}`} ref={scrollRefs?.current['carpetFloorCovers']}>{commonValues.filmFloorCovers && " | CARPET FLOOR COVERS "}</span></span>
            {selectedMiscCheckMarks.map((checkMark, chmpkIndex) => <span key={chmpkIndex} style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
              {' '} |  <span className={`${(highLightItem === `mChId_${checkMark}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`mChId_${checkMark.uId}`]}>{getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks)}</span>
            </span>)}
          </div>

          {(threeDays150Miles.additionalAmount) ? (
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Additional amount:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'additionalAmount') ? styles.highLighted : ""}`} ref={scrollRefs?.current['additionalAmount']}>${threeDays150Miles.additionalAmount}</span>
              </p>
            </li>) : ''
          }

          {(!isCuFtDepartment) ? <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>NOT TO EXCEED PRICE:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {(department.rates && department.rates.calcMethod === 'deduct') ? <>
                  <span className={`${(highLightItem === 'totalCash3Days150miles') ? styles.highLighted : ""}`} ref={scrollRefs?.current['totalCash3Days150miles']}>${Total_Cash_threeDays150Miles}</span> cash /  <span className={`${(highLightItem === 'totalCard3Days150miles') ? styles.highLighted : ""}`} ref={scrollRefs?.current['totalCard3Days150miles']}>${Total_Card_threeDays150Miles}</span> card
                </>
                  :
                  <>
                    <span className={`${(highLightItem === 'totalCard3Days150miles') ? styles.highLighted : ""}`} ref={scrollRefs?.current['totalCard3Days150miles']}>$
                      {Total_Card_threeDays150Miles}
                    </span>
                  </>}
              </p>
            </li>
            {(department.rates && department.rates.calcMethod !== 'deduct') ?
              <div style={{ paddingLeft: '30px' }}>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>NOT TO EXCEED PRICE with  <span className={`${(highLightItem === 'cashDiscountPercent') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cashDiscountPercent']}>{getCurrentRatePercent(threeDays150Miles, department)}%</span> cash discount:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>$
                    <span className={`${(highLightItem === 'totalCash3Days150miles') ? styles.highLighted : ""}`} ref={scrollRefs?.current['totalCash3Days150miles']}>
                      {Total_Cash_threeDays150Miles}
                    </span>
                  </p>
                </li>
              </div>
              : ""}
          </> : ''}

          {isCuFtDepartment && <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>FLAT RATE:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {(department.rates && department.rates.calcMethod === 'deduct') ? <>
                  <span className={`${(highLightItem === 'flatRateCash3Days150miles') ? styles.highLighted : ""}`} ref={scrollRefs?.current['flatRateCash3Days150miles']}>${Flat_rate_Cash_3days_150_Day3}</span> cash / <span className={`${(highLightItem === 'flatRateCard3Days150miles') ? styles.highLighted : ""}`} ref={scrollRefs?.current['flatRateCard3Days150miles']}>${Flat_rate_Card_3days_150_Day3}</span> card
                </>
                  :
                  <>
                    <span className={`${(highLightItem === 'flatRateCard3Days150miles') ? styles.highLighted : ""}`} ref={scrollRefs?.current['flatRateCard3Days150miles']}>
                      {Flat_rate_Card_3days_150_Day3}
                    </span>
                  </>}
              </p>
            </li>
            {(department.rates && department.rates.calcMethod !== 'deduct') ?
              <div style={{ paddingLeft: '30px' }}>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>FLAT RATE with <span className={`${(highLightItem === 'cashDiscountPercent') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cashDiscountPercent']}>{getCurrentRatePercent(threeDays150Miles, department)}%</span> cash discount:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === 'flatRateCash3Days150miles') ? styles.highLighted : ""}`} ref={scrollRefs?.current['flatRateCash3Days150miles']}>
                      ${Flat_rate_Cash_3days_150_Day3}
                    </span>
                  </p>
                </li>
              </div>
              : ""}
          </>}

          <CalculationSeparateDayView commonValues={commonValues} anyCalculationType={threeDays150Miles} department={department}
            Trucks_Unpacking_Separate_Day={Trucks_Unpacking_Separate_Day}
            Movers_Unpacking_Separate_Day={Movers_Unpacking_Separate_Day}
            Unpacking_Separate_Day_Lower_hours={Unpacking_Separate_Day_Lower_hours}
            Unpacking_Separate_Day_Higher_hours={Unpacking_Separate_Day_Higher_hours}
            Total_Card_Unpacking_Separate_Day={Total_Card_Unpacking_Separate_Day}
            Total_Cash_Unpacking_Separate_Day={Total_Cash_Unpacking_Separate_Day}
            Rate_cash_Unpacking_Separate_Day={Rate_cash_Unpacking_Separate_Day}
            Rate_card_Unpacking_Separate_Day={Rate_card_Unpacking_Separate_Day}
            Truck_fee_Unpacking_Separate_Day={Truck_fee_Unpacking_Separate_Day}
            Flat_rate_Unpacking_Separate_Day={Flat_rate_Unpacking_Separate_Day}
            Flat_rate_Unpacking_Separate_Day_cash={Flat_rate_Unpacking_Separate_Day_cash}
            isCuFtDepartment={isCuFtDepartment}
            UnPacking_Charge={UnPacking_Charge}
            highLightItem={highLightItem}
            scrollRefs={scrollRefs}
          />

        </div>
        : ""}
    </ul>
  );
};
