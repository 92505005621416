import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { SocketContext } from '../../context/socket';
import useCollapse from 'react-collapsed'

// Calc util functions

import { generateNotIncludedServicesText, generateUnpackingText, getAdditionalServicesResText, getChargesResText, getCheckMarkLabelById, getCurrentRatePercent, getFormattedRateInfo, getFormattedRateLoadInfo, getFurnitureText, getHigherNumberOfHours, getLowerNumberOfHours, getTagsValues, getTotalTimeRange, performComparison, replaceOptionsTagsByValues, replaceTagsByValues } from '../../util/utils';

// Styles
import styles from "./CalculationOneDayParams.module.css";
//Components
import { CalculationSeparateDayView } from '../calculation-separate-day-view/Calculation-separate-day-view';
import draftToHtml from 'draftjs-to-html';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import CopyIcon from '../results-copy-icon/Results-copy-icon';

import { ExtraOptionsResults } from '../extra-options-results/Extra-options-results'
import { AdditionalServicesResults } from '../additional-services-results/Additional-services-results';
import { useDispatch } from 'react-redux';
import { setTotal_cubesG, setTotalFragileBoxes, setTotalMediumBoxes, setTotalSmallBoxes, setTWOTripsOrOK } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { AdditionalServicesEstimateInfo } from '../additional-services-estimate-info/Additional-services-estimate-info';
import { set } from 'lodash';
import TruckloadsView from '../truckloads/TruckloadsView';
import PackingSuppliesOnTheGo from '../packingSuppliesOnTheGo/Packing-supplies-on-the-go';

export const CalculationOneDayParams = ({
  commonValues,
  oneDayPacking,
  department,
  selectedMiscCheckMarks,
  checkboxValueChange,

  editorState,
  calculationData,
  tags,
  setPreviewText, setOptionsPreviewText,
  popUpShown,
  setShowGenerateEmail,
  furniture,
  roomsAreas,
  highLightItem,
  company,
  scrollRefs,
  setTagsValuesDefault,
  customTagsValues,
  showCustomTags
}) => {
  const dispatch = useDispatch();
  const [Total_cubes, setTotal_cubes] = useState(0);
  const [Total_small_boxes, setTotal_small_boxes] = useState(0);
  const [Total_medium_boxes, setTotal_medium_boxes] = useState(0)
  const [Total_fragile_boxes, setTotal_fragile_boxes] = useState(0)
  const [Total_box_cubes, setTotal_box_cubes] = useState(0)
  const [Stop_1_Truckload, setStop_1_Truckload] = useState(0)
  const [Stop_CN_Truckload, setStop_CN_Truckload] = useState(0)
  const [Charge_per_mile_amount, setCharge_per_mile_amount] = useState(0)
  const [Movers_to_load_One_Day_hours, setMovers_to_load_One_Day_hours] = useState(0)
  const [Loading_hours_One_Day, setLoading_hours_One_Day] = useState(0)
  const [Adjusted_loading_time_One_Day, setAdjusted_loading_time_One_Day] = useState(0)
  const [Drivetime_One_Day, setDrivetime_One_Day] = useState(0)
  const [Movers_to_unload_One_Day_hours, setMovers_to_unload_One_Day_hours] = useState(0)
  const [Unloading_hours_One_Day, setUnloading_hours_One_Day] = useState(0)
  const [Total_Hours_One_Day, setTotal_Hours_One_Day] = useState(0)
  const [Truckloads, setTruckloads] = useState(0)
  const [Total_Destinationfee_One_Day, setTotal_Destinationfee_One_Day] = useState(0)
  const [Rate_cash_Movers_To_Load_One_Day, setRate_cash_Movers_To_Load_One_Day] = useState(0)
  const [Rate_card_Movers_To_Load_One_Day, setRate_card_Movers_To_Load_One_Day] = useState(0)
  const [Truck_fee_One_Day, setTruck_fee_One_Day] = useState(0)
  const [Movers_One_Day, setMovers_One_Day] = useState(0)
  const [Rate_cash_One_Day, setRate_cash_One_Day] = useState(0)
  const [Rate_card_One_Day, setRate_card_One_Day] = useState(0)
  const [Rate_cash_Movers_To_Unload_One_Day, setRate_cash_Movers_To_Unload_One_Day] = useState(0)
  const [Rate_card_Movers_To_Unload_One_Day, setRate_card_Movers_To_Unload_One_Day] = useState(0)
  const [Trucks_One_Day, setTrucks_One_Day] = useState(0)
  const [TWO_Trips_Or_OK, setTWO_Trips_Or_OK] = useState('')
  const [BIGGEST_or_OK, setBIGGEST_or_OK] = useState('')
  const [Double_drive, setDouble_drive] = useState('')
  const [Lower_Number_of_Hours_One_Day, setLower_Number_of_Hours_One_Day] = useState(0)
  const [Higher_Number_of_Hours_One_Day, setHigher_Number_of_Hours_One_Day] = useState(0)
  const [Heavy_fee, setHeavy_fee] = useState(0)
  const [Packing_kit_Show, setPacking_kit_Show] = useState(0)
  const [Packing_kit_total_amount_with_taxes, setPacking_kit_total_amount_with_taxes] = useState(0)
  const [Total_Card_One_day, setTotal_Card_One_day] = useState(0)
  const [Total_Cash_One_day, setTotal_Cash_One_day] = useState(0)
  const [Combined_mileage, setCombined_mileage] = useState(0)
  const [Round_trip_miles, setRound_trip_miles] = useState(0)
  const [Flat_rate_One_Day, setFlat_rate_One_Day] = useState(0)
  const [Flat_rate_One_Day_cash, setFlat_rate_One_Day_cash] = useState(0)
  const [Packing_Charge, setPacking_Charge] = useState(0)
  const [UnPacking_Charge, setUnPacking_Charge] = useState(0)
  const [Total_cubes_Within_Building, setTotal_cubes_Within_Building] = useState(0);
  const [Truckloads_to_move, setTruckloads_to_move] = useState(0);


  const [Trucks_Unpacking_Separate_Day, setTrucks_Unpacking_Separate_Day] = useState(0)
  const [Movers_Unpacking_Separate_Day, setMovers_Unpacking_Separate_Day] = useState(0)
  const [Unpacking_Separate_Day_Lower_hours, setUnpacking_Separate_Day_Lower_hours] = useState(0)
  const [Unpacking_Separate_Day_Higher_hours, setUnpacking_Separate_Day_Higher_hours] = useState(0)
  const [Total_Card_Unpacking_Separate_Day, setTotal_Card_Unpacking_Separate_Day] = useState(0)
  const [Total_Cash_Unpacking_Separate_Day, setTotal_Cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_cash_Unpacking_Separate_Day, setRate_cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_card_Unpacking_Separate_Day, setRate_card_Unpacking_Separate_Day] = useState(0)
  const [Truck_fee_Unpacking_Separate_Day, setTruck_fee_Unpacking_Separate_Day] = useState(0)
  const [Flat_rate_Unpacking_Separate_Day, setFlat_rate_Unpacking_Separate_Day] = useState(0)
  const [Flat_rate_Unpacking_Separate_Day_cash, setFlat_rate_Unpacking_Separate_Day_cash] = useState(0)

  const [Time_to_pack, setTime_to_pack] = useState(0);
  const isCuFtDepartment = department?.type === 'cuft';

  const socket = useContext(SocketContext); // Access the socket using useContext


  useEffect(() => {
    socket.emit('getFormulasValues', { calculationType: 'oneDayPacking', commonValues: commonValues, oneDayPacking: oneDayPacking, department: department })


    socket.on('Total_cubes', (data) => { setTotal_cubes(data); dispatch(setTotal_cubesG(data)); });
    socket.on('Total_small_boxes', (data) => {
      setTotal_small_boxes(data)
      dispatch(setTotalSmallBoxes(data));
    });
    socket.on('Total_medium_boxes', (data) => {
      setTotal_medium_boxes(data)
      dispatch(setTotalMediumBoxes(data));
    });
    socket.on('Total_fragile_boxes', (data) => {
      setTotal_fragile_boxes(data)
      dispatch(setTotalFragileBoxes(data));
    });
    socket.on('Total_box_cubes', (data) => {
      setTotal_box_cubes(data)
    });
    socket.on('Stop_1_Truckload', (data) => {
      setStop_1_Truckload(data)
    });
    socket.on('Stop_CN_Truckload', (data) => {
      setStop_CN_Truckload(data)
    });
    socket.on('Charge_per_mile_amount', (data) => {
      setCharge_per_mile_amount(data)
    });
    socket.on('Movers_to_load_One_Day_hours', (data) => {
      setMovers_to_load_One_Day_hours(data)
    });
    socket.on('Loading_hours_One_Day', (data) => {
      setLoading_hours_One_Day(data)
    });
    socket.on('Adjusted_loading_time_One_Day', (data) => {
      setAdjusted_loading_time_One_Day(data)
    });
    socket.on('Drivetime_One_Day', (data) => {
      setDrivetime_One_Day(data)
    });
    socket.on('Movers_to_unload_One_Day_hours', (data) => {
      setMovers_to_unload_One_Day_hours(data)
    });
    socket.on('Unloading_hours_One_Day', (data) => {
      setUnloading_hours_One_Day(data)
    });
    socket.on('Total_Hours_One_Day', (data) => {
      setTotal_Hours_One_Day(data)
    });
    socket.on('Truckloads', (data) => {
      setTruckloads(data)
    });
    socket.on('Total_Destinationfee_One_Day', (data) => {
      setTotal_Destinationfee_One_Day(data)
    });
    socket.on('Rate_cash_Movers_To_Load_One_Day', (data) => {
      setRate_cash_Movers_To_Load_One_Day(data)
    });
    socket.on('Rate_card_Movers_To_Load_One_Day', (data) => {
      setRate_card_Movers_To_Load_One_Day(data)
    });
    socket.on('Truck_fee_One_Day', (data) => {
      setTruck_fee_One_Day(data)
    });
    socket.on('Movers_One_Day', (data) => {
      setMovers_One_Day(data)
    });
    socket.on('Rate_cash_One_Day', (data) => {
      setRate_cash_One_Day(data)
    });
    socket.on('Rate_card_One_Day', (data) => {
      setRate_card_One_Day(data)
    });
    socket.on('Rate_cash_Movers_To_Unload_One_Day', (data) => {
      setRate_cash_Movers_To_Unload_One_Day(data)
    });
    socket.on('Rate_card_Movers_To_Unload_One_Day', (data) => {
      setRate_card_Movers_To_Unload_One_Day(data)
    });
    socket.on('Trucks_One_Day', (data) => {
      setTrucks_One_Day(data)
    });
    socket.on('TWO_Trips_Or_OK', (data) => {
      setTWO_Trips_Or_OK(data)
      dispatch(setTWOTripsOrOK(data));
    });
    socket.on('BIGGEST_or_OK', (data) => {
      setBIGGEST_or_OK(data)
    });
    socket.on('Double_drive', (data) => {
      setDouble_drive(data)
    });
    socket.on('Lower_Number_of_Hours_One_Day', (data) => {
      setLower_Number_of_Hours_One_Day(data)
    });
    socket.on('Higher_Number_of_Hours_One_Day', (data) => {
      setHigher_Number_of_Hours_One_Day(data)
    });
    socket.on('Heavy_fee', (data) => {
      setHeavy_fee(data)
    });
    socket.on('Packing_kit_Show', (data) => {
      setPacking_kit_Show(data)
    });
    socket.on('Packing_kit_total_amount_with_taxes', (data) => {
      setPacking_kit_total_amount_with_taxes(data)
    });
    socket.on('Total_Card_One_day', (data) => {
      setTotal_Card_One_day(data)
    });
    socket.on('Total_Cash_One_day', (data) => {
      setTotal_Cash_One_day(data)
    });
    socket.on('Total_cubes_Within_Building', (data) => {
      setTotal_cubes_Within_Building(data);
    })
    socket.on('Truckloads_to_move', (data) => {
      setTruckloads_to_move(data);
    })

    socket.on('Flat_rate_One_Day', (data) => setFlat_rate_One_Day(data));
    socket.on('Flat_rate_One_Day_cash', (data) => setFlat_rate_One_Day_cash(data));
    socket.on('Packing_Charge', (data) => setPacking_Charge(data));
    socket.on('UnPacking_Charge', (data) => setUnPacking_Charge(data));

    socket.on('Combined_mileage', (data) => { setCombined_mileage(data) });
    socket.on('Round_trip_miles', (data) => { setRound_trip_miles(data) });
    socket.on('Trucks_Unpacking_Separate_Day', (data) => setTrucks_Unpacking_Separate_Day(data));
    socket.on('Movers_Unpacking_Separate_Day', (data) => setMovers_Unpacking_Separate_Day(data));
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => setUnpacking_Separate_Day_Lower_hours(data));
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => setUnpacking_Separate_Day_Higher_hours(data));
    socket.on('Total_Card_Unpacking_Separate_Day', (data) => setTotal_Card_Unpacking_Separate_Day(data));
    socket.on('Total_Cash_Unpacking_Separate_Day', (data) => setTotal_Cash_Unpacking_Separate_Day(data));
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => setRate_cash_Unpacking_Separate_Day(data));
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => setRate_card_Unpacking_Separate_Day(data));
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => setTruck_fee_Unpacking_Separate_Day(data));
    socket.on('Flat_rate_Unpacking_Separate_Day', (data) => setFlat_rate_Unpacking_Separate_Day(data));
    socket.on('Flat_rate_Unpacking_Separate_Day_cash', (data) => setFlat_rate_Unpacking_Separate_Day_cash(data));



    socket.on('Time_to_pack', (data) => setTime_to_pack(data));
  }, [commonValues, oneDayPacking, department])
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  useEffect(() => {
    if (editorState) {
      let templateText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      let tagValues = getTagsValues({
        calculation: calculationData, tags,
        calculatedParams: {
          Total_cubes: Total_cubes,
          Total_small_boxes: Total_small_boxes,
          Total_medium_boxes: Total_medium_boxes,
          Total_fragile_boxes: Total_fragile_boxes,
          Total_box_cubes: Total_box_cubes,
          Stop_1_Truckload: Stop_1_Truckload,
          Stop_CN_Truckload: Stop_CN_Truckload,
          Charge_per_mile_amount: Charge_per_mile_amount,
          Movers_to_load_One_Day_hours: Movers_to_load_One_Day_hours,
          Loading_hours_One_Day: Loading_hours_One_Day,
          Adjusted_loading_time_One_Day: Adjusted_loading_time_One_Day,
          Drivetime_One_Day: Drivetime_One_Day,
          Movers_to_unload_One_Day_hours: Movers_to_unload_One_Day_hours,
          Unloading_hours_One_Day: Unloading_hours_One_Day,
          Total_Hours_One_Day: Total_Hours_One_Day,
          Truckloads: Truckloads,
          Truckloads_to_move: Truckloads_to_move,
          Total_cubes_Within_Building: Total_cubes_Within_Building,
          Total_Destinationfee_One_Day: Total_Destinationfee_One_Day,
          Rate_cash_Movers_To_Load_One_Day: Rate_cash_Movers_To_Load_One_Day,
          Rate_card_Movers_To_Load_One_Day: Rate_card_Movers_To_Load_One_Day,
          Truck_fee_One_Day: Truck_fee_One_Day,
          Movers_One_Day: Movers_One_Day,
          Rate_cash_One_Day: Rate_cash_One_Day,
          Rate_card_One_Day: Rate_card_One_Day,
          Rate_cash_Movers_To_Unload_One_Day: Rate_cash_Movers_To_Unload_One_Day,
          Rate_card_Movers_To_Unload_One_Day: Rate_card_Movers_To_Unload_One_Day,
          Trucks_One_Day: Trucks_One_Day,
          TWO_Trips_Or_OK: TWO_Trips_Or_OK,
          BIGGEST_or_OK: BIGGEST_or_OK,
          Double_drive: Double_drive,
          Lower_Number_of_Hours_One_Day: Lower_Number_of_Hours_One_Day,
          Higher_Number_of_Hours_One_Day: Higher_Number_of_Hours_One_Day,
          Heavy_fee: Heavy_fee,
          Packing_kit_Show: Packing_kit_Show,
          Packing_kit_total_amount_with_taxes: Packing_kit_total_amount_with_taxes,
          Total_Card_One_day: Total_Card_One_day,
          Total_Cash_One_day: Total_Cash_One_day,
          Combined_mileage: Combined_mileage,
          Round_trip_miles: Round_trip_miles,
          Trucks_Unpacking_Separate_Day: Trucks_Unpacking_Separate_Day,
          Movers_Unpacking_Separate_Day: Movers_Unpacking_Separate_Day,
          Unpacking_Separate_Day_Lower_hours: Unpacking_Separate_Day_Lower_hours,
          Unpacking_Separate_Day_Higher_hours: Unpacking_Separate_Day_Higher_hours,
          Total_Card_Unpacking_Separate_Day: Total_Card_Unpacking_Separate_Day,
          Total_Cash_Unpacking_Separate_Day: Total_Cash_Unpacking_Separate_Day,
          Rate_cash_Unpacking_Separate_Day: Rate_cash_Unpacking_Separate_Day,
          Rate_card_Unpacking_Separate_Day: Rate_card_Unpacking_Separate_Day,
          Truck_fee_Unpacking_Separate_Day: Truck_fee_Unpacking_Separate_Day,
          Flat_rate_Unpacking_Separate_Day: Flat_rate_Unpacking_Separate_Day,
          Flat_rate_Unpacking_Separate_Day_cash: Flat_rate_Unpacking_Separate_Day_cash,
          Flat_rate_One_Day: Flat_rate_One_Day,
          Flat_rate_One_Day_cash: Flat_rate_One_Day_cash,
          Packing_Charge: Packing_Charge,
          UnPacking_Charge: UnPacking_Charge,
        }, furniture, roomsAreas
      })
      const tagValuesCopy = JSON.parse(JSON.stringify(tagValues));
      if (typeof setTagsValuesDefault === 'function') {
        setTagsValuesDefault(prevState => ({
          ...prevState,
          [calculationData.calculationType]: tagValuesCopy
        }));
      }
      if (customTagsValues && Object.keys(customTagsValues).length > 0) {
        for (const key in customTagsValues) {
          if (`|{${key}}` in tagValues && tagValues[`|{${key}}`] !== '') {
            tagValues[`|{${key}}`] = customTagsValues[key];
          }
        }
      }
      setPreviewText(replaceTagsByValues(templateText, tagValues))
      setOptionsPreviewText(replaceOptionsTagsByValues(templateText, tagValues))
    }
  }, [popUpShown, tags, showCustomTags]);




  const handleResCopy = (evt) => {
    evt.preventDefault();
    var truckloads = (Number(Total_cubes_Within_Building) > 0) ? Truckloads_to_move : Truckloads;
    let chargesText = getChargesResText(department, oneDayPacking.selectedChargesNamesDay1, Trucks_One_Day);
    let additionalServicesText = getAdditionalServicesResText(department, commonValues.selectedAdditionalServices, Trucks_One_Day, commonValues.additionalServicesPlaceType, Total_cubes);
    let proceduresText = '';

    let procedureAdded = false;
    commonValues.procedures.forEach((procedure, pkIndex) => {
      if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
        if (!procedureAdded) {
          proceduresText += 'Procedures:\n';
          procedureAdded = true;
        }
        proceduresText += '• ' + procedure.procedureType + ' x ' + procedure.numberOfProcedures + ':';
        if (Object.keys(department).length !== 0) {
          department.packing.cratings.forEach((crating, index) => {
            if (procedure.procedureType === crating[`crateName${index}`]) {
              proceduresText += ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
            }
          });
        }
        proceduresText += '\n';
      }
    });

    let unpackingText = generateUnpackingText(commonValues, department, isCuFtDepartment, Movers_Unpacking_Separate_Day, Rate_cash_Unpacking_Separate_Day, Rate_card_Unpacking_Separate_Day, Truck_fee_Unpacking_Separate_Day, Trucks_Unpacking_Separate_Day, Unpacking_Separate_Day_Lower_hours, Unpacking_Separate_Day_Higher_hours, UnPacking_Charge, Total_Cash_Unpacking_Separate_Day, Total_Card_Unpacking_Separate_Day, oneDayPacking, Flat_rate_Unpacking_Separate_Day_cash, Flat_rate_Unpacking_Separate_Day)


    let notIncludedServicesText = generateNotIncludedServicesText(department, Total_cubes, commonValues)

    let suppliesPrices = `• Small box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.smallBox : ''}
  • Medium box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.mediumBox : ''}
  • Large box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.largeBox : ''}
  • Roll of packing paper: ${(Object.keys(department).length !== 0) ? department.packing.supplies.packingPapper : ''}
  • Roll of bubble wrap: ${(Object.keys(department).length !== 0) ? department.packing.supplies.bubbleWrap : ''}
         `;



    const invoiceText = `${(getFurnitureText(calculationData, furniture, roomsAreas, 'furnitureGoes').inventoryText) ? `INVENTORY` : ''}
Total Cubic ft: ${parseFloat(Total_cubes).toFixed(2)} cu ft.
${(Total_cubes_Within_Building > 0) ? `Cubic ft within building: ${parseFloat(Total_cubes_Within_Building).toFixed(2)} cu ft.
Cubic ft to move: ${parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)} cu ft.` : ''}
Total Truckloads: ${truckloads}
${(truckloads > 1 && (Math.floor(Number(truckloads))) - Number(truckloads) < 0) ? Math.floor(truckloads) + ' truck(s) + ' + Math.floor(Number((Number(truckloads) - Math.floor(truckloads)).toFixed(2) * 100)) + "%" : ''}

${(isCuFtDepartment) ? `Total round trip miles: ${parseFloat(Round_trip_miles).toFixed(1)} ${(commonValues.unpacking === 'Separate Day') ? `
Unpacking day Round trip miles: ${parseFloat(commonValues.milesToParkingCuFt * 2).toFixed(1)}` : ''}` : `Total miles:${parseFloat(Combined_mileage).toFixed(1)}`}
${(commonValues.extraStops.filter((extraStop, index) => extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0).length > 0) ? `There will be ${commonValues.extraStops.filter((extraStop, index) => extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0).length} additional stops` : ''}



${(Number(oneDayPacking?.moversToLoad) > 0) ?
        `Movers to Load:${Number(oneDayPacking.moversToLoad)}` +
        ((!isCuFtDepartment) ? `Rate - load: ${getFormattedRateLoadInfo(department, Total_Destinationfee_One_Day, Rate_cash_Movers_To_Load_One_Day, Rate_card_Movers_To_Load_One_Day, Truck_fee_One_Day).formattedRateTextOnly}` : '').toString()
        :
        `Movers: ${Movers_One_Day}
${(!isCuFtDepartment) ? `Rate: ${getFormattedRateInfo(department, Total_Destinationfee_One_Day, Rate_cash_One_Day, Rate_card_One_Day, Truck_fee_One_Day).formattedRateTextOnly}` : ''}
${(isCuFtDepartment && Truck_fee_One_Day) ? `Truck fee: $${Truck_fee_One_Day}` : ''}`}
${(Number(oneDayPacking?.moversToDrivesAndUnload) > 0) ? `Movers to Unload: ${Number(oneDayPacking.moversToDrivesAndUnload)}
${(!isCuFtDepartment) ? ` Rate - unload: ${(department.rates && department.rates.calcMethod === 'deduct')
          ? '$' + Rate_cash_Movers_To_Unload_One_Day + '/$' + Rate_card_Movers_To_Unload_One_Day
          : '$' + Rate_card_Movers_To_Unload_One_Day}/h` : ''}` : ''}
Trucks: ${Trucks_One_Day}${commonValues?.shuttle ? "+ SHUTTLE " + ((department.extraOptions?.shuttle.isCountable) ? `(x${oneDayPacking.quantityDay1})` : '') : ''}
${(TWO_Trips_Or_OK !== "2 TRIPS") ? ((BIGGEST_or_OK !== 'OK') ? `${BIGGEST_or_OK}` : "") : ''}${(TWO_Trips_Or_OK !== 'OK') ? `${TWO_Trips_Or_OK}` : ""}
${(!isCuFtDepartment) ? `Double Drive:${Double_drive}` : ''}

${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && oneDayPacking.selectedChargesNamesDay1.length > 0) ? `
Extra options:` : ''}
${chargesText}
${additionalServicesText}

Total Time Range: ${getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_One_Day, Higher_Number_of_Hours_One_Day)}
${proceduresText}
${(Total_Destinationfee_One_Day > 0) ?
        `Destination fee: $${(department.truckFee.tfdfCombined) ?
          Total_Destinationfee_One_Day + Truck_fee_One_Day
          : Total_Destinationfee_One_Day
        }`
        : ""
      }

${(Heavy_fee !== 0) ?
        `Extra Heavy Items Charge: $${Heavy_fee}`
        : ""
      }

${commonValues.liftGate ? ' | LIFTGATE' : ''}
${commonValues.hardFloorCovers ? ' | HARDWOOD FLOOR COVERS' : ''}
${commonValues.filmFloorCovers ? ' | CARPET FLOOR COVERS' : ''}${selectedMiscCheckMarks.map(checkMark => ` | ${getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks).toUpperCase()}`).join('')}


${(commonValues.packing !== "No" && !isCuFtDepartment) ?
        (commonValues.packingKitOverride > 0) ? `Packing Kit:${'$' + Packing_kit_Show} {commonValues.packing} [$${Packing_kit_total_amount_with_taxes} with tax]` :
          `Packing Kit:${'$' + Packing_kit_Show} ${(commonValues.packing === "Yes") ? "Full" : commonValues.packing} [$${Packing_kit_total_amount_with_taxes} with tax]` : ""
      }
${(commonValues.unpacking === 'Same Day') ? `UNPACKING ${(commonValues.partialUnPacking) ? "PARTIAL" : ''}` : ''}


${(isCuFtDepartment && Packing_Charge) ? `Packing Charge:${Packing_Charge}` : ''}

${((isCuFtDepartment && commonValues.unpacking === 'Same Day') && Packing_Charge) ? `UnPacking Charge: ${UnPacking_Charge}` : ''}

${(commonValues.packing !== "No" && (isCuFtDepartment ? department.packing.packingKits.enabled : true)) ?
        `Packing Supplies On The Go:` +
        `${department.packing.supplies.calculationMethod === 'suppliesPrices' ? suppliesPrices : ''}
  ${(!isCuFtDepartment) ? suppliesPrices : ''}
${department.packing.supplies.calculationMethod === 'perBox' ?
          `Per 1 packed box price: $${department.packing.supplies.perOnePackedBox}`
          : ''}`
        : ''
      }
${(isCuFtDepartment) ? notIncludedServicesText : ''}  
${(oneDayPacking.additionalAmount) ? (
        "Additional amount: $" + oneDayPacking.additionalAmount
      ) : ''
      }

${(isCuFtDepartment) ? `FLAT RATE: $${(department.rates && department.rates.calcMethod === 'deduct') ?
        Flat_rate_One_Day_cash + ' cash / $' + Flat_rate_One_Day + " card" : Flat_rate_One_Day}
${(department.rates && department.rates.calcMethod !== 'deduct') ? `FLAT RATE with ${getCurrentRatePercent(oneDayPacking, department)}% cash discount: $${Flat_rate_One_Day_cash}` : ""}` : ''
      }

${(!isCuFtDepartment) ? `NOT TO EXCEED PRICE: $${(department.rates && department.rates.calcMethod === 'deduct') ?
        Total_Cash_One_day + ' cash / $' + Total_Card_One_day + " card"
        : Total_Card_One_day}
${(department.rates && department.rates.calcMethod !== 'deduct') ? `NOT TO EXCEED PRICE with ${getCurrentRatePercent(oneDayPacking, department)}% cash discount: $${Total_Cash_One_day}`
          : ""}
      ` : ''
      }

${unpackingText}
  `;
    const textArea = document.createElement("textarea");
    textArea.value = invoiceText.replace(/\n{2,}/g, '\n\n') + '\n';;

    // Append the textarea to the document
    document.body.appendChild(textArea);

    // Select and copy the text
    textArea.select();
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textArea);

  }

  if (typeof setShowGenerateEmail === 'function') {
    if (department && Object.keys(department).length > 0 && oneDayPacking.rate && Trucks_One_Day > 0 && Movers_One_Day > 0) {
      setShowGenerateEmail(true);
    } else {
      setShowGenerateEmail(false);
    }
  }


  return (
    <ul className={styles.paramsSection}>

      <button {...getToggleProps()} className={styles.btnSubmit}>
        <i className={isExpanded ? styles.up : styles.down}></i>
      </button>
      <section {...getCollapseProps()}>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Small boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_small_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Medium boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_medium_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Fragile boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_fragile_boxes}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Boxes cubic ft:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Total_box_cubes).toFixed(2) + " cu ft."}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Time to pack for 1 mover:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Time_to_pack).toFixed(2) + " h"}
          </p>
        </li>
        <li className={styles.paramsItem} >
          <p className={styles.paramsName}>Truckloads from Stop 1:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_1_Truckload.toFixed(3))}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>
            Truckloads from Additional stops:
          </p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_CN_Truckload).toFixed(3)}
          </p>
        </li>



        {(Charge_per_mile_amount !== 0) ?
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Mileage charge:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {'$' + Charge_per_mile_amount}
            </p>
          </li>
          : ""}


        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Loading hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {(Number(oneDayPacking?.moversToLoad) > 0)
              ? parseFloat(Movers_to_load_One_Day_hours).toFixed(3)
              : parseFloat(Loading_hours_One_Day).toFixed(3)}
            {' h'}
          </p>
        </li>
        {(Number(oneDayPacking?.moversToLoad) > 0) ?
          <div style={{ paddingLeft: '30px' }}>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Adjusted Loading hours:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Adjusted_loading_time_One_Day).toFixed(3)} {' h'}
              </p>
            </li>
          </div>
          :
          ""
        }

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Drive Time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Drivetime_One_Day).toFixed(2)} {' h'}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Unloading hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {(Number(oneDayPacking?.moversToLoad) > 0) ? parseFloat(Movers_to_unload_One_Day_hours).toFixed(3)
              : parseFloat(Unloading_hours_One_Day).toFixed(3)} {' h'}
          </p>
        </li>


        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_Hours_One_Day + " hours"}

          </p>
        </li>
      </section>

      {(department && Object.keys(department).length > 0 && oneDayPacking.rate && Trucks_One_Day > 0 && Movers_One_Day > 0) ?
        <div >
          <h2 className={styles.title}><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Results  <CopyIcon onClick={handleResCopy} style={{ marginLeft: '5px' }} /></div>  {(Total_box_cubes > 0 && commonValues.packing === 'No') ?
            <>
              <span style={{ color: 'red' }}> - No Packing
              </span>
              <label className={styles.commonLabeling} style={{ marginLeft: '5px' }}>
                Confirm
                <input
                  className={styles.mycheckbox}
                  type='checkbox'
                  name={'isNoPackingConfirmed'}
                  checked={commonValues.isNoPackingConfirmed}
                  value={commonValues.isNoPackingConfirmed}
                  onChange={checkboxValueChange}
                />
              </label>
            </> : ''}
          </h2>

          <div>
            {(getFurnitureText(calculationData, furniture, roomsAreas, 'furnitureGoes').inventoryText) &&
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>
                  <span className={`${(highLightItem === 'inventoryText') ? styles.highLighted : ""}`} ref={scrollRefs?.current['inventoryText']}>
                    INVENTORY
                  </span>
                </p>
              </li>
            }
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Total Cubic ft:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'totalCubicFt') ? styles.highLighted : ""}`} ref={scrollRefs?.current['totalCubicFt']}>{parseFloat(Total_cubes).toFixed(2)}</span> cu ft.
              </p>
            </li>

            {(Total_cubes_Within_Building > 0) ? <>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Cubic ft within building:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === 'cuftWithinBuilding') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftWithinBuilding']}>{parseFloat(Total_cubes_Within_Building).toFixed(2)}</span> cu ft.
                </p>
              </li>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Cubic ft to move:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === 'cuftToMove') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftToMove']}></span>{parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)} cu ft.
                </p>
              </li>
            </> : ''}
            <TruckloadsView
              Truckloads={Truckloads} Truckloads_to_move={Truckloads_to_move} Total_cubes_Within_Building={Total_cubes_Within_Building}
              scrollRefs={scrollRefs}
              highLightItem={highLightItem}
            />
            {(isCuFtDepartment) ?
              <>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Total round trip miles:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}><span className={`${(highLightItem === 'totalRoundTripMiles') ? styles.highLighted : ""}`} ref={scrollRefs?.current['totalRoundTripMiles']}>{parseFloat(Round_trip_miles).toFixed(1)}</span></p>
                </li>
                {commonValues.unpacking === 'Separate Day' &&
                  <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>Unpacking day Round trip miles:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}><span className={`${(highLightItem === 'unpackingDayRoundTripMiles') ? styles.highLighted : ""}`} ref={scrollRefs?.current['unpackingDayRoundTripMiles']}>{parseFloat(commonValues.milesToParkingCuFt * 2).toFixed(1)}</span></p>
                  </li>
                }
              </>
              :
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Total miles:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}><span className={`${(highLightItem === 'combinedMileage') ? styles.highLighted : ""}`} ref={scrollRefs?.current['combinedMileage']}>{parseFloat(Combined_mileage).toFixed(1)}</span></p>
              </li>
            }

            {
              (commonValues.extraStops.filter((extraStop, index) =>
                extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
              ).length > 0) ?
                <li className={styles.paramsItem}><div>
                  There will be <span className={`${(highLightItem === 'additionalStopsCount') ? styles.highLighted : ""}`} ref={scrollRefs?.current['additionalStopsCount']}>{commonValues.extraStops.filter((extraStop, index) =>
                    extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
                  ).length}</span> additional stops</div>
                </li>
                : ''
            }
            {(Number(oneDayPacking?.moversToLoad) > 0)
              ?
              <>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Movers to Load:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === 'moversToLoad') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToLoad']}>{Number(oneDayPacking.moversToLoad)}</span>
                  </p>
                </li>
                {(!isCuFtDepartment) ?
                  <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>Rate - load</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}>
                      {getFormattedRateLoadInfo(department, Total_Destinationfee_One_Day, Rate_cash_Movers_To_Load_One_Day, Rate_card_Movers_To_Load_One_Day, Truck_fee_One_Day, highLightItem, scrollRefs, styles).formattedRate}
                    </p>
                  </li>
                  : ''}
              </>
              :
              <>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Movers:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === 'movers') ? styles.highLighted : ""}`} ref={scrollRefs?.current['movers']}>{Movers_One_Day}</span>
                  </p>
                </li>
                {!isCuFtDepartment &&
                  <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>Rate:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}>
                      {getFormattedRateInfo(department, Total_Destinationfee_One_Day, Rate_cash_One_Day, Rate_card_One_Day, Truck_fee_One_Day, highLightItem, scrollRefs, styles).formattedRate}
                    </p>
                  </li>
                }
                {(isCuFtDepartment && Truck_fee_One_Day) ?
                  <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>Truck fee:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}>
                      <span className={`${(highLightItem === 'truckfee') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckfee']}>{Truck_fee_One_Day}</span>
                    </p>
                  </li>
                  : ''
                }
              </>
            }
            {
              (Number(oneDayPacking?.moversToDrivesAndUnload) > 0)
                ?
                <>
                  <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>Movers to Unload:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}>
                      <span className={`${(highLightItem === 'moversToDrivesAndUnload') ? styles.highLighted : ""}`} ref={scrollRefs?.current['moversToDrivesAndUnload']}>{Number(oneDayPacking.moversToDrivesAndUnload)}</span>
                    </p>
                  </li>
                  {(!isCuFtDepartment) ?
                    <li className={styles.paramsItem}>
                      <p className={styles.paramsName}>Rate - unload:</p>
                      <span className={styles.paramsDecor}></span>
                      <p className={styles.paramsValue}>
                        {(department.rates && department.rates.calcMethod === 'deduct')
                          ? <><span className={`${(highLightItem === 'rateCashUnload') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCashUnload']}>${Rate_cash_Movers_To_Unload_One_Day}</span>/<span className={`${(highLightItem === 'rateCardUnload') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardUnload']}>${Rate_card_Movers_To_Unload_One_Day}</span></>
                          : <span className={`${(highLightItem === 'rateCardUnload') ? styles.highLighted : ""}`} ref={scrollRefs?.current['rateCardUnload']}>${Rate_card_Movers_To_Unload_One_Day}</span>
                        }/h
                      </p>
                    </li>
                    : ''}
                </>
                : ""
            }
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Trucks:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === 'trucks') ? styles.highLighted : ""}`} ref={scrollRefs?.current['trucks']}>{Trucks_One_Day}</span>
                {commonValues?.shuttle && <>  <span className={`${(highLightItem === 'shuttle') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttle']}>+ SHUTTLE</span> {((department.extraOptions?.shuttle.isCountable) ? <span className={`${(highLightItem === 'shuttleQty') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttleQty']}>(x{oneDayPacking.quantityDay1})</span> : '')}</>}
              </p>
            </li>
            {(TWO_Trips_Or_OK !== "2 TRIPS") ?
              (BIGGEST_or_OK !== 'OK') ?
                <li className={styles.paramsItem}>
                  <span className={`${(highLightItem === 'biggestOrOk') ? styles.highLighted : ""}`} ref={scrollRefs?.current['biggestOrOk']}>{BIGGEST_or_OK}</span>
                </li>
                : ""
              : ''}
            {(TWO_Trips_Or_OK !== 'OK' && !(commonValues.baseLoadingHours == 0 || commonValues.baseUnloadingHours == 0)) ?
              <li className={styles.paramsItem}>
                <span className={`${(highLightItem === 'twoTrips') ? styles.highLighted : ""}`} ref={scrollRefs?.current['twoTrips']}>{TWO_Trips_Or_OK}</span>
              </li>
              : ""
            }
            {!isCuFtDepartment &&
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Double Drive:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === 'cuftToMove') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftToMove']}>{Double_drive}</span>
                </p>
              </li>
            }

            <ExtraOptionsResults
              department={department}
              selectedChargesNames={oneDayPacking.selectedChargesNamesDay1}
              trucksCount={Trucks_One_Day}
              scrollRefs={scrollRefs}
              highLightItem={highLightItem}
            />

            {isCuFtDepartment &&
              <AdditionalServicesResults
                selectedAdditionalServices={commonValues.selectedAdditionalServices}
                department={department}
                additionalServicesPlaceType={commonValues.additionalServicesPlaceType}
                Total_cubes={Total_cubes}
                trucksCount={Trucks_One_Day}
                scrollRefs={scrollRefs}
                highLightItem={highLightItem}
              />
            }

            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Total Time Range:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <><span className={`${(highLightItem === 'lowerNumberOfHours') ? styles.highLighted : ""}`} ref={scrollRefs?.current['lowerNumberOfHours']}>{getLowerNumberOfHours((department) ? department.minimumHours : 0, Lower_Number_of_Hours_One_Day)}</span> <span className={`${(highLightItem === 'higherNumberOfHours') ? styles.highLighted : ""}`} ref={scrollRefs?.current['higherNumberOfHours']}>{getHigherNumberOfHours((department) ? department.minimumHours : 0, Higher_Number_of_Hours_One_Day)}</span> hours</>
              </p>
            </li>

            {(Total_Destinationfee_One_Day > 0) ?
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Destination fee:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>$
                  <span className={`${(highLightItem === 'destinationFee') ? styles.highLighted : ""}`} ref={scrollRefs?.current['destinationFee']}>{(department.truckFee.tfdfCombined) ?
                    Total_Destinationfee_One_Day + Truck_fee_One_Day
                    : Total_Destinationfee_One_Day
                  }</span>
                </p>
              </li>
              : ""}

            {(Heavy_fee !== 0) ?
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Extra Heavy Items Charge:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === 'heavyFee') ? styles.highLighted : ""}`} ref={scrollRefs?.current['heavyFee']}>{'$' + Heavy_fee}</span>
                </p>
              </li>
              : ""}

            {(commonValues.procedures.length > 0 && commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '')) ?
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Procedures:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>

                </p>
                <ul>
                  {
                    commonValues.procedures.map((procedure, pkIndex) => {
                      if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
                        return <li key={pkIndex}><span className={`${(highLightItem === `pIdName_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`pIdName_${procedure.uid}`]}>{procedure.procedureType}</span> x <span className={`${(highLightItem === `pIdQty_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current['destinationFee']}>{procedure.numberOfProcedures}</span>:{
                          (Object.keys(department).length !== 0) && department.packing.cratings.map((crating, index) => {
                            if (procedure.procedureType === crating[`crateName${index}`]) {
                              return <span className={`${(highLightItem === `pIdCost_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`pIdCost_${procedure.uid}`]}> ${(Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]))}</span>;
                            }
                            else {
                              return ''
                            }
                          })
                        }</li>
                      }
                      else { return '' }
                    })
                  }
                </ul>
              </li>
              : ''}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'liftGate') ? styles.highLighted : ""}`} ref={scrollRefs?.current['liftGate']}>{commonValues.liftGate && ' | LIFTGATE'}</span></span>
              <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'hardwoodFloorCovers') ? styles.highLighted : ""}`} ref={scrollRefs?.current['hardwoodFloorCovers']}>{commonValues.hardFloorCovers && " | HARDWOOD FLOOR COVERS"}</span></span>
              <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'carpetFloorCovers') ? styles.highLighted : ""}`} ref={scrollRefs?.current['carpetFloorCovers']}>{commonValues.filmFloorCovers && " | CARPET FLOOR COVERS "}</span></span>
              {selectedMiscCheckMarks.map((checkMark, chmpkIndex) => <span key={chmpkIndex} style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
                {' '} |  <span className={`${(highLightItem === `mChId_${checkMark}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`mChId_${checkMark.uId}`]}>{getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks)}</span>
              </span>)}
            </div>

            {(commonValues.packing !== "No" && !isCuFtDepartment) ?
              (commonValues.packingKitOverride > 0) ?
                <li className={styles.paramsItem} style={{ color: "red" }}>
                  <p className={styles.paramsName}>Packing Kit:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === 'packingKit') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKit']}>{'$' + Packing_kit_Show} {commonValues.packing}</span>  <span className={`${(highLightItem === 'packingKitWithTax') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKitWithTax']}>[${Packing_kit_total_amount_with_taxes}</span> with tax]
                  </p>
                </li>
                :
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Packing Kit:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === 'packingKit') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKit']}>{'$' + Packing_kit_Show}</span>  {(commonValues.packing === "Yes") ? "Full" : commonValues.packing} <span className={`${(highLightItem === 'packingKitWithTax') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKitWithTax']}>[${Packing_kit_total_amount_with_taxes}</span> with tax]
                  </p>
                </li>
              : ""}

            {(commonValues.unpacking === 'Same Day') &&
              <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
                <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'cuftToMove') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftToMove']}>UNPACKING</span>  <span className={`${(highLightItem === 'cuftToMove') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftToMove']}>{commonValues.partialUnPacking && "PARTIAL"}</span></span>
              </div>
            }
            {(isCuFtDepartment && Packing_Charge) ?
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Packing Charge:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === 'packingCharge') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingCharge']}>${Packing_Charge}</span>
                </p>
              </li>
              : ''
            }

            {((isCuFtDepartment && commonValues.unpacking === 'Same Day') && Packing_Charge) ?
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>UnPacking Charge:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === 'unPackingCharge') ? styles.highLighted : ""}`} ref={scrollRefs?.current['unPackingCharge']}>${UnPacking_Charge}</span>
                </p>
              </li>
              : ''
            }


            <PackingSuppliesOnTheGo
              department={department}
              highLightItem={highLightItem}
              scrollRefs={scrollRefs}
              commonValues={commonValues}
              isCuFtDepartment={isCuFtDepartment}
            />

            {(isCuFtDepartment) ? <AdditionalServicesEstimateInfo
              commonValues={commonValues}
              department={department}
              totalCubes={Total_cubes}
              scrollRefs={scrollRefs}
              highLightItem={highLightItem}
            /> : ''}

            {(oneDayPacking.additionalAmount) ? (
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Additional amount:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === 'additionalAmount') ? styles.highLighted : ""}`} ref={scrollRefs?.current['additionalAmount']}>${oneDayPacking.additionalAmount}</span>
                </p>
              </li>) : ''
            }

            {isCuFtDepartment && <>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>FLAT RATE:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>$
                  {(department.rates && department.rates.calcMethod === 'deduct') ?
                    <><span className={`${(highLightItem === 'flatRateOneDayCash') ? styles.highLighted : ""}`} ref={scrollRefs?.current['flatRateOneDayCash']}>{Flat_rate_One_Day_cash}</span> cash / <span className={`${(highLightItem === 'flatRateOneDayCard') ? styles.highLighted : ""}`} ref={scrollRefs?.current['flatRateOneDayCard']}>${Flat_rate_One_Day}</span>  card</>
                    :
                    <>
                      <span className={`${(highLightItem === 'flatRateOneDayCard') ? styles.highLighted : ""}`} ref={scrollRefs?.current['flatRateOneDayCard']}>{Flat_rate_One_Day}</span>
                    </>}
                </p>
              </li>
              {(department.rates && department.rates.calcMethod !== 'deduct') ?
                <div style={{ paddingLeft: '30px' }}>
                  <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>FLAT RATE with  <span className={`${(highLightItem === 'cashDiscountPercent') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cashDiscountPercent']}>{getCurrentRatePercent(oneDayPacking, department)}%</span> cash discount:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}>
                      <span className={`${(highLightItem === 'cuftToMove') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftToMove']}>${Flat_rate_One_Day_cash}</span>
                    </p>
                  </li>
                </div>
                : ""}
            </>}


            {(!isCuFtDepartment) ? <>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>NOT TO EXCEED PRICE:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  {(department.rates && department.rates.calcMethod === 'deduct') ?
                    <> <span className={`${(highLightItem === 'cashDiscount') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cashDiscount']}>${Total_Cash_One_day}</span> cash /  <span className={`${(highLightItem === 'notToExceedPrice') ? styles.highLighted : ""}`} ref={scrollRefs?.current['notToExceedPrice']}>${Total_Card_One_day}</span> card</>
                    :
                    <>
                      <span className={`${(highLightItem === 'cuftToMove') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftToMove']}>${Total_Card_One_day}</span>
                    </>}
                </p>
              </li>
              {(department.rates && department.rates.calcMethod !== 'deduct') ?
                <div style={{ paddingLeft: '30px' }}>
                  <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>NOT TO EXCEED PRICE with <span className={`${(highLightItem === 'cashDiscountPercent') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cashDiscountPercent']}>{getCurrentRatePercent(oneDayPacking, department)}%</span> cash discount:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}>
                      <span className={`${(highLightItem === 'cashDiscount') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cashDiscount']}>${Total_Cash_One_day}</span>
                    </p>
                  </li>
                </div>
                : ""}
            </> : ''}

            <CalculationSeparateDayView
              commonValues={commonValues}
              anyCalculationType={oneDayPacking}
              department={department}
              Trucks_Unpacking_Separate_Day={Trucks_Unpacking_Separate_Day}
              Movers_Unpacking_Separate_Day={Movers_Unpacking_Separate_Day}
              Unpacking_Separate_Day_Lower_hours={Unpacking_Separate_Day_Lower_hours}
              Unpacking_Separate_Day_Higher_hours={Unpacking_Separate_Day_Higher_hours}
              Total_Card_Unpacking_Separate_Day={Total_Card_Unpacking_Separate_Day}
              Total_Cash_Unpacking_Separate_Day={Total_Cash_Unpacking_Separate_Day}
              Rate_cash_Unpacking_Separate_Day={Rate_cash_Unpacking_Separate_Day}
              Rate_card_Unpacking_Separate_Day={Rate_card_Unpacking_Separate_Day}
              Truck_fee_Unpacking_Separate_Day={Truck_fee_Unpacking_Separate_Day}
              Flat_rate_Unpacking_Separate_Day={Flat_rate_Unpacking_Separate_Day}
              Flat_rate_Unpacking_Separate_Day_cash={Flat_rate_Unpacking_Separate_Day_cash}
              isCuFtDepartment={isCuFtDepartment}
              UnPacking_Charge={UnPacking_Charge}
              highLightItem={highLightItem}
              scrollRefs={scrollRefs}
            />
          </div>
        </div>
        : ""}
    </ul >
  );
};
